import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  OnDestroy,
} from '@angular/core';
import { ISectorOverviewDataResponse } from '../../../interfaces';
import { autoDestroy, formatPercentage } from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { TreeMapModule } from '@swimlane/ngx-charts';

import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-sector-breakdown',
  templateUrl: './sector-breakdown.component.html',
  styleUrls: ['./sector-breakdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SectionComponent, TreeMapModule],
})
export class SectorBreakdownComponent implements OnInit, OnDestroy {
  singleBreakdown: any[] = [];
  @Input()
  set sector(sector: ISectorOverviewDataResponse[] | undefined) {
    if (sector) {
      this.singleBreakdown = [];
      sector.forEach((s, i) => {
        this.singleBreakdown.push({
          name: s.sectorName,
          value: s.reitsGrossAmountSum,
          extra: {
            percentage: formatPercentage(s.grossAmountSumPercentage, 0),
            idx: i,
            display: this.convertToInternationalCurrencySystem(
              s.reitsGrossAmountSum,
            ),
          },
        });
      });
    }
  }

  customColors = (name: any) => {
    const value = this.singleBreakdown.find((s) => s.name === name).extra.idx;
    return this.colorScheme.domain[value];
  };

  onSelect(event: any) {}
  // options
  tooltipDisabled: boolean = false;
  gradient: boolean = false;
  animations: boolean = true;

  colorScheme: any = {
    domain: colorPallete[GraphId.SectorBreakdown],
  };

  constructor() {}

  style!: HTMLStyleElement;

  ngOnInit() {
    if (navigator.appVersion.indexOf('Chrome/') != -1) {
      this.style = document.createElement('style');
      this.style.innerHTML = `.treemap-label {
        padding-left: 12px;
        padding-top: var(--size--spacing-x1);
        padding-bottom: var(--size--spacing-x6);
      }`;
      document.body.appendChild(this.style);
    } else {
      this.style = document.createElement('style');
      this.style.innerHTML = `.treemap-label {
        padding: var(--size--spacing-x6);
      }`;
      document.body.appendChild(this.style);
    }
  }

  labelFormatting(c: { data: any; label: any }) {
    return `<div class='value-information'>
            <div class='name'>${c.label}</div>
            <div class='total'>
              <div class='total-value'>£${c.data.extra.display}</div>
              <div class='total-percent'>(${c.data.extra.percentage})</div>
            </div>
          </div>`;
  }

  convertToInternationalCurrencySystem(labelValue: number) {
    // Six Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(0) + 'b'
      : // Tree Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e3
        ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(0) + 'b'
        : Math.abs(Number(labelValue));
  }

  ngOnDestroy(): void {
    document.body.removeChild(this.style);
  }
}
