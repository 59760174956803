import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ReitMetadataResponse,
  ReitOverviewService,
  ReitService,
} from '@kolytics/shared-api';
import {
  autoDestroy,
  followDestroy,
  formatPercentage,
} from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { EChartsOption } from 'echarts';
import * as moment from 'moment';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import {
  IReit,
  IReitDataResponse,
  PremToGavGraphItem,
  PremToGavGraphResponse,
} from '../../../interfaces';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { NgClass } from '@angular/common';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-overview-gva',
  templateUrl: './overview-gva.component.html',
  styleUrls: ['./overview-gva.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SectionComponent, NgClass, GraphComponent],
})
@autoDestroy()
export class OverviewGvaComponent implements OnInit {
  @Input() title!: string;
  @Input() reitId!: string;

  @Input() reitData!: IReitDataResponse;
  @Input() chartData!: PremToGavGraphResponse;

  @Input() metadata!: ReitMetadataResponse;

  @Input() showTimeSelect = true;
  @Input() height =
    'calc(var(--size--spacing-x67-5) + var(--size--spacing-x2-5))';
  @Input() printFormat = false;

  public times = ['1M', 'YTD', '1Y', '5Y', '10Y'];
  @Input() public options: EChartsOption | undefined = {
    grid: {
      backgroundColor: '#ffffff',
      show: true,
      borderColor: '#ffffff',
      borderWidth: 0,
      height: '86%',
      width: '85%',
      top: '6',
      left: '70',
    },
    xAxis: {
      type: 'category',
      data: [],
      boundaryGap: false,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        margin: 22,
      },
      axisPointer: {
        show: true,
        type: 'none',
        label: {
          show: false,
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: any, index: number) => {
          return formatPercentage(value, 0, this.metadata.culture);
        },
        margin: 20,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
      },
    },
    series: [],
    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: (params: any) => {
        if (params.componentType && params.componentType === 'series') {
          return `<div style="background: ${
            params.color
          }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);">${params.seriesName.toUpperCase()}-${
            params.name
          }: (${formatPercentage(
            params.value,
            1,
            this.metadata.culture,
          )})</div>`;
        }
        return '';
      },
    },
  };
  public series: any[] = [];
  public titles: { name: string; color: string; disabled: boolean }[] = [];
  currentType = '';
  premToGavGraphData: PremToGavGraphResponse = {} as PremToGavGraphResponse;
  loading: boolean = true;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private reitService: ReitService,
    private reitOverviewService: ReitOverviewService,
  ) {}

  public ngOnInit(): void {
    if (!this.reitId && !this.reitData) {
      this.activatedRoute.parent?.params
        .pipe(
          filter((params) => !!params),
          tap((data) => {
            this.reitId = data.id;
          }),
          switchMap(({ id }) => this.reitService.apiReitDataReitIdGet(id)),
          map((response: IReitDataResponse) => response.reits.reit),
          followDestroy(this),
        )
        .subscribe((reit: IReit) => {
          this.setTitles(reit.name, reit.sector.name);
          this.currentType = this.times[0];
          this.getPremToGavGraph();
        });
    } else {
      this.reitService
        .apiReitDataReitIdGet(Number(this.reitId))
        .pipe(map((response: IReitDataResponse) => response.reits.reit))
        .subscribe((reit: IReit) => {
          this.setTitles(reit.name, reit.sector.name);
          this.currentType = this.times[0];
          this.getPremToGavGraph();
        });
    }
  }

  getPremToGavGraph() {
    if (this.chartData) {
      this.premToGavGraphData = this.chartData;
      this.setXAxis(this.premToGavGraphData);
      this.setSeries(this.premToGavGraphData);
      this.runFilter(this.titles);
      this.loading = false;
    } else {
      this.reitOverviewService
        .apiReitReitIdOverviewPremToGavGraphTypeGet(
          Number(this.reitId),
          this.currentType,
        )
        .pipe(filter((x) => x.graphData.length > 0 && this.titles.length === 2))
        .subscribe((data) => {
          this.premToGavGraphData = data;
          this.setXAxis(this.premToGavGraphData);
          this.setSeries(this.premToGavGraphData);
          this.runFilter(this.titles);
          this.loading = false;
        });
    }
  }

  runFilter(data: { name: string; color: string; disabled: boolean }[]): void {
    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());

    const series = this.series.filter((e) =>
      filter.includes(e.name.toLowerCase()),
    );
    (this.options as EChartsOption).series = [...(series as any)];
    const temp = Object.assign({}, this.options);
    this.options = undefined;
    setTimeout(() => {
      this.options = temp;
      this.cdr.detectChanges();
    }, 0);
  }

  setXAxis(data: PremToGavGraphResponse) {
    ((this.options as EChartsOption).xAxis as any).data = data.graphData.map(
      (x) => moment(x.point).format('DD MMM YY'),
    );
  }

  getValueByFilterName(filterNames: string[], item: PremToGavGraphItem) {
    const yValues = [];
    if (filterNames.includes(this.titles[0].name.toLocaleLowerCase())) {
      yValues.push(item.value);
    }
    if (filterNames.includes(this.titles[1].name.toLocaleLowerCase())) {
      yValues.push(item.sectorAverageValue);
    }

    return yValues;
  }

  setSeries(data: PremToGavGraphResponse) {
    this.series = [...this.getSeries(data)];
  }

  getSeries(data: PremToGavGraphResponse) {
    return [
      {
        data: data.graphData.map((x) => x.value),
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: colorPallete[GraphId.OverviewPremDiscToGav][0],
        name: this.titles[0].name,
      },
      {
        data: data.graphData.map((x) => x.sectorAverageValue),
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: colorPallete[GraphId.OverviewPremDiscToGav][1],
        name: this.titles[1].name,
      },
    ];
  }

  setTitles(name: string, sectorName: string) {
    this.titles.push(
      {
        name: name,
        color: colorPallete[GraphId.OverviewPremDiscToGav][0],
        disabled: false,
      },
      {
        name: `${sectorName} AVERAGE`,
        color: colorPallete[GraphId.OverviewPremDiscToGav][1],
        disabled: false,
      },
    );
  }

  filterPrem(type: string) {
    this.currentType = type;
    this.getPremToGavGraph();
  }
}
