<klt-section title="Indexed Like-for-Like Net Rental Income (LFL NRI)">
  <div class="graph-box">
    <klt-graph
      height="388px"
      [loading]="titles.length == 0"
      [options]="options"
      [titles]="titles"
      (filter)="runFilter($event)"
    >
    </klt-graph>
  </div>
</klt-section>
