export enum GraphId {
  OverviewTotalReturnPerformance,
  OverviewPremDiscToGav,
  OverviewInvestmentLocations,
  OverviewPropertyByLocation,
  OverviewPropertyByType,
  OverviewInvestability,
  ProjectionPremDiscToGav,
  ProjectionsTotalShareholderReturns,
  ProjectionLflNri,
  ProjectionAffoDpsYield,
  ProjectionsBalanceSheet,
  ProjectionDebtMaturities,
  FinancialEps,
  FinancialDps,
  FinancialRevenue,
  FinancialDirectCosts,
  FinancialInDirectCosts,
  FinancialInterestCosts,
  FinancialNtaShare,
  FinancialPremDiscToGav,
  FinancialPropotionalLtv,
  FinancialNtCashFlow,
  FinancialFinancingActivities,
  FinancialInvestingActivities,
  SectorBreakdown,
  SectorOverviewMarketCapitalisation,
  SectorOverviewPortfolioComposition,
  SectorOverviewInvestability,
  SectorOverviewHistoricalSectorReturns,
  SectorOverviewBeta,
  SectorProjectionIndexedMarketRateGrowth,
  SectorProjectionOccupancyLevels,
  SectorProjectionLflNri,
  SectorProjectionCostAnalysis,
  SectorProjectionBalanceSheeetStrength,
  SectorProjectionRiskReturn,
  DataAnalyticsBuildChart
}

export const GRAPH_ID = {
  OVERVIEW: {
    TOTAL_RETURN_PERFOMANCE: 'overview_total_return_perfomance',
    PREM_DISC_TO_GAV: 'overview_prem_disc_to_gav',
    INVESTMENT_LOCATIONS: 'overview_investment_locations',
    PROPERTY_BY_LOCATION: 'overview_property_by_location',
    PROPERTY_BY_TYPE: 'overview_property_by_type',
    INVESTABILITY: 'overview_investability',
  },

  PROJECTIONS: {
    PREM_DISC_TO_GAV: 'projections_prem_disc_to_gav',
    TOTAL_SHAREHOLDER_RETURNS: 'projections_total_shareholder_returns',
    LFL_NRI: 'projections_lfl_nri',
    AFFO_DPS_YIELD: 'projections_affo_dps_yield',
    BALANCE_SHEET_STRENGHT: 'projections_balance_sheet_strenght',
    DEBT_MATURITIES: 'projections_debt_maturities',
  },
  FINANCIALS: {
    EPS: 'financials_eps',
    DPS: 'financials_dps',
    REVENUE: 'financials_revenue',
    DIRECT_COSTS: 'financials_direct_costs',
    INDIRECT_COSTS: 'financials_indirect_costs',
    INTEREST_COSTS: 'financials_interest_costs',
    NTA_SHARE: 'financials_nta_share',
    PREM_DISC_TO_GAV: 'financials_prem_disc_to_gav',
    PROPOTIONAL_LTV: 'financials_propotional_ltv',
    NET_CASH_FLOW: 'financials_net_cash_flow',
    FINANCING_ACTIVITIES: 'financials_financing_activities',
    INVESTING_ACTIVITIES: 'financials_investing_activities',
  },
};
