import { Component } from '@angular/core';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import {
  ClientQuery,
  ClientSignalQuery,
} from 'apps/client/src/app/store/client.query';
import { ClientStoreService } from 'apps/client/src/app/store/client.store.service';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { snapshotManager } from '@datorama/akita';
import { ClientSignalState } from 'apps/client/src/app/store/client.state';
import { PreviewService } from '../../../../shared/services/preview.service';
import { RouterOutlet } from '@angular/router';
import { HeaderTabComponent } from '../../components/header-tab/header-tab.component';

@UntilDestroy()
@Component({
    selector: 'app-financials',
    templateUrl: './financials.component.html',
    styleUrls: ['./financials.component.scss'],
    standalone: true,
    imports: [HeaderTabComponent, RouterOutlet],
})
export class FinancialsComponent {
  public links: { path: string; text: string; claim?: string }[] = [];

  reitId!: number;
  scenarioAId!: number;

  constructor(
    private previewService: PreviewService,
    private clientFacade: ClientFacadeService,
    private clientStore: ClientStoreService,
    private clientQuery: ClientQuery,
    private clientSignalQuery: ClientSignalQuery,
  ) {}

  ngOnInit(): void {
    this.clientStore.setFinancialLoading(true);
    this.clientFacade.financial$
      .pipe(untilDestroyed(this))

      .subscribe((data) => {
        this.clientFacade.financialData = data;
      });

    const path = document.location.pathname;
    const id = `${path}`.split('/')[2];
    this.reitId = Number(id);

    this.clientFacade.localStorageUpdates$
      .pipe(untilDestroyed(this))
      .subscribe((state: ClientSignalState) => {
        if (!state) return;
        const oldState = this.clientSignalQuery.getValue();

        const selectedScenarioIds =
          this.clientQuery.getValue().selectedScenarios[this.reitId];
        const selectedAssumptionScenarioIds =
          this.clientQuery.getValue().selectedAssumptionScenarios[this.reitId];

        const scenarioIds = [
          ...selectedScenarioIds,
          ...selectedAssumptionScenarioIds,
        ];

        const pScenarioId = state.portfolio[this.reitId]?.scenarioId;
        const pUpdatedAt = state.portfolio[this.reitId]?.updatedAt;

        const cScenarioId = state.company[this.reitId]?.scenarioId;
        const cUpdatedAt = state.company[this.reitId]?.scenarioId;

        const oScenarioId = state.other[this.reitId]?.scenarioId;
        const oUpdatedAt = state.other[this.reitId]?.scenarioId;

        if (this.reitId) {
          if (
            (scenarioIds.includes(pScenarioId) &&
              pUpdatedAt !== oldState.portfolio[this.reitId]?.updatedAt) ||
            (scenarioIds.includes(cScenarioId) &&
              cUpdatedAt !== oldState.company[this.reitId]?.updatedAt) ||
            (scenarioIds.includes(oScenarioId) &&
              oUpdatedAt !== oldState.other[this.reitId]?.updatedAt)
          ) {
            snapshotManager.setStoresSnapshot(
              { clientSignals: state },
              { skipStorageUpdate: true },
            );
            this.refreshData();
          }
        }
      });

    this.links = [
      {
        path: `/${this.previewService.routingReitPrefix()}/${id}/financials/income`,
        text: 'Income statement',
      },
      {
        path: `/${this.previewService.routingReitPrefix()}/${id}/financials/balance`,
        text: 'Balance sheet',
      },
      {
        path: `/${this.previewService.routingReitPrefix()}/${id}/financials/cash`,
        text: 'Cash flow statement',
      },
    ];
  }

  refreshData() {
    this.clientStore.setFinancialLoading(true);
    this.clientFacade
      .getReitId()
      .pipe(
        take(1),
        tap((reitId: number) => (this.reitId = reitId)),
        switchMap(() =>
          this.clientFacade
            .getSelectedLeftScenarioId(this.reitId)
            .pipe(take(1)),
        ),
        tap((scenarioAId: number) => (this.scenarioAId = scenarioAId)),
        tap(() => this.clientStore.setFinancialLoading(true)),
        switchMap(() => this.clientFacade.financial$.pipe(take(1))),
      )
      .subscribe((data) => {
        this.clientFacade.financialData = data;
      });
  }
}
