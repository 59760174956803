import { Component, Inject, OnInit } from '@angular/core';
import { KltModalRefService } from '@kolytics/shared-components';
import { ClientStoreService } from 'apps/client/src/app/store/client.store.service';
import { ButtonComponent } from '../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { ModalComponent } from '../../../../../../../../libs/shared-components/src/lib/components/modal/modal.component';
import { ReitAssumptionService, ReitService } from '@kolytics/shared-api';

@Component({
  selector: 'klt-remove-scenario-modal',
  templateUrl: './remove-scenario-modal.component.html',
  styleUrls: ['./remove-scenario-modal.component.scss'],
  standalone: true,
  imports: [ModalComponent, ButtonComponent],
})
export class RemoveScenarioModalComponent {
  scenarioId = '';
  reitId!: number;
  constructor(
    protected readonly kltModalRefService: KltModalRefService,
    private reitService: ReitService,
    private clientStore: ClientStoreService,
    private reitAssumptionService: ReitAssumptionService,
    @Inject('KLT_MODAL_DATA') private data: any,
  ) {}

  ngOnInit(): void {
    this.setUserScenarioId();
  }

  setUserScenarioId() {
    const path = document.location.pathname;
    const pathSplit = `${path}`.split('/');
    this.scenarioId = pathSplit[pathSplit.length - 2];
  }

  public onClose(): void {
    this.kltModalRefService.close(RemoveScenarioModalComponent, false);
  }

  public onRemove(): void {
    this.reitAssumptionService
      .apiReitReitIdAssumptionUserScenarioIdDelete(
        Number(this.scenarioId),
        this.data.reitId,
      )
      .subscribe((res) => {
        this.clientStore.removeScenario(
          this.data.reitId,
          Number(this.scenarioId),
        );
        this.kltModalRefService.close(RemoveScenarioModalComponent, res);
      });
  }
}
