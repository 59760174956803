import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@kolytics/shared-api';

@Injectable({
  providedIn: 'root',
})
export class AccountResolverService {
  constructor(public userService: UserService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.apiMyAccountGet();
  }
}
