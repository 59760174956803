<div class="overview-property-value-location">
  <klt-section type="none" title="Property value by location">
    <div class="value-box">
      @if (single && single.length > 0) {
        <div class="value-zone" [style.height]="height" [style.width]="width">
          <ngx-charts-tree-map
            [scheme]="colorScheme"
            [results]="single!"
            [gradient]="gradient"
            [animations]="animations"
            [customColors]="customColors"
            [labelFormatting]="labelFormatting"
            [tooltipDisabled]="tooltipDisabled"
          >
            <ng-template #tooltipTemplate let-model="model" let-i="index">
              <div class="tooltip">
                <p>{{ model.name }}</p>
                <p>{{ model.extra.display }} ({{ model.extra.percentage }}%)</p>
              </div>
            </ng-template>
          </ngx-charts-tree-map>
        </div>
      }
      @if (single.length == 0) {
        <div class="graph-loader">
          <div class="inner">
            <div class="loader"></div>
          </div>
        </div>
      }
    </div>
  </klt-section>
</div>
