<div class="wrapper--checkbox" [ngSwitch]="size" (click)="select()">
  <div
    class="checkbox"
    [class.checked]="checked"
    [class.disabled]="disabled"
    [class.small]="size === 'small'"
    [class.large]="size === 'large'"
  >
    <ng-container
      *ngSwitchCase="'small'"
      [ngTemplateOutlet]="small"
    ></ng-container>
    <ng-container
      *ngSwitchCase="'large'"
      [ngTemplateOutlet]="large"
    ></ng-container>
  </div>
</div>

<ng-template #small>
  <div class="tick-box" [class.checked]="checked">
    <klt-icon
      class="icon"
      [size]="iconSize"
      icon="tick"
      [color]="iconColor"
    ></klt-icon>
  </div>
  <div class="content">
    <span class="label">{{ label }}</span>
  </div>
</ng-template>

<ng-template #large>
  <div class="content">
    <span class="label">{{ label }}</span>
    <span class="description">{{ description }}</span>
  </div>
  <div class="tick-box" [class.checked]="checked">
    <klt-icon
      class="icon"
      [size]="iconSize"
      icon="tick"
      [color]="iconColor"
    ></klt-icon>
  </div>
</ng-template>
