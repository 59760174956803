import {
  Component,
  Directive,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  PaginationPageChange,
  TableColumnGroup,
  TableColumns,
  TableData,
  TableSort,
} from '@kolytics/shared-components';
import { PaginationComponent } from '../pagination/pagination.component';
import { TableScrollComponent } from './table-scroll/table-scroll.component';

/**
 * @name TableToolbarDirective
 * @description Table toolbar placeholder/template
 */

@Directive({
  selector: 'klt-table-toolbar, [kltTableToolbar]',
  exportAs: 'kltTableToolbar',
  standalone: true,
})
export class TableToolbarDirective {}

/**
 * @name TableComponent
 * @description Data listing view
 *
 * @param tableName<string|undefined> - Title that will be displayed at the top of the table
 * @param sections<TableSections[]|undefined> - Sections indicates the groupings of the columns
 * @param headers<TableHeaders|undefined> - Table headers. Important!
 * @param data<TableData[]|undefined> - Table data
 * @param filterHeaders<string[]|undefined> - Filter headers
 *
 * @param pageLength<number> - Total list length
 * @param pageIndex<number> - Current page index. Default 0
 * @param pageSize<number> - Total items to show in the list. Default 0
 *
 * @emits sort<{col:string;sort:'asc'|'desc'}> - Emits the sorted column
 */

@Component({
  selector: 'klt-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  imports: [TableScrollComponent, PaginationComponent],
})
export class TableComponent {
  @Input() tableId: string = '';
  @Input() tableName: string | undefined;
  @Input() columnGroups: TableColumnGroup[] | undefined;
  @Input() columns: TableColumns | undefined;
  @Input() data: TableData[] | undefined;
  @Input() filteredColumns: string[] | undefined;
  @Input() highlightSpaceRow: number | undefined;
  @Input() spacingAfterRow: number | undefined;
  @Input() spacingAfterRowIndexes: number[] | undefined;
  @Input() stickLeftColumns: TableColumns | undefined;
  @Input() stickRightColumns: TableColumns | undefined;
  @Input() editableTable: boolean | undefined;
  @Input() hideSection: boolean | undefined;
  @Input() stickyHeader: boolean | undefined;
  @Input() showTopHeader = true;
  @Input() noPadding = false;
  @Input() verticalGroupLine: boolean = false;

  @Input() pageLength: number | undefined;
  @Input() pageIndex: number = 1;
  @Input() pageSize: number = 0;
  @Input() showTooltip: boolean = false;

  @Input() rowClasses = '';

  @Output() sort = new EventEmitter<TableSort | undefined>();
  @Output() rowClick = new EventEmitter<TableData>();
  @Output() actionClick = new EventEmitter<{ name: string; data: TableData }>();
  @Output() cellClick = new EventEmitter<{ name: string; data: TableData }>();
  @Output() pageChange = new EventEmitter<PaginationPageChange>();
  @Output() cellSaveClick = new EventEmitter<{
    value: string;
    row: number;
    cell: number;
  }>();
}
