import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notEmpty',
  standalone: true,
  pure: false,
})
export class NotEmptyPipe implements PipeTransform {
  transform(value: any[] | null | undefined): boolean {
    if (!value) return false;
    return value.length > 0;
  }
}
