import {
  ApplicationRef,
  ComponentFactoryResolver,
  Injectable,
  Injector,
} from '@angular/core';
import { OpenedModal } from '../../interfaces/modal.interface';
import { ModalGlobalsService } from './modal-globals.service';
import { KltModalService } from './modal.service';

/**
 * @name KltModalRefService
 * @description Use from the modal component to get its reference
 */
@Injectable()
export class KltModalRefService<C = any> extends KltModalService {
  constructor(
    modalStorageService: ModalGlobalsService,
    componentFactoryResolver: ComponentFactoryResolver,
    appRef: ApplicationRef,
    injector: Injector,
  ) {
    super(modalStorageService, componentFactoryResolver, appRef, injector);
  }

  /**
   * @description Used to close the opened modal
   * @param compRef
   * @param returnValue
   */
  close(compRef: C | any, returnValue?: any) {
    const openedModals: OpenedModal[] = this._modalStorageService.openedModals;
    const openedModalIndex: number | undefined = openedModals.findIndex(
      (m) => m.compRef.instance instanceof compRef,
    );

    if (openedModalIndex !== -1) {
      const openedModal: OpenedModal = openedModals[openedModalIndex];
      this._appRef.detachView(openedModal.compRef.hostView);
      openedModal.compRef.destroy();

      this._modalStorageService.openedModals.splice(openedModalIndex, 1);
      this._cleanModalOverlay(openedModal);
      this._modalStorageService.afterClosed.next(returnValue);
    }
  }
}
