import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IconComponent } from '../../../../../../../../../libs/shared-assets/src/lib/components/icon/icon.component';

import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';
import { ReitManagementService, ReitService } from '@kolytics/shared-api';

interface ILead {
  name: string;
  tenure: string;
  shareholding: string;
  biography: string;
  photo: string;

  expanded: boolean;
}

@Component({
  selector: 'klt-overview-team',
  templateUrl: './overview-team.component.html',
  styleUrls: ['./overview-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SectionComponent, IconComponent],
})
export class OverviewTeamComponent implements OnInit {
  @Input() public leads!: ILead[];
  @Input() public pageSize: number = 3;

  @Input() showPagination = true;
  @Input() showTitleScroll = true;

  public paginateLeads: ILead[] = [];
  public pageActive = 1;
  totalCount = 0;
  reitId: number = 0;

  constructor(
    private reitService: ReitService,
    private changeDetectorRef: ChangeDetectorRef,
    protected readonly activatedRoute: ActivatedRoute,
    private reitManagementService: ReitManagementService,
  ) {}

  public ngOnInit(): void {
    if (!this.leads) {
      this.reitId = parseInt(
        this.activatedRoute.snapshot.parent?.paramMap.get('id') || '',
        10,
      );

      this.reitManagementService
        .apiReitManagementReitIdGet(this.reitId)
        .subscribe((res) => {
          this.leads = res.data.map((item: any) => ({
            name: `${item.firstName} ${item.lastName}`,
            tenure: item.tenure,
            shareholding: item.shareholding,
            biography: item.biography || '',
            photo: item.photo || '',
            expanded: false,
          }));
          this.totalCount = res.data.length;
          this.pagination();
        });
    } else {
      this.pagination();
    }
  }

  public pagination(): void {
    this.paginateLeads = this.leads.filter(
      (e, i) =>
        i >= (this.pageActive - 1) * this.pageSize &&
        i <= this.pageActive * this.pageSize - 1,
    );
    this.changeDetectorRef.detectChanges();
  }
}
