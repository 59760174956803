import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { IconComponent } from '../../../../../shared-assets/src/lib/components/icon/icon.component';

import { DragAndDropDirective } from '../../directives/drag-and-drop.directive';
import { FileService } from '@kolytics/shared-api';

export interface FileData {
  fileName: string;
  tempFileName: string;
}

@Component({
  selector: 'klt-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  standalone: true,
  imports: [DragAndDropDirective, IconComponent, ProgressBarComponent],
})
export class FileUploaderComponent implements OnInit {
  @Input() title!: string;
  @Input() fileName!: string;
  @Input() allowedFileTypes: string[] = [];

  @Input() error: boolean = false;
  @Input() errorLabel!: string;

  @Output() fileSelected = new EventEmitter<File>();
  @Output() fileUploaded = new EventEmitter<FileData>();

  @ViewChild('fileDropRef')
  public readonly fileDropRef!: ElementRef<HTMLInputElement>;

  randomId!: number;

  selectedFile: File | undefined;
  progress: number = 0;
  completed: boolean = false;
  uploading: boolean = false;
  errorMessages: string[] = [];

  constructor(private fileService: FileService) {}

  ngOnInit(): void {
    this.randomId = Math.random();
  }

  public get showDrag(): boolean {
    return !this.selectedFile;
  }

  public get showProcess(): boolean {
    return !!this.selectedFile && !this.completed;
  }

  public get showError(): boolean {
    return this.error && !this.completed;
  }

  public get showSuccess(): boolean {
    return this.completed && this.errorMessages.length === 0;
  }

  public get showUpload(): boolean {
    return this.completed && this.errorMessages.length === 0;
  }

  onFileUploaded(files: any) {
    this.prepareFilesList(files ? Array.from(files) : []);
  }

  fileBrowseHandler(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    this.prepareFilesList(files ? Array.from(files) : []);
  }

  prepareFilesList(files: File[]) {
    this.removeFile();

    if (files.length > 0) {
      const file = files[0];

      if (this.allowedFileTypes.includes(file.type)) {
        this.selectedFile = file;
        this.fileSelected.emit(file);

        this.fileService
          .apiFileTempPost(file)
          .pipe(
            finalize(() => {
              this.progress = 100;
              const timeout = setTimeout(() => {
                this.completed = true;
                this.fileName = file.name;
                clearTimeout(timeout);
              }, 500);
            }),
          )
          .subscribe((data: FileData) => {
            this.fileUploaded.emit(data);
          });
      } else {
        alert('File not allowed!');
      }
    }
  }

  removeFile() {
    this.completed = false;
    this.errorMessages = [];
    this.progress = 0;
    this.selectedFile = undefined;
    this.fileDropRef.nativeElement.value = '';
  }
}
