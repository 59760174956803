<div class="wrapper--table">
  <div class="page--content">
    @if (path !== 'other_assumptions') {
      <div>
        <klt-table
          [columnGroups]="reitTableData.sections"
          [columns]="reitTableData.headers"
          [filteredColumns]="[]"
          [stickLeftColumns]="reitTableData.stickLeftColumns"
          [stickRightColumns]="reitTableData.stickRightColumns"
          [data]="reitTableData.data"
          [pageLength]="reitTableData.total"
          [spacingAfterRow]="path === 'company' ? 0 : 0"
          [spacingAfterRowIndexes]="path === 'company' ? [5] : [0]"
          (sort)="onTableSort($event)"
          (cellSaveClick)="onCellSave($event)"
          >
        </klt-table>
      </div>
    }
    @if (path === 'other_assumptions') {
      <div>
        <klt-other-table
          [showToolbar]="false"
          [reitId]="reitId"
          [scenarioAId]="userScenarioId"
        ></klt-other-table>
      </div>
    }
  </div>
</div>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="default"
  color="#fff"
  type="ball-clip-rotate-multiple"
  [fullScreen]="true"
></ngx-spinner>
