import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownOption, ValueChangeEvent } from '@kolytics/shared-components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { filter, switchMap } from 'rxjs/operators';

import { InputComponent } from '../input/input.component';
import { DropdownOptionsComponent } from '../dropdown-options/dropdown-options.component';
import { IconComponent } from '../../../../../../shared-assets/src/lib/components/icon/icon.component';
import { NgClass } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'klt-single-selection',
  templateUrl: './single-selection.component.html',
  styleUrls: ['./single-selection.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    IconComponent,
    DropdownOptionsComponent
],
})
export class SingleSelectionComponent extends InputComponent implements OnInit {
  @ViewChild('input')
  inputElRef!: ElementRef;

  @ViewChild('wrapper') wrapper!: ElementRef;

  @Input()
  placeholder: string = 'Select options';
  @Input() allowEdit = false;
  @Input() allowAddNew = false;
  @Input() allowFilter = false;
  @Input() allowHttpFilter = false;

  @Input() iconColor = 'dark';

  expanded = false;
  localSelectedValue!: DropDownOption;
  localSelectedValues: DropDownOption[] = [];

  options: DropDownOption[] = [];
  filteredOptions: DropDownOption[] = [];

  @Output() selectionChange = new EventEmitter<DropDownOption>();
  @Output() afterInserting = new EventEmitter<DropDownOption>();
  @Output() afterEditing = new EventEmitter<DropDownOption>();
  @Output() afterHttpFiltering = new EventEmitter<string>();
  @Output() httpFilterChanged = new EventEmitter<string>();

  editing = false;
  adding = false;

  constructor(
    fb: FormBuilder,
    private _el: ElementRef,
  ) {
    super(fb);
  }

  private _searchable = false;

  get searchable(): boolean {
    return this._searchable;
  }

  @Input() set searchable(v: boolean) {
    this._searchable = v;
    if (v) {
      this.filteredOptions = [];
    }
  }

  private _items: DropDownOption[] = [];

  get items(): DropDownOption[] {
    return this._items;
  }

  @Input() set items(v: DropDownOption[]) {
    this._items = v;
    if (v) {
      this.options = v.map((item) => {
        const newItem = {
          ...item,
          name: item.label,
        };
        return Object.assign({}, newItem);
      });
      if (!this.searchable) {
        this.filteredOptions = this.options;
      }
    }
  }

  get selectedValue(): any {
    return this.localSelectedValue;
  }

  @Input() set selectedValue(value: any) {
    this.localSelectedValue = value;
    this.value = this.options.find((e) => e.value === value)?.label;
    this.options.forEach((e) => {
      e.selected = e.value === this.localSelectedValue;
      return e;
    });
  }

  get empty(): boolean {
    return this.filteredOptions.length <= 0;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.handleClickOutside();
    if (this.searchable) {
      this.valueChangedObservable
        .pipe(distinctUntilChanged())
        .subscribe((event: ValueChangeEvent) => {
          if (event.value) {
            this.formStates.focused = true;
            this.filteredOptions = this.options.filter(
              (e) =>
                e.label.toLowerCase().indexOf(event.value.toLowerCase()) > -1,
            );
            this.expanded = !this.empty;
          } else {
            this.formStates.error = false;
            this.filteredOptions = [...this.options];
          }
        });
    }
  }

  exist(value: string): DropDownOption | undefined {
    return this.options.find((e) => e.value === value);
  }

  toggle(event: Event): void {
    this.expanded = !this.expanded;
    this.inputElRef.nativeElement.focus();
  }

  private handleClickOutside() {
    fromEvent(document, 'click')
      .pipe(
        filter(({ target }) => {
          const origin = this.wrapper?.nativeElement;
          return origin.contains(target as HTMLElement) === false;
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        if (!this.editing && !this.adding) {
          if (this.expanded) {
            this.expanded = false;
          }
        }
      });
  }

  select(option: DropDownOption): void {
    this.localSelectedValue = option;
    this.selectedValue = option.value;
    this.filteredOptions = [...this.options];
    this.selectionChange.emit(option.value);
  }

  optionsChanged(options: DropDownOption[]): void {
    this.value = options.map((e) => e.label).join(', ');
    if (!this.value) {
      this.setLabelRaised(false);
    }
  }

  beforeSavingItem() {
    this.setLabelRaised(true);
  }

  afterSavingItem(item: DropDownOption) {
    if (this.options.findIndex((x) => x.value === item.value) === -1) {
      this.options.push(item);
      this.afterInserting.emit(item);
    } else {
      this.afterEditing.emit(item);
    }
  }

  onFilterOptionFocus() {
    this.setLabelRaised(true);
  }

  onFilterOptionBlur() {
    if (!this.localSelectedValue) {
      this.setLabelRaised(false);
    }
  }

  clear(): void {
    this.value = null;
    this.setLabelRaised(false);
  }

  save(options: DropDownOption[]): void {
    this.value = options.map((e) => e.label).join(', ');
  }

  onClosed(): void {
    this.expanded = false;
    if (!this.localSelectedValue) {
      this.setLabelRaised(false);
    }
  }

  setLabelRaised(state: boolean) {
    this.formStates.labelRaised = state;
  }
}
