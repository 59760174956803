import { TableColumns, TableData } from '@kolytics/shared-components';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export class TableBuilder {
  private headers: TableColumns = {};
  private table!: { headers: TableColumns; data: TableData[]; total: number };

  constructor() {}

  withColumn(
    column: string,
    title: string,
    fill: string,
    type: string | any,
    sortable: boolean,
  ): TableBuilder {
    this.headers[column] = { title, fill, type, sortable };
    return this;
  }

  build(): { headers: TableColumns; data: TableData[]; total: number } {
    this.table = {
      headers: this.headers,
      data: [],
      total: 0,
    };
    return this.table;
  }
}
