<div class="overview-gva">
  <klt-section [title]="title || 'Prem/(Disc) to GAV'" type="none">
    <div class="graph-box">
      @if (showTimeSelect) {
        <div class="time-select">
          <div class="time-frame">
            @for (time of times; track time) {
              <div
                class="time-frame-item"
                [ngClass]="{ active: currentType === time }"
                (click)="filterPrem(time)"
                >
                {{ time }}
              </div>
            }
          </div>
        </div>
      }
      <klt-graph
        [loading]="loading"
        [showTimeSelect]="showTimeSelect"
        (filter)="runFilter($event)"
        [options]="options"
        [titles]="titles"
        [height]="height"
      ></klt-graph>
    </div>
  </klt-section>
</div>
