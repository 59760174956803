import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { countries } from 'libs/shared-assets/src/lib/assets/jsons/country';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { dropdownAnimation } from '../../../animations';
import { ValueChangeEvent } from '../../../interfaces';
import { InputExpandComponent } from '../input-expand/input-expand.component';
import { IconComponent } from '../../../../../../shared-assets/src/lib/components/icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'klt-input',
  templateUrl: './input.component.html',
  animations: [dropdownAnimation()],
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    IconComponent,
    InputExpandComponent,
  ],
})

/**
 * @name InputComponent
 * @description Functional form component for Input Text, Password, Emails etc.
 * @property label<string> - The floating label text of the input field
 * @property name<string> - Passed as a name tag to the form element
 * @property toolTip<string> - Text to display when hovering over the lock icon. Works only if locked = true
 * @property tabIndex<number | 'auto'> - Determines the element's tab index
 * @property noTab<boolean> - Determines if the element is included in the tab order
 * @property hasFocus<boolean> - If set to true, it initializes with having focus. Use only one per page
 * @property pattern<string> - RegExp pattern for value validation
 * @property errorLabel<string> - External error label (from server events)
 * @property type<string> - The element's content type. Determines behavior and validation
 * @property disabled<boolean> - If set to true, the element is read-only
 * @property validateOn<'blur' | 'change'> - Determines if content validation will happen while typing or when focus is lost.
 * @property info<string> - The text content of the information line below the element
 * @property locked<boolean> - If true the element is read only and a lock icon appears with a tooltip on hover.
 * @property required<boolean> - If true the element will be marked as invalid if not filled
 * @property maxlength<number> - The maximum characters allowed to be typed in the element
 * @property verifying<boolean> - Toggles the verifying icon animation, useful for server queries
 * @property error<boolean> - If true forces a custom error display. Works in conjunction with errorLabel
 * @property value<string> - Sets a predefined value for the element
 * @emits valueCleared<{value:string,status:string}> - Fires whenever the user clears the element value
 * @emits valueChanged<{value:string,status:string}> - Fires whenever the element value is changed
 * @emits lostFocus<{value:string,status:string}> - Fires whenever the element loses focus
 * @implements OnInit, OnDestroy
 * @author Sotiris Varotsis
 */
export class InputComponent implements OnInit, OnDestroy {
  @ViewChild('input', { static: true })
  input: ElementRef | undefined;

  @ViewChild('textarea', { static: true })
  textarea: ElementRef | undefined;

  /**
   * Use formStates to programmatically control the form element's state
   * These are used as CSS class definitions as well.
   * Copy them over to other form elements
   * @property disabled - When the element is disabled
   * @property labelRaised - When the floating label needs to be raised
   * @property focused - When the element has focused
   * @property error - When the element has an error
   * @property valid - When the value of the element is valid
   * @property selected - Used for radio and checkboxes
   */

  formStates = {
    disabled: false,
    labelRaised: false,
    focused: false,
    error: false,
    valid: false,
    selected: false,
  };

  countries = countries;
  filterCountries: any[] = [];
  searchCountry = '';
  showCountry = false;

  selectedCountry = {
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB',
  };

  /**
   * displayIcon is only used for input fields that have additional icons
   * such as clear, validation, password reveal and spinners
   */

  displayIcon = '';

  passwordVisible = false;
  errorMessage = '';

  form: FormGroup = new FormGroup({});
  formSubscription: Subscription = new Subscription();
  statusSubscription: Subscription = new Subscription();

  formValidators: ValidatorFn[] = [];

  @Input() label = '';
  @Input() placeholder = '';
  @Input() name = '';
  @Input() toolTip = 'Cannot change content';
  @Input() tabIndex: any = 'auto';
  @Input() noTab = false;
  @Input() hasFocus = false;
  @Input() pattern: any = '';
  @Input() type:
    | 'text'
    | 'password'
    | 'email'
    | 'textarea'
    | 'phone'
    | 'number' = 'text';
  @Input() disabled = false;
  @Input() validateOn: 'blur' | 'change' = 'change';
  @Input() info = '';
  @Input() locked = false;
  @Input() required = false;
  @Input() maxlength = 524288;
  @Input() rightIcon = '';
  @Input() rightIconSize = 'regular';
  @Input() hideNotch: boolean = false;
  @Input() hideStateIndicator: boolean = false;
  @Input() allowedCharacters!: string;

  @Input() wrapperClass = '';
  @Input() inputClass = '';
  @Input() labelClass = '';

  _verifying = false;

  get verifying(): boolean {
    return this._verifying;
  }

  @Input() set verifying(value: boolean) {
    this._verifying = value;
    this.handleIcon();
  }

  _errorLabel = '';

  get errorLabel(): string {
    return this._errorLabel;
  }

  @Input() set errorLabel(value: string) {
    this._errorLabel = value;
    if (value) {
      this.errorMessage = value;
    }
  }

  _error = false;

  get error(): boolean {
    return this._error;
  }

  @Input() set error(value: boolean) {
    this._error = value;
    this.formStates.error = value;
    if (value) {
      this.form.setErrors({ incorrect: true });
      this.form.markAsTouched();
    } else {
      this.form.setErrors(null);
    }
  }

  _value: string | number | null | undefined = null;
  get value(): string | number | null | undefined {
    return this._value;
  }

  @Input() set value(value: string | number | null | undefined) {
    this._value = value;
    if (this.form.controls.formValue) {
      this.form.controls.formValue.setValue(this.value);
      if (value) {
        this.form.controls.formValue.markAsDirty();

        // Update the formStates in case the value comes asynchronously
        // and not during the component initialization.
        if (!this.formStates.labelRaised) {
          this.formStates.labelRaised = true;
        }
        if (!this.formStates.valid) {
          this.formStates.valid = true;
        }
      } else {
        this.form.controls.formValue.markAsPristine();
      }
    }
  }

  @Output() valueCleared: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChanged: EventEmitter<ValueChangeEvent> =
    new EventEmitter<ValueChangeEvent>();
  @Output() lostFocus: EventEmitter<ValueChangeEvent> =
    new EventEmitter<ValueChangeEvent>();

  @Output() enterPressed: EventEmitter<Event> = new EventEmitter<Event>();

  @Output() patternInvalid: EventEmitter<ValueChangeEvent> =
    new EventEmitter<ValueChangeEvent>();

  valueClearedObservable = this.valueChanged.asObservable();
  valueChangedObservable = this.valueChanged.asObservable();
  lostFocusChangedObservable = this.valueChanged.asObservable();

  // , public translate: TranslateService
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.filterCountry();
    if (this.required) {
      this.formValidators.push(Validators.required);
    }
    if (this.type === 'email') {
      this.formValidators.push(Validators.email);
    }

    if (this.pattern) {
      this.formValidators.push(Validators.pattern(this.pattern));
    }

    const validators = this.formValidators;
    this.form = this.fb.group({
      formValue: new FormControl('', {
        validators,
        updateOn: this.validateOn === 'change' ? 'change' : 'blur',
      }),
    });

    if (this.value) {
      this.form.controls.formValue.setValue(this.value);
      this.form.controls.formValue.markAsDirty();
      this.formStates.labelRaised = true;
      this.formStates.valid = true;
    } else {
      this.form.controls.formValue.markAsPristine();
    }

    if (this.locked) {
      this.disabled = true;
      this.formStates.disabled = true;
    }

    if (this.disabled) {
      this.formStates.disabled = true;
      this.tabIndex = -1;
    }

    if (this.hasFocus) {
      if (this.input) {
        this.input.nativeElement.focus();
      }
      if (this.textarea) {
        this.textarea.nativeElement.focus();
      }
    }

    this.handleIcon();
    this.formSubscription = this.form.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        let modifiedValue: string = this.form.controls.formValue.value;

        this.errorMessage = this.errorLabel || 'Error'; //this.translate.instant('COMPONENTS.INPUT.ERROR');
        this.formStates.error = false;

        if (this.form.controls.formValue.errors?.email) {
          this.formStates.error = true;
          this.errorMessage = 'Please enter a valid e-mail address'; // this.translate.instant('COMPONENTS.INPUT.ERROR_INVALID_EMAIL');
        }
        if (this.form.controls.formValue.errors?.required) {
          this.formStates.error = true;
          this.errorMessage = 'This field is required'; // this.translate.instant('COMPONENTS.INPUT.ERROR_REQUIRED');
        }
        if (!this.form.controls.formValue.value && !this.required) {
          this.form.controls.formValue.markAsPristine();
        }

        if (this.type === 'email') {
          if (this.form.controls.formValue.errors?.pattern) {
            this.formStates.error = true;
            this.errorMessage = 'Please enter a valid e-mail address';
          }
        }

        if (this.type === 'phone') {
          if (this.form.controls.formValue.errors?.pattern) {
            this.formStates.error = true;
            this.errorMessage = 'Please enter a valid phone number';
          }
        }

        if (this.allowedCharacters && modifiedValue) {
          const valueCharArray = Array.from(modifiedValue);
          const allowedCharArray = Array.from(this.allowedCharacters);

          let hasUpdates = false;
          valueCharArray.forEach((valueChar) => {
            if (allowedCharArray.indexOf(valueChar) === -1) {
              hasUpdates = true;
              modifiedValue = modifiedValue.replace(valueChar, '');
            }
          });

          if (hasUpdates) {
            this.form.patchValue({
              formValue: modifiedValue,
            });
          }
        }

        this.handleIcon();
        this.valueChanged.emit({
          value: modifiedValue,
          status: this.form.controls.formValue.status,
        });
      });

    this.statusSubscription = this.form.statusChanges.subscribe((status) => {
      this.formStates.valid = status === 'VALID';
      this.handleIcon();
    });
  }

  enterKeySubmit(event: Event) {
    this.enterPressed.emit(event);
  }
  disableTab(evt: any): void {
    evt.preventDefault();
  }

  togglePasswordVisibility(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.passwordVisible = !this.passwordVisible;
  }

  clear(e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    if (this.input) {
      this.input.nativeElement.value = '';
    }
    if (this.textarea) {
      this.textarea.nativeElement.value = '';
    }

    this.form.controls.formValue.setValue('');
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.handleIcon();
    this.valueCleared.emit();
  }

  /**
   * getFormClasses()
   * @description constructs the form class object based on the element's states
   * @returns string
   */

  getFormClasses(): string {
    const classArray = [];
    for (const [key, value] of Object.entries(this.formStates)) {
      if (value) {
        classArray.push(key);
      }
    }

    if (this.type === 'textarea') {
      classArray.push('textarea');
    }

    if (this.hideNotch) {
      classArray.push('no-notch');
    }

    return classArray.join(' ');
  }

  handleIcon(): void {
    if (this.verifying) {
      this.displayIcon = '';
    } else {
      if (this.type === 'password') {
        this.displayIcon = 'eye';
      } else {
        if (this.locked) {
          this.displayIcon = 'lock';
        } else {
          if (this.formStates.focused) {
            if (this.form.controls.formValue.value) {
              this.displayIcon = 'clear';
            } else {
              this.displayIcon = '';
            }
          } else {
            if (this.formStates.valid) {
              this.displayIcon = 'tick';
            } else {
              this.displayIcon = '';
            }
          }
        }
      }
    }
  }

  /**
   * @name onFocus()
   * Handles events when the element gains focus. Copy this over to your form component.
   * labelRaised and focused formStates are necessary. The rest pertains to the icons
   */

  onFocus(): void {
    this.formStates.labelRaised = true;
    this.formStates.focused = true;
    if (this.type !== 'password') {
      if (this.form.controls.formValue.value) {
        this.displayIcon = 'clear';
      } else {
        this.displayIcon = '';
      }
    } else {
      this.displayIcon = 'eye';
    }
  }

  /**
   * @name onBlur()
   * Handles events when the element loses focus. Copy this over to your form component.
   * labelRaised and focused formStates are necessary as well as the lostFocus emitted event. The rest pertains to the icons
   */

  onBlur(): void {
    const value = this.form.controls.formValue.value;
    this.lostFocus.emit({
      value: this.form.controls.formValue.value,
      status: this.form.controls.formValue.status,
    });

    if (this.form.controls.formValue.errors?.pattern) {
      this.formStates.error = true;
      this.patternInvalid.emit({
        status: 'invalid',
        value: this.form.controls.formValue.value,
      });
    }
    if (!value) {
      this.formStates.labelRaised = false;
    }
    this.formStates.focused = false;
    if (this.type !== 'password') {
      if (this.formStates.valid && value) {
        this.displayIcon = 'tick';
      } else {
        this.displayIcon = '';
      }
    } else {
      this.displayIcon = 'eye';
    }
  }

  filterCountry(): void {
    this.filterCountries = this.countries.filter((e) =>
      e.name.toLowerCase().includes(this.searchCountry.toLowerCase()),
    );
  }

  /**
   * @name ngOnDestroy()
   * Make sure to always unsubscribe your RxJs subscriptions otherwise
   * bad things will happen
   */

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }
}
