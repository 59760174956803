import {
  animate,
  AnimationTriggerMetadata,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export function navigationOverlayAnimation(): AnimationTriggerMetadata {
  return trigger('navigationOverlayAnimation', [
    transition(':enter', [
      query(
        ':self',
        [
          style({
            opacity: 0,
          }),
          stagger(100, [
            animate(
              '0.3s',
              style({
                opacity: 0.6,
              }),
            ),
          ]),
        ],
        { optional: true },
      ),
    ]),
    transition(':leave', [
      query(
        ':self',
        [
          style({
            opacity: 0.6,
          }),
          stagger(100, [
            animate(
              '0.3s',
              style({
                opacity: 0,
              }),
            ),
          ]),
        ],
        { optional: true },
      ),
    ]),
  ]);
}

export function navigationProfileAnimation(): AnimationTriggerMetadata {
  return trigger('navigationProfileAnimation', [
    transition(':enter', [
      query(
        ':self',
        [
          style({
            opacity: 0,
            height: 0,
            overflow: 'hidden',
          }),
          stagger(100, [
            animate(
              '0.3s',
              style({
                opacity: 1,
                height: '*',
              }),
            ),
          ]),
        ],
        { optional: true },
      ),
    ]),
    transition(':leave', [
      query(
        ':self',
        [
          style({
            opacity: 1,
            height: '*',
            overflow: 'hidden',
          }),
          stagger(100, [
            animate(
              '0.3s',
              style({
                opacity: 1,
                height: 0,
                overflow: 'hidden',
                padding: 0,
              }),
            ),
          ]),
        ],
        { optional: true },
      ),
    ]),
  ]);
}
