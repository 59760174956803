<klt-section title="Risk return">
  <div class="graph-box">
    <div class="graph">
      <klt-graph
        height="340px"
        [loading]="titles.length == 0"
        [options]="options"
        [titles]="titles"
        (filter)="runFilter($event)"
      >
      </klt-graph>
      <div class="bottom">
        <b>Risk (Beta)</b>
      </div>
    </div>
    <div class="explained">
      <h4>Risk return explained</h4>
      <p [innerHTML]="desc"></p>
    </div>
  </div>
</klt-section>
