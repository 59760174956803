import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { formatNumber } from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { EChartsOption } from 'echarts';
import { number } from 'echarts/types/dist/echarts';
import {
  ISectorOverviewBetaResponse,
  ISectorOverviewMCapResponse,
} from '../../../interfaces';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-sector-beta',
  templateUrl: './sector-beta.component.html',
  styleUrls: ['./sector-beta.component.scss'],
  standalone: true,
  imports: [SectionComponent, GraphComponent],
})
export class SectorBetaComponent implements OnInit {
  titles: { name: string; color: string; disabled: boolean }[] = [];
  loading: boolean = true;
  @Input()
  set sector(sector: ISectorOverviewBetaResponse | undefined) {
    if (sector) {
      this.initGraph(sector);
    }
  }

  options: EChartsOption | undefined = {
    grid: {
      backgroundColor: '#fff',
      show: true,
      borderWidth: 0,
      height: '85%',
      width: '100%',
      top: '10',
      left: '35',
    },
    xAxis: {
      type: 'category',
      data: [],
      axisLine: {
        show: false,
        onZero: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        margin: 22,
      },
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: any, index: number) => {
          return formatNumber(value, 2);
        },
        // margin: 20
      },
      splitLine: {
        show: true,
      },
    },
    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: function (params: any) {
        if (params.componentType && params.componentType === 'series') {
          return `
            <div style="
                background: ${params.color};
                padding: var(--size--spacing-x2) var(--size--spacing-x4);
                display: flex;
                flex-direction: column;
                gap: var(--size--spacing-x2)
              ">
              <h4 style="color: var(--color--text-light)">${
                params.seriesName
              }</h4>
                  <b style="color: var(--color--text-light); text-transform: capitalize">(Value: ${formatNumber(
                    params.data.value[1],
                    2,
                  )})</b>
            </div>
          `;
        }
        return '';
      },
    },
  };

  lines = [];

  series: any[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  runFilter(
    data: {
      name: string;
      color: string;
      disabled: boolean;
      opacity?: number;
    }[],
  ): void {
    this.titles = data;
    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter((e) =>
      filter.includes(e.name.toLowerCase()),
    );

    (this.options as EChartsOption).series = [
      ...(series as any),
      ...this.lines,
    ];

    const temp = Object.assign({}, this.options);

    // this.options = undefined;

    setTimeout(() => {
      this.options = temp;
      this.loading = false;
      this.cdr.detectChanges();
    }, 0);
  }

  initGraph(sector: ISectorOverviewBetaResponse) {
    this.titles = [];
    this.series = [];

    ((this.options as EChartsOption).xAxis as any).data = sector.years;
    sector.items.forEach((s, index) => {
      //set titles
      if (
        this.titles.find((o) => o.name === s.label.toLowerCase()) == undefined
      ) {
        this.titles.push({
          name: s.label.toLowerCase(),
          color: colorPallete[GraphId.SectorOverviewBeta][this.titles.length],
          disabled: false,
        });
      }

      let itemData: {
        type: string;
        z: number;
        clip: boolean;
        barMaxWidth: number;
        barMinWidth: number;
        barWidth: number;
        name: string;
        data: {
          value: any;
          itemStyle: { color: string; borderColor: string };
        }[];
      } = {
        type: 'candlestick',
        clip: true,
        barMaxWidth: s.barMaxWidth,
        barMinWidth: s.barMinWidth,
        barWidth: s.barWidth,
        name: s.label,
        z: index,
        data: [],
      };
      for (let value of s.values) {
        itemData.data.push({
          itemStyle: {
            color: s.color,
            borderColor: 'transparent',
          },
          value: value.data,
        });
      }

      //set data
      this.series.push(itemData);
    });

    this.runFilter(this.titles);
  }
}
