import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadingSpinnerComponent } from '../../../../../../../../libs/shared-components/src/lib/components/loading-spinner/loading-spinner.component';
import { IconComponent } from '../../../../../../../../libs/shared-assets/src/lib/components/icon/icon.component';


@Component({
    selector: 'klt-link-action',
    templateUrl: './link-action.component.html',
    styleUrls: ['./link-action.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    IconComponent,
    LoadingSpinnerComponent
],
})
export class LinkActionComponent {
  @Input()
  public iconLeft: string | undefined;

  @Input()
  public iconRight: string | undefined;

  @Input()
  public text: string | undefined;

  @Input() showLoading = false;
}
