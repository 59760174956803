<div
  class="wrapper--button {{ type }} {{ border }}"
  [class.disabled]="disabled"
  [class.loading]="loading"
  (click)="onClick()"
  >
  @if (!deletePending) {
    <div
      class="wrapper--button-container"
      [class.loading]="loading"
      [class.space]="leftIcon || rightIcon"
      >
      @if (leftIcon) {
        <klt-icon
          class="left-icon"
          [size]="iconSize"
          [icon]="leftIcon"
          [color]="iconColor"
        ></klt-icon>
      }
      <div class="label">
        <ng-content></ng-content>
      </div>
      @if (rightIcon) {
        <klt-icon
          class="right-icon"
          [class.expanded]="dropdownExpanded"
          [size]="iconSize"
          [icon]="rightIcon"
          [color]="iconColor"
        ></klt-icon>
      }
    </div>
  }
  @if (loading) {
    <div class="loading-wrapper">
      <div class="spinner {{ type }}"></div>
    </div>
  }
  @if (deletePending) {
    <div
      class="wrapper--button-container"
      [class.loading]="false"
      [class.space]="true"
      >
      <klt-icon
        class="left-icon"
        [size]="iconSize"
        [icon]="'trash'"
        [color]="'error'"
      ></klt-icon>
      <div class="label">
        <span>&nbsp;Are you sure?</span>
      </div>
    </div>
  }
</div>
