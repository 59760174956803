import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { formatCurrency, formatNumber } from '@kolytics/shared-functions';
import {
  financialActualColor,
  financialBaseColor,
  financialColorPallete,
  financialGoodColor,
  financialPoorColor,
} from '@kolytics/types/graph';
import { ECharts, EChartsOption } from 'echarts';
import { XAXisOption } from 'echarts/types/dist/shared';
import { IReitDataResponse } from '../../../pages/reits/interfaces';
import { getColNameByYear } from '../../../pages/reits/utils';

@Component({
  selector: 'klt-base-graph',
  templateUrl: './base-graph.component.html',
  standalone: true,
})
export class BaseGraphComponent implements OnInit {
  graphId!: number;
  protected _reit!: IReitDataResponse;
  get reit() {
    return this._reit;
  }

  private excludedKeys = ['year', 'label'];

  protected graphType: 'line' | 'area' = 'line';
  public options: EChartsOption | undefined;

  public lines = [];
  public series: any[] = [];
  protected _keys: string[] = [];
  public titles: {
    name: string;
    color: string;
    disabled: boolean;
    small?: boolean;
  }[] = [];

  protected suffix = '';
  protected currencyPerShare = false;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly currencyPipe: CurrencyPipe,
  ) {}

  ngOnInit(): void {}

  protected tooltipFormatter(
    params: any,
    decimalPoints?: number,
    locale?: string,
    currencyCode?: string,
    pence?: boolean,
    currencyFormat?: string,
  ) {
    if (params.componentType && params.componentType === 'series') {
      return `<div style="background: ${
        params.color
      }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);">${params.seriesName.toUpperCase()}-${
        params.name
      }: (${formatCurrency(
        params.value,
        decimalPoints,
        locale,
        currencyCode,
        pence,
        currencyFormat,
      )})</div>`;
    }
    return '';
  }

  protected getLineColor(key: string, index: number, keys: string[]): string {
    if (key === 'actual') return financialActualColor;
    if (key === 'base') return financialBaseColor;
    if (key === 'good') return financialGoodColor;
    if (key === 'poor') return financialPoorColor;

    const otherKeys = keys.filter(
      (e) => e !== 'actual' && e !== 'base' && e !== 'good' && e !== 'poor',
    );
    return financialColorPallete[otherKeys.indexOf(key)];
  }

  protected getTitleColor(key: string, index: number, keys: string[]): string {
    if (key === 'actual') return financialActualColor;
    if (key === 'base') return financialBaseColor;
    if (key === 'good') return financialGoodColor;
    if (key === 'poor') return financialPoorColor;

    const otherKeys = keys.filter(
      (e) => e !== 'actual' && e !== 'base' && e !== 'good' && e !== 'poor',
    );
    return financialColorPallete[otherKeys.indexOf(key)];
  }

  protected getTitles(): {
    name: string;
    color: string;
    disabled: boolean;
    small?: boolean;
  }[] {
    return this._keys.map((key, index) => {
      return {
        name: key.toUpperCase(),
        color: this.getTitleColor(key, index, this._keys),
        disabled: false,
        small: true,
      };
    });
  }

  protected getSeries(
    value: Record<string, number>[],
    middle: number,
    name: string,
    index: number,
    lastActual: Record<string, number>,
  ) {
    switch (this.graphType) {
      case 'line':
        return [
          {
            data: value.map((e) =>
              e.year <= lastActual.year ? e[name] : undefined,
            ),
            type: 'line',
            smooth: true,
            showSymbol: false,
            color: this.getLineColor(name, index, this._keys),
            z: 2,
            name: name.toUpperCase(),
          },
          {
            data: value.map((e) =>
              e.year >= lastActual.year ? e[name] : undefined,
            ),
            type: 'line',
            smooth: true,
            showSymbol: false,
            color: this.getLineColor(name, index, this._keys),
            lineStyle: {
              type: 'dashed',
            },
            z: 2,
            name: name.toUpperCase(),
          },
        ];
      case 'area':
        return [
          {
            data: value.map((e) => e[name]),
            type: 'line',
            z: 2,
            smooth: true,
            showSymbol: false,
            areaStyle: {},
            color: this.getLineColor(name, index, this._keys),
            name: name.toUpperCase(),
            silent: false,
          },
        ];
    }
  }

  protected reset(value: Record<string, number>[]): void {
    value = value.sort((a, b) => a.year - b.year);

    this.setDataForXAxis(this.options, value);

    this._keys = Object.entries(value[0])
      .map((item) => item[0])
      .filter((e) => !this.excludedKeys.includes(e));
    const middle =
      value.length % 2 === 0
        ? value.length / 2 - 1
        : parseInt((value.length / 2).toString().split('.')[0], 0);

    this.series = [];

    const actuals = value.filter((e) => e.actual !== null);
    const lastActual = actuals[actuals.length - 1];

    this._keys.forEach((key, index) => {
      this.series.push(
        ...this.getSeries(value, middle, key, index, lastActual),
      );
    });

    this.titles = this.getTitles();
    this.runFilter(this.titles);
  }

  public runFilter(
    data: { name: string; color: string; disabled: boolean }[],
  ): void {
    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter((e) =>
      filter.includes(e.name.toLowerCase()),
    );
    (this.options as EChartsOption).series = [
      ...(series as any),
      ...this.lines,
    ];
    const temp = Object.assign({}, this.options);
    //this.options = undefined;
    setTimeout(() => {
      this.options = temp;
      this.cdr.detectChanges();
    }, 0);
  }

  protected setDataForXAxis(
    options: EChartsOption | undefined,
    value: { [name: string]: number }[],
  ) {
    const labels = value.map((x) => x.label);
    if (labels.length > 0) {
      ((options as EChartsOption).xAxis as any).data = labels;
    } else {
      const years = value.map((x) => getColNameByYear(x.year));
      ((options as EChartsOption).xAxis as any).data = years;
    }
  }
}
