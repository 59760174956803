import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  FeatureService,
  KltModalRefService,
} from '@kolytics/shared-components';
import { ModalPermissionRestrictComponent } from 'libs/shared-components/src/lib/modals/modal-permission-restrict/modal-permission-restrict.component';
import { fromEvent, Subscription, take } from 'rxjs';

@Directive({
    selector: '[kltFeatured]',
    standalone: true,
})
export class FeaturedDirective implements OnInit, OnDestroy {
  subscription = new Subscription();

  private _feature: string | undefined;
  get feature(): string | undefined {
    return this._feature;
  }
  @Input() set feature(v: string | undefined) {
    this._feature = v;

    this.checkFeatured(v);
  }

  constructor(
    private elementRef: ElementRef,
    private rendered: Renderer2,
    private featuredService: FeatureService,
    private kltModalRefService: KltModalRefService,
  ) {}

  ngOnInit(): void {
    const element = this.elementRef.nativeElement;
    this.subscription = fromEvent(element, 'click', {
      capture: true,
    }).subscribe((e: any) => {
      const hasFeture = this.featuredService.hasFeature(this.feature || '');
      if (!hasFeture) {
        e.stopPropagation();
        this.kltModalRefService
          .open(ModalPermissionRestrictComponent)
          .afterClosed.pipe(take(1))
          .subscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  checkFeatured(feature: string | undefined) {
    if (!feature) {
      this.showUnlocked();
      return;
    }
    const hasFeture = this.featuredService.hasFeature(feature);
    if (!hasFeture) {
      this.showLocked();
    }
  }
  showLocked() {
    if (!this.elementRef) return;
    this.rendered.addClass(this.elementRef.nativeElement, 'access-locked');
  }
  showUnlocked() {
    if (!this.elementRef) return;
    this.rendered.removeClass(this.elementRef.nativeElement, 'access-locked');
  }
}
