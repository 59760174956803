import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { formatNumber } from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { EChartsOption } from 'echarts';
import { ISectorOverviewMCapResponse } from '../../../interfaces';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-market-capitalisation',
  templateUrl: './market-capitalisation.component.html',
  styleUrls: ['./market-capitalisation.component.scss'],
  standalone: true,
  imports: [SectionComponent, GraphComponent],
})
export class MarketCapitalisationComponent implements OnInit {
  loading: boolean = true;
  yAxisDetails: { min: number; max: number } = { min: 5, max: 300 };
  series: any[] = [];
  titles: { name: string; color: string; disabled: boolean }[] = [];

  @Input()
  set sector(sector: ISectorOverviewMCapResponse | undefined) {
    if (sector) {
      this.initGraph(sector);
    }
  }

  options: EChartsOption | undefined = {
    grid: {
      backgroundColor: '#fafafa',
      show: true,
      borderColor: '#fff',
      borderWidth: 0,
      height: '90%',
      width: '95%',
      top: '10',
      left: '70',
    },
    animation: true,
    xAxis: {
      type: 'category',
      axisLine: {
        show: false,
        onZero: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        margin: 22,
      },
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: any, index: number) => {
          return '£' + formatNumber(value, 2) + 'b';
        },
        margin: 20,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
      },
    },
    series: [],
    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: function (params: any) {
        if (params.componentType && params.componentType === 'series') {
          return `<div style="background: ${
            params.color
          }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);">${params.seriesName.toUpperCase()}-${
            params.name
          }: (£${formatNumber(params.data, 2)}b)</div>`;
        }
        return '';
      },
    },
  };

  lines = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  setMaxY(series: any[]) {}

  runFilter(data: { name: string; color: string; disabled: boolean }[]): void {
    this.titles = data;
    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter((e) =>
      filter.includes(e.name.toLowerCase()),
    );
    this.setMaxY(series);
    (this.options as EChartsOption).series = [
      ...this.lines,
      ...(series as any),
    ];

    const temp = Object.assign({}, this.options);

    // this.options = undefined;

    setTimeout(() => {
      this.options = temp;
      this.loading = false;
      this.cdr.detectChanges();
    }, 0);
  }

  initGraph(sector: ISectorOverviewMCapResponse) {
    this.titles = [];
    this.series = [];

    const colorLength =
      colorPallete[GraphId.SectorOverviewMarketCapitalisation].length;
    ((this.options as EChartsOption).xAxis as any).data = sector.years;
    sector.data.forEach((s, index) => {
      //set titles
      this.titles.push({
        name: s.sectorName.toLowerCase(),
        color:
          colorPallete[GraphId.SectorOverviewMarketCapitalisation][
            index %
              colorPallete[GraphId.SectorOverviewMarketCapitalisation].length
          ],
        disabled: false,
      });

      //set data
      this.series.push({
        zlevel: sector.data.length - index,
        data: s.values,
        type: 'bar',
        barWidth: 30,
        itemStyle: {
          borderRadius: 2,
        },
        smooth: true,
        stack: 'one',
        showSymbol: false,
        color:
          colorPallete[GraphId.SectorOverviewMarketCapitalisation][
            index % colorLength
          ],
        name: s.sectorName.toLowerCase(),
        areaStyle: {
          color:
            colorPallete[GraphId.SectorOverviewMarketCapitalisation][
              index % colorLength
            ],
          opacity: 1,
        },
        lineStyle: {
          width: 1,
        },
      });
    });
    this.series.reverse();
    this.runFilter(this.titles);
  }
}
