import { GRAPH_ID } from '../../../../../libs/types/src/lib/graph/graph.constant';

export interface ClientState {
  reitId: number;
  scenarios: {
    [key: string]: {
      name: string;
      scenarioId: number;
      isDefault: boolean;
      initialScenarioId?: number;
    }[];
  };
  selectedScenario: { [key: string]: number };
  selectedScenarios: { [key: string]: number[] };
  selectedAssumptionScenarios: { [key: string]: number[] };

  selectedLeftScenarioId: { [key: string]: number };
  selectedRightScenarioId: { [key: string]: number };

  financialIsLoading: boolean;
  projectionIsLoading: boolean;

  graphLoading: { [key: string]: boolean };

  stickyKeys: { [key: string]: boolean };
}

export function createInitialState(): ClientState {
  return {
    reitId: -1,
    scenarios: {},
    selectedScenario: {},
    selectedScenarios: {},
    selectedAssumptionScenarios: {},

    selectedLeftScenarioId: {},
    selectedRightScenarioId: {},

    financialIsLoading: false,
    projectionIsLoading: false,

    graphLoading: {
      [GRAPH_ID.PROJECTIONS.PREM_DISC_TO_GAV]: false,
    },
    stickyKeys: {},
  };
}

export interface ClientSignalState {
  portfolio: {
    [key: string]: { scenarioId: number; updatedAt: number; resetted: boolean };
  };
  company: {
    [key: string]: { scenarioId: number; updatedAt: number; resetted: boolean };
  };
  other: {
    [key: string]: { scenarioId: number; updatedAt: number; resetted: boolean };
  };
  status: { [key: string]: { shouldUpdate: boolean } };
}

export function createInitialSignalState(): ClientSignalState {
  return {
    portfolio: {},
    company: {},
    other: {},

    status: {},
  };
}
