import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[kltTableNumberFormatter]',
  standalone: true,
})
export class TableNumberFormatterDirective {
  _value: any = '';
  get value(): any {
    return this._value;
  }
  @Input() set value(v: any) {
    this._value = v;
    if (v) {
      this.formatValue();
    }
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  formatValue() {
    let isNegative = false;
    if (typeof this.value === 'string')
      isNegative = this.value.startsWith('-') && this.value !== '-';
    if (typeof this.value === 'number') isNegative = this.value < 0;

    if (isNegative) {
      this.renderer.addClass(this.elementRef.nativeElement, 'warn');
    }
  }
}
