<klt-modal modalTitle="Delete scenario" (closeModal)="onClose()">
  <div class="remove-scenario-modal-content">
    <div class="content-row title">
      <h3>
        <b>Scenario name</b>
      </h3>
    </div>
    <div class="content-row confirm">Are you sure you want to delete this?</div>
    <div class="content-row remove-button">
      <klt-button class="error" type="primary" (click)="onRemove()"
        >REMOVE SCENARIO</klt-button
      >
    </div>
    <div class="content-row cancel-button">
      <b (click)="onClose()">Cancel</b>
    </div>
  </div>
</klt-modal>
