import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PaginationPageChange } from '@kolytics/shared-components';
import { ButtonComponent } from '../common/button/button.component';

/**
 * @name PaginationComponent
 * @description Item list paginator
 *
 * @param pageLength<number> - Total list length. Default 0
 * @param pageIndex<number> - Current page index. Default 0
 * @param pageSize<number> - Total items to show in the list. Default 5
 *
 * @emits pageChange<PaginationPageChange> - Current length, pageIndex, and pageSize
 */

@Component({
    selector: 'klt-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    standalone: true,
    imports: [ButtonComponent],
})
export class PaginationComponent {
  @ViewChild('inputElement')
  public inputElement!: ElementRef;

  @Input() page: number = 1;
  @Input() pageSize: number = 5;
  @Input() totalPages: number = 0;

  @Output() pageChange = new EventEmitter<PaginationPageChange>();

  onPrevious(): void {
    if (this.page !== 0) {
      --this.page;
      this.onSubmit();
    }
  }

  onNext(): void {
    if (this.page !== this.totalPages) {
      ++this.page;
      this.onSubmit();
    }
  }

  onChange(e: any): void {
    if (!e.target.value) {
      return;
    }

    if (/^\d*$/.test(e.target.value)) {
      this.page = parseInt(e.target.value, 10) - 1;

      if (this.page < 0) this.page = 0;
      if (this.page > this.totalPages - 1) this.page = this.totalPages - 1;
    }

    this.inputElement.nativeElement.value = this.page + 1;
  }

  onSubmit(): void {
    this.pageChange.emit({
      length: this.totalPages,
      pageIndex: this.page,
      pageSize: this.pageSize,
      page: this.page,
    });
  }
}
