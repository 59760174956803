<!-- Search expanding -->
@if (layout === 'default') {
  <div [formGroup]="form" class="wrapper--input" [class.expanded]="expanded">
    <klt-icon
      class="search-icon"
      [size]="'medium'"
      icon="search"
      [color]="theme === 'light' ? 'dark' : 'light'"
      (click)="toggle()"
    ></klt-icon>
    <div class="wrapper--input_el">
      <input
        #input
        class="input"
        [type]="type"
        [placeholder]="placeholder"
        formControlName="formValue"
        [class.text-light]="theme === 'dark'"
        [class.text-dark]="theme === 'light'"
        />
        <klt-icon
          class="input-icon-close"
          icon="close"
          [color]="'darkTint'"
          (mousedown)="clear($event)"
        ></klt-icon>
      </div>
    </div>
  }

  <!-- Search expanding alt -->
  @if (layout === 'alt') {
    <div
      [formGroup]="form"
      class="wrapper--input alt"
      [class.expanded]="expanded"
      >
      <klt-icon
        class="search-icon"
        [size]="iconSize"
        icon="search"
        [color]="theme === 'light' ? 'dark' : 'light'"
        (click)="toggle()"
      ></klt-icon>
      <input
        #input
        class="input"
        [type]="type"
        [placeholder]="placeholder"
        formControlName="formValue"
        [class.text-light]="theme === 'dark'"
        [class.text-dark]="theme === 'light'"
        />
        <klt-icon
          class="input-icon-close"
          icon="close"
          [color]="'darkTint'"
          (mousedown)="clear($event)"
        ></klt-icon>
      </div>
    }
