<div class="reits--header-tab__container">
  <div class="reits--header-tab__list">
    @for (link of links; track link) {
      @if (!link.feature) {
        <ng-container *hasClaim="link.claim">
          <div
            [routerLink]="link.path"
            [queryParams]="link.dataParser"
            routerLinkActive="s--active"
            [queryParamsHandling]="link.keepQueryParams ? 'merge' : ''"
            class="reits--header-tab__item"
            >
            {{ link.text }}
          </div>
        </ng-container>
      } @else {
        <ng-container *hasClaim="link.claim">
          <div
            kltFeatured
            [feature]="link.feature"
            [routerLink]="link.path"
            [queryParams]="link.dataParser"
            [queryParamsHandling]="link.keepQueryParams ? 'merge' : ''"
            routerLinkActive="s--active"
            class="reits--header-tab__item"
            >
            {{ link.text }}
          </div>
        </ng-container>
      }
    }
  </div>
</div>
