import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { snapshotManager } from '@datorama/akita';
import { KltModalService, TableData } from '@kolytics/shared-components';
import { untilDestroyed } from '@ngneat/until-destroy';
import { ClientSignalState } from 'apps/client/src/app/store/client.state';
import { KltToastService } from 'libs/shared-components/src/lib/services/toast/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { fromEvent } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ReitStoreService } from '../../../services/reit-store.service';
import { BaseReit } from '../base-reit/base-reit.util';
import {
  ReitAssumptionService,
  ReitService,
  UserAssumptionsService,
} from '@kolytics/shared-api';

@Component({
  template: '',
})
export abstract class AssumptionBaseComponent extends BaseReit {
  @Input() allData!: TableData[];

  queueRunning = false;
  queueCount: number = 0;
  queueState: number = 1;
  queueInterval: any;

  isPopup: boolean = false;

  constructor(
    protected readonly kltModalService: KltModalService,
    protected readonly kltToastService: KltToastService,
    protected readonly router: Router,
    protected reitService: ReitService,
    protected userAssumptionService: UserAssumptionsService,
    protected spinner: NgxSpinnerService,
    protected reitStoreService: ReitStoreService,
    protected cdr: ChangeDetectorRef,
    protected reitAssumptionService: ReitAssumptionService,
  ) {
    super(kltModalService, kltToastService, router);
  }

  protected abstract valueOfReitId(): string;
  protected abstract valueOfUserScenarioId(): number | string;

  listeningStorageUpdates(
    reitId: number,
    scenarioId: number,
    key: 'portfolio' | 'company' | 'other',
    callback: any,
    initCallback?: any,
  ) {
    fromEvent<StorageEvent>(window, 'storage')
      .pipe(
        filter((event) => event.key === 'client-state'),
        map(
          (event: StorageEvent) =>
            JSON.parse(event.newValue as string)
              ?.clientSignals as ClientSignalState,
        ),
        untilDestroyed(this),
      )
      .subscribe((state: ClientSignalState) => {
        if (state.status[reitId].shouldUpdate) {
          state.status[reitId].shouldUpdate = false;
          snapshotManager.setStoresSnapshot(
            { clientSignals: state },
            { skipStorageUpdate: true },
          );
          initCallback();
          return;
        }

        if (Number(state[key][reitId].scenarioId) === Number(scenarioId)) {
          if (state[key][reitId].resetted) {
            state[key][reitId].resetted = false;
            snapshotManager.setStoresSnapshot(
              { clientSignals: state },
              { skipStorageUpdate: true },
            );
            initCallback();
          } else {
            snapshotManager.setStoresSnapshot(
              { clientSignals: state },
              { skipStorageUpdate: true },
            );
            callback();
          }
        }
      });
  }

  protected reloadTableData(showMessage: boolean, message?: string) {
    this.userAssumptionService
      .apiUserAssumptionsGetUserAssumptionsScenarioReitIdUserScenarioIdGet(
        Number(this.valueOfReitId()),
        Number(this.valueOfUserScenarioId()),
      )
      .subscribe(
        (res) => {
          this.reitStoreService.storeAssumptionProfile(res);
          this.spinner.hide();
          if (showMessage && message) {
            this.kltToastService.success(message, 4000);
          }
        },
        (error) => {
          this.spinner.hide();
          this.kltToastService.success(error, 4000);
        },
      );
  }

  checkQueue(
    reitId: number,
    scenarioId: number,
    assumptionTypeId: number,
    callback: any,
  ) {
    this.reitAssumptionService
      .apiReitReitIdAssumptionQueueScenarioIdAssumptionTypeIdGet(
        scenarioId,
        assumptionTypeId,
        String(reitId),
      )
      .pipe(take(1))
      .subscribe((data) => {
        this.queueRunning = data.assumptionQueueState === 2;

        this.queueState = data.assumptionQueueState;

        if (this.queueCount !== data.queueCount) {
          this.queueCount = data.queueCount;
          callback();
        }

        if (!this.queueRunning) {
          this.queueCount = 0;
          clearInterval(this.queueInterval);
          console.log('[Queue] Queue is empty. Timer Stopped');
        } else {
          console.log(`[Queue] Remaining items in queue: ${this.queueCount}`);
        }
      });
  }

  protected onOtherCellSave(
    reitId: number,
    {
      value,
      row,
      cell,
      scenarioId,
    }: {
      value: string;
      row: number;
      cell: number;
      scenarioId: number;
    },
  ): void {
    const columnName = Object.entries(this.reitTableData.headers || {})[
      cell
    ][0];
    const { rowId, type } = this.reitTableData.data[row][columnName] as any;

    if (this.valueOfReitId() === null || !columnName) return;

    this.userAssumptionService
      .apiUserAssumptionsUserAssumptionOtherReitIdPut(
        Number(this.valueOfReitId()),
        {
          fieldValue: parseFloat(value.replace('%', '')),
          scenarioId: scenarioId,
          rowId: rowId,
          type: type,
        },
      )
      .subscribe(
        (data: any) => {
          this.queueRunning = data.assumptionQueueState === 2;
          this.queueCount = data.queueCount;

          if (this.queueState === 1) {
            console.log('[Queue] Timer started');
            this.queueInterval = setInterval(() => {
              this.checkQueueOther(reitId, scenarioId);
            }, 1000);
          }
          this.queueState = data.assumptionQueueState;
        },
        (err) => {
          this.queueRunning = false;
        },
      );
  }

  checkQueueOther(reitId: number, scenarioId: number) {
    this.checkQueue(reitId, scenarioId, 3, () => {});
  }
}
