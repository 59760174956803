<div class="cash-flow-statement-compare">
  <div class="cash-flow-compare">
    <div class="cash-flow-compare-button"></div>
    <div class="cash-flow-compare-button">
      <klt-section
        type="button"
        buttonText="SELECT SCENARIOS"
        (buttonClick)="handleClick()"
        >
      </klt-section>
    </div>
  </div>

  <klt-cash-flow-statement-net-cashing
    [reit]="clientFacade.financialData"
    [metadata]="metadata"
  ></klt-cash-flow-statement-net-cashing>
  <klt-separator></klt-separator>
  <div class="cash-flow-statement-zone">
    <klt-cash-flow-statement-financing
      [reit]="clientFacade.financialData"
      [metadata]="metadata"
    ></klt-cash-flow-statement-financing>
    <klt-cash-flow-statement-investing
      [reit]="clientFacade.financialData"
      [metadata]="metadata"
    ></klt-cash-flow-statement-investing>
  </div>
  <klt-separator></klt-separator>
  @if (!(clientQuery.selectFinancialIsLoading$ | async)) {
    <klt-cash-flow-statement-table
      [metadata]="metadata"
      [reit]="clientFacade.financialData"
    ></klt-cash-flow-statement-table>
  }
</div>
