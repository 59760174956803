<div class="wrapper--table">
  @if (columns) {
    @if (showTopHeader) {
      <div class="top-header">
        @if (tableName) {
          <h3>{{ tableName }}</h3>
        }
        <div class="toolbar-container">
          <ng-content
            select="klt-table-toolbar, [kltTableToolbar]"
          ></ng-content>
        </div>
      </div>
    }
    <div class="table-container">
      <div class="table-center" #tableDivCenter>
        <klt-table-scroll
          #tableCenter
          [tableId]="tableId"
          [columns]="columns"
          [rowClasses]="rowClasses"
          [stickLeftColumns]="stickLeftColumns"
          [stickRightColumns]="stickRightColumns"
          [stickyHeader]="stickyHeader"
          [columnGroups]="columnGroups"
          [filteredColumns]="filteredColumns"
          [highlightSpaceRow]="highlightSpaceRow"
          [spacingAfterRow]="spacingAfterRow"
          [spacingAfterRowIndexes]="spacingAfterRowIndexes"
          [editableTable]="editableTable"
          [hideSection]="hideSection"
          [noPadding]="noPadding"
          [verticalGroupLine]="verticalGroupLine"
          [data]="data"
          (actionClick)="actionClick.emit($event)"
          (cellClick)="cellClick.emit($event)"
          (rowClick)="rowClick.emit($event)"
          (sort)="sort.emit($event)"
          (cellSaveClick)="cellSaveClick.emit($event)"
          [showTooltip]="showTooltip"
        ></klt-table-scroll>
      </div>
    </div>
    <!-- Table pagination -->
    @if (pageLength) {
      <klt-pagination
        [totalPages]="pageLength"
        [page]="pageIndex"
        [pageSize]="pageSize"
        (pageChange)="pageChange.emit($event)"
      ></klt-pagination>
    }
  }
</div>
