import { Component, Input } from '@angular/core';
import { ProductFeature } from '@kolytics/types/common';
import { FeaturedDirective } from '../../directives/featured.directive';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { HasClaimDirective } from '../../directives/has-claim.directive';


@Component({
  selector: 'klt-table-navigation',
  templateUrl: './table-navigation.component.html',
  styleUrls: ['./table-navigation.component.scss'],
  standalone: true,
  imports: [
    HasClaimDirective,
    RouterLinkActive,
    RouterLink,
    FeaturedDirective
],
})
export class ReitTableNavigationComponent {
  @Input() links: {
    path: string;
    text: string;
    feature?: string;
    claim?: string;
    dataParser?: any;
    keepQueryParams?: boolean;
  }[] = [
    {
      path: '/reits/my',
      text: 'My Reits',
      feature: ProductFeature.MyReits,
      keepQueryParams: true,
    },
    {
      path: '/reits/all',
      text: 'All Reits',
      feature: ProductFeature.AllReits,
      keepQueryParams: true,
    },
    {
      path: '/reits/sectors',
      text: 'Sectors',
      feature: ProductFeature.Sectors,
      claim: 'permission:sectors',
    },
  ];
}
