import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { formatNumber, formatPercentage } from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { EChartsOption } from 'echarts';
import {
  ISectorProjectionBalanceSheetResponse,
  ISeries,
} from '../../../interfaces';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-sectors-projections-balance-sheet-strength',
  templateUrl: './sectors-projections-balance-sheet-strength.component.html',
  styleUrls: ['./sectors-projections-balance-sheet-strength.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SectionComponent, GraphComponent],
})
export class SectorsProjectionsBalanceSheetStrengthComponent implements OnInit {
  public desc: string = '';
  @Input()
  set sector(
    balanceSheetResponse: ISectorProjectionBalanceSheetResponse | undefined,
  ) {
    if (balanceSheetResponse?.balanceSheet) {
      this.desc = balanceSheetResponse.desc;
      this.initGraph(balanceSheetResponse.balanceSheet);

      const pYears = [
        balanceSheetResponse.balanceSheet[0]?.series[0]?.year,
        balanceSheetResponse.balanceSheet[1]?.series[0]?.year,
        balanceSheetResponse.balanceSheet[2]?.series[0]?.year,
      ].sort();
      this.pastYears = [...new Set(pYears)].filter((e) => e).join('/');

      const prYears = [
        balanceSheetResponse.balanceSheet[0]?.series[1]?.year,
        balanceSheetResponse.balanceSheet[1]?.series[1]?.year,
        balanceSheetResponse.balanceSheet[2]?.series[1]?.year,
      ].sort();
      this.presentYears = [...new Set(prYears)].filter((e) => e).join('/');

      const fYears = [
        balanceSheetResponse.balanceSheet[0]?.series[2]?.year,
        balanceSheetResponse.balanceSheet[1]?.series[2]?.year,
        balanceSheetResponse.balanceSheet[2]?.series[2]?.year,
      ].sort();
      this.futureYears = [...new Set(fYears)].filter((e) => e).join('/');
    }
  }

  public options: EChartsOption | undefined = {
    grid: {
      show: true,
      borderWidth: 0,
      height: '86%',
      width: '90%',
      top: '6',
      left: '70',
    },
    xAxis: {
      type: 'value',
      axisLine: {
        show: true,
      },
      axisLabel: {
        margin: 22,
        formatter: (value: any) => {
          return formatNumber(value, 1) + 'x';
        },
        color: '#1E2337',
        fontSize: 12,
        lineHeight: 20,
      },
      axisTick: {
        show: false,
      },
      axisPointer: {
        show: true,
        type: 'none',
        label: {
          show: false,
        },
      },
    },
    yAxis: {
      type: 'value',
      name: 'LTV',
      nameLocation: 'middle',
      nameGap: 50,
      axisLabel: {
        formatter: (value: any) => {
          if (value + '' === '125') {
            return 'LTV';
          }
          return formatPercentage(value, 1);
        },
        margin: 20,
        color: '#1E2337',
        fontSize: 12,
        lineHeight: 20,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
      },
    },

    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: function (params: any) {
        if (params.componentType && params.componentType === 'series') {
          return `<div style="background: ${
            params.borderColor
          }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);">${
            params.seriesName
          } - ${params.data.name} (${formatPercentage(
            params.data.value[1],
            1,
          )} - ${formatNumber(params.data.value[0], 1)}x)</div>`;
        }
        return '';
      },
    },
  };
  public lines = [];
  public series: ISeries[] = [];

  public titles: {
    name: string;
    color: string;
    disabled: boolean;
    small?: boolean;
  }[] = [];

  pastYears!: string;
  presentYears!: string;
  futureYears!: string;

  constructor(protected readonly cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {}

  public runFilter(
    data: { name: string; color: string; disabled: boolean; small?: boolean }[],
  ): void {
    this.titles = data;
    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter((e) =>
      filter.includes(e.name.toLowerCase()),
    );
    (this.options as EChartsOption).series = [
      ...(series as any),
      ...this.lines,
    ];
    const temp = Object.assign({}, this.options);
    setTimeout(() => {
      this.options = temp;
      this.cdr.detectChanges();
    }, 0);
  }

  initGraph(sector: ISectorProjectionBalanceSheetResponse[]) {
    this.titles = [];
    this.series = [];

    let getAllDebtValues: number[] = [];
    let getAllDebtValuesStr: string[] = [];
    sector.forEach((s, index) => {
      this.titles.push({
        name: s.label,
        color:
          colorPallete[GraphId.SectorProjectionBalanceSheeetStrength][
            index %
              colorPallete[GraphId.SectorProjectionBalanceSheeetStrength].length
          ],
        disabled: false,
        small: true,
      });

      let lineData: any[] = [];
      let markPoint: any[] = [];
      let seriesData: any[] = [];
      s.series.forEach((d, i) => {
        seriesData.push({
          name: d.year,
          symbolSize: i == 0 ? 13 : 10,
          xAxis: d.data[0],
          value: d.data,
          symbol: i == 0 ? 'diamond' : i == 1 ? 'polygon' : 'circle',
          itemStyle: {
            borderColor:
              colorPallete[GraphId.SectorProjectionBalanceSheeetStrength][
                index %
                  colorPallete[GraphId.SectorProjectionBalanceSheeetStrength]
                    .length
              ],
            color:
              i == 2
                ? '#fff'
                : colorPallete[GraphId.SectorProjectionBalanceSheeetStrength][
                    index %
                      colorPallete[
                        GraphId.SectorProjectionBalanceSheeetStrength
                      ].length
                  ],
          },
        });
      });

      for (let value of seriesData) {
        getAllDebtValues.push(value.xAxis);
      }

      const currentYear = new Date().getFullYear();
      let actual = [seriesData[0], seriesData[1]];
      let forecast = [seriesData[1], seriesData[2]];

      this.series.push({
        data: forecast,
        type: 'line',
        smooth: true,
        showSymbol: true,
        color:
          colorPallete[GraphId.SectorProjectionBalanceSheeetStrength][
            index %
              colorPallete[GraphId.SectorProjectionBalanceSheeetStrength].length
          ],
        symbolSize: 0,
        name: s.label,
        lineStyle: {
          type: 'dashed',
          width: 2,
        },
      } as ISeries);

      this.series.push({
        data: actual,
        type: 'line',
        smooth: true,
        showSymbol: true,
        color:
          colorPallete[GraphId.SectorProjectionBalanceSheeetStrength][
            index %
              colorPallete[GraphId.SectorProjectionBalanceSheeetStrength].length
          ],
        symbolSize: 0,
        name: s.label,
        lineStyle: {
          width: 2,
        },
      } as ISeries);
    });

    getAllDebtValues.sort(function (a, b) {
      return a - b;
    });

    getAllDebtValues = getAllDebtValues.map((ele) => Math.ceil(ele));
    getAllDebtValues = getAllDebtValues.filter(
      (item, index) => getAllDebtValues.indexOf(item) === index,
    );

    ((this.options as EChartsOption).xAxis as any).data = getAllDebtValues;

    this.runFilter(this.titles);
  }
}
