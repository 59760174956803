import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconComponent } from '../../../../../shared-assets/src/lib/components/icon/icon.component';


/**
 * @name ModalComponent
 * @description Base container that should be used when creating a modal.
 *              Can be serve as base-template
 * @property modalTitle<string | undefined> - Title of the modal
 * @emits closeModal<boolean> - Fires when close modal is clicked
 */

@Component({
    selector: 'klt-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: true,
    imports: [IconComponent],
})
export class ModalComponent {
  @Input() headerVisible: boolean = true;
  @Input() modalTitle: string | undefined = '';
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
}
