<div class="sectors--detail--wrapper">
  <div class="page--header">
    <div class="page--header-container">
      <div class="h-left">
        <div class="eyebrow" (click)="back()">SECTORS</div>
        <h1>{{ name }}</h1>
        <div class="description">{{ numberOfReits }} REITs</div>
      </div>
      <div class="h-right" [class.hidden]="scrolling$ | async">
        <!--        <klt-button [rightIcon]="'down'" [dropdownExpanded]="dropdownExpanded" (click)="toggle($event)">DOWNLOAD REPORT-->
        <!--        </klt-button>-->
        <!--        <div class="options">-->
        <!--          <klt-dropdown-options [type]="'multi'" [open]="dropdownExpanded" [groupedOptions]="reportOptions"-->
        <!--                                [minimum]="1" (closed)="dropdownExpanded = false"></klt-dropdown-options>-->
        <!--        </div>-->
        <!-- (cleared)="clear()"
        (saved)="save($event)"
        (selected)="select($event)"
        (changed)="optionsChanged($event)"
        (closed)="onClosed()" -->
      </div>
    </div>
  </div>
  <div class="page--subheader">
    <div class="tabs-container">
      <div
        class="tab-item"
        routerLink="/reits/sectors/sector/{{ currentId }}/overview"
        [routerLinkActive]="['active']"
        queryParamsHandling="merge"
      >
        Overview
      </div>
      <div
        class="tab-item"
        routerLink="/reits/sectors/sector/{{ currentId }}/projections"
        [routerLinkActive]="['active']"
        queryParamsHandling="merge"
      >
        Projections
      </div>
      <div class="h-right" [class.show]="scrolling$ | async">
        <!--        <klt-button [rightIcon]="'down'" leftIcon="download" [dropdownExpanded]="dropdownExpanded" (click)="toggle($event)"> REPORT-->
        <!--        </klt-button>-->
        <!--        <div class="options">-->
        <!--          <klt-dropdown-options [type]="'multi'" [open]="dropdownExpanded" [groupedOptions]="reportOptions"-->
        <!--                                [minimum]="1" (closed)="dropdownExpanded = false"></klt-dropdown-options>-->
        <!--        </div>-->
        <!-- (cleared)="clear()"
        (saved)="save($event)"
        (selected)="select($event)"
        (changed)="optionsChanged($event)"
        (closed)="onClosed()" -->
      </div>
    </div>
  </div>
  <div class="page--content">
    <router-outlet></router-outlet>
  </div>
</div>
