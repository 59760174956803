<klt-modal modalTitle="Select region" (closeModal)="onClose()">
  <div class="filter-top-actions" [class.full]="searchExpanded">
    <klt-checkbox
      [sizeLarge]="true"
      [checked]="isAllToggled"
      [intermediate]="isIntermediateToggled"
      (valueChanged)="onToggleAll($event)"
      >
      Select all
    </klt-checkbox>
    <klt-input-expand
      layout="alt"
      iconSize="small"
      placeholder="Search filters"
      (valueChanged)="onValueChange($event)"
      (valueCleared)="onValueChange('')"
      (expand)="searchExpanded = $event"
      >
    </klt-input-expand>
  </div>
  <div class="filters">
    <ul>
      @for (col of filteredColumns$ | async; track col) {
        <li
          [class.selected]="col.selected"
          >
          <klt-checkbox
            [sizeLarge]="true"
            [checked]="col.selected"
            (valueChanged)="onSelectColumn($event, col)"
            >
            {{ col.title }}
          </klt-checkbox>
        </li>
      }
    </ul>
  </div>
  <div class="filter-bottom-actions">
    <klt-button type="secondary" (click)="onClose()">CANCEL</klt-button>
    <klt-button type="primary" (click)="onSubmit()">APPLY CHANGES</klt-button>
  </div>
</klt-modal>
