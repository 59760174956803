import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { KltModalRefService } from '@kolytics/shared-components';
import { ButtonComponent } from '../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { InputComponent } from '../../../../../../../../libs/shared-components/src/lib/components/form/input/input.component';
import { ModalComponent } from '../../../../../../../../libs/shared-components/src/lib/components/modal/modal.component';

@Component({
    selector: 'klt-create-scenario-modal',
    templateUrl: './create-scenario-modal.component.html',
    styleUrls: ['./create-scenario-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ModalComponent,
        InputComponent,
        ButtonComponent,
    ],
})
export class CreateScenarioModalComponent implements OnInit {
  public name = 'New Scenario';

  constructor(protected readonly kltModalRefService: KltModalRefService) {}

  ngOnInit(): void {}

  public onClose(): void {
    this.kltModalRefService.close(CreateScenarioModalComponent, undefined);
  }

  public onCreate(): void {
    this.kltModalRefService.close(CreateScenarioModalComponent, this.name);
  }
}
