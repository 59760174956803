@if (!useOldTable) {
  <klt-table-v2
    [stickyTop]="stickyTable"
    [loading]="false"
    [data]="reitTableData.data"
    [columns]="reitTableData.headers"
    [columnGroups]="reitTableData.sections"
    [filteredColumnKeys]="filteredColumns"
    [top]="isPopup ? 57 : 187"
    [showTooltipField]="true"
    >
    @if (showToolbar) {
      <klt-table-toolbar>
        @if (!isPopup) {
          <div class="toolbar">
            <div class="toolbar--left">
              @if (!isPopup) {
                <klt-link-action
                  [iconRight]="'pop-out'"
                  (click)="popout()"
                  [text]="'Pop-out'"
                ></klt-link-action>
              }
            </div>
            <div class="toolbar--right">
              <klt-link-action
                kltFeatured
                [feature]="downloadsFeature"
                [iconLeft]="'download'"
                [text]="'Download Excel'"
                (click)="downloadExcel()"
              ></klt-link-action>
              <klt-loading-spinner
                [show]="excelLoading"
                [label]="''"
              ></klt-loading-spinner>
              <klt-financials-compare
                (afterClosed)="closeCompareDialog($event)"
              ></klt-financials-compare>
            </div>
          </div>
        }
      </klt-table-toolbar>
    }
  </klt-table-v2>
}

@if (useOldTable) {
  <div class="cash-flow-statement-table">
    <klt-table
      [columnGroups]="reitTableData.sections"
      [columns]="reitTableData.headers"
      [filteredColumns]="filteredColumns"
      [stickLeftColumns]="reitTableData.stickLeftColumns"
      [stickRightColumns]="reitTableData.stickRightColumns"
      [stickyHeader]="true"
      [data]="reitTableData.data"
      [pageLength]="reitTableData.total"
      [showTopHeader]="showTopHeader"
      (sort)="onTableSort($event)"
      >
      @if (showToolbar) {
        <klt-table-toolbar>
          @if (!isPopup) {
            <div class="toolbar">
              <div class="toolbar--left">
                @if (!isPopup) {
                  <klt-link-action
                    [iconRight]="'pop-out'"
                    (click)="popout()"
                    [text]="'Pop-out'"
                  ></klt-link-action>
                }
              </div>
              <div class="toolbar--right">
                <klt-link-action
                  kltFeatured
                  [feature]="downloadsFeature"
                  [iconLeft]="'download'"
                  [text]="'Download Excel'"
                  (click)="downloadExcel()"
                ></klt-link-action>
                <klt-loading-spinner
                  [show]="excelLoading"
                  [label]="''"
                ></klt-loading-spinner>
                <klt-financials-compare
                  (afterClosed)="closeCompareDialog($event)"
                ></klt-financials-compare>
              </div>
            </div>
          }
        </klt-table-toolbar>
      }
    </klt-table>
  </div>
}
