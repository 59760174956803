import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  KltModalRefService,
  ModalData,
  OpenedModalData,
  TableData,
} from '@kolytics/shared-components';
import { ButtonComponent } from '../../components/common/button/button.component';
import { ModalComponent } from '../../components/modal/modal.component';

@Component({
    selector: 'klt-remove-modal',
    templateUrl: './remove-modal.component.html',
    styleUrls: ['./remove-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ModalComponent, ButtonComponent],
})
export class RemoveModalComponent implements OnInit {
  data!: any;

  constructor(
    @Inject('KLT_MODAL_DATA')
    protected readonly modalData: ModalData<any>,
    protected readonly kltModalRefService: KltModalRefService,
  ) {}

  public ngOnInit(): void {
    this.data = this.modalData;
  }

  public onClose(): void {
    this.kltModalRefService.close(RemoveModalComponent, false);
  }

  public onRemove(): void {
    this.kltModalRefService.close(RemoveModalComponent, true);
  }
}
