import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
} from '@angular/forms';
import { ContentChange, QuillEditorComponent, QuillModules } from 'ngx-quill';
import Quill from 'quill';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { dropdownAnimation } from '../../animations';
import { NgClass } from '@angular/common';

/**
 * @name TextEditorComponent
 * @desc Rich Text Editor
 *
 * @package https://www.npmjs.com/package/ngx-quill
 *
 * @param modules<QuillModules|undefined> - Editor configuration. Please check documentation for more info.
 */

@Component({
  selector: 'klt-text-editor',
  templateUrl: './text-editor.component.html',
  animations: [dropdownAnimation()],
  styleUrls: ['./text-editor.component.scss'],
  standalone: true,
  imports: [NgClass, QuillEditorComponent, FormsModule],
})
export class TextEditorComponent implements OnInit {
  @ViewChild('editor', { static: true }) editor!: QuillEditorComponent;

  @Output()
  public readonly change: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public readonly changeText: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  instanceCreated = new EventEmitter<Quill>();

  @Input()
  placeholder: string = '';

  @Input()
  sanitize: boolean = false;

  @Input()
  modules!: QuillModules;

  form!: FormGroup;
  localSelectedValue!: string;

  get value(): any {
    return this.localSelectedValue;
  }
  @Input() set value(v: any) {
    if (!this.localSelectedValue) {
      this.localSelectedValue = v;
    }
  }

  @Input()
  required = false;

  @Input()
  error = false;

  @Input()
  showCharacterCount = false;

  @Input()
  allowCharacterOverflow = false;

  @Input()
  maxCharacters = Number.MAX_VALUE;

  characters = 0;

  content: any = '';
  quillEditorRef!: Quill;

  private _errorLabel: string = '';
  public errorMessage: string = '';

  get errorLabel(): string {
    return this._errorLabel;
  }

  @Input() set errorLabel(value: string) {
    this._errorLabel = value;
    if (value) {
      this.errorMessage = value;
    }
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      formValue: new FormControl(this.value || ''),
    });

    this.editor.onContentChanged
      .pipe(distinctUntilChanged())
      .subscribe((data: ContentChange) => {
        this.characters = data.editor.getLength() - 1;
      });

    this.editor.onContentChanged
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((data: ContentChange) => {
        if (this.maxCharacters !== -1) {
          if (
            data.editor.getLength() > this.maxCharacters &&
            !this.allowCharacterOverflow
          ) {
            data.editor.deleteText(this.maxCharacters, data.editor.getLength());
          }
        }

        this.error =
          this.required && (data.html === null || data.text.trim().length === 0)
            ? true
            : false;

        this.change.emit(data.html || '');
        this.changeText.emit(data.text || '');
      });
  }

  getFormClasses() {
    const classes = [];

    if (
      this.error ||
      (this.allowCharacterOverflow && this.characters > this.maxCharacters)
    ) {
      classes.push('error');
    }
    return classes.join(' ');
  }

  getEditorInstance(editorInstance: any) {
    this.quillEditorRef = editorInstance;
    this.instanceCreated.emit(this.quillEditorRef);
  }
}
