import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OpenedModal } from '@kolytics/shared-components';

/**
 * @name ModalGlobalsService
 * @description Store global data that will be called from
 *              ModalService & ModalRefService.
 *              Note: Should be injected to the root to avoid conflicts
 * @property openedModals<OpenedModal[]> - Store collections of opened modals
 * @property transitionMaxDuration<number> - Overall transition duration from the modal
 * @property afterClosed<Subject<any | undefined> - Gets the returned data from the modal after closing
 */
@Injectable({
  providedIn: 'root',
})
export class ModalGlobalsService {
  openedModals: OpenedModal[] = [];

  readonly transitionMaxDuration: number = 500;

  readonly afterClosed = new Subject<any | undefined>();
}
