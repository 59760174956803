@if (show) {
  <div
    class="outer-container {{ classes }}"
    [class.sticky]="sticky"
    [attr.style]="styles"
  >
    <div class="container-loader">
      <div class="inner">
        <div class="loader size-{{ size }}"></div>
      </div>
      @if (label) {
        <div class="label">
          {{ label }}
        </div>
      }
    </div>
  </div>
}
