<div class="overview-investment">
  <klt-section
    title="Investment locations"
    type="button"
    buttonIconLeft="download"
    buttonText="DOWNLOAD"
    (buttonClick)="downloadExcel()"
    [ctaFeature]="downloadsFeature"
    [showCta]="reitLocal !== null"
  >
    <!-- <klt-loading-spinner [show]="excelLoading" [label]="''"></klt-loading-spinner> -->
    <div class="map-box">
      <div class="map-zone">
        @if (reitLocal) {
          <klt-map
            [zoom]="zoom"
            [source]="source"
            [markers]="markers"
            [current]="current"
            [displayPropertyData]="displayPropertyData"
            (expand)="expand()"
          ></klt-map>
        }
      </div>
    </div>
  </klt-section>
</div>
