import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { dropdownAnimation } from '../../../animations';
import { DropDownOption, DropDownAction } from '../../../interfaces';
import { IconComponent } from '../../../../../../shared-assets/src/lib/components/icon/icon.component';


@Component({
    selector: 'klt-dropdown-actions',
    templateUrl: './dropdown-actions.component.html',
    styleUrls: ['./dropdown-actions.component.scss'],
    animations: [dropdownAnimation()],
    standalone: true,
    imports: [
    IconComponent
],
})
export class DropdownActionsComponent {
  @ViewChild('wrapper')
  public wrapper!: ElementRef;

  @Input() public open = false;
  @Input() public options: DropDownOption[] = [];
  @Input() public actions: DropDownAction[] = [];

  @Output() public readonly closed = new EventEmitter<void>();

  @Output() public readonly selectedOption = new EventEmitter<DropDownOption>();
  @Output() public readonly selectedAction = new EventEmitter<DropDownAction>();

  public selectOption(option: DropDownOption): void {
    this.selectedOption.emit(option);
    this.closed.emit();
  }

  public selectAction(action: DropDownAction): void {
    this.selectedAction.emit(action);
    this.closed.emit();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any): void {
    const wrapperRef = this.wrapper?.nativeElement;
    if (wrapperRef && !wrapperRef.contains(target)) {
      this.open = false;
      this.closed.emit();
    }
  }
}
