import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from '../input/input.component';
import { IconComponent } from '../../../../../../shared-assets/src/lib/components/icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'klt-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    standalone: true,
    imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    IconComponent
],
})

/**
 * @class {CheckboxComponent}
 * @extends {InputComponent}
 * @description Standalone checkbox form component.
 *
 * @description Checkbox label must be text only if passed through the 'label' @Input parameter.
 *
 * If you need to have additional functionality on the label (i.e. links, or popups on click)
 * omit 'label' @Input entirely, and pass the label element directly as a child
 *
 * ex: <ark-checkbox><div (click)="doSomething()">My Super Fancy Label</div></ark-checkbox>
 *
 * @implements OnInit
 */
export class CheckboxComponent
  extends InputComponent
  implements OnChanges, OnInit
{
  @Input() sizeLarge: boolean = false;
  @Input() checked: boolean = false;
  @Input() intermediate: boolean = false;

  constructor(fb: FormBuilder) {
    super(fb);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes?.checked?.currentValue !== undefined ||
      changes?.checked?.currentValue !== null
    ) {
      this.formStates.selected =
        changes?.checked?.currentValue || changes?.intermediate?.currentValue;
    }
  }

  ngOnInit(): void {
    if (this.disabled) {
      this.formStates.disabled = true;
      this.tabIndex = -1;
    }
  }

  toggleSelection(evt: Event): void {
    evt.stopPropagation();
    evt.preventDefault();
    if (!this.disabled) {
      this.formStates.selected = !this.formStates.selected;
      if (this.required) {
        this.formStates.valid = this.formStates.selected;
        this.formStates.error = !this.formStates.valid;
      } else {
        this.formStates.valid = true;
        this.formStates.error = false;
      }
      this.valueChanged.emit({
        value: this.formStates.selected,
        status: this.formStates.valid ? 'VALID' : 'INVALID',
      });
    }
  }

  onFocus(): void {
    this.formStates.focused = true;
  }

  onBlur(): void {
    this.lostFocus.emit({
      value: this.formStates.selected,
      status: this.formStates.valid ? 'VALID' : 'INVALID',
    });
    this.formStates.focused = false;
  }
}
