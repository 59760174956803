import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  effect,
  input,
  signal,
} from '@angular/core';
import { mapSetting } from 'libs/shared-assets/src/lib/assets/settings';
import { GeoJSONSource, LngLatLike, Map } from 'mapbox-gl';
import { Feature } from 'geojson';
import { IconComponent } from '../../../../../shared-assets/src/lib/components/icon/icon.component';

import { MapTooltipComponent } from './map-tooltip/map-tooltip.component';
// import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import * as mapboxgl from 'mapbox-gl';
import { environment } from '@kolytics/envs';

type MarkerType = {
  lngLat: LngLatLike;
  type: 'circle' | 'position';
  hideContent?: boolean;
  size?: 'small' | 'large' | 'medium';
  content?: string;
};

@Component({
  selector: 'klt-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MapTooltipComponent, IconComponent],
})
export class MapComponent implements OnInit, AfterViewInit {
  private SOURCE_ID = 'points';
  private LAYER_ID = 'points';

  map!: mapboxgl.Map;
  @ViewChild('mapElement') mapElement!: ElementRef;

  @ViewChild('iconElement') iconElement!: ElementRef;

  @ViewChildren('matTooltip') matTooltips!: QueryList<MapTooltipComponent>;

  @Input()
  public displayPropertyData!: boolean;

  @Input()
  public zoom = signal(4.8);

  @Input()
  public closeButtonVisible: boolean = false;

  public current = input<LngLatLike>([0.7839646685014259, 52.07317888945238]);
  public markers = input<MarkerType[]>([]);
  public source = input<GeoJSON.FeatureCollection<GeoJSON.Geometry>>({
    type: 'FeatureCollection',
    features: [],
  });

  @Output()
  public expand: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output()
  public close: EventEmitter<unknown> = new EventEmitter<unknown>();

  public style: any = mapSetting;

  constructor(protected readonly cdr: ChangeDetectorRef) {
    effect(() => {
      this.map.setZoom(this.zoom());
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.map = new mapboxgl.Map({
      accessToken: environment.mapbox.accessToken,
      container: this.mapElement.nativeElement,
      style: mapSetting.style as any,
      zoom: this.zoom(),
      center: [12.550343, 55.665957],
    });
    this.map.on('click', (e) => {
      const bbox: any = [
        [e.point.x - 5, e.point.y - 5],
        [e.point.x + 5, e.point.y + 5],
      ];

      const selectedFeatures = this.map.queryRenderedFeatures(bbox, {
        layers: ['points'],
      });
      if (selectedFeatures.length > 0) {
        this.map.flyTo({
          center: [
            selectedFeatures[0].properties?.longitude,
            selectedFeatures[0].properties?.latitude,
          ],
          zoom: 8,
          animate: true,
        });
      }
    });
    this.map.on('load', () => {
      setTimeout(() => {
        this.handleMap();
      }, 2000);
    });
  }

  public plus(): void {
    this.map.setZoom(this.map.getZoom() + 0.1);
  }

  public minus(): void {
    this.map.setZoom(this.map.getZoom() - 0.1);
  }

  handleMap() {
    this.markers().forEach((item: any) => {
      new mapboxgl.Marker(this.iconElement.nativeElement)
        .setLngLat(item.lngLat)
        .addTo(this.map);
    });

    this.map.setCenter(this.current());
    this.map.addSource(this.SOURCE_ID, {
      type: 'geojson',
      cluster: true,
      data: this.source(),
    });
    this.map.addLayer({
      id: this.LAYER_ID,
      type: 'circle',
      source: this.SOURCE_ID,
      filter: ['has', 'point_count'],
      paint: {
        'circle-stroke-width': 20,
        'circle-stroke-color': '#e3d0e3',
        'circle-stroke-opacity': 0.5,
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#8c648c',
          100,
          '#8c648c',
          750,
          '#8c648c',
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 1, 20],
      },
    });

    this.map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: this.SOURCE_ID,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-size': 14,
      },
      paint: {
        'text-color': '#fff',
        'text-opacity': 1,
      },
    });

    this.map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: this.SOURCE_ID,
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#8c648c',
        'circle-radius': 6,
        'circle-stroke-width': 12,
        'circle-stroke-color': '#e3d0e3',
        'circle-stroke-opacity': 0.5,
      },
    });

    this.handleTooltip(this.map);
  }

  handleTooltip(map: mapboxgl.Map) {
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    let tooltip: MapTooltipComponent | undefined;
    map.on('mouseenter', 'unclustered-point', (e: any) => {
      map.getCanvas().style.cursor = 'pointer';

      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      tooltip = this.matTooltips.find(
        (t: any) => t.feature.properties.id == e.features[0].properties.id,
      );
      if (tooltip) {
        tooltip.hide = false;
        popup
          .setMaxWidth('300px')
          .setOffset(20)
          .setLngLat(coordinates)
          .setHTML(tooltip.element.nativeElement.innerHTML)
          .addTo(map);
      }
    });
    map.on('mouseleave', 'unclustered-point', () => {
      if (tooltip) {
        tooltip.hide = true;
      }
      map.getCanvas().style.cursor = '';
      popup.remove();
    });
  }
}
