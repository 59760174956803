import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  get userId() {
    return localStorage.getItem('userId');
  }

  constructor() {}

  setAccessToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  setUserId(userId: number) {
    return localStorage.setItem('userId', userId + '');
  }

  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  removeAccessToken() {
    localStorage.removeItem('access_token');
  }

  clear() {
    this.removeAccessToken();
  }

  isLoggedIn() {
    return !!this.getAccessToken();
  }

  logout() {
    this.clear();
  }
}
