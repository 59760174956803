<div class="map-tooltip-wrapper">
  <div class="map-tooltip-body" #content>
    <div class="prop-name">{{ feature.properties?.propertyName }}</div>
    <div class="sub-sector">{{ feature.properties?.subSector }}</div>
    <div class="item-content">
      <div class="item">
        Ownership <b>{{ feature.properties?.ownership }}%</b>
      </div>
      <div class="item">
        Total est.rent
        <b>{{
          !displayPropertyData
            ? 'n/a'
            : mFormatter(feature.properties?.assetRent)
        }}</b>
      </div>
      <div class="item">
        Total SF <b>{{ kFormatter(feature.properties?.assetSf) }}</b>
      </div>
      <div class="item">
        100% value
        <b>{{
          !displayPropertyData
            ? 'n/a'
            : mFormatter(feature.properties?.assetValue)
        }}</b>
      </div>
    </div>
  </div>
</div>
