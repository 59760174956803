import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FeatureService } from '../../services';
import { IconComponent } from '../../../../../shared-assets/src/lib/components/icon/icon.component';
import { FeaturedDirective } from '../../directives/featured.directive';
import { ButtonComponent } from '../common/button/button.component';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'klt-section',
    templateUrl: './section.component.html',
    styleUrls: ['./section.component.scss'],
    standalone: true,
    imports: [
    NgTemplateOutlet,
    ButtonComponent,
    FeaturedDirective,
    IconComponent
],
})
export class SectionComponent implements OnInit {
  @Input()
  public title: string = '';

  @Input()
  public type: 'none' | 'button' | 'pagination' = 'none';

  @Input()
  public length: number = 0;

  @Input()
  public buttonText: string = '';

  @Input()
  public buttonIconLeft!: string;

  @Input()
  public buttonIconRight!: string;

  public pages: number[] = [];

  public pageActive = 1;

  @Input() showCta = true;

  @Input()
  public showTitleScroll = true;

  @Input()
  public showPagination = true;

  @Input() ctaFeature!: string;
  get hasFeature() {
    if (!this.ctaFeature) return true;
    return this.featureService.hasFeature(this.ctaFeature);
  }

  @Input() sectionTitleClass = '';

  @Output()
  public readonly paginate: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public buttonClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private featureService: FeatureService) {}

  public ngOnInit(): void {
    this.initPages();
  }

  public initPages() {
    this.pages = [];

    const size = parseInt(this.length / 3 + '') + (this.length % 3 > 0 ? 1 : 0);
    for (let i = 1; i <= size; i++) {
      this.pages.push(i);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.length?.currentValue) {
      this.initPages();
    }
  }

  public increase(): void {
    if (this.pageActive < this.pages.length) {
      this.pageActive += 1;
      this.paginate.emit(this.pageActive);
    }
  }

  public decrease(): void {
    if (this.pageActive > 1) {
      this.pageActive -= 1;
      this.paginate.emit(this.pageActive);
    }
  }

  public onClick(): void {
    this.buttonClick.emit();
  }
}
