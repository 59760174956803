import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { formatPercentage } from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { EChartsOption } from 'echarts';
import {
  ISectorProjectionCostAnalysisDesc,
  ISectorProjectionCostAnalysisResponse,
  ISectorProjectionCostAnalysisSeries,
} from '../../../interfaces';
import { IconComponent } from '../../../../../../../../../libs/shared-assets/src/lib/components/icon/icon.component';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-sectors-projections-cost-analysis',
  templateUrl: './sectors-projections-cost-analysis.component.html',
  styleUrls: ['./sectors-projections-cost-analysis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SectionComponent, GraphComponent, IconComponent],
})
export class SectorsProjectionsCostAnalysisComponent implements OnInit {
  labels: string[] = [];

  @Input() desc: ISectorProjectionCostAnalysisDesc | undefined;
  @Input()
  set sector(
    costAnalysisResponse: Record<string, Record<string, number>> | undefined,
  ) {
    if (costAnalysisResponse) {
      this.initGraph(costAnalysisResponse);
    }
  }

  public options: EChartsOption | undefined = {
    grid: {
      containLabel: true,
      backgroundColor: '#ffffff',
      show: true,
      borderColor: '#ffffff',
      borderWidth: 0,
      height: '86%',
      width: '90%',
      top: '6',
      left: '70',
    },
    xAxis: {
      axisLine: {
        show: false,
      },
      axisLabel: {
        formatter: (value: any) => {
          // value = value.split(/(\s+)/)[0];
          if (value + '' === '200') {
            return '200      ';
          }
          return formatPercentage(value, 2);
        },
      },
      type: 'value',
      axisTick: {
        show: false,
      },
      axisPointer: {
        show: true,
        type: 'none',
        label: {
          show: false,
        },
      },
    },
    yAxis: {
      type: 'category',
      axisTick: {
        show: false,
      },
      data: [],
      splitLine: {
        show: false,
      },
      axisLabel: {
        lineHeight: 7,
        formatter: (value: any, index: number) => {
          value = value.split(/(\s+)/).join('\r\n');
          return value;
        },
      },
    },
    series: [],
    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: function (params: any) {
        if (params.componentType && params.componentType === 'series') {
          return `<div style="background: ${
            params.color
          }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);">${params.seriesName.toUpperCase()}-${
            params.name
          }: (${formatPercentage(params.value, 1)})</div>`;
        }
        return '';
      },
    },
  };
  public series: ISectorProjectionCostAnalysisSeries[] = [];

  public titles: {
    name: string;
    color: string;
    disabled: boolean;
    small?: boolean;
  }[] = [
    {
      name: 'DIRECT COSTS',
      color: colorPallete[GraphId.SectorProjectionCostAnalysis][0],
      disabled: false,
      small: true,
    },
    {
      name: 'G&A',
      color: colorPallete[GraphId.SectorProjectionCostAnalysis][1],
      disabled: false,
      small: true,
    },
    {
      name: 'CAPEX',
      color: colorPallete[GraphId.SectorProjectionCostAnalysis][2],
      disabled: false,
      small: true,
    },
    {
      name: 'INTEREST',
      color: colorPallete[GraphId.SectorProjectionCostAnalysis][3],
      disabled: false,
      small: true,
    },
  ];
  public active = '';
  constructor(protected readonly cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.runFilter(this.titles);
  }

  public runFilter(
    data: { name: string; color: string; disabled: boolean; small?: boolean }[],
  ): void {
    this.titles = data;
    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter((e) =>
      filter.includes(e.name.toLowerCase()),
    );
    (this.options as EChartsOption).series = [...(series as any)];
    const temp = Object.assign({}, this.options);
    // this.options = undefined;
    setTimeout(() => {
      this.options = temp;
      this.cdr.detectChanges();
    }, 0);
  }

  initGraph(records: Record<string, Record<string, number>>) {
    this.labels = [];
    this.series = [];

    this.labels.push(...Object.keys(records['CAPEX']));
    this.titles.forEach((e) => {
      this.series.push({
        name: e.name,
        type: 'bar',
        stack: 'reit',
        color: e.color,
        emphasis: {
          focus: 'series',
        },
        data: Object.values(records[e.name]),
      });
    });

    ((this.options as EChartsOption).yAxis as any).data = this.labels;
    this.runFilter(this.titles);
  }
}
