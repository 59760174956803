import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { FeatureService } from '@kolytics/shared-components';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeaturedGuard {
  constructor(private featureService: FeatureService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const features = route.data['features'] as string[];
    if (!features || features.length <= 0) {
      return true;
    }
    const featured = features.some((feature) =>
      this.featureService.hasFeature(feature),
    );
    return featured;
  }
}
