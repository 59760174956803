import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import {
  TableColumnGroup,
  TableColumns,
  TableData,
  TableFormattedColumnGroup,
  TableSort,
} from '@kolytics/shared-components';

import { KltToastService } from '../../../services/toast/toast.service';
import { NegativeFormatterPipe } from '../../../pipes/negative-formatter.pipe';
import { FeaturedDirective } from '../../../directives/featured.directive';
import { ClickOutsideDirective } from '../../../directives/click-outside.directive';
import { TableNumberFormatterDirective } from '../../../directives/table-number-formatter.directive';
import { ChangeHighlightDirective } from '../../../directives/change-highlight.directive';
import { InputComponent } from '../../form/input/input.component';
import { NgxEchartsDirective } from 'ngx-echarts';
import { IconComponent } from '../../../../../../shared-assets/src/lib/components/icon/icon.component';
import { ButtonComponent } from '../../common/button/button.component';
import { NgClass, NgIf, NgFor, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'klt-table-scroll',
  templateUrl: './table-scroll.component.html',
  styleUrls: ['./table-scroll.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgFor,
    ButtonComponent,
    NgSwitch,
    NgSwitchCase,
    IconComponent,
    NgxEchartsDirective,
    InputComponent,
    ChangeHighlightDirective,
    TableNumberFormatterDirective,
    ClickOutsideDirective,
    FeaturedDirective,
    NegativeFormatterPipe,
  ],
})
export class TableScrollComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() tableId: string = '';
  @Input() columnGroups: TableColumnGroup[] | undefined;
  @Input() columns: TableColumns | undefined;
  @Input() data: TableData[] | undefined;
  @Input() filteredColumns: string[] | undefined;
  @Input() stickLeftColumns: TableColumns | undefined;
  @Input() stickRightColumns: TableColumns | undefined;
  @Input() spacingAfterRow: number | undefined;
  @Input() spacingAfterRowIndexes: number[] | undefined;
  @Input() highlightSpaceRow: number | undefined;
  @Input() editableTable: boolean | undefined;
  @Input() hideSection: boolean | undefined;
  @Input() stickyHeader: boolean | undefined;
  @Input() noPadding: boolean = false;
  @Input() verticalGroupLine: boolean = false;
  @Input() showTooltip: boolean = false;
  @Input() rowClasses = '';
  @ViewChild('tableScroll')
  public readonly tableScroll!: ElementRef<HTMLDivElement>;

  @ViewChild('shadowLeft')
  public readonly shadowLeft!: ElementRef<HTMLDivElement>;

  @ViewChild('shadowRight')
  public readonly shadowRight!: ElementRef<HTMLDivElement>;

  @ViewChild('section')
  public readonly section!: ElementRef<HTMLTableRowElement>;

  tableFormArray: FormArray | undefined;

  columnDef: string[] = [];
  columnLeftDef: string[] = [];
  columnRightDef: string[] = [];
  sortedColumn: TableSort | undefined;

  formattedColumnGroups: TableFormattedColumnGroup[] = [];
  showShadowLeft: boolean = false;
  showShadowRight: boolean = false;
  hideSectionForSticky = false;

  openedEditableWizard: string | undefined;

  lastOpenened: any;
  lastIsOpened: boolean = false;

  @Output() sort = new EventEmitter<TableSort | undefined>();
  @Output() rowClick = new EventEmitter<TableData>();
  @Output() actionClick = new EventEmitter<{ name: string; data: TableData }>();
  @Output() cellClick = new EventEmitter<{ name: string; data: TableData }>();
  @Output() cellSaveClick = new EventEmitter<{
    value: string;
    row: number;
    cell: number;
  }>();

  @HostListener('document:click', ['$event'])
  onClick(event: PointerEvent) {
    // const targetElement: HTMLElement = event.target as HTMLElement;
    // const editWizardClassName: string = 'edit-wizard';
    // if (!this.hasClass(targetElement, editWizardClassName)) {
    //   if (this.openedEditableWizard) {
    //     const editWizardEl: HTMLElement = document.querySelector(this.openedEditableWizard) as HTMLElement;
    //     this.renderer.removeClass(editWizardEl, 'show');
    //     this.openedEditableWizard = undefined;
    //   }
    // }
  }

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    protected readonly toast: KltToastService,
  ) {}

  public ngOnChanges(): void {
    if (!this.columns) {
      throw new Error('Column definitions cannot be empty!');
    }
    if (this.filteredColumns) {
      if (this.columns) {
        if (this.filteredColumns.length) {
          this.columnDef = Object.keys(this.columns).filter(
            (h) => this.filteredColumns?.indexOf(h) !== -1,
          );
        } else {
          this.columnDef = Object.keys(this.columns);
        }
      }
      if (this.stickLeftColumns) {
        if (this.filteredColumns.length) {
          this.columnLeftDef = Object.keys(this.stickLeftColumns).filter(
            (h) => this.filteredColumns?.indexOf(h) !== -1,
          );
        } else {
          this.columnLeftDef = Object.keys(this.stickLeftColumns);
        }
      }
      if (this.stickRightColumns) {
        if (this.filteredColumns.length) {
          this.columnRightDef = Object.keys(this.stickRightColumns).filter(
            (h) => this.filteredColumns?.indexOf(h) !== -1,
          );
        } else {
          this.columnRightDef = Object.keys(this.stickRightColumns);
        }
      }
      this.formatTableColumnGroup();
    }

    if (this.tableScroll) {
      this.handleScroll();
    }
  }

  public ngOnInit(): void {
    if (!this.columns) {
      throw new Error('Column definitions cannot be empty!');
    }
    this.columnDef = Object.keys(this.columns);
    if (this.stickLeftColumns) {
      this.columnLeftDef = Object.keys(this.stickLeftColumns);
    }
    if (this.stickRightColumns) {
      this.columnRightDef = Object.keys(this.stickRightColumns);
    }
    this.formatTableColumnGroup();
  }

  public ngAfterViewInit(): void {
    this.handleScroll();
  }

  public onClickRow(tableRowData: TableData): void {
    this.rowClick.emit(tableRowData);
  }

  public getIcon(row: TableData, col: string): string {
    const action = row[col]?.action;
    return action ? action.icon : '';
  }

  public getStyle(row: TableData, col: string): string {
    const action = row[col]?.action;
    return action ? action.style : 'basic';
  }

  public onClickAction(name: string, data: TableData): void {
    this.actionClick.emit({ name, data });
  }
  public onClickCell(name: string, data: TableData): void {
    this.cellClick.emit({ name, data });
  }

  public get spacingColumns(): {
    count: number;
    spacing?: boolean;
    spacingFill?: string;
  }[] {
    if (this.columnGroups) {
      const count = this.columnGroups.filter((e) => e.spacing).length;
      if (count > 0) {
        const columns = this.columnGroups.map((e, i) => ({
          count: e.columnDefs.length,
          spacing: e.spacing,
          spacingFill: e.spacingFill,
        }));
        const rs: { count: number; spacing?: boolean }[] = [columns[0]];
        let k = 0;
        if (columns[0].spacing && columns[1]) {
          rs.push(columns[1]);
          k = 1;
        }
        for (let i = 1; i < columns.length; i++) {
          let column = columns[i];
          if (column.spacing) {
            rs.push(column);
            k = rs.length - 1;
          } else {
            rs[k].count + column.count;
          }
        }
        return rs.map((e, i) => ({
          ...e,
          count:
            i === 0
              ? e.count + this.columnLeftDef.length
              : i === rs.length - 1
                ? e.count + this.columnRightDef.length
                : e.count,
        }));
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  public onSort(columnDef: string): void {
    if (!this.sortedColumn || columnDef !== this.sortedColumn.sortedBy) {
      this.sortedColumn = { sortedBy: columnDef, state: 'asc' };
    } else if (columnDef === this.sortedColumn.sortedBy) {
      if (this.sortedColumn.state === 'asc') {
        this.sortedColumn.state = 'desc';
      } else {
        this.sortedColumn = undefined;
      }
    }
    this.sort.emit(this.sortedColumn);
  }

  public onEditCell(
    data: any,
    rowIndex: number,
    colIndex: number,
    col: string,
    event: any,
  ): void {
    this.lastIsOpened = false;
    if (this.lastOpenened) {
      this.lastOpenened.opened = false;
    }
    this.lastOpenened = data;

    data.opened = true;
    const { x, y } = event.target.getBoundingClientRect();
    if (!data?.editable) {
      return;
    }

    // We need to delay the logic to prevent conflict with the click HostListener
    setTimeout(() => {
      this.openedEditableWizard = `#editable-cell-${col}-${rowIndex}-${colIndex}`;

      const editableWizardEl: HTMLElement = document.querySelector(
        this.openedEditableWizard,
      ) as HTMLElement;
      const { classList } = editableWizardEl;
      if (!classList.contains('show')) {
        this.renderer.setStyle(editableWizardEl, 'top', y - 10 + 'px');
        this.renderer.setStyle(editableWizardEl, 'left', x - 10 + 'px');
        this.renderer.addClass(editableWizardEl, 'show');
      }

      setTimeout(() => {
        const inputEl: HTMLInputElement = document.querySelector(
          `${this.openedEditableWizard} input`,
        ) as any;
        inputEl.select();

        this.lastIsOpened = true;
      }, 100);
    }, 2);
  }

  public getPosition(
    element: HTMLElement,
    hover: boolean,
  ): { top: string; left: string } {
    const { offsetWidth } = element;
    const { x, y } = element.getBoundingClientRect();
    const position = {
      top: `${hover ? y + 66 : y + 78}px`,
      left: `${x + offsetWidth / 2 - 10}px`,
    };
    return position;
  }

  onDismissWizard(data?: any): void {
    if (this.lastIsOpened) {
      data.opened = false;
      this.lastIsOpened = false;

      if (!this.openedEditableWizard) {
        return;
      }

      const editWizardEl = document.querySelector(this.openedEditableWizard);

      this.renderer.removeClass(editWizardEl, 'show');
      this.openedEditableWizard = undefined;
    }
  }
  public onCloseEditWizard(event: MouseEvent, data?: any): void {
    event.preventDefault();
    event.stopPropagation();

    data.opened = false;
    if (!this.openedEditableWizard) {
      return;
    }

    const editWizardEl = document.querySelector(this.openedEditableWizard);

    this.renderer.removeClass(editWizardEl, 'show');
    this.openedEditableWizard = undefined;

    this.lastIsOpened = false;
  }

  public onSaveEditWizard(
    event: any,
    row: number,
    cell: number,
    rowColumn?: TableData,
    key?: string,
  ): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.openedEditableWizard) {
      return;
    }

    const editWizardEl = document.querySelector(this.openedEditableWizard);

    const inputEl: HTMLInputElement = document.querySelector(
      `${this.openedEditableWizard} input`,
    ) as any;

    this.cellSaveClick.emit({ value: inputEl.value, row, cell });

    this.renderer.removeClass(editWizardEl, 'show');
    this.openedEditableWizard = undefined;
  }

  public handleScroll(): void {
    const scrollLeft = this.tableScroll.nativeElement.scrollLeft;
    const scrollWidth = this.tableScroll.nativeElement.scrollWidth;
    const offsetWidth = this.tableScroll.nativeElement.offsetWidth;
    this.showShadowLeft = scrollLeft > 0;
    this.showShadowRight =
      parseInt((offsetWidth + scrollLeft).toFixed(0), 0) < scrollWidth;
    this.hideSectionForSticky =
      this.tableScroll.nativeElement.scrollTop > 0 && !!this.stickyHeader;
    this.cdr.detectChanges();
    this.calculatePositionShadow();
    this.cdr.detectChanges();
  }

  public editValue(data: string, key: string, row: TableData) {
    const col = row[key];
    if (col.editData) {
      const { value, validate, invalidErrorMessage } = col.editData;
      col.editData.invalid = !validate(data);
      if (!col.editData.invalid) {
        col.editData.value = Number(data);
      } else {
        this.toast.error(invalidErrorMessage, 4000);
      }
    }
  }

  protected calculatePositionShadow(): void {
    const height = this.section ? this.section.nativeElement.offsetHeight : 0;
    let sumLeft = 0;
    if (this.columnLeftDef.length > 0) {
      for (let i = 1; i <= this.columnLeftDef.length; i++) {
        const left: HTMLTableColElement = document.querySelector(
          `td.td-${i}-${this.tableId}.sticky-left`,
        ) as HTMLTableColElement;
        if (left) {
          sumLeft += left.offsetWidth;
        }
      }
    }
    if (this.shadowLeft) {
      this.shadowLeft.nativeElement.style.left = sumLeft + 'px';
      this.shadowLeft.nativeElement.style.top = height + 'px';
      this.shadowLeft.nativeElement.style.height = `calc(100% -  ${height}px - var(--size--spacing-x4))`;
    }
    let sumRight = 0;
    if (this.columnRightDef.length > 0) {
      for (
        let i = 1 + this.columnLeftDef.length + this.columnDef.length;
        i <=
        this.columnRightDef.length +
          this.columnLeftDef.length +
          this.columnDef.length;
        i++
      ) {
        const right: HTMLTableColElement = document.querySelector(
          `td.td-${i}.sticky-right`,
        ) as HTMLTableColElement;
        if (right) {
          sumRight += right.offsetWidth;
        }
      }
    }
    if (this.shadowRight) {
      this.shadowRight.nativeElement.style.right = sumRight + 'px';
      this.shadowRight.nativeElement.style.top = height + 'px';
      this.shadowRight.nativeElement.style.height = `calc(100% -  ${height}px - var(--size--spacing-x4))`;
    }
  }

  /**
   * @description Handles the overall Table columns and groupings to display
   */
  private formatTableColumnGroup(): void {
    // Important to clean before setting
    this.formattedColumnGroups = [];

    if (this.stickLeftColumns) {
      this.columnLeftDef.forEach((header) => {
        const columnConfig = (this.stickLeftColumns as TableColumns)[header];
        this.formattedColumnGroups.push({
          groupTitle: null,
          columns: [{ key: header, config: columnConfig }],
        });
      });
    }
    this.columnDef.forEach((header) => {
      const columnConfig = (this.columns as TableColumns)[header];

      if (this.columnGroups) {
        const section = this.columnGroups.find(
          (s) => s.columnDefs.indexOf(header) !== -1,
        );
        if (section) {
          const fHeader = this.formattedColumnGroups.find(
            (fHeader) => fHeader.groupTitle === section.sectionTitle,
          );
          if (fHeader) {
            // Inserting column to a group
            fHeader.columns.push({
              key: header,
              config: columnConfig,
            });
          } else {
            // New column group
            this.formattedColumnGroups.push({
              groupTitle: section.sectionTitle,
              columns: [{ key: header, config: columnConfig }],
              color: section.color ? section.color : undefined,
              spacing: section.spacing,
            });
          }
          return;
        }
      }

      // Column without a group
      this.formattedColumnGroups.push({
        groupTitle: null,
        columns: [{ key: header, config: columnConfig }],
      });
    });
    if (this.stickRightColumns) {
      this.columnRightDef.forEach((header) => {
        const columnConfig = (this.stickRightColumns as TableColumns)[header];
        this.formattedColumnGroups.push({
          groupTitle: null,
          columns: [{ key: header, config: columnConfig }],
        });
      });
    }
  }

  checkSticky(colName: string): string {
    if (!this.stickLeftColumns && !this.stickRightColumns) {
      return '';
    }
    let item;
    if (this.stickLeftColumns) {
      item = this.stickLeftColumns[colName];
      if (item) {
        return 'sticky-left';
      }
    }
    if (this.stickRightColumns) {
      item = this.stickRightColumns[colName];
      if (item) {
        return 'sticky-right';
      }
    }
    return '';
  }

  getType(name: string, columns?: TableColumns): string {
    if (!columns) {
      return '';
    }
    const column = columns[name];
    return column ? column.type : '';
  }

  public findSpacing(key: string): boolean {
    return (this.columnGroups || [])
      .filter((e) => e.spacing)
      .some((e) => e.columnDefs[e.columnDefs.length - 1] === key);
  }

  public getSpacingFill(key: string): string | undefined {
    const item = (this.columnGroups || [])
      .filter((e) => e.spacing)
      .find((e) => e.columnDefs[e.columnDefs.length - 1] === key);
    return item ? item.spacingFill : undefined;
  }

  private hasClass(element: HTMLElement, classname: string): boolean {
    if (!element.className || element.tagName === 'TD') {
      return false;
    }

    if (element.className.split(' ').indexOf(classname) >= 0) {
      return true;
    }

    const elementParentNode: HTMLElement = element.parentNode as HTMLElement;
    return elementParentNode && this.hasClass(elementParentNode, classname);
  }

  isEndOfGroup(column: any) {
    let columnName = column;

    if (!column || !column['key']) {
      columnName = column;
    } else {
      columnName = column.key;
    }

    let result = false;
    this.formattedColumnGroups.forEach((group) => {
      if (
        group.groupTitle &&
        group.columns &&
        group.columns[group.columns.length - 1].key === columnName
      ) {
        result = true;
      }
    });
    return result;
  }

  isFirstOfGroup(column: any) {
    let columnName = column;

    if (!column || !column['key']) {
      columnName = column;
    } else {
      columnName = column.key;
    }

    let result = false;
    this.formattedColumnGroups.forEach((group) => {
      if (
        group.groupTitle &&
        group.columns &&
        group.columns[0].key === columnName
      ) {
        result = true;
      }
    });
    return result;
  }

  @HostListener('document:scroll')
  onScroll() {
    this.cdr.detectChanges();
  }
}
