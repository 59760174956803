import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
    selector: '[kltChangeHighlight]',
    standalone: true,
})
export class ChangeHighlightDirective {
  previousValue: any;

  private _row: any;
  get row(): any {
    return this._row;
  }
  @Input() set row(v: any) {
    this._row = v;

    if (v && !this.previousValue) {
      this.previousValue = v.editData?.value;
    }

    if (
      v &&
      this.previousValue &&
      this.previousValue !== v.editData?.value &&
      v.fill !== 'affected'
    ) {
      this.rendered.addClass(this.elementRef.nativeElement, 'fill-affected');
      v.fill = 'affected';
      v.updated = true;
      setTimeout(() => {
        this.rendered.removeClass(
          this.elementRef.nativeElement,
          'fill-affected',
        );
        v.fill = null;
        v.updated = false;
      }, 3000);
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private rendered: Renderer2,
    private elementRef: ElementRef,
  ) {}
}
