import { GraphId } from './graph.constant';

export const colorPallete: { [graphId: number]: string[] } = {};

const treemapColorPallete = [
  '#4696a0',
  '#8c648c',
  '#555a6e',
  '#96aaaa',
  '#fa8c7d',
  '#d2b964',
  '#be6478',
  '#5ac8b9',
  '#ed8576',
  '#6eaf7d',
  '#dc4b5f',
  '#e0ca4a',
  '#50a0d2',
  '#ed8576',
  '#57b3ab',
  '#c59857',
  '#9e58c5',
  '#7d9cfa',
  '#a6d257',
  '#63b0a6',
  '#f94f67',
  '#b56c8c',
  '#ff8c7d',
  '#ba87d2',
  '#e478d2',
  '#9cd26f',
  '#53be75',
  '#f17d5a',
  '#6388c5',
  '#7b96af',
  '#c88f5a',
  '#85d2b1',
  '#7d6f96',
  '#b54b4b',
  '#d2698c',
  '#a2c857',
  '#4e8c63',
  '#a0c850',
  '#c54a50',
  '#5a88c5',
  '#8c649a',
  '#88b56c',
  '#7d68ba',
  '#63b7a0',
  '#b565b5',
  '#648cad',
  '#d24b4b',
  '#f4c857',
  '#8c6d64',
  '#7d7b9c',
];

colorPallete[GraphId.OverviewPropertyByLocation] = treemapColorPallete;
colorPallete[GraphId.OverviewPropertyByType] = treemapColorPallete;
colorPallete[GraphId.OverviewInvestability] = ['#fa8c7d', '#96aaaa'];
colorPallete[GraphId.OverviewTotalReturnPerformance] = ['#808000', '#fa8c7d'];
colorPallete[GraphId.OverviewPremDiscToGav] = ['#808000', '#8c648c'];

export const financialActualColor = '#1e2337';
export const financialBaseColor = '#555a6e';
export const financialGoodColor = '#4696a0';
export const financialPoorColor = '#e18c96';

export const financialColorPallete = ['#8c648c', '#d2b964', '#648cad'];

colorPallete[GraphId.FinancialEps] = treemapColorPallete;
colorPallete[GraphId.FinancialDps] = treemapColorPallete;
colorPallete[GraphId.FinancialRevenue] = treemapColorPallete;
colorPallete[GraphId.FinancialDirectCosts] = treemapColorPallete;
colorPallete[GraphId.FinancialInDirectCosts] = treemapColorPallete;
colorPallete[GraphId.FinancialInterestCosts] = treemapColorPallete;
colorPallete[GraphId.FinancialNtaShare] = treemapColorPallete;
colorPallete[GraphId.FinancialPremDiscToGav] = treemapColorPallete;
colorPallete[GraphId.FinancialPropotionalLtv] = treemapColorPallete;
colorPallete[GraphId.FinancialNtCashFlow] = treemapColorPallete;
colorPallete[GraphId.FinancialFinancingActivities] = treemapColorPallete;
colorPallete[GraphId.FinancialInvestingActivities] = treemapColorPallete;
colorPallete[GraphId.DataAnalyticsBuildChart] = treemapColorPallete;
colorPallete[GraphId.ProjectionPremDiscToGav] = [
  '#96aaaa',
  '#fa8c7d',
  '#8c648c',
];

colorPallete[GraphId.ProjectionsTotalShareholderReturns] = [
  '#8c648c',
  '#F79AA5',
  '#96aaaa',
];

colorPallete[GraphId.ProjectionLflNri] = ['#e18c96'];
colorPallete[GraphId.ProjectionAffoDpsYield] = ['#96aaaa', '#8c648c'];
colorPallete[GraphId.ProjectionsBalanceSheet] = ['#668080', '#8c648c'];
colorPallete[GraphId.ProjectionDebtMaturities] = ['#d2b964', '#be6478'];

colorPallete[GraphId.SectorBreakdown] = treemapColorPallete;
colorPallete[GraphId.SectorOverviewMarketCapitalisation] = treemapColorPallete;
colorPallete[GraphId.SectorProjectionIndexedMarketRateGrowth] =
  treemapColorPallete;
colorPallete[GraphId.SectorProjectionOccupancyLevels] = treemapColorPallete;
colorPallete[GraphId.SectorProjectionLflNri] = treemapColorPallete;
colorPallete[GraphId.SectorProjectionCostAnalysis] = treemapColorPallete;
colorPallete[GraphId.SectorProjectionBalanceSheeetStrength] =
  treemapColorPallete;
colorPallete[GraphId.SectorProjectionRiskReturn] = treemapColorPallete;
colorPallete[GraphId.SectorOverviewPortfolioComposition] = treemapColorPallete;
colorPallete[GraphId.SectorOverviewInvestability] = ['#fa8c7d', '#96aaaa'];
colorPallete[GraphId.SectorOverviewHistoricalSectorReturns] = [
  '#8c648c',
  '#d2b964',
];
colorPallete[GraphId.SectorOverviewBeta] = ['#96aaaa', '#fa8c7d', '#8c648c'];
