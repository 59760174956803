import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularSvgIconModule } from 'angular-svg-icon';
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { QuillModule } from 'ngx-quill';

import { BoxComponent } from './components/common/box/box.component';
import { ButtonComponent } from './components/common/button/button.component';
import { CheckboxButtonComponent } from './components/form/checkbox-button/checkbox-button.component';
import { CheckboxComponent } from './components/form/checkbox/checkbox.component';
import { ChipComponent } from './components/form/chip/chip.component';
import { DropdownActionsComponent } from './components/form/dropdown-actions/dropdown-actions.component';
import { DropdownGroupComponent } from './components/form/dropdown-group/dropdown-group.component';
import { DropdownOptionsComponent } from './components/form/dropdown-options/dropdown-options.component';
import { DropdownWrapperComponent } from './components/form/dropdown-wrapper/dropdown-wrapper.component';
import { InputDateComponent } from './components/form/input-date/input-date.component';
import { InputExpandComponent } from './components/form/input-expand/input-expand.component';
import { InputComponent } from './components/form/input/input.component';
import { SingleSelectionComponent } from './components/form/single-selection/single-selection.component';
import { SingleSelectionGroupComponent } from './components/form/single-selection-group/single-selection-group.component';
import { ToggleSwitchComponent } from './components/form/toggle-switch/toggle-switch.component';
import { GraphComponent } from './components/graph/graph.component';
import { MapTooltipComponent } from './components/map/map-tooltip/map-tooltip.component';
import { MapComponent } from './components/map/map.component';
import { ModalComponent } from './components/modal/modal.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ScoreboardComponent } from './components/scoreboard/scoreboard.component';
import { SectionComponent } from './components/section/section.component';
import { SeparatorComponent } from './components/separator/separator.component';
import { TableScrollComponent } from './components/table/table-scroll/table-scroll.component';
import {
  TableComponent,
  TableToolbarDirective,
} from './components/table/table.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { ToastComponent } from './components/toast/toast.component';
import { AsHtmlDirective } from './directives/as-html.directive';
import { InputNumberLimiterDirective } from './directives/input-number-limiter.directive';
import { DisplayComponentsComponent } from './pages/display-components/display-components.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { HtmlToTextPipe } from './pipes/html-to-text.pipe';
import { ResetTimezonePipe } from './pipes/reset-timezone.pipe';
import {
  KltModalRefService,
  KltModalService,
  KltTimezoneService,
} from './services';
import { KltToastService } from './services/toast/toast.service';
import { ChangeHighlightDirective } from './directives/change-highlight.directive';
import { TableNumberFormatterDirective } from './directives/table-number-formatter.directive';
import { NegativeFormatterPipe } from './pipes/negative-formatter.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ScenarioToOptionPipe } from './pipes/scenario-to-option.pipe';
import { OptionHidePipe } from './pipes/option-hide.pipe';
import { NumberSuffixPipe } from './directives/number-suffix.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HasClaimDirective } from './directives/has-claim.directive';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageContentComponent } from './components/page-content/page-content.component';
import { PageAdminComponent } from './pages/page-admin/page-admin.component';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { ReitUpdateCardComponent } from './components/reit-update-card/reit-update-card.component';
import { HasFeatureDirective } from './directives/has-feature.directive';
import { ModalPermissionRestrictComponent } from './modals/modal-permission-restrict/modal-permission-restrict.component';
import { FeaturedDirective } from './directives/featured.directive';
import { HasRoleDirective } from './directives/has-role.directive';
import { TableV2Component } from './components/table-v2/table-v2.component';
import { TableCellGraphComponent } from './components/table-v2/table-cell-graph/table-cell-graph.component';
import { TableCellActionComponent } from './components/table-v2/table-cell-action/table-cell-action.component';
import { TableCellHtmlComponent } from './components/table-v2/table-cell-html/table-cell-html.component';

import { MatTooltipModule } from '@angular/material/tooltip';
import { LimitCharactersPipe } from './pipes/limit-characters.pipe';
import { ReitTableNavigationComponent } from './components/table-navigation/table-navigation.component';
import { RouterModule } from '@angular/router';
import { LocaleNumberPipe } from './pipes/locale-number.pipe';
import { LocaleCurrencyPipe } from './pipes/locale-currency.pipe';
import { NumberComponent } from './components/number/number.component';
import { CurrencyComponent } from './components/currency/currency.component';
import { PercentageComponent } from './components/percentage/percentage.component';
import { LocalePercentagePipe } from './pipes/locale-percentage.pipe';
import { NegativeFormatterDirective } from './directives/negative-formatter.directive';
import { MinusRemovalPipe } from './pipes/minus-removal.pipe';
import { ToPencePipe } from './pipes/to-pence.pipe';
import { RemoveModalComponent } from './modals/remove-modal/remove-modal.component';
import { ModalFileUploadComponent } from './modals/modal-file-upload/modal-file-upload.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { MinPipe } from './pipes/min.pipe';
import { MaxPipe } from './pipes/max.pipe';
// COMPONENTS

@NgModule({
  exports: [
    InputComponent,
    ButtonComponent,
    InputExpandComponent,
    DropdownOptionsComponent,
    CheckboxButtonComponent,
    CheckboxComponent,
    BoxComponent,
    SingleSelectionComponent,
    SingleSelectionGroupComponent,
    ModalComponent,
    ChipComponent,
    TableComponent,
    PaginationComponent,
    ScoreboardComponent,
    TableToolbarDirective,
    ScoreboardComponent,
    DropdownGroupComponent,
    HighlightPipe,
    DropdownWrapperComponent,
    DisplayComponentsComponent,
    TextEditorComponent,
    ToastComponent,
    InputNumberLimiterDirective,
    SeparatorComponent,
    GraphComponent,
    MapComponent,
    SectionComponent,
    InputDateComponent,
    ToggleSwitchComponent,
    DropdownActionsComponent,
    MapTooltipComponent,
    HtmlToTextPipe,
    ResetTimezonePipe,
    AsHtmlDirective,
    ChangeHighlightDirective,
    TableNumberFormatterDirective,
    NegativeFormatterPipe,
    ClickOutsideDirective,
    LoadingSpinnerComponent,
    ScenarioToOptionPipe,
    OptionHidePipe,
    NumberSuffixPipe,
    SafeHtmlPipe,
    LimitCharactersPipe,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    HasClaimDirective,
    FileUploaderComponent,
    ProgressBarComponent,
    PageHeaderComponent,
    PageContentComponent,
    PageAdminComponent,
    ModalContentComponent,
    ReitUpdateCardComponent,
    HasFeatureDirective,
    FeaturedDirective,
    ModalPermissionRestrictComponent,
    TableV2Component,
    ReitTableNavigationComponent,
    LocaleNumberPipe,
    CurrencyComponent,
    NumberComponent,
    PercentageComponent,
    NegativeFormatterDirective,
    MinusRemovalPipe,
    ToPencePipe,
    RemoveModalComponent,
    ModalFileUploadComponent,
    DragAndDropDirective,
    MinPipe,
    MaxPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['clean'],
          ['link'],
        ],
      },
    }),
    NgxEchartsModule.forRoot({
      echarts,
    }),
    // NgxMapboxGLModule.withConfig({
    //     accessToken: 'pk.eyJ1IjoiYW5sYWxheWtlciIsImEiOiJja3M0cmozbmoxem04Mm9xbTdzdjhqa292In0.H5KAmP_-4eIShxgfY7vR8g',
    // }),
    AngularSvgIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    InputComponent,
    ButtonComponent,
    InputExpandComponent,
    DropdownOptionsComponent,
    CheckboxButtonComponent,
    CheckboxComponent,
    BoxComponent,
    SingleSelectionComponent,
    SingleSelectionGroupComponent,
    ModalComponent,
    ChipComponent,
    TableComponent,
    PaginationComponent,
    ScoreboardComponent,
    TableToolbarDirective,
    ScoreboardComponent,
    DropdownGroupComponent,
    HighlightPipe,
    DropdownWrapperComponent,
    TextEditorComponent,
    DisplayComponentsComponent,
    InputNumberLimiterDirective,
    TableScrollComponent,
    ToastComponent,
    InputNumberLimiterDirective,
    SeparatorComponent,
    GraphComponent,
    MapComponent,
    SectionComponent,
    InputDateComponent,
    ToggleSwitchComponent,
    DropdownActionsComponent,
    MapTooltipComponent,
    HtmlToTextPipe,
    ResetTimezonePipe,
    AsHtmlDirective,
    ChangeHighlightDirective,
    TableNumberFormatterDirective,
    NegativeFormatterPipe,
    ClickOutsideDirective,
    LoadingSpinnerComponent,
    ScenarioToOptionPipe,
    OptionHidePipe,
    NumberSuffixPipe,
    SafeHtmlPipe,
    HasClaimDirective,
    FileUploaderComponent,
    ProgressBarComponent,
    PageHeaderComponent,
    PageContentComponent,
    PageAdminComponent,
    ModalContentComponent,
    ReitUpdateCardComponent,
    HasFeatureDirective,
    FeaturedDirective,
    ModalPermissionRestrictComponent,
    HasRoleDirective,
    TableV2Component,
    TableCellGraphComponent,
    TableCellActionComponent,
    TableCellHtmlComponent,
    LimitCharactersPipe,
    ReitTableNavigationComponent,
    LocaleNumberPipe,
    LocaleCurrencyPipe,
    NumberComponent,
    CurrencyComponent,
    PercentageComponent,
    LocalePercentagePipe,
    NegativeFormatterDirective,
    MinusRemovalPipe,
    ToPencePipe,
    RemoveModalComponent,
    ModalFileUploadComponent,
    DragAndDropDirective,
    MinPipe,
    MaxPipe,
  ],
  providers: [
    KltModalService,
    KltModalRefService,
    KltToastService,
    KltTimezoneService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class SharedComponentsModule {}
