import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  autoDestroy,
  extractQueryRegionIds,
  followDestroy,
} from '@kolytics/shared-functions';
import { ActivatedRoute } from '@angular/router';
import { ClientStoreService } from '../../../../store/client.store.service';
import {
  ISectorBreakDownOverviewResponse,
  ISectorOverviewDataResponse,
  ISectorOverviewResponse,
} from '../../interfaces';
import { Meta, Title } from '@angular/platform-browser';
import { SelectDialogItem } from '../../utils/components/base-reit/base-reit.util';
import { SelectRegionDialogComponent } from '../../dialogs/select-region-dialog/select-region-dialog.component';
import { SectorRegionService } from '../../services/sector-region.service';
import { OverviewSectorUpdateComponent } from './overview-sector-update/overview-sector-update.component';
import { SectorBetaComponent } from './sector-beta/sector-beta.component';
import { HistoricalSectorComponent } from './historical-sector/historical-sector.component';
import { SectorInvestabilityComponent } from './sector-investability/sector-investability.component';
import { PortfolioCompositionComponent } from './portfolio-composition/portfolio-composition.component';
import { MarketCapitalisationComponent } from './market-capitalisation/market-capitalisation.component';
import { SeparatorComponent } from '../../../../../../../../libs/shared-components/src/lib/components/separator/separator.component';
import { SectorBreakdownComponent } from './sector-breakdown/sector-breakdown.component';
import { ButtonComponent } from '../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { ReitService, SectorService } from '@kolytics/shared-api';

@Component({
  selector: 'klt-sectors-overview',
  templateUrl: './sectors-overview.component.html',
  styleUrls: ['./sectors-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    SectorBreakdownComponent,
    SeparatorComponent,
    MarketCapitalisationComponent,
    PortfolioCompositionComponent,
    SectorInvestabilityComponent,
    HistoricalSectorComponent,
    SectorBetaComponent,
    OverviewSectorUpdateComponent,
  ],
})
@autoDestroy()
export class SectorsOverviewComponent implements OnInit {
  sectorName!: string;
  sectors!: ISectorOverviewResponse;
  reitsUpdate: {
    title: string;
    date: string;
    description: string;
    showMore?: boolean;
  }[] = [];
  breakdownChart: any[];

  sectorId!: string;

  constructor(
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly service: ReitService,
    private clientStoreService: ClientStoreService,
    private sectorRegionService: SectorRegionService,
    public meta: Meta,
    public title: Title,
    private cdr: ChangeDetectorRef,
    private sectorService: SectorService,
  ) {
    this.breakdownChart = [];
  }

  async ngOnInit() {
    await this.sectorRegionService.init();
    this.activatedRoute.parent?.params
      .pipe(
        map((params) => params.id),
        switchMap((id) => {
          this.sectorId = id;
          return this.sectorService.apiSectorDataSectorIdOverviewGet(
            id,
            this.sectorRegionService.regionsIds,
          );
        }),
        tap(({ sectors }) => {}),
        followDestroy(this),
      )
      .subscribe((data) => {
        this.sectors = data;
        this.sectorName =
          data.sectors.investability.selectedSectorInvestability.series;
        this.title.setTitle(`Kolytics | ${this.sectorName} sector`);
        this.cdr.detectChanges();
      });
  }

  getData() {}

  public openRegionFilter(): void {
    this.sectorRegionService.openRegionFilter(Number(this.sectorId), () => {
      this.sectorService
        .apiSectorDataSectorIdOverviewGet(
          Number(this.sectorId),
          this.sectorRegionService.regionsIds,
        )
        .subscribe((data) => {
          this.sectors = data;
          this.sectorName =
            data.sectors.investability.selectedSectorInvestability.series;
          this.title.setTitle(`Kolytics | ${this.sectorName} sector`);
          this.cdr.detectChanges();
        });
    });
  }
}
