<klt-modal modalTitle="Create scenario" (closeModal)="onClose()">
  <div class="create-scenario-modal-content">
    <div class="content-row input">
      <klt-input
        [value]="name"
        (valueChanged)="name = $event.value"
        label="Scenario name"
        placeholder="Scenario name"
      ></klt-input>
    </div>
    <div class="content-row confirm-button">
      <klt-button type="primary" (click)="onCreate()">SAVE SCENARIO</klt-button>
    </div>
    <div class="content-row cancel-button">
      <b (click)="onClose()">Cancel</b>
    </div>
  </div>
</klt-modal>
