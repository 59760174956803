import { Pipe, PipeTransform } from '@angular/core';
import { formatPercentage } from '@kolytics/shared-functions';

@Pipe({
    name: 'localePercentage',
    standalone: true,
})
export class LocalePercentagePipe implements PipeTransform {
  transform(
    value?: number,
    decimalPoints?: number,
    locale?: string,
  ): string | undefined {
    if (!value || isNaN(value)) return '';
    return formatPercentage(value, decimalPoints, locale);
  }
}
