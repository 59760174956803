<div
  class="wrapper"
  [formGroup]="form"
  [class.size-large]="sizeLarge"
  [class.intermediate]="intermediate"
  [ngClass]="getFormClasses()"
  >
  <input
    type="checkbox"
    class="checkbox"
    [class.intermediate]="intermediate"
    [tabIndex]="tabIndex"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (keydown.space)="toggleSelection($event)"
    (click)="toggleSelection($event)"
    />
    <label>{{ label }}@if (!label) {
      <ng-content></ng-content>
    }</label>
    @if (intermediate) {
      <klt-icon
        icon="line"
        color="light"
        [size]="sizeLarge ? 'xsmall' : 'tiny'"
        class="checkbox-tick"
      ></klt-icon>
    } @else {
      <klt-icon
        icon="tick"
        color="light"
        [size]="sizeLarge ? 'xsmall' : 'tiny'"
        class="checkbox-tick"
      ></klt-icon>
    }
  </div>
  @if (formStates.error) {
    <div class="error" [@dropdownAnimation]>
      <span [innerHTML]="errorMessage"></span>
    </div>
  }
