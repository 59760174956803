<div class="balance-sheet-nta-share">
  <klt-section title="Proportional LTV" type="none">
    <div class="graph-box">
      <klt-graph
        [loading]="clientQuery.selectFinancialIsLoading$ | async"
        height="calc(var(--size--spacing-x67-5) + var(--size--spacing-x2-5))"
        [titles]="titles"
        [options]="options"
        (filter)="runFilter($event)"
      >
      </klt-graph>
    </div>
  </klt-section>
</div>
