<div class="sectors-overview--content">
  <div class="header">
    <h3>Overview</h3>
    <klt-button class="region" type="secondary" (click)="openRegionFilter()"
      >SELECT REGION</klt-button
    >
  </div>

  <klt-sector-breakdown
    [sector]="sectors && sectors.sectors.breakdown"
  ></klt-sector-breakdown>
  <klt-separator></klt-separator>
  <klt-market-capitalisation
    [sector]="sectors && sectors.sectors.marketCap"
  ></klt-market-capitalisation>
  <klt-separator></klt-separator>
  <klt-portfolio-composition
    [sector]="sectors && sectors.sectors.portfolioComposition"
  ></klt-portfolio-composition>
  <klt-separator></klt-separator>
  <div class="overview-zone">
    <div class="overview-zone-item">
      <klt-sector-investability
        [data]="sectors && sectors.sectors.investability"
      ></klt-sector-investability>
    </div>
    <div class="overview-zone-item">
      <klt-historical-sector
        [sectorName]="sectorName"
        [data]="sectors && sectors.sectors.historicalReturns"
      ></klt-historical-sector>
    </div>
  </div>
  <klt-separator></klt-separator>
  <klt-sector-beta [sector]="sectors && sectors.sectors.beta"></klt-sector-beta>
  <klt-separator></klt-separator>
  <klt-overview-sector-update></klt-overview-sector-update>
</div>
