import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PreviewService {
  constructor(private router: Router) {}

  routingReitPrefix(path?: string): string {
    const url = path ?? this.router.url;
    if (url.includes('preview')) {
      return 'preview';
    }
    return 'reits';
  }

  isPreview(path?: string): boolean {
    const url = path ?? this.router.url;
    return url.includes('preview');
  }
}
