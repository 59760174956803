import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChangeDetectorRef, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { TokenInterceptorService } from './shared/interceptors/token-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { TrialExpirationInterceptorService } from './shared/interceptors/trial-expiration-interceptor.service';
import { UnauthorizeInterceptorService } from '../../../../libs/shared-components/src/lib/interceptors/unauthorize-interceptor.service';
import { JwtModule } from '@auth0/angular-jwt';
import { TablePageComponent } from './table-page/table-page.component';
import {
  ApiModule,
  Configuration,
  ConfigurationParameters,
} from '@kolytics/shared-api';
import { ForwardHeadersInterceptor } from './shared/interceptors/forward-headers.interceptor';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.baseApiUrl,
    credentials: {
      Bearer: () => `Bearer ${localStorage.getItem('access_token')}`,
    },
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HammerModule,
    NgxChartsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    ApiModule.forRoot(apiConfigFactory),
    NavigationComponent,
    TablePageComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizeInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TrialExpirationInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ForwardHeadersInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
