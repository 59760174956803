import { SentryConfig } from 'libs/envs/src/lib/sentry';
import { MapboxConfig } from 'libs/envs/src/lib/mapbox';

export const environment = {
  production: true,
  apiUrl: 'https://staging.kolytics.com:8090/api',
  baseApiUrl: 'https://staging.kolytics.com:8090',
  customerUrl: 'https://staging.kolytics.com',
  adminUrl: 'https://staging-admin.kolytics.com',
  queryUrl: 'http://staging-query.kolytics.com',
  sentry: {
    ...SentryConfig,
    environment: 'staging',
  },
  mapbox: {
    ...MapboxConfig,
  },
};
