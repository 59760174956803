<div class="map--dialog">
  <klt-map
    [zoom]="zoom"
    [markers]="markers"
    [current]="current"
    [source]="source"
    [closeButtonVisible]="true"
    (close)="close()"
  ></klt-map>
</div>
