@if (true) {
  <klt-table-v2
    [stickyTop]="true"
    [loading]="false"
    [equalWidth]="true"
    [data]="reitTableData.data"
    [columns]="reitTableData.headers"
    [columnGroups]="reitTableData.sections"
    [filteredColumnKeys]="filteredColumns"
    [top]="isPopup ? 77 : 187"
    (cellSaveClick)="onCellSave($event)"
    (sort)="onTableSort($event)"
    >
  </klt-table-v2>
}
