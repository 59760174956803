<div class="balance-sheet">
  <div class="balance-sheet-compare">
    <div class="balance-sheet-compare-button"></div>
    <div class="balance-sheet-compare-button">
      <klt-section
        type="button"
        buttonText="SELECT SCENARIOS"
        (buttonClick)="handleClick()"
        >
      </klt-section>
    </div>
  </div>

  <klt-balance-sheet-nta-share
    [reit]="clientFacade.financialData"
    [metadata]="metadata"
  ></klt-balance-sheet-nta-share>
  <klt-separator></klt-separator>
  <div class="balance-sheet-zone">
    <div class="balance-sheet-zone-item">
      <klt-balance-sheet-prem-gav
        [reit]="clientFacade.financialData"
      ></klt-balance-sheet-prem-gav>
    </div>
    <div class="balance-sheet-zone-item">
      <klt-balance-sheet-proportional-ltv
        [reit]="clientFacade.financialData"
      ></klt-balance-sheet-proportional-ltv>
    </div>
  </div>
  <klt-separator></klt-separator>
  @if (!(clientQuery.selectFinancialIsLoading$ | async)) {
    <klt-balance-sheet-table
      [metadata]="metadata"
      [reit]="clientFacade.financialData"
    ></klt-balance-sheet-table>
  }
</div>
