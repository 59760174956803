<div class="other-assumptions-table">
  @if (showToolbar) {
    <klt-table-toolbar>
      @if ((isPopup && queueRunning) || !isPopup) {
        <div
          class="toolbar"
          [class.sticky]="queueRunning"
          [class.top]="isPopup"
          >
          <div class="toolbar--left">
            @if (!isPopup) {
              <klt-link-action
                [iconRight]="'pop-out'"
                (click)="popout()"
                [text]="'Pop-out'"
              ></klt-link-action>
            }
          </div>
          <div class="toolbar--right">
            <klt-loading-spinner
              [show]="queueRunning"
          [label]="
            'Saving...' + (queueCount > 1 ? ' (' + queueCount + ')' : '')
          "
            ></klt-loading-spinner>
            @if (!isPopup) {
              <div class="more-group">
                <span>More</span>
                <klt-dropdown-wrapper>
                  <klt-button
                    class="more"
                    type="icon"
                    rightIcon="more-vertical"
                    (click)="toggleMore($event)"
                  ></klt-button>
                  <div class="options">
                    <klt-dropdown-actions
                      [open]="more"
                      [actions]="moreActions"
                      [options]="moreOptions"
                      (selectedOption)="solveOption($event)"
                      (selectedAction)="solveAction($event)"
                      (closed)="more = false"
                      >
                    </klt-dropdown-actions>
                  </div>
                </klt-dropdown-wrapper>
              </div>
            }
          </div>
        </div>
      }
    </klt-table-toolbar>
  }
  <klt-other-capex-table
    [structuralCAPEXReserve]="assumptionOther"
    [showToolbar]="showToolbar"
    [reitId]="reitId"
    [userScenarioId]="scenarioAId"
    (cellSaved)="onCellSave($event)"
    >
  </klt-other-capex-table>
  <klt-other-erv-table
    [ervGrowthPeriod]="assumptionOther"
    [reitId]="reitId"
    [userScenarioId]="scenarioAId"
    (cellSaved)="onCellSave($event)"
    >
  </klt-other-erv-table>
  <klt-other-market-table
    [spreadsArrangementFee]="assumptionOther"
    [reitId]="reitId"
    [userScenarioId]="scenarioAId"
    (cellSaved)="onCellSave($event)"
    >
  </klt-other-market-table>
</div>
