import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { EChartsOption } from 'echarts';
import { IReitDataResponse } from '../../../../interfaces';
import { BaseGraphComponent } from 'apps/client/src/app/shared/components/base-graph/base-graph.component';
import { CurrencyPipe, AsyncPipe } from '@angular/common';
import { ClientQuery } from 'apps/client/src/app/store/client.query';
import { formatPercentage } from '@kolytics/shared-functions';
import { GraphId } from '@kolytics/types/graph';
import { GraphComponent } from '../../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-balance-sheet-proportional-ltv',
  templateUrl: './balance-sheet-proportional-ltv.component.html',
  styleUrls: ['./balance-sheet-proportional-ltv.component.scss'],
  standalone: true,
  imports: [SectionComponent, GraphComponent, AsyncPipe],
})
export class BalanceSheetProportionalLtvComponent extends BaseGraphComponent {
  @Input()
  public set reit(value: IReitDataResponse) {
    if (value) {
      this._reit = value;
      this.reset(value.reits.reitFinancials.proportionalLtvGraph);
    }
  }

  public _options: EChartsOption | undefined = {
    grid: {
      show: true,
      borderWidth: 0,
      containLabel: true,
      top: 10,
      right: 10,
      bottom: 0,
      left: 5,
    },
    xAxis: {
      type: 'category',
      data: ['17A', '18A', '19A', '20A', '21F', '22F', '23F', '24F', '25F'],
      boundaryGap: false,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        margin: 22,
      },
      splitArea: {
        show: true,
      },
      axisPointer: {
        show: true,
        type: 'none',
        label: {
          show: false,
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: any, index: number) => {
          return formatPercentage(value, 1);
        },
        margin: 20,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
      },
    },
    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: function (params: any) {
        if (params.componentType && params.componentType === 'series') {
          return `<div style="background: ${
            params.color
          }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);">${params.seriesName.toUpperCase()}-${
            params.name
          }: (${formatPercentage(params.value, 1)})</div>`;
        }
        return '';
      },
    },
    series: [],
  };

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected currencyPipe: CurrencyPipe,
    public clientQuery: ClientQuery,
  ) {
    super(cdr, currencyPipe);
    this.graphType = 'line';
    this.options = this._options;

    this.graphId = GraphId.FinancialPropotionalLtv;
  }
}
