import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ReitMetadataResponse } from '@kolytics/shared-api';
import { formatNumber, formatPercentage } from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { EChartsOption } from 'echarts';
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared';

import {
  IReitProjectionBalanceSheetGraphItem,
  ISeries,
} from '../../../interfaces';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';
import {
  getDynamicRange,
  getDynamicRangePercentage,
  roundToNearestMutliple,
} from '../../../utils';

@Component({
  selector: 'klt-projection-balance-sheet-strength',
  templateUrl: './projection-balance-sheet-strength.component.html',
  styleUrls: ['./projection-balance-sheet-strength.component.scss'],
  standalone: true,
  imports: [SectionComponent, GraphComponent],
})
export class ProjectionBalanceSheetStrengthComponent {
  @Input() height = '415px';
  @Input() selectedScenarioName = 'base';
  @Input()
  public set items(value: IReitProjectionBalanceSheetGraphItem[] | undefined) {
    this.reset(value || []);
  }
  @Input() loading: boolean | null = false;

  @Input() public options: EChartsOption | undefined = {
    grid: {
      show: true,
      borderColor: '#fff',
      backgroundColor: '#ffffff',
      borderWidth: 0,
      containLabel: true,
      top: 10,
      right: 10,
      bottom: 0,
      left: 5,
    },
    xAxis: {
      type: 'category',
      data: [
        '15A',
        '16A',
        '17A',
        '18A',
        '19A',
        '20A',
        '21F',
        '22F',
        '23F',
        '24F',
        '25F',
      ],
      boundaryGap: false,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        formatter: (value: any) => {
          if (value === '15A') {
            return '  15A';
          }
          if (value === '25F') {
            return '25F  ';
          }
          return value;
        },
        lineHeight: 50,
      },
      splitArea: {
        show: true,
      },
      axisPointer: {
        show: true,
        type: 'none',
        label: {
          show: false,
        },
      },
    },
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: (value: any, index: number) => {
            return formatPercentage(value, 2);
          },
        },
      },
      {
        type: 'value',
        axisLabel: {
          formatter: (value: any, index: number) => {
            return value + 'x';
          },
        },
      },
    ],
    series: [],
    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: function (params: any) {
        if (params.componentType && params.componentType === 'series') {
          const check = params.seriesName.toUpperCase().includes('LTV');
          return `<div style="background: ${
            params.color
          }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);">${params.seriesName.toUpperCase()}-${
            params.name
          }: (${
            check
              ? formatPercentage(params.value, 1)
              : formatNumber(params.value, 1)
          })</div>`;
        }
        return '';
      },
    },
  };

  public series: ISeries[] = [];

  public lines = [];

  public titles: {
    name: string;
    color: string;
    disabled: boolean;
    text?: string;
  }[] = [
    {
      name: 'LOAN TO VALUE (LTV)',
      text: `LOAN TO VALUE (LTV) (${this.selectedScenarioName})`,
      color: colorPallete[GraphId.ProjectionsBalanceSheet][0],
      disabled: false,
    },
    {
      name: 'DEBT / EBITDA (RHS)',
      text: `DEBT / EBITDA (RHS) (${this.selectedScenarioName})`,
      color: colorPallete[GraphId.ProjectionsBalanceSheet][1],
      disabled: false,
    },
  ];

  constructor(private cdr: ChangeDetectorRef) {}

  public runFilter(
    data: { name: string; color: string; disabled: boolean; text?: string }[],
  ): void {
    this.titles = data;

    this.titles[0].text = `LOAN TO VALUE (LTV) (${this.selectedScenarioName})`;
    this.titles[1].text = `DEBT / EBITDA (RHS) (${this.selectedScenarioName})`;

    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter((e) =>
      filter.includes(e.name.toLowerCase()),
    );

    (this.options as EChartsOption).series = [
      ...(series as any),
      ...this.lines,
    ];

    const temp = Object.assign({}, this.options);

    this.options = undefined;

    setTimeout(() => {
      this.options = temp;
      this.cdr.detectChanges();
    }, 0);
  }

  public reset(value: IReitProjectionBalanceSheetGraphItem[] = []): void {
    if (value.length > 0) {
      value = value.sort((a, b) => a.year - b.year);
      ((this.options as EChartsOption).xAxis as any).data = value.map(
        (e) => e.label,
      );
      const actuals = value.filter((e) => e.label.endsWith('A'));
      const lastActual = actuals[actuals.length - 1];

      const ltv = this.getLtv(value, lastActual.year);
      const ebitda = this.getEbitda(value, lastActual.year);

      this.series = [...ltv, ...ebitda] as any;

      const ltvDataActual = ltv[0].data
        .filter((e) => e !== null)
        .map((e) => Number(e));
      const ltvDataForecast = ltv[1].data
        .filter((e) => e !== null)
        .map((e) => Number(e));

      const ebitdaDataActual = ebitda[0].data
        .filter((e) => e !== null)
        .map((e) => Number(e));
      const ebitdaDataForecast = ebitda[1].data
        .filter((e) => e !== null)
        .map((e) => Number(e));

      const min = Math.min(...ebitdaDataActual, ...ebitdaDataForecast);
      const max = Math.max(...ebitdaDataActual, ...ebitdaDataForecast);

      const aroundMax = roundToNearestMutliple(max);
      const interval = aroundMax / 4;

      const rateMin = Math.min(...ltvDataActual, ...ltvDataForecast);
      const rateMax = Math.max(...ltvDataActual, ...ltvDataForecast);

      const [spreadMin, spreadMax, spreadInterval] = getDynamicRangePercentage(
        0,
        rateMax,
        0.1,
      );

      ((this.options as EChartsOption).yAxis as YAXisOption[])[0].min =
        spreadMin;
      ((this.options as EChartsOption).yAxis as YAXisOption[])[1].min = 0;
      ((this.options as EChartsOption).yAxis as YAXisOption[])[0].max =
        spreadMax;
      ((this.options as EChartsOption).yAxis as YAXisOption[])[1].max =
        aroundMax;
      (
        ((this.options as EChartsOption).yAxis as YAXisOption[])[0] as any
      ).interval = spreadInterval;
      (
        ((this.options as EChartsOption).yAxis as YAXisOption[])[1] as any
      ).interval = interval;

      this.runFilter(this.titles);
    } else {
      (this.options as EChartsOption).series = [];
    }
  }

  public getLtv(
    value: IReitProjectionBalanceSheetGraphItem[] = [],
    lastActual: number,
  ) {
    return [
      {
        data: value.map((e) =>
          e.year <= lastActual ? (e.ltv ? e.ltv : null) : null,
        ),
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#93a7a7',
        name: 'LOAN TO VALUE (LTV)',
        areaStyle: {
          color: colorPallete[GraphId.ProjectionsBalanceSheet][0],
          opacity: 0.6,
        },
        lineStyle: {
          width: 1,
        },
        silent: false,
      },
      {
        data: value.map((e) =>
          e.year >= lastActual ? (e.ltv ? e.ltv : null) : null,
        ),
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#93a7a7',
        name: 'LOAN TO VALUE (LTV)',
        areaStyle: {
          color: colorPallete[GraphId.ProjectionsBalanceSheet][0],
          opacity: 0.5,
        },
        lineStyle: {
          width: 1,
        },
        silent: false,
      },
    ];
  }

  public getEbitda(
    value: IReitProjectionBalanceSheetGraphItem[] = [],
    lastActual: number,
  ) {
    return [
      {
        data: value.map((e) =>
          e.year <= lastActual ? (e.debEbitda ? e.debEbitda : null) : null,
        ),
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: colorPallete[GraphId.ProjectionsBalanceSheet][1],
        name: 'DEBT / EBITDA (RHS)',
        lineStyle: {
          width: 4,
        },
        yAxisIndex: 1,
      },
      {
        data: value.map((e) =>
          e.year >= lastActual ? (e.debEbitda ? e.debEbitda : null) : null,
        ),
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: colorPallete[GraphId.ProjectionsBalanceSheet][1],
        name: 'DEBT / EBITDA (RHS)',
        lineStyle: {
          type: 'dashed',
          width: 4,
        },
        yAxisIndex: 1,
      },
    ];
  }
}
