<klt-modal modalTitle="Compare" (closeModal)="onClose()">
  <div class="modal-content">
    <p>Select up to 3 scenarios</p>
    <div class="form-input">
      @for (scenario of scenarios$ | async; track scenario) {
        <div
          class="form-input-checkbox"
          >
          <klt-checkbox-button
            [label]="scenario.name"
            [id]="scenario.scenarioId"
            (selectionChanged)="select($event)"
            [checked]="isChecked(scenario.scenarioId)"
            [disabled]="!isChecked(scenario.scenarioId) && disabled"
          ></klt-checkbox-button>
        </div>
      }
    </div>
  </div>

  <div class="actions-wrapper">
    <klt-button type="secondary" (click)="onClose()">CANCEL</klt-button>
    <klt-button type="primary" (click)="onSubmit()">COMPARE</klt-button>
  </div>
</klt-modal>
