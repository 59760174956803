import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
    selector: 'klt-overview-company',
    templateUrl: './overview-company.component.html',
    styleUrls: ['./overview-company.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [SectionComponent],
})
export class OverviewCompanyComponent {
  @Input() showCta: boolean = true;
  @Input() websiteUrl: string = '';
  @Input() companyBackground: string = '';

  constructor(private router: Router) {}

  histories = [
    {
      title: 'Early history',
      descriptions: [
        'Hammerson was established in 1942 by Lewis Hammerson, originally developing residential property then expanding into commercial property in 1948.',
        'Hammerson became a public company in 1954, and began a programme of partnering with local authorities to redevelop UK cities’ retail offer.',
        'In the 1950’s Hammerson expanded to developing shopping centres in Australia. By the 1960’s it grew its presence in New Zealand, US, Canada and Europe. The Company opened Brent Cross, the UK’s first covered mall, in 1976, and expanded into French commercial property in 1985.',
      ],
    },
    {
      title: 'Major events',
      descriptions: [
        'In 1989 Dutch property investment company Rodamco made an unsolicited takeover bid, but Hammerson was successful in resisting it.',
        'In the late 1990’s various overseas interests were sold and the focus was on the UK and France. Hammerson enters the outlets market in 1998 with Bicester Village. Shopping centre development ramps up in the early 2000’s and Hammerson is a leading developer.',
        'In 2005 Hammerson joins the FTSE100, however, within a few years the company overextends investment and development and has to recapitalise via a highly dilutive equity rights issue.',
        'In 2012 Hammerson disposed on office portfolio to focus on retail. Multiple retail investments and JVs formed in next few years, investment and development activity was once again ramping up despite a growing headwind from ecommerce. The company agrees a takeover of rival Intu Properties in December 2017. Early 2018 Hammerson is demoted from the FTSE100.',
      ],
    },
    {
      title: 'Recent history',
      descriptions: [
        'By March 2018 is itself subject to a takeover by French REIT Klépierre; Hammerson board reject offer on the basis it materially undervalues the company and future prospects. In April 2018 following pressure from investors Hammerson drops the takeover bid for Intu.',
        'With failed M&A and a worsening outlook for UK retail, Hammerson embarks on a net disposal programme, but quantum and pricing fail to prevent underperformance.\nThe Covid-19 crisis further exacerbated the outlook and repositioning, the company is forced to do another highly dilutive equity right issue. Over the coming months management changes are announced to rebuild confidence and pivot the company’s strategy.',
      ],
    },
    {
      title: 'Material current issues',
      descriptions: ['None'],
    },
  ];

  goToWebsite() {
    let url: string = '';
    if (!/^http[s]?:\/\//.test(this.websiteUrl)) {
      url += 'http://';
    }

    url += this.websiteUrl;
    window.open(url, '_blank');
  }
}
