import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { LngLatLike } from 'mapbox-gl';
import { KltModalService } from '@kolytics/shared-components';
import { MapDialogComponent } from '../../../dialogs';
import { IReit, IReitDataResponse } from '../../../interfaces';
import { FeatureService } from 'libs/shared-components/src/lib/services/feature.service';
import { ProductFeature } from '@kolytics/types/common';
import { MapComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/map/map.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';
import { ExportService, ReitService } from '@kolytics/shared-api';

@Component({
  selector: 'klt-overview-investment',
  templateUrl: './overview-investment.component.html',
  styleUrls: ['./overview-investment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SectionComponent, MapComponent],
})
export class OverviewInvestmentComponent implements OnInit {
  @Input()
  public set reit(reit: IReitDataResponse | null) {
    if (reit) {
      this.reitLocal = reit.reits.reit;
      this.displayPropertyData = reit.reits.reit.displayPropertyData;
      const { reitLocation, reitOverview } = reit.reits;
      if (reitLocation) {
        this.zoom.set(reitLocation.zoomLevel);
        this.current = [+reitLocation.longitude, +reitLocation.latitude];
      }
      this.source = {
        ...this.source,
        features: reitOverview.investmentLocations.map((item) => ({
          type: 'Feature',
          properties: {
            ...item,
          },
          geometry: {
            type: 'Point',
            coordinates: [+item.longitude, +item.latitude],
          },
          hideTooltip: true,
        })),
      };

      this.markers = [];
      this.markers.push({
        type: 'position',
        lngLat: this.current,
      });
      this.cdr.detectChanges();
    }
  }

  downloadsFeature = ProductFeature.Downloads;
  hasDownloadsFeature = false;
  reitLocal!: IReit | undefined;
  excelLoading = false;
  public current: LngLatLike = [0.7839646685014259, 52.07317888945238];
  public zoom = signal(4.8);
  public source: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: 'FeatureCollection',
    features: [],
  };
  public markers: {
    lngLat: LngLatLike;
    type: 'circle' | 'position';
    hideContent?: boolean;
    size?: 'small' | 'large' | 'medium';
    content?: string;
  }[] = [];

  public displayPropertyData!: boolean;
  constructor(
    protected readonly service: ReitService,
    protected readonly kltModalService: KltModalService,
    protected readonly cdr: ChangeDetectorRef,
    private featureService: FeatureService,
    private exportService: ExportService,
  ) {}
  ngOnInit(): void {
    this.hasDownloadsFeature = this.featureService.hasFeature(
      this.downloadsFeature,
    );
  }

  public expand(): void {
    this.kltModalService.open(MapDialogComponent, {
      data: {
        zoom: this.zoom,
        markers: this.markers,
        current: this.current,
        source: this.source,
      },
    });
  }

  downloadExcel() {
    this.excelLoading = true;
    this.exportService
      .apiExportLocationsReitIdGet(this.reitLocal?.id!)
      .subscribe(
        (data: Blob) => {
          this.excelLoading = false;

          const file = new Blob([data], { type: 'text/csv' });
          const fileURL = URL.createObjectURL(file);

          var a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = `${this.reitLocal?.name} portfolio.xlsx`;
          document.body.appendChild(a);
          a.click();
        },
        (err) => {
          this.excelLoading = false;
        },
      );
  }
}
