<div class="modal--wrapper">
  <klt-modal modalTitle="Upgrade Access To Unlock" (closeModal)="onClose()">
    <div>
      <div class="modal--content">
        <p>This action is only allowed for Premium Plus users.</p>
        <p>
          Please upgrade your plan by contacting to
          <a href="mailto:info@kolytics.com">info&#64;kolytics.com</a>
        </p>
      </div>
    </div>
  </klt-modal>
</div>
