import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { KltModalService } from '@kolytics/shared-components';
import { SelectProfileDialogComponent } from '../../../dialogs';
import { first, tap } from 'rxjs/operators';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
    selector: 'klt-portfolio-compare',
    templateUrl: './portfolio-compare.component.html',
    styleUrls: ['./portfolio-compare.component.scss'],
    standalone: true,
    imports: [SectionComponent],
})
export class PortfolioCompareComponent implements OnInit {
  @Output()
  public onSelect: EventEmitter<any> = new EventEmitter<any>();

  @Output() buttonClick = new EventEmitter<void>();

  constructor(protected readonly kltModalService: KltModalService) {}

  ngOnInit(): void {}

  public handleClick(): void {
    this.buttonClick.emit();
  }
}
