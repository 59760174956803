import {
  KeyValue,
  NgIf,
  NgFor,
  NgSwitch,
  NgSwitchCase,
  KeyValuePipe,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  PaginationPageChange,
  TableColumnConfig,
  TableColumnGroup,
  TableColumns,
  TableData,
  TableSort,
} from '@kolytics/shared-components';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { NegativeFormatterPipe } from '../../pipes/negative-formatter.pipe';
import { PaginationComponent } from '../pagination/pagination.component';
import { ButtonComponent } from '../common/button/button.component';
import { TableCellGraphComponent } from './table-cell-graph/table-cell-graph.component';
import { InputComponent } from '../form/input/input.component';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { NumberComponent } from '../number/number.component';
import { TableNumberFormatterDirective } from '../../directives/table-number-formatter.directive';
import { ChangeHighlightDirective } from '../../directives/change-highlight.directive';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { IconComponent } from '../../../../../shared-assets/src/lib/components/icon/icon.component';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'klt-table-v2',
  templateUrl: './table-v2.component.html',
  styleUrls: ['./table-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatTooltip,
    IconComponent,
    LoadingSpinnerComponent,
    NgSwitch,
    ChangeHighlightDirective,
    NgSwitchCase,
    TableNumberFormatterDirective,
    NumberComponent,
    ClickOutsideDirective,
    InputComponent,
    TableCellGraphComponent,
    ButtonComponent,
    PaginationComponent,
    KeyValuePipe,
    NegativeFormatterPipe,
    SafeHtmlPipe,
  ],
})
export class TableV2Component implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('scrollWrap') scrollWrap!: ElementRef;
  @ViewChild('tableWrapSticky') tableWrapSticky!: ElementRef;
  @ViewChild('tableWrap') tableWrap!: ElementRef;
  @ViewChild('table') table!: ElementRef;
  @ViewChild('scrollWrapSticky') scrollWrapSticky!: ElementRef;

  @ViewChild('tableHead') tableHead!: ElementRef;
  @ViewChild('tableHeadGroup') tableHeadGroup!: ElementRef;

  @ViewChild('scrollbarInverted') scrollbarInverted!: ElementRef;
  @ViewChildren('[data-header]') headerElements!: QueryList<ElementRef>;

  @Input() loading: boolean = false;

  @Input() noVerticalPadding = true;
  @Input() verticalPadding!: string;
  @Input() autoHeight = true;
  @Input() height: string = 'auto';

  @Input() top = 58;
  @Input() stickyTop = false;
  @Input() showTooltip = false;
  @Input() showTooltipField = false;
  @Input() showColumnGroups = true;

  @Input() data!: TableData[];
  @Input() columns!: TableColumns;
  @Input() columnGroups!: TableColumnGroup[];

  @Input() totalPages?: number = 0;
  @Input() page?: number;
  @Input() pageSize?: number;

  @Input() groupLine = true;

  @Input() equalWidth = false;
  columnWidth!: string;

  @Output() sort = new EventEmitter<TableSort | undefined>();
  @Output() actionClick = new EventEmitter<{ name: string; data: TableData }>();
  @Output() rowClick = new EventEmitter<TableData>();
  @Output() cellClick = new EventEmitter<{ key: string; row: TableData }>();
  @Output() cellSaveClick = new EventEmitter<{
    value: string;
    row: any;
    column: any;
  }>();
  @Output() pageChange = new EventEmitter<PaginationPageChange>();

  scrollLeft = 0;
  scrollableHeaders: string[] = [];
  scrollableHeaderGroups: string[] = [];

  @Input() filteredColumnKeys: string[] = [];

  filteredColumns!: TableColumns;
  filteredColumnGroups!: TableColumnGroup[];

  sortedColumn: TableSort | undefined;

  openedEditableWizard: string | undefined;

  lastOpenened: any;
  lastIsOpened: boolean = false;

  tableWidth = 0;

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!!this.columns) {
      this.filteredColumnKeys = Object.keys(this.columns);
    }
  }

  ngAfterViewInit(): void {}

  ngOnChanges(): void {
    if (!!this.columns && this.filteredColumnKeys.length <= 0) {
      this.filteredColumnKeys = Object.keys(this.columns);
    }

    if (this.equalWidth) {
      this.columnWidth = `${100 / this.filteredColumnKeys.length}%`;
    }

    if (this.stickyTop && this.top && this.tableWrapSticky) {
      this.tableWrapSticky.nativeElement.style.top = `${top}px`;
    }

    if (this.filteredColumnKeys && !!this.columns) {
      if (this.filteredColumnKeys.length) {
        const cols: TableColumns = {};
        Object.keys(this.columns).forEach((key) => {
          if (this.filteredColumnKeys.includes(key)) {
            cols[key] = this.columns[key];
          }
        });
        this.filteredColumns = cols;
      } else {
        this.filteredColumns = this.columns;
      }
    }

    this.render();
  }

  render() {
    this.formatColumnGroups();
    this.setScrolalbelHeaders();

    if (!this.stickyTop) return;
    this.setScrollableHeaderGroups();

    setTimeout(() => {
      const headers = document.querySelectorAll('[data-header]');
      const fixedHeaders = document.querySelectorAll('[data-fixed-header]');
      headers.forEach((element: any, index: number) => {
        const { offsetWidth } = element;
        (fixedHeaders.item(index) as any).style.width = `${offsetWidth}px`;
      });

      this.setColumnGroupWidths();
    }, 1);

    setTimeout(() => {
      const marginTop =
        this.tableHeadGroup.nativeElement.offsetHeight +
        this.tableHead.nativeElement.offsetHeight;

      this.renderer.setStyle(
        this.tableWrap.nativeElement,
        'margin-top',
        `-${marginTop}px`,
      );
    }, 100);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.render();
  }

  formatColumnGroups() {
    if (this.filteredColumnKeys.length <= 0 || !this.columnGroups) return;
    this.filteredColumnGroups = [];

    Object.keys(this.filteredColumns).forEach((key) => {
      const columnGroup = this.columnGroups.find((e) =>
        e.columnDefs.includes(key),
      );

      if (!columnGroup) {
        const defs = [key].filter((e) => this.filteredColumnKeys.includes(e));
        this.filteredColumnGroups.push({
          columnDefs: defs,
          columnDefSize: defs.length,
          sectionTitle: '',
          key: key,
        });
      } else {
        const defs = columnGroup.columnDefs.filter((e) =>
          this.filteredColumnKeys.includes(e),
        );
        const defsKey = defs.join('.');
        const filteredGroup = this.filteredColumnGroups.find(
          (e) => e.key === defsKey,
        );

        if (filteredGroup) {
          filteredGroup.key = defsKey;
          filteredGroup.columnDefs = defs;
          filteredGroup.columnDefSize = defs.length;
        } else {
          this.filteredColumnGroups.push({
            columnDefs: defs,
            sectionTitle: columnGroup.sectionTitle,
            columnDefSize: defs.length,
            key: defsKey,
            sticky: columnGroup.sticky,
          });
        }
      }
    });

    Object.keys(this.filteredColumns).forEach((key) => {
      this.filteredColumnGroups.forEach((group) => {
        const index = group.columnDefs.indexOf(key);
        if (index > -1 && group.sectionTitle) {
          this.filteredColumns[key].firstOfGroup = index === 0;
          this.filteredColumns[key].lastOfGroup =
            index === group.columnDefs.length - 1;
        }
      });
    });
  }

  setColumnGroupWidths() {
    if (!this.stickyTop || !this.filteredColumnGroups) return;

    this.tableWidth = 0;

    this.filteredColumnGroups.forEach((group) => {
      let width = 0;
      group.columnDefs.forEach((colDef) => {
        width += (
          document.querySelectorAll(`[data-header="${colDef}"]`) as any
        )[0]?.offsetWidth;
      });
      (
        document.querySelectorAll(`[data-group-header="${group.key}"]`) as any
      )[0].width = `${width}px`;

      this.tableWidth += width;
    });
  }

  setScrollableHeaderGroups() {
    if (this.stickyTop && this.filteredColumnGroups) {
      this.filteredColumnGroups.forEach((group) => {
        if (!group.sticky) {
          this.scrollableHeaderGroups.push(group.key ?? '');
        }
      });
    }
  }
  setScrolalbelHeaders() {
    if (this.stickyTop && this.filteredColumns) {
      Object.keys(this.filteredColumns).forEach((key) => {
        if (!this.filteredColumns[key].sticky) {
          this.scrollableHeaders.push(key);
        }
      });
    }
  }

  onScrollInverted() {
    this.updateScroll(this.scrollbarInverted, this.scrollWrap);
  }

  onScroll(e: any) {
    this.updateScroll(this.scrollWrap, this.scrollbarInverted);

    this.scrollLeft = this.scrollWrap.nativeElement.scrollLeft;

    const fixedHeaders = document.querySelectorAll('[data-fixed-header]');
    fixedHeaders.forEach((header: any) => {
      if (this.scrollableHeaders.includes(header.dataset.fixedHeader)) {
        (header as any).style.transform = `translateX(-${this.scrollLeft}px)`;
      }
    });

    const fixedGroupHeaders = document.querySelectorAll('[data-group-header]');
    fixedGroupHeaders.forEach((header: any) => {
      if (this.scrollableHeaderGroups.includes(header.dataset.groupHeader)) {
        (header as any).style.transform = `translateX(-${this.scrollLeft}px)`;
      }
    });
  }

  updateScroll(
    sourceElement: ElementRef<any>,
    destinationElement: ElementRef<any>,
  ) {
    const source = sourceElement.nativeElement as HTMLElement;
    const destination = destinationElement.nativeElement as HTMLElement;

    destination.scrollLeft = source.scrollLeft;
  }

  public onSort(columnDef: string): void {
    if (!this.sortedColumn || columnDef !== this.sortedColumn.sortedBy) {
      this.sortedColumn = { sortedBy: columnDef, state: 'asc' };
    } else if (columnDef === this.sortedColumn.sortedBy) {
      if (this.sortedColumn.state === 'asc') {
        this.sortedColumn.state = 'desc';
      } else {
        this.sortedColumn = undefined;
      }
    }
    this.sort.emit(this.sortedColumn);
  }

  toggleTooltip(columnKey: string) {}

  onClickAction(e: Event, name: string, data: TableData): void {
    e.preventDefault();
    e.stopPropagation();
    this.actionClick.emit({ name, data });
  }
  onClickRow(tableRowData: TableData): void {
    this.rowClick.emit(tableRowData);
  }
  onClickCell(key: string, row: TableData): void {
    this.cellClick.emit({ key: key, row: row });
  }

  originalOrder = (
    a: KeyValue<string, TableColumnConfig>,
    b: KeyValue<string, TableColumnConfig>,
  ): number => {
    return 0;
  };

  public onEditCell(data: any, col: string, event: any): void {
    this.lastIsOpened = false;
    if (this.lastOpenened) {
      this.lastOpenened.opened = false;
    }
    this.lastOpenened = data;

    data.opened = true;
    const { x, y } = event.target.getBoundingClientRect();
    if (!data?.editable) {
      return;
    }

    // We need to delay the logic to prevent conflict with the click HostListener
    setTimeout(() => {
      this.openedEditableWizard = `#editable-cell-${col}`;

      const editableWizardEl: HTMLElement = document.querySelector(
        this.openedEditableWizard,
      ) as HTMLElement;
      const { classList } = editableWizardEl;
      if (!classList.contains('show')) {
        this.renderer.setStyle(editableWizardEl, 'top', y - 10 + 'px');
        this.renderer.setStyle(editableWizardEl, 'left', x - 10 + 'px');
        this.renderer.addClass(editableWizardEl, 'show');
      }

      setTimeout(() => {
        const inputEl: HTMLInputElement = document.querySelector(
          `${this.openedEditableWizard} input`,
        ) as any;
        inputEl.select();

        this.lastIsOpened = true;
      }, 100);
    }, 2);
  }

  onDismissWizard(data?: any): void {
    if (this.lastIsOpened) {
      data.opened = false;
      this.lastIsOpened = false;

      if (!this.openedEditableWizard) {
        return;
      }

      const editWizardEl = document.querySelector(this.openedEditableWizard);

      this.renderer.removeClass(editWizardEl, 'show');
      this.openedEditableWizard = undefined;
    }
  }
  public onCloseEditWizard(event: MouseEvent, data?: any): void {
    event.preventDefault();
    event.stopPropagation();

    data.opened = false;
    if (!this.openedEditableWizard) {
      return;
    }

    const editWizardEl = document.querySelector(this.openedEditableWizard);

    this.renderer.removeClass(editWizardEl, 'show');
    this.openedEditableWizard = undefined;

    this.lastIsOpened = false;
  }

  public onSaveEditWizard(event: any, row: any, column: any): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.openedEditableWizard) {
      return;
    }

    const editWizardEl = document.querySelector(this.openedEditableWizard);

    const inputEl: HTMLInputElement = document.querySelector(
      `${this.openedEditableWizard} input`,
    ) as any;

    this.cellSaveClick.emit({ value: inputEl.value, row, column });

    this.renderer.removeClass(editWizardEl, 'show');
    this.openedEditableWizard = undefined;
  }
}
