import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'klt-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    standalone: true,
})
export class ProgressBarComponent implements OnInit {
  @Input() progress: number | null = null;
  constructor() {}

  ngOnInit(): void {}
}
