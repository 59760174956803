import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { KltModalRefService } from '@kolytics/shared-components';
import { ButtonComponent } from '../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { ModalComponent } from '../../../../../../../../libs/shared-components/src/lib/components/modal/modal.component';
import { ReitAssumptionService, ReitService } from '@kolytics/shared-api';

@Component({
  selector: 'klt-reset-scenario-modal',
  templateUrl: './reset-scenario-modal.component.html',
  styleUrls: ['./reset-scenario-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalComponent, ButtonComponent],
})
export class ResetScenarioModalComponent implements OnInit {
  loading = false;
  scenarioId = '';

  constructor(
    protected readonly kltModalRefService: KltModalRefService,
    private reitService: ReitService,
    private reitAssumptionService: ReitAssumptionService,
    @Inject('KLT_MODAL_DATA') private data: { reitId: number },
  ) {}

  ngOnInit(): void {
    this.setUserScenarioId();
  }

  setUserScenarioId() {
    const path = document.location.pathname;
    const pathSplit = `${path}`.split('/');
    this.scenarioId = pathSplit[pathSplit.length - 2];
  }

  public onClose(): void {
    this.kltModalRefService.close(ResetScenarioModalComponent, false);
  }

  public onReset(): void {
    this.loading = true;
    this.reitAssumptionService
      .apiReitReitIdAssumptionResetUserScenarioIdGet(
        Number(this.scenarioId),
        this.data.reitId,
      )
      .subscribe(
        (res) => {
          this.loading = false;
          this.kltModalRefService.close(
            ResetScenarioModalComponent,
            res.result,
          );
        },
        (err) => {
          this.loading = false;
        },
      );
  }
}
