enum ErrorCodes {
  InvalidUser = 'invalid_user',
  InvalidEmail = 'invalid_email',
  InvalidPassword = 'invalid_password',
  InvalidCredentials = 'invalid_credentials',
  InvalidEmailDistribution = 'invalid_email_distribution',
  InvalidEmailDistributionEmail = 'invalid_email_distribution_email',
  InvalidToken = 'invalid_token',
  InvalidReitId = 'invalid_reitId',
  InvalidReitManagementTeamId = 'invalid_ReitManagementTeamId',
  InvalidSector = 'invalid_sector',
  InvalidReitUpdateId = 'invalid_UpdateId',
  InvalidRounUpId = 'invalid_roundup_id',
  RoundUpDeleteNotAllowed = 'roundup_delete_not_allowed',
  TrialExpired = 'trial_expired',

  PasswordMustContainsAtLeastOneUpercaseChar = 'password_must_contains_at_least_one_upercase_char',
  PasswordMustContainsAtLeastOneLowercaseChar = 'password_must_contains_at_least_one_lowercase_char',
  PasswordMustContainsAtLeastOneNumber = 'password_must_contains_at_least_one_number',
  PasswordMustContainsAtLeast8Characters = 'password_must_contains_at_least_8_characters',
}

export const ErrorMessages = new Map<string, string>([
  [ErrorCodes.InvalidUser, "This user doesn't have access"],
  [ErrorCodes.InvalidEmail, "This email doesn't exist"],
  [ErrorCodes.InvalidPassword, 'This password is incorrect.'],
  [ErrorCodes.InvalidCredentials, 'InvalidCredentials'],
  [ErrorCodes.InvalidEmailDistribution, 'InvalidCredentials'],
  [ErrorCodes.InvalidEmailDistributionEmail, 'InvalidCredentials'],
  [ErrorCodes.InvalidToken, 'Token is invalid or expired.'],
  [ErrorCodes.InvalidReitId, 'InvalidCredentials'],
  [ErrorCodes.InvalidReitManagementTeamId, 'InvalidCredentials'],
  [ErrorCodes.InvalidSector, 'InvalidCredentials'],
  [ErrorCodes.InvalidReitUpdateId, 'InvalidCredentials'],
  [ErrorCodes.InvalidRounUpId, 'InvalidCredentials'],
  [ErrorCodes.RoundUpDeleteNotAllowed, 'InvalidCredentials'],
  [ErrorCodes.TrialExpired, 'InvalidCredentials'],
  [
    ErrorCodes.PasswordMustContainsAtLeastOneUpercaseChar,
    'Password must contains at least one upercase character.',
  ],
  [
    ErrorCodes.PasswordMustContainsAtLeastOneLowercaseChar,
    'Password must contains at least one lowercase character.',
  ],
  [
    ErrorCodes.PasswordMustContainsAtLeastOneNumber,
    'Password must contains at least one number.',
  ],
  [
    ErrorCodes.PasswordMustContainsAtLeast8Characters,
    'Password must contains at least 8 characters.',
  ],
]);
