<div class="overview-team">
  <klt-section
    title="Management team"
    type="pagination"
    [length]="totalCount"
    [showPagination]="showPagination"
    [showTitleScroll]="showTitleScroll"
    (paginate)="pageActive = $event; pagination()"
    >
    <div class="lead-box">
      @for (lead of paginateLeads; track lead) {
        <div class="lead-item">
          <div class="lead-avatar">
            <img [src]="lead.photo" [alt]="lead.name" />
          </div>
          <div class="lead-avatar print">
            <div class="row">
              <img [src]="lead.photo" [alt]="lead.name" />
            </div>
            <div class="row column">
              <div class="tenure">
                <div class="label">tenure</div>
                <div class="value">
                  {{ lead.tenure }}
                </div>
              </div>
              @if (lead.shareholding != null && lead.shareholding != '') {
                <div
                  class="shareholding"
                  >
                  <div class="label">shareholding</div>
                  <div class="value">
                    {{ lead.shareholding }}
                  </div>
                </div>
              }
            </div>
          </div>
          <div class="lead-content">
            <div class="name-row">
              <div class="name">
                {{ lead.name }}
              </div>
            </div>
            <div class="info-row">
              <div
                class="role"
                [class.expanded]="lead.expanded"
                [innerHTML]="lead.biography"
              ></div>
              <div class="bio-label">biography</div>
              <div class="another-info">
                <div class="tenure">
                  <div class="label">tenure</div>
                  <div class="value">
                    {{ lead.tenure }}
                  </div>
                </div>
                <div class="separator-ver"></div>
                @if (lead.shareholding != null && lead.shareholding != '') {
                  <div
                    class="shareholding"
                    >
                    <div class="label">shareholding</div>
                    <div class="value">
                      {{ lead.shareholding }}
                    </div>
                  </div>
                }
                <klt-icon
                  class="icon-down"
                  [class.expanded]="lead.expanded"
                  icon="down"
                  (click)="lead.expanded = !lead.expanded"
                ></klt-icon>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  </klt-section>
</div>
