import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  DropDownAction,
  DropDownOption,
  KltModalService,
  TableColumnGroup,
  TableColumns,
  TableData,
} from '@kolytics/shared-components';
import { KltToastService } from 'libs/shared-components/src/lib/services/toast/toast.service';
import {
  IAssumptionProfileScenarioResponse,
  IBaseReit,
  IReitData,
} from '../../../interfaces';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import {
  RemoveScenarioModalComponent,
  RenameScenarioModalComponent,
  ResetScenarioModalComponent,
  ResetToLastSaveModalComponent,
  CreateScenarioModalComponent,
} from '../../../modals';
import { filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import { autoDestroy, followDestroy } from '@kolytics/shared-functions';
import { getColNameByYear } from '../../../utils';
import { ReitStoreService } from '../../../services/reit-store.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { AssumptionBaseComponent } from '../../../utils/components/assumption/assumption-base-component';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import { ClientSignalStore } from 'apps/client/src/app/store/client.store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DropdownActionsComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/form/dropdown-actions/dropdown-actions.component';
import { ButtonComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { DropdownWrapperComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/form/dropdown-wrapper/dropdown-wrapper.component';
import { LoadingSpinnerComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/loading-spinner/loading-spinner.component';
import { LinkActionComponent } from '../../../components/link-action/link-action.component';
import {
  TableComponent,
  TableToolbarDirective,
} from '../../../../../../../../../libs/shared-components/src/lib/components/table/table.component';
import { TableV2Component } from '../../../../../../../../../libs/shared-components/src/lib/components/table-v2/table-v2.component';
import {
  ReitAssumptionService,
  ReitService,
  UserAssumptionsService,
} from '@kolytics/shared-api';

@Component({
  selector: 'klt-company-table',
  templateUrl: './company-table.component.html',
  styleUrls: ['./company-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TableV2Component,
    TableToolbarDirective,
    LinkActionComponent,
    LoadingSpinnerComponent,
    DropdownWrapperComponent,
    ButtonComponent,
    DropdownActionsComponent,
    TableComponent,
  ],
})
@UntilDestroy()
@autoDestroy()
export class CompanyTableComponent
  extends AssumptionBaseComponent
  implements OnInit
{
  public more: boolean = false;
  public moreOptions: DropDownOption[] = [
    //{ label: 'Reset to last save', value: 0 },
    { label: 'Reset scenario', value: 1 },
    { label: 'Rename scenario', value: 2 },
    { label: 'Delete scenario', value: 3 },
  ];
  public moreActions: DropDownAction[] = [
    {
      label: 'Create scenario',
      value: 'create',
      icon: 'plus',
      color: 'cta',
    },
  ];

  public reitTableData: IReitData = {
    sections: [],
    headers: {},
    total: 0,
    data: [],
  };

  reitId!: number;

  scenarioAId!: number;
  scenarioBId!: number;

  companyYears: number[] = [];
  private readonly baseScenarioName = 'Base';
  private readonly userScenarioName = 'User';

  private _updatedData: any;
  get updatedData(): any {
    return this._updatedData;
  }
  @Input() set updatedData(v: any) {
    this._updatedData = v;

    if (v) {
      this.buildTableData(v);
    }
  }

  @Input() showToolbar = true;
  @Input() showTopHeader = true;
  @Input() useOldTable = false;
  @Input() init = true;

  constructor(
    protected readonly kltModalService: KltModalService,
    protected readonly kltToastService: KltToastService,
    protected readonly reitService: ReitService,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly ref: ChangeDetectorRef,
    protected readonly reitStoreService: ReitStoreService,
    protected readonly spinner: NgxSpinnerService,
    protected readonly cdr: ChangeDetectorRef,
    protected clientFacade: ClientFacadeService,
    protected clientSignalStore: ClientSignalStore,
    protected userAssumptionService: UserAssumptionsService,
    protected reitAssumptionService: ReitAssumptionService,
  ) {
    super(
      kltModalService,
      kltToastService,
      router,
      reitService,
      userAssumptionService,
      spinner,
      reitStoreService,
      cdr,
      reitAssumptionService,
    );
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setReitId();

    if (this.init) {
      this.initiateAssumptionScenario();
      this.listeningStorageUpdates(
        this.reitId,
        this.scenarioAId,
        'company',
        () => this.refreshData(this.scenarioAId, false),
        () => this.initiateAssumptionScenario(false),
      );
    }
  }

  setReitId() {
    const queryReitId = this.activatedRoute.snapshot.params['reitId'];
    const scenarioAId = this.activatedRoute.snapshot.params['scenarioA'];
    const scenarioBId = this.activatedRoute.snapshot.params['scenarioB'];
    this.isPopup = queryReitId && scenarioAId && scenarioBId;
    if (this.isPopup) {
      this.reitId = queryReitId;
      this.scenarioAId = scenarioAId;
      this.scenarioBId = scenarioBId;
    } else {
      const path = document.location.pathname;
      const pathSplit = `${path}`.split('/');
      this.reitId = Number(pathSplit[2]);
      this.scenarioAId = Number(pathSplit[pathSplit.length - 2]);
    }
  }

  protected valueOfReitId(): string {
    return this.reitId.toString();
  }

  protected valueOfUserScenarioId(): number | string {
    return this.scenarioAId;
  }

  // run once on ngOnInit
  initiateAssumptionScenario(notifyUpdates: boolean = false) {
    this.clientFacade
      .getReitId()
      .pipe(
        take(1),
        switchMap((reitId: number) =>
          this.clientFacade.getSelectedLeftScenarioId(reitId).pipe(
            take(1),
            switchMap((left) =>
              this.clientFacade.getSelectedRightScenarioId(reitId).pipe(
                take(1),
                map((right) => [left, right]),
              ),
            ),
          ),
        ),
        switchMap(([left, right]) =>
          this.reitAssumptionService.apiReitReitIdAssumptionComparePost(
            Number(this.reitId),
            {
              isDefaultScenarioA: true,
              isDefaultScenarioB: true,
              reitId: Number(this.reitId),
              scenarioAId: left,
              scenarioBId: right,
              userId: 1,
            },
          ),
        ),
      )
      .subscribe((data: any) => {
        this.buildTableData(data);
        this.ref.markForCheck();

        if (notifyUpdates) {
          this.clientSignalStore.update((state) => ({
            company: {
              ...state.company,
              [this.reitId]: {
                scenarioId: this.scenarioAId,
                updatedAt: Date.now(),
              },
            },
          }));
        }
      });
  }

  buildTableData(data: any) {
    this.scenarioAId = data.result.userScenario.scenarioId;
    this.scenarioBId = data.result.userBaseScenario.scenarioId;
    const { sections, header } = this.createSectionsAndHeaders(data);
    const stickLeftColumns: TableColumns = {
      // field: {
      //   title: 'Field',
      //   fill: 'purple',
      //   sortable: true,
      //   type: 'text',
      // },
    };
    this.reitTableData = {
      sections: sections,
      headers: header,
      data: this.setTableData(data),
      stickLeftColumns: stickLeftColumns,
    } as IReitData;
  }

  createSectionsAndHeaders(data: IAssumptionProfileScenarioResponse) {
    const sections: TableColumnGroup[] = [
      { sectionTitle: '', columnDefs: ['field'], sticky: true },
    ];
    const header: TableColumns = {
      field: {
        title: 'Field',
        fill: 'purple',
        sortable: true,
        type: 'text',
        sticky: true,
      },
    };
    const userAssumptionsCompany =
      data.result.userAssumptionCompany || data.result.assumptionCompany;
    const baseAssumptionsCompany =
      data.result.baseAssumptionCompany ||
      data.result.userBaseAssumptionCompany;
    if (userAssumptionsCompany.length > 0) {
      this.companyYears = this.getYears(userAssumptionsCompany);
      sections.push({
        sectionTitle: data.result.userScenario.name,
        columnDefs: this.companyYears.map((year: number) =>
          this.generateColumnDefs(year, this.userScenarioName),
        ),
        spacing: true,
        spacingFill: 'light',
      });

      for (const year of this.companyYears) {
        const colName = this.generateColumnDefs(year, this.userScenarioName);
        header[colName] = {
          title: getColNameByYear(year),
          fill: year < moment().year() ? 'purple' : 'purpleDisabled',
          sortable: false,
          type: 'text',
          style: { center: true },
        };
      }
    }

    if (baseAssumptionsCompany.length > 0) {
      this.companyYears = this.getYears(baseAssumptionsCompany);
      sections.push({
        sectionTitle: data.result.userBaseScenario?.name || 'Base',
        columnDefs: this.companyYears.map((year: number) =>
          this.generateColumnDefs(year, this.baseScenarioName),
        ),
      });

      for (const year of this.companyYears) {
        const colName = this.generateColumnDefs(year, this.baseScenarioName);
        header[colName] = {
          title: getColNameByYear(year),
          fill: 'dark',
          sortable: false,
          type: 'text',
          spacingAfter: true,
          style: { center: true },
        };
      }
    }

    return { sections, header };
  }

  getYears(data: IBaseReit[]) {
    const years = [];
    for (const row of data.map((x) => x.rows)) {
      years.push(...row.map((x) => x.year));
    }
    return [...new Set(years)].sort();
  }

  generateColumnDefs(year: number, scenarioName: string) {
    const colName = getColNameByYear(year);
    return `${colName}_${scenarioName}`;
  }

  setTableData(data: IAssumptionProfileScenarioResponse): TableData[] {
    const baseAssumptionsCompany =
      data.result.baseAssumptionCompany ||
      data.result.userBaseAssumptionCompany;
    const userAssumptionsCompany =
      data.result.userAssumptionCompany || data.result.assumptionCompany;

    if (baseAssumptionsCompany.length > 0) {
      return baseAssumptionsCompany.map((item: IBaseReit, index: number) => {
        return this.setTableItem(item, userAssumptionsCompany);
      });
    }
    return [];
  }

  isSpacing(fieldName: string) {
    const fieldNames = [
      'Other',
      'Acquistion NIY',
      'Disposal NIY',
      'Development Margin',
      'New Shares',
      'Cumulative Interest Cost',
      'Scrip Dividend New Shares',
    ];
    if (fieldNames.includes(fieldName)) {
      return true;
    }
    return false;
  }

  setTableItem(item: IBaseReit, userAssumptionsCompany: IBaseReit[]) {
    const value: TableData = {
      field: {
        data: item.fieldName,
        fill: 'none',
        spacing: this.isSpacing(item.fieldName),
      },
      methodName: { data: item.methodName, fill: 'none' },
    };

    for (const year of this.companyYears) {
      const row = item.rows.find((x) => x.year === year);

      const isSpacing = this.isSpacing(row?.fieldName ?? '');

      const yearLabel = this.generateColumnDefs(year, this.baseScenarioName);
      if (row) {
        value[yearLabel] = {
          data: row.fieldValue + (item.type === 'percentage' ? '%' : ''),
          dataType: item.type,
          editable: item.editable,
          editData: {
            value: row.fieldValue,
            validate: (value: string) => {
              return +value > 0;
            },
            invalidErrorMessage: 'Invalid Value',
          },
          id: row.id,
          style: { center: true },
          spacing: isSpacing,
        } as any;
      } else {
        value[yearLabel] = {
          data: 0,
          editable: false,
          id: 0,
          spacing: isSpacing,
        } as any;
      }
    }
    if (userAssumptionsCompany.length > 0) {
      let userItem = userAssumptionsCompany.find(
        (x) => x.fieldName === item.fieldName,
      );
      if (!userItem) {
        userItem = {
          ...item,
          editable: false,
          rows: item.rows.map((x) => {
            return { ...{ ...x, fieldValue: 0 } };
          }),
        };
      }
      for (const year of this.companyYears) {
        const row = userItem.rows.find((x) => x.year === year);
        const isSpacing = this.isSpacing(row?.fieldName ?? '');
        const yearLabel = this.generateColumnDefs(year, this.userScenarioName);
        if (row) {
          value[yearLabel] = {
            data: row.fieldValue + (item.type === 'percentage' ? '%' : ''),
            dataType: item.type,
            editable: userItem.editable,
            editData: {
              value: row.fieldValue,
              validate: (value: string) => {
                return +value > 0;
              },
              invalidErrorMessage: 'Invalid Value',
            },
            id: row.id,
            style: { center: true },
            spacing: isSpacing,
          } as any;
        } else {
          value[yearLabel] = {
            data: 0,
            editable: false,
            id: 0,
            spacing: isSpacing,
          } as any;
        }
      }
    }
    return value;
  }

  updateSectionTitle(data: { name: string; scenarioId: string }) {
    if (this.reitTableData.sections.length == 2) {
      this.reitTableData.sections[0].sectionTitle = data.name;
      this.reitTableData.data = [...this.reitTableData.data];
      this.clientFacade.renameScenario(this.reitId, data.name, data.scenarioId);
      this.ref.markForCheck();

      this.clientSignalStore.update((state) => ({
        status: {
          ...state.status,
          [this.reitId]: {
            shouldUpdate: true,
          },
        },
      }));
    }
  }

  popout(): void {
    const key = 'company';
    this.storeTableInfoInStorage(key);
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        `/reits/popout/company/${this.reitId}/${this.scenarioAId}/${this.scenarioBId}`,
      ]),
    );

    window.open(
      url,
      '_blank',
      'location=yes,height=800,width=600,scrollbars=yes,status=yes',
    );
  }

  storeTableInfoInStorage(key: string) {
    localStorage.setItem(key, JSON.stringify(this.reitTableData));
  }

  public toggleMore(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.more = !this.more;
  }

  public save(): void {
    this.kltToastService.success('Changes save', 4000);
  }

  public solveOption(option: DropDownOption): void {
    switch (option.value) {
      case 0:
        this.resetToLastSave();
        break;
      case 1:
        this.resetScenario();
        break;
      case 2:
        this.renameScenario();
        break;
      case 3:
        this.deleteScenario();
        break;
    }
  }

  public solveAction(action: DropDownAction): void {
    switch (action.value) {
      case 'create':
        this.createScenario();
        break;
    }
  }

  protected resetToLastSave(): void {
    this.kltModalService
      .open(ResetToLastSaveModalComponent)
      .afterClosed.pipe(
        first(),
        tap((value: boolean) => {
          if (value) {
            this.kltToastService.success('Last save reset', 4000);
          }
        }),
        followDestroy(this),
      )
      .subscribe();
  }

  protected resetScenario(): void {
    this.kltModalService
      .open(ResetScenarioModalComponent, { data: { reitId: this.reitId } })
      .afterClosed.pipe(first(), followDestroy(this))
      .subscribe(() => {
        this.initiateAssumptionScenario(false);
        this.clientSignalStore.update((state) => ({
          company: {
            ...state.company,
            [this.reitId]: {
              scenarioId: Number(this.scenarioAId),
              updatedAt: Date.now(),
              resetted: true,
            },
          },
        }));
      });
  }

  protected renameScenario(): void {
    this.kltModalService
      .open(RenameScenarioModalComponent, { data: { reitId: this.reitId } })
      .afterClosed.pipe(
        first(),
        tap((data: { name: string; scenarioId: string }) => {
          if (data.name) {
            if (data.name === 'REIT') {
              this.kltToastService.error(
                'This name is already used for a scenario',
                4000,
              );
            } else {
              this.updateSectionTitle(data);
              this.kltToastService.success('Scenario renamed', 4000);
            }
          }
        }),
        followDestroy(this),
      )
      .subscribe();
  }

  protected deleteScenario(): void {
    this.kltModalService
      .open(RemoveScenarioModalComponent, { data: { reitId: this.reitId } })
      .afterClosed.pipe(
        first(),
        tap((value: boolean) => {
          if (value) {
            this.kltToastService.success('Scenario deleted', 4000);
            this.router.navigate([`/reits/${this.reitId}/assumptions`]);
          }
        }),
        followDestroy(this),
      )
      .subscribe();
  }

  protected createScenario(): void {
    this.kltModalService
      .open(CreateScenarioModalComponent)
      .afterClosed.pipe(
        first(),
        tap((value: string | undefined) => {
          if (value) {
            if (value === 'REIT') {
              this.kltToastService.error(
                'This name is already used for a scenario',
                4000,
              );
            } else {
              this.router.navigate([
                `/reits/${this.reitId}/assumptions/create/${value}`,
              ]);
            }
          }
        }),
        followDestroy(this),
      )
      .subscribe();
  }

  onCellSave({
    value,
    row,
    column,
  }: {
    value: string;
    row: any;
    column: any;
  }): void {
    const id = (
      this.activatedRoute.snapshot.parent as ActivatedRouteSnapshot
    ).paramMap.get('id');

    const methodName = row.methodName;
    const fieldName = row.field;
    const columnName = column;
    const { id: rowId, dataType } = row[columnName] as any;

    if (this.reitId === null || !fieldName || !columnName) return;

    this.userAssumptionService
      .apiUserAssumptionsUserAssumptionCompanyReitIdPut(this.reitId, {
        fieldValue: parseFloat(value),
        rowId,
        scenarioId: this.scenarioAId,
        type: dataType,
        methodName: methodName.data,
      })
      .subscribe(
        (data: any) => {
          const fValue =
            dataType === 'percentage'
              ? parseFloat(value) + '%'
              : parseFloat(value) + '';
          this.reitTableData.data = this.reitTableData.data.map(
            (item, index) =>
              index === row
                ? {
                    ...item,
                    [columnName]: {
                      ...item[columnName],
                      data: fValue,
                    },
                  }
                : item,
          );

          this.queueRunning = data.assumptionQueueState === 2;
          this.queueCount = data.queueCount;

          if (this.queueState === 1) {
            console.log('[Queue] Timer started');
            this.queueInterval = setInterval(() => {
              this.checkQueue(this.scenarioAId);
            }, 1000);
          }
          this.queueState = data.assumptionQueueState;
        },
        (err) => {
          this.queueRunning = false;
        },
      );
  }

  checkQueue(scenarioId: number) {
    super.checkQueue(this.reitId, scenarioId, 2, () => {
      this.refreshData(scenarioId, true);
    });
  }

  refreshData(scenarioId: number, notifyUpdates: boolean) {
    this.clientFacade
      .getReitId()
      .pipe(
        take(1),
        switchMap((reitId: number) =>
          this.clientFacade.getSelectedLeftScenarioId(reitId).pipe(
            take(1),
            switchMap((left) =>
              this.clientFacade.getSelectedRightScenarioId(reitId).pipe(
                take(1),
                map((right) => [left, right]),
              ),
            ),
          ),
        ),
        switchMap(([left, right]) =>
          this.reitAssumptionService.apiReitReitIdAssumptionComparePost(
            Number(this.reitId),
            {
              isDefaultScenarioA: true,
              isDefaultScenarioB: true,
              reitId: Number(this.reitId),
              scenarioAId: left,
              scenarioBId: right,
              userId: 1,
            },
          ),
        ),
      )
      .subscribe((data: any) => {
        const allData = this.setTableData(data);
        const { sections, header } = this.createSectionsAndHeaders(data);

        let updatesAvailable = false;
        Object.keys(header).forEach((key) => {
          this.reitTableData.data.forEach((item, index) => {
            const oldData = item[key].data;
            const newData = allData[index][key].data;

            if (oldData !== newData) {
              this.reitTableData.data[index][key] = {
                ...allData[index][key],
                fill: allData[index][key].fill,
              };

              updatesAvailable = true;
            }
          });
        });

        if (updatesAvailable && notifyUpdates) {
          this.clientSignalStore.update((state) => ({
            company: {
              ...state.company,
              [this.reitId]: {
                scenarioId: Number(scenarioId),
                updatedAt: Date.now(),
              },
            },
          }));
        }

        this.cdr.detectChanges();
      });
  }
}
