import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_DECIMAL_POINTS,
  DEFAULT_DECIMAL_SEPARATOR,
  DEFAULT_GROUP_SEPRATOR,
  DEFAULT_LOCALE,
} from './constants';

const getCurrency = (
  value: number,
  locale: string,
  currencyCode: string,
  decimalPoints: number = 2,
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalPoints,
  }).format(value);
};

const getPercentage = (
  value: number,
  locale: string,
  decimalPoints: number = 2,
  minimumDecimalPoints: number = 0,
) => {
  return new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: minimumDecimalPoints,
    maximumFractionDigits: decimalPoints,
  }).format(value);
};

const getNumber = (
  value: number,
  locale: string,
  decimalPoints: number = 2,
  minimumDecimalPoints: number = 0,
) => {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: minimumDecimalPoints,
    maximumFractionDigits: decimalPoints,
  }).format(value);
};

const getGroupSeparator = (locale: string, value: number = 1000.1) => {
  return Intl.NumberFormat(locale)
    .formatToParts(value)
    .find((part) => part.type === 'group')?.value;
};
const getDecimalSeparator = (locale: string, value: number = 1000.1) => {
  return Intl.NumberFormat(locale)
    .formatToParts(value)
    .find((part) => part.type === 'decimal')?.value;
};

export const getCurrencySymbol = (
  value: number,
  locale: string,
  currencyCode?: string,
  decimalPoints?: number,
) => {
  const symbol = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalPoints,
  })
    .formatToParts(value)
    .find((part) => part.type === 'currency')?.value;

  return symbol;
};

export const formatCurrency = (
  value?: number,
  decimalPoints?: number,
  locale?: string,
  currencyCode?: string,
  pence?: boolean,
  currencyFormat: string = '{0}{1}',
) => {
  if (!value) value = 0;
  const defaultGroupSeparator = getGroupSeparator(DEFAULT_LOCALE);
  const defaultDecimalSeparator = getDecimalSeparator(DEFAULT_LOCALE);

  const defaultCurrencySymbol = getCurrencySymbol(
    value,
    locale ?? DEFAULT_LOCALE,
    currencyCode,
    decimalPoints,
  );

  const currency = getNumber(
    value,
    DEFAULT_LOCALE,
    decimalPoints ?? DEFAULT_DECIMAL_POINTS,
    decimalPoints ?? DEFAULT_DECIMAL_POINTS,
  );

  let result = currency.replace(
    defaultGroupSeparator ?? ' ',
    DEFAULT_GROUP_SEPRATOR,
  );
  result = result.replace(
    defaultDecimalSeparator ?? DEFAULT_DECIMAL_SEPARATOR,
    '.',
  );

  result = result.replace(defaultCurrencySymbol + '', '');
  result = currencyFormat
    .replace('{0}', String(defaultCurrencySymbol).trim())
    .replace('{1}', result.trim());

  if (pence && currencyCode === 'GBP' && defaultCurrencySymbol) {
    result = result.replace(defaultCurrencySymbol, '');
    result = `${result}p`;
  }

  return result;
};

export const formatPercentage = (
  value?: number,
  decimalPoints?: number,
  locale?: string,
  minimumDecimalPoints: number = 0,
) => {
  if (!value) value = 0;
  const defaultGroupSeparator = getGroupSeparator(DEFAULT_LOCALE);
  const defaultDecimalSeparator = getDecimalSeparator(DEFAULT_LOCALE);

  const percent = getPercentage(
    value,
    DEFAULT_LOCALE,
    decimalPoints ?? DEFAULT_DECIMAL_POINTS,
    minimumDecimalPoints,
  );

  let result = percent.replace(
    defaultGroupSeparator ?? ' ',
    DEFAULT_GROUP_SEPRATOR,
  );
  result = result.replace(
    defaultDecimalSeparator ?? DEFAULT_DECIMAL_SEPARATOR,
    '.',
  );

  return result;
};

export const formatNumber = (
  value?: number,
  decimalPoints?: number,
  locale?: string,
  minimumDecimalPoints: number = 0,
) => {
  if (!value) value = 0;
  const defaultGroupSeparator = getGroupSeparator(DEFAULT_LOCALE);
  const defaultDecimalSeparator = getDecimalSeparator(DEFAULT_LOCALE);

  const number = getNumber(
    value,
    DEFAULT_LOCALE,
    decimalPoints ?? DEFAULT_DECIMAL_POINTS,
    minimumDecimalPoints,
  );

  let result = number.replace(
    defaultGroupSeparator || ' ',
    DEFAULT_GROUP_SEPRATOR,
  );
  result = result.replace(
    defaultDecimalSeparator ?? DEFAULT_DECIMAL_SEPARATOR,
    '.',
  );

  return result;
};
