export enum Claims {
  REITS_MY__VIEW = 'reits.my:view',
  REITS_ALL__VIEW = 'reits.all:view',

  SECTORS__VIEW = 'sectors:view',
  OVERVIEW__VIEW = 'overview:view',
  PROJECTIONS__VIEW = 'projections:view',
  FINANCIALS__VIEW = 'financials:view',
  FINANCIALS_INCOME_STATEMENT__VIEW = 'financials.income_statement:view',
  FINANCIALS_BALANCE_SHEET__VIEW = 'financials.balance_sheet:view',
  FINANCIALS_CASHFLOW_STATEMENT__VIEW = 'financials.cashflow_statement:view',
  ASSUMPTION__VIEW = 'assumption:view',
  ASSUMPTION__CREATE = 'assumption:create',
  ASSUMPTION__UPDATE = 'assumption:update',
  ASSUMPTION__DELETE = 'assumption:delete',

  RESTRICTION_DOWNLOAD_ROWS__15 = 'restriction.download.rows:15',
}
