import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  PaginationPageChange,
  TableData,
  TableSort,
} from '@kolytics/shared-components';
import { reitTableData } from 'apps/client/src/app/shared/constants/mock-data';
import { KltModalService } from 'libs/shared-components/src/lib/services/modal/modal.service';
import { KltToastService } from 'libs/shared-components/src/lib/services/toast/toast.service';
import { catchError, first, switchMap, take, tap } from 'rxjs/operators';
import { IReitData } from '../../interfaces';
import {
  autoDestroy,
  followDestroy,
  formatNumber,
  formatPercentage,
} from '@kolytics/shared-functions';
import { BaseReit } from '../../utils/components/base-reit/base-reit.util';
import { EMPTY, iif, of } from 'rxjs';
import { ProductFeature } from '@kolytics/types/common';
import { ExportService, ReitService } from '@kolytics/shared-api';
import { RemoveModalComponent } from '@kolytics/shared-components/modals';
import { ButtonComponent } from '../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { FeaturedDirective } from '../../../../../../../../libs/shared-components/src/lib/directives/featured.directive';
import { LinkActionComponent } from '../../components/link-action/link-action.component';

import { ReitTableNavigationComponent } from '../../../../../../../../libs/shared-components/src/lib/components/table-navigation/table-navigation.component';
import { TableToolbarDirective } from '../../../../../../../../libs/shared-components/src/lib/components/table/table.component';
import { TableV2Component } from '../../../../../../../../libs/shared-components/src/lib/components/table-v2/table-v2.component';
import { SectorRegionService } from '../../services/sector-region.service';
import { HasClaimDirective } from 'libs/shared-components/src/lib/directives/has-claim.directive';

@Component({
  selector: 'klt-my-reits',
  templateUrl: './my-reits.component.html',
  styleUrls: ['./my-reits.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TableV2Component,
    TableToolbarDirective,
    ReitTableNavigationComponent,
    LinkActionComponent,
    FeaturedDirective,
    ButtonComponent,
    HasClaimDirective,
  ],
})
@autoDestroy()
export class MyReitsComponent extends BaseReit implements OnInit {
  public reitTableData: IReitData = reitTableData as IReitData;
  pageIndex = 1;
  pageSize = 1000;
  orderBy = '';
  orderByDirection = '';
  excelLoading = false;
  modelLoading = false;

  loading = true;

  isPopout = false;
  decimalPoints = 1;
  downloadsFeature = ProductFeature.Downloads;

  constructor(
    protected readonly kltModalService: KltModalService,
    protected readonly kltToastService: KltToastService,
    protected readonly service: ReitService,
    private exportService: ExportService,
    protected readonly router: Router,
    protected readonly cdr: ChangeDetectorRef,
    private sectorRegionService: SectorRegionService,
  ) {
    super(kltModalService, kltToastService, router);
  }

  async ngOnInit() {
    await this.sectorRegionService.init();

    this.isPopout = this.router.url.indexOf('/popout/') > -1;
    this.reitTableData.data = [
      {
        company: { data: 'Loading...' },
        country: { data: 'Loading...' },
        sector: { data: 'Loading...' },
        price: { data: 'Loading...' },
        nnniy: { data: 'Loading...' },
        growth: { data: 'Loading...' },
        devt: { data: 'Loading...' },
        unLeveredIrr: { data: 'Loading...' },
        wacd: { data: 'Loading...' },
        ltv: { data: 'Loading...' },
        debtBoost: { data: 'Loading...' },
        leveredIrr: { data: 'Loading...' },
        openingPDGav: { data: 'Loading...' },
        spotImpliedIrr: { data: 'Loading...' },
        closingPDGav: { data: 'Loading...' },
        impIrr: { data: 'Loading...' },
        eqRP: { data: 'Loading...' },
        requiredReturn: { data: 'Loading...' },
        excessReturns: { data: 'Loading...' },

        corporateScore: { data: 'Loading...' },
        bsRiskScore: { data: 'Loading...' },
        niy: { data: 'Loading...' },
        irr: { data: 'Loading...' },
        r: { data: 'Loading...' },
        b: { data: 'Loading...' },
        correlationToMkt: { data: 'Loading...' },
        kolyticsRating: { data: 'Loading...' },

        giy: { data: 'Loading...' },
        opex: { data: 'Loading...' },
        capex: { data: 'Loading...' },
        gandA: { data: 'Loading...' },
        investmentIrr: { data: 'Loading...' },

        'action-my-reit': {
          data: '',
          action: { name: 'remove', icon: 'trash', style: 'basic' },
        },
      },
    ];
    this.cdr.detectChanges();
    this.fetchReits();
  }

  sort(e: TableSort | undefined) {
    this.orderBy = e?.sortedBy ?? '';
    this.orderByDirection = e?.state ?? '';
    this.fetchReits();
  }
  public openRegionFilter(): void {
    this.sectorRegionService.openRegionFilter(undefined, () => {
      this.fetchReits();
    });
  }

  public fetchReits(): void {
    this.service
      .apiReitMyReitsGet(
        this.pageIndex,
        this.pageSize,
        this.orderBy,
        this.orderByDirection,
        this.sectorRegionService.regionsIds,
      )
      .pipe(
        tap((res) => {
          this.reitTableData = {
            ...(reitTableData as IReitData),
            data: res.data!.map((item: any) => ({
              id: { data: item.reitId + '' },
              company: { data: item.reit.name },
              country: { data: item.reitCountry + '    ' },
              sector: { data: item.sectorName + '    ' },
              price: { data: item.sharePrice },

              nnniy: {
                data: formatPercentage(item.nnniy, this.decimalPoints),
                color: 'success',
              },
              growth: {
                data: formatPercentage(item.growth, this.decimalPoints),
                color: 'warn',
              },

              devt: {
                data: formatPercentage(item.devt, this.decimalPoints),
                color: 'warn',
              },
              unLeveredIrr: {
                data: formatPercentage(item.unleveredIrr, this.decimalPoints),
              },

              wacd: { data: formatPercentage(item.wacd, this.decimalPoints) },
              ltv: { data: formatPercentage(item.ltv, this.decimalPoints) },
              debtBoost: {
                data: formatPercentage(item.debtBoost, this.decimalPoints),
              },
              yieldImpact: {
                data: formatPercentage(item.yieldImpact, this.decimalPoints),
              },
              leveredIrr: {
                data: formatPercentage(item.leveredIrr, this.decimalPoints),
                color: 'success',
              },
              openingPDGav: {
                data: formatPercentage(item.openingPDGav, this.decimalPoints),
                color: 'success',
              },
              spotImpliedIrr: {
                data: formatPercentage(item.spotImpliedIrr, this.decimalPoints),
                color: 'success',
              },
              closingPDGav: {
                data: formatPercentage(item.closingPDGav, this.decimalPoints),
                color: 'success',
              },
              impIrr: {
                data: formatPercentage(item.impliedIrr, this.decimalPoints),
                color: 'success',
              },

              eqRP: {
                data: formatPercentage(item.eqRiskPrem, this.decimalPoints),
                color: 'warn',
              },
              requiredReturn: {
                data: formatPercentage(item.requiredReturn, this.decimalPoints),
                color: 'success',
              },

              excessReturns: {
                data: formatPercentage(item.excessReturns, this.decimalPoints),
                color: 'success',
              },

              corporateScore: {
                data: formatNumber(item.corporateScore, this.decimalPoints),
              },
              bsRiskScore: {
                data: formatNumber(item.bsRiskScore, this.decimalPoints),
              },
              niy: { data: formatPercentage(item.niy, this.decimalPoints) },
              irr: { data: formatPercentage(item.irr, this.decimalPoints) },
              r: { data: formatPercentage(item.r, this.decimalPoints) },
              b: { data: formatNumber(item.b, this.decimalPoints) },
              correlationToMkt: {
                data: formatNumber(item.correlationToMkt, this.decimalPoints),
              },
              kolyticsRating: { data: item.kolyticsRating },

              giy: { data: formatPercentage(item.giy, this.decimalPoints) },
              opex: { data: formatPercentage(item.opex, this.decimalPoints) },
              capex: { data: formatPercentage(item.capex, this.decimalPoints) },
              gandA: { data: formatPercentage(item.gandA, this.decimalPoints) },
              investmentIrr: {
                data: formatPercentage(item.investmentIrr, this.decimalPoints),
              },

              'action-my-reit': {
                data: '',
                action: { name: 'remove', icon: 'trash', style: 'basic' },
              },
            })),
            total: 0,
          };

          super.ngOnInit();
          this.cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  onPageChange(e: PaginationPageChange) {
    this.pageIndex = e.pageIndex;
    this.fetchReits();
  }

  public popout(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/reits/popout/reits/my`]),
    );

    window.open(url, '_blank');
  }

  public onActionClick(action: { name: string; data: TableData }): void {
    const itemAction = action.data[action.name].action;
    switch (itemAction?.name) {
      case 'remove':
        this.removeRow(action.data);
        break;
    }
  }

  protected removeRow(item: TableData): void {
    this.service
      .apiReitMyReitsReitIdDelete(Number(item['id'].data))
      .pipe(
        take(1),
        tap(() => {
          this.kltToastService.success(`${item['company'].data} removed`, 4000);
          this.reitTableData.data = this.reitTableData.data.filter(
            (row) => row['id'].data !== item['id'].data,
          );
          this.cdr.detectChanges();
        }),
        catchError((err) => {
          this.kltToastService.error(
            `${item['company'].data} remove failed`,
            4000,
          );
          return EMPTY;
        }),
      )
      .subscribe();
  }

  public onRowClickId(id?: string) {
    this.router.navigate([`reits/${id}/overview`]);
  }

  downloadExcel() {
    this.excelLoading = true;
    this.exportService.apiExportReitsGet().subscribe(
      (data: Blob) => {
        const file = new Blob([data], { type: 'text/csv' });
        const fileURL = URL.createObjectURL(file);

        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'High-level PropCo Metrics.xlsx';
        document.body.appendChild(a);
        a.click();

        this.excelLoading = false;
      },
      (err) => {
        // console.log(err);
        this.excelLoading = false;
      },
    );
  }

  downloadModels() {
    this.modelLoading = true;
    this.exportService.apiExportModelsGet().subscribe(
      (data) => {
        const blob = new Blob([data], {
          type: 'application/zip',
        });
        const fileURL = window.URL.createObjectURL(blob);

        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'Models.zip';
        document.body.appendChild(a);
        a.click();

        this.modelLoading = false;
      },
      (err) => {
        this.modelLoading = false;
      },
    );
  }
}
