import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'negativeFormatter',
  standalone: true,
})
export class NegativeFormatterPipe implements PipeTransform {
  transform(value: any): string {
    if (!value) return value;
    if (value === '-') return value;

    let isNegative = false;
    if (typeof value === 'string') isNegative = value.startsWith('-');
    if (typeof value === 'number') isNegative = value < 0;
    if (!isNegative) return value;

    let formattedValue: string = value.toString();
    if (isNegative) {
      formattedValue = formattedValue.replace('-', '');
      formattedValue = `(${formattedValue})`;
      return formattedValue;
    }
    return formattedValue;
  }
}
