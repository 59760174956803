<div
  class="table-scroll"
  [ngClass]="{ 'sticky-header': stickyHeader }"
  #tableScroll
  (scroll)="handleScroll()"
>
  <table>
    <div *ngIf="showShadowLeft" #shadowLeft class="shadow-left"></div>
    <thead>
      <!-- Table groups -->
      <tr #section *ngIf="columnGroups && !hideSection">
        <ng-container *ngFor="let group of formattedColumnGroups">
          <ng-container *ngIf="group.groupTitle === null; else groupTemplate">
            <th
              class="none-section"
              [class.sticky-right]="
                checkSticky(group.columns[0].key) === 'sticky-right'
              "
              [class.sticky-left]="
                checkSticky(group.columns[0].key) === 'sticky-left'
              "
            ></th>
          </ng-container>
          <ng-template #groupTemplate>
            <th
              [attr.colspan]="group.columns.length"
              [class.sticky-right]="
                checkSticky(group.columns[0].key) === 'sticky-right'
              "
              [class.sticky-left]="
                checkSticky(group.columns[0].key) === 'sticky-left'
              "
            >
              <div
                class="section-header"
                [ngClass]="'color-' + (group.color ? group.color : 'dark')"
              >
                <span class="line"></span>
                <ng-container *ngIf="group.groupTitle">
                  <span class="title">{{ group.groupTitle }}</span>
                </ng-container>
                <span class="line"></span>
              </div>
            </th>
            <th *ngIf="group.spacing" class="cell-spacing">
              <div class="cell-spacing"></div>
            </th>
          </ng-template>
        </ng-container>
      </tr>

      <!-- Table headers -->
      <tr #header>
        <ng-container *ngFor="let group of formattedColumnGroups">
          <ng-container *ngFor="let column of group.columns">
            <th
              [ngClass]="'fill-' + column.config.fill || 'none'"
              [class.sticky-right]="checkSticky(column.key) === 'sticky-right'"
              [class.sticky-left]="checkSticky(column.key) === 'sticky-left'"
              [attr.colspan]="column.config.colspan"
              [attr.width]="column.config.fixWidth"
            >
              <div
                class="cell-header"
                [class.no-padding]="noPadding"
                [class.group-start]="
                  verticalGroupLine && isFirstOfGroup(column)
                "
                #cellHeader
              >
                <span class="title" [innerText]="column.config.title"></span>
                <!-- <ng-container *ngIf="column.config.sortable">
                  <klt-button
                    iconSize="xsmall"
                    class="sort-btn"
                    [class.active]="sortedColumn?.sortedBy === column.key"
                    [leftIcon]="
                      sortedColumn?.state === 'asc' ? 'arrow-up' : 'arrow-down'
                    "
                    [type]="
                      column.config.fill === 'light' ||
                      column.config.fill === 'tint'
                        ? 'secondary'
                        : 'primary'
                    "
                    (click)="onSort(column.key)"
                  >
                  </klt-button>
                </ng-container> -->
                <span
                  *ngIf="showTooltip"
                  class="header-tooltip"
                  #tooltip
                  [style.--hoverLeft]="getPosition(cellHeader, true).left"
                  [style.--hoverTop]="getPosition(cellHeader, true).top"
                  [style.--left]="getPosition(cellHeader, false).left"
                  [style.--top]="getPosition(cellHeader, false).top"
                  [innerHTML]="
                    column.config.tooltip
                      ? column.config.tooltip
                      : column.config.title
                  "
                ></span>
              </div>
            </th>
          </ng-container>
          <th *ngIf="group.spacing" class="cell-spacing">
            <div class="cell-spacing"></div>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody #body>
      <!-- Table data -->
      <ng-container *ngFor="let row of data; let trIndex = index">
        <tr
          class="{{ (trIndex + 1) % 2 === 0 ? 'tr-even' : 'tr-odd' }} {{
            row['class']?.data
          }}"
          [class.highlight-row]="
            highlightSpaceRow && (trIndex + 1) % highlightSpaceRow === 0
          "
          [class.non-public-bg]="
            row['isPublic'] && row['isPublic'].data === 'false'
          "
          [class.non-active-bg]="
            row['isActive'] && row['isActive'].data === 'false'
          "
        >
          <ng-container *ngFor="let col of columnLeftDef; let i = index">
            <ng-container [ngSwitch]="getType(col, stickLeftColumns)">
              <ng-container *ngSwitchCase="'html'">
                <td
                  (click)="onClickRow(row)"
                  [class.td-spacing]="row[col] && row[col].spacing"
                  [class.group-start]="verticalGroupLine && isFirstOfGroup(col)"
                  [class.group-end]="verticalGroupLine && isEndOfGroup(col)"
                  class="sticky-left {{
                    'fill-' +
                      (row[col] ? row[col].fill : 'none') +
                      (row[col] && row[col].border
                        ? ' border-' + row[col].border
                        : '')
                  }} td-{{ i + 1 }}-{{ tableId }}"
                  [class.non-public-bg]="
                    row['isPublic'] && row['isPublic'].data === 'false'
                  "
                  [class.non-active-bg]="
                    row['isActive'] && row['isActive'].data === 'false'
                  "
                >
                  <div
                    class="cell-data"
                    [class.no-padding]="noPadding"
                    [class.editable]="row[col] && row[col].editable"
                    [ngClass]="
                      row[col] && row[col].color
                        ? 'color-' + row[col].color
                        : ''
                    "
                  >
                    <p [innerHTML]="row[col] && row[col].data"></p>
                    <!-- {{ row[col]?.data }} -->
                  </div>
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'text'">
                <td
                  (click)="onClickRow(row)"
                  [class.td-spacing]="row[col] && row[col].spacing"
                  [class.group-start]="verticalGroupLine && isFirstOfGroup(col)"
                  [class.group-end]="verticalGroupLine && isEndOfGroup(col)"
                  [class.td-highlight-top]="row[col] && row[col].highlight"
                  [class.td-highlight-bottom]="
                    row[col] &&
                    row[col].highlight &&
                    ((data &&
                      data[trIndex + 1] &&
                      !data[trIndex + 1][col].highlight) ||
                      (data && !data[trIndex + 1]))
                  "
                  class="sticky-left {{
                    'fill-' +
                      (row[col] ? row[col].fill : 'none') +
                      (row[col] && row[col].border
                        ? ' border-' + row[col].border
                        : '')
                  }} td-{{ i + 1 }}-{{ tableId }}"
                >
                  <div
                    class="cell-data"
                    [class.no-padding]="noPadding"
                    [class.editable]="row[col] && row[col].editable"
                    [ngClass]="
                      row[col] && row[col].color
                        ? 'color-' + row[col].color
                        : ''
                    "
                  >
                    <p>{{ row[col] && row[col].data }}</p>
                  </div>
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'action'">
                <td class="sticky-left td-{{ i + 1 + columnLeftDef.length }}">
                  <div class="cell-data action {{ getStyle(row, col) }}">
                    <ng-container
                      *ngIf="getStyle(row, col) === 'basic'; else circle"
                    >
                      <klt-icon
                        [icon]="getIcon(row, col)"
                        (click)="onClickAction(col, row)"
                      ></klt-icon>
                    </ng-container>
                    <ng-template #circle>
                      <span>circle</span>
                      <div class="circle">
                        <klt-button
                          type="icon"
                          iconSize="xsmall"
                          [leftIcon]="getIcon(row, col)"
                          border="round"
                          (click)="onClickAction(col, row)"
                        >
                        </klt-button>
                      </div>
                    </ng-template>
                  </div>
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'chart'">
                <td
                  (click)="onClickRow(row)"
                  class="sticky-left td-{{ i + 1 + columnLeftDef.length }}"
                >
                  <div
                    echarts
                    [options]="row[col] && row[col].chart"
                    class="graph-chart"
                    style="height: 100%; width: 100%"
                  ></div>
                </td>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let col of columnDef; let cdIndex = index">
            <ng-container [ngSwitch]="getType(col, columns)">
              <ng-container *ngSwitchCase="'html'">
                <td
                  (click)="onClickRow(row)"
                  [class.td-spacing]="row[col] && row[col].spacing"
                  [class.group-start]="verticalGroupLine && isFirstOfGroup(col)"
                  [class.group-end]="verticalGroupLine && isEndOfGroup(col)"
                  [class.td-highlight-top]="row[col] && row[col].highlight"
                  [class.td-highlight-bottom]="
                    row[col] &&
                    row[col].highlight &&
                    ((data &&
                      data[trIndex + 1] &&
                      !data[trIndex + 1][col].highlight) ||
                      (data && !data[trIndex + 1]))
                  "
                  [class.td-no-stick-first]="cdIndex === 0"
                  [class.td-no-stick-last]="cdIndex === columnDef.length - 1"
                  [class.td-no-stick]="
                    cdIndex !== 0 && cdIndex !== columnDef.length - 1
                  "
                  class="{{
                    'fill-' +
                      (row[col] ? row[col].fill : 'none') +
                      (row[col] && row[col].border
                        ? ' border-' + row[col].border
                        : '')
                  }} td-{{ cdIndex + 1 + columnLeftDef.length }}"
                >
                  <div
                    class="cell-data"
                    [class.no-padding]="noPadding"
                    [class.editable]="row[col] && row[col].editable"
                    [ngClass]="
                      row[col] && row[col].color
                        ? 'color-' + row[col].color
                        : ''
                    "
                    (click)="
                      onEditCell(row[col], trIndex, cdIndex, col, $event)
                    "
                  >
                    <p [innerHTML]="row[col] && row[col].data"></p>
                    <!-- {{ row[col]?.data }} -->
                    <div
                      *ngIf="row[col]?.editable"
                      class="edit-wizard"
                      [id]="
                        'editable-cell-' + col + '-' + trIndex + '-' + cdIndex
                      "
                    >
                      <klt-input
                        type="number"
                        [value]="row[col] && (row[col].editData?.value ?? 0)"
                        [hideNotch]="true"
                        [hasFocus]="true"
                        [errorLabel]="''"
                        [error]="row[col] && !!row[col].editData?.invalid"
                        (valueChanged)="editValue($event.value, col, row)"
                        [hideStateIndicator]="true"
                      >
                      </klt-input>
                      <div class="edit-action">
                        <klt-icon
                          (click)="
                            onSaveEditWizard($event, trIndex, cdIndex, row, col)
                          "
                          icon="tick"
                          size="small"
                          color="cta"
                        ></klt-icon>
                        <div class="edit-separator"></div>
                        <klt-icon
                          icon="close"
                          size="small"
                          (click)="onCloseEditWizard($event)"
                        ></klt-icon>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'text'">
                <td
                  (click)="onClickRow(row)"
                  [class.td-spacing]="row[col] && row[col].spacing"
                  [class.group-start]="verticalGroupLine && isFirstOfGroup(col)"
                  [class.group-end]="verticalGroupLine && isEndOfGroup(col)"
                  [class.td-highlight-top]="row[col] && row[col].highlight"
                  [class.td-highlight-bottom]="
                    row[col] &&
                    row[col].highlight &&
                    ((data &&
                      data[trIndex + 1] &&
                      !data[trIndex + 1][col].highlight) ||
                      (data && !data[trIndex + 1]))
                  "
                  [class.td-no-stick-first]="cdIndex === 0 && !stickLeftColumns"
                  [class.td-no-stick-last]="cdIndex === columnDef.length - 1"
                  [class.td-no-stick]="
                    (cdIndex !== 0 && cdIndex !== columnDef.length - 1) ||
                    (cdIndex === 0 && stickLeftColumns)
                  "
                  [style.text-decoration]="
                    row[col] && (row[col].style?.textDecoration ?? 'unset')
                  "
                  [style.text-decoration-style]="
                    row[col] && (row[col].style?.textDecorationStyle ?? 'unset')
                  "
                  [style.color]="row[col] && (row[col].style?.color ?? 'unset')"
                  class="{{
                    'fill-' +
                      (row[col] ? row[col].fill : 'none') +
                      (row[col] && row[col].border
                        ? ' border-' + row[col].border
                        : '')
                  }} td-{{ cdIndex + 1 + columnLeftDef.length }}"
                >
                  <div
                    class="cell-data"
                    [class.no-padding]="noPadding"
                    kltChangeHighlight
                    [row]="row[col]"
                    [class.editable]="row[col] && row[col].editable"
                    [ngClass]="
                      row[col] && row[col].color
                        ? 'color-' + row[col].color
                        : ''
                    "
                    (click)="onClickCell(col, row)"
                  >
                    <p
                      kltTableNumberFormatter
                      [value]="row[col] && row[col].data"
                    >
                      {{ row[col] && row[col].data | negativeFormatter
                      }}{{ row[col] && row[col].suffix }}
                    </p>
                    <div
                      *ngIf="row[col]?.editable && row[col]?.opened"
                      class="edit-wizard"
                      [id]="
                        'editable-cell-' + col + '-' + trIndex + '-' + cdIndex
                      "
                      kltClickOutside
                      (clickOutside)="onDismissWizard(row[col])"
                    >
                      <klt-input
                        type="number"
                        [value]="row[col] && (row[col].editData?.value ?? 0)"
                        [hideNotch]="true"
                        [hasFocus]="true"
                        [errorLabel]="''"
                        [error]="row[col] && !!row[col].editData?.invalid"
                        [hideStateIndicator]="true"
                        (enterPressed)="
                          onSaveEditWizard($event, trIndex, cdIndex, row, col)
                        "
                      >
                      </klt-input>
                      <div class="edit-action">
                        <klt-icon
                          (click)="
                            onSaveEditWizard($event, trIndex, cdIndex, row, col)
                          "
                          icon="tick"
                          size="small"
                          color="cta"
                        ></klt-icon>
                        <div class="edit-separator"></div>
                        <klt-icon
                          icon="close"
                          size="small"
                          (click)="onCloseEditWizard($event, row[col])"
                        ></klt-icon>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'icon-link'">
                <td
                  [class.td-no-stick-first]="cdIndex === 0"
                  [class.td-no-stick-last]="cdIndex === columnDef.length - 1"
                  [class.td-no-stick]="
                    cdIndex !== 0 && cdIndex !== columnDef.length - 1
                  "
                >
                  <div
                    class="cell-data action {{ getStyle(row, col) }}"
                    [class.no-padding]="noPadding"
                  >
                    <div
                      class="icon-link-container"
                      *ngIf="row[col]?.data"
                      (click)="onClickAction(col, row)"
                    >
                      <klt-icon
                        [color]="'cta'"
                        [icon]="getIcon(row, col)"
                      ></klt-icon>
                      <span
                        class="link"
                        [style.color]="row[col] && row[col].color"
                        >{{ row[col] && row[col].data }}</span
                      >
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'action'">
                <td
                  kltFeatured
                  [feature]="row[col] && row[col].action?.feature"
                  [class.td-no-stick-first]="cdIndex === 0"
                  [class.td-no-stick-last]="cdIndex === columnDef.length - 1"
                  [class.td-no-stick]="
                    cdIndex !== 0 && cdIndex !== columnDef.length - 1
                  "
                >
                  <div class="cell-data action {{ getStyle(row, col) }}">
                    <ng-container
                      *ngIf="getStyle(row, col) === 'basic'; else circle"
                    >
                      <klt-icon
                        [icon]="getIcon(row, col)"
                        (click)="onClickAction(col, row)"
                      ></klt-icon>
                    </ng-container>
                    <ng-template #circle>
                      <span>circle</span>
                      <div class="circle">
                        <klt-button
                          type="icon"
                          iconSize="xsmall"
                          [leftIcon]="getIcon(row, col)"
                          border="round"
                          (click)="onClickAction(col, row)"
                        >
                        </klt-button>
                      </div>
                    </ng-template>
                  </div>
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'chart'">
                <td
                  (click)="onClickRow(row)"
                  [class.td-no-stick-first]="cdIndex === 0"
                  [class.td-no-stick-last]="cdIndex === columnDef.length - 1"
                  [class.td-no-stick]="
                    cdIndex !== 0 && cdIndex !== columnDef.length - 1
                  "
                  style="height: 40px; width: 200px; padding-right: 10px"
                >
                  <div
                    echarts
                    [options]="row[col] && row[col].chart"
                    class="graph-chart"
                    style="height: 45px; width: 100%"
                  ></div>
                </td>
              </ng-container>
            </ng-container>
            <td
              *ngIf="findSpacing(col)"
              class="cell-spacing fill-{{ getSpacingFill(col) }}"
            >
              <div class="cell-spacing"></div>
            </td>
          </ng-container>
          <ng-container *ngFor="let col of columnRightDef; let i = index">
            <ng-container [ngSwitch]="getType(col, stickRightColumns)">
              <ng-container *ngSwitchCase="'html'">
                <td
                  (click)="onClickRow(row)"
                  [class.group-start]="verticalGroupLine && isFirstOfGroup(col)"
                  [class.group-end]="verticalGroupLine && isEndOfGroup(col)"
                  class="sticky-right {{
                    'fill-' +
                      (row[col] ? row[col].fill : 'none') +
                      (row[col] && row[col].border
                        ? ' border-' + row[col].border
                        : '')
                  }} td-{{ i + 1 + columnRightDef.length + columnDef.length }}"
                >
                  <div
                    class="cell-data"
                    [class.no-padding]="noPadding"
                    [class.editable]="row[col] && row[col].editable"
                    [ngClass]="
                      row[col] && row[col].color
                        ? 'color-' + row[col] && row[col].color
                        : ''
                    "
                  >
                    <p [innerHTML]="row[col] && row[col].data"></p>
                    <!-- {{ row[col]?.data }} -->
                  </div>
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'text'">
                <td
                  (click)="onClickRow(row)"
                  [class.group-start]="verticalGroupLine && isFirstOfGroup(col)"
                  [class.group-end]="verticalGroupLine && isEndOfGroup(col)"
                  class="sticky-right {{
                    'fill-' +
                      (row[col] ? row[col].fill : 'none') +
                      (row[col] && row[col].border
                        ? ' border-' + row[col].border
                        : '')
                  }} td-{{ i + 1 + columnRightDef.length + columnDef.length }}"
                >
                  <div
                    class="cell-data"
                    [class.no-padding]="noPadding"
                    [class.editable]="row[col] && row[col].editable"
                    [ngClass]="
                      row[col] && row[col].color
                        ? 'color-' + row[col] && row[col].color
                        : ''
                    "
                  >
                    <p>{{ row[col] && row[col].data }}</p>
                  </div>
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'action'">
                <td class="sticky-right">
                  <div class="cell-data action {{ getStyle(row, col) }}">
                    <ng-container
                      *ngIf="getStyle(row, col) === 'basic'; else circle"
                    >
                      <klt-icon
                        [icon]="getIcon(row, col)"
                        (click)="onClickAction(col, row)"
                      ></klt-icon>
                    </ng-container>
                    <ng-template #circle>
                      <span>circle</span>
                      <div class="circle">
                        <klt-button
                          type="icon"
                          iconSize="xsmall"
                          [leftIcon]="getIcon(row, col)"
                          border="round"
                          (click)="onClickAction(col, row)"
                        >
                        </klt-button>
                      </div>
                    </ng-template>
                  </div>
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'chart'">
                <td (click)="onClickRow(row)" class="sticky-right">
                  <div
                    echarts
                    [options]="row[col].chart"
                    class="graph-chart"
                    style="height: 100%; width: 100%"
                  ></div>
                </td>
              </ng-container>
            </ng-container>
          </ng-container>
        </tr>
        <tr *ngIf="spacingAfterRow && (trIndex + 1) % spacingAfterRow === 0">
          <ng-container *ngIf="spacingColumns.length === 0">
            <td
              [attr.colspan]="
                columnLeftDef.length + columnDef.length + columnRightDef.length
              "
            >
              <div class="cell-after-spacing">
                <div class="line"></div>
              </div>
            </td>
          </ng-container>
          <ng-container *ngIf="spacingColumns.length > 0">
            <ng-container *ngFor="let spacingColumn of spacingColumns">
              <td [attr.colspan]="spacingColumn.count">
                <div class="cell-after-spacing">
                  <div class="line"></div>
                </div>
              </td>
              <td
                *ngIf="spacingColumn.spacing"
                class="cell-spacing fill-{{ spacingColumn.spacingFill }}"
              >
                <div class="cell-spacing"></div>
              </td>
            </ng-container>
          </ng-container>
        </tr>
        <tr
          *ngIf="
            spacingAfterRowIndexes &&
            spacingAfterRowIndexes.includes(trIndex + 1)
          "
        >
          <ng-container *ngIf="spacingColumns.length === 0">
            <td
              [attr.colspan]="
                columnLeftDef.length + columnDef.length + columnRightDef.length
              "
            >
              <div class="cell-after-spacing">
                <div class="line"></div>
              </div>
            </td>
          </ng-container>
          <ng-container *ngIf="spacingColumns.length > 0">
            <ng-container *ngFor="let spacingColumn of spacingColumns">
              <td [attr.colspan]="spacingColumn.count">
                <div class="cell-after-spacing">
                  <div class="line"></div>
                </div>
              </td>
              <td
                *ngIf="spacingColumn.spacing"
                class="cell-spacing fill-{{ spacingColumn.spacingFill }}"
              >
                <div class="cell-spacing"></div>
              </td>
            </ng-container>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
    <div *ngIf="showShadowRight" #shadowRight class="shadow-right"></div>
  </table>
</div>
