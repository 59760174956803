<div class="reits--action">
  @if (iconLeft) {
    <span class="reits--action--left">
      <klt-icon [icon]="iconLeft" [color]="'cta'"></klt-icon>
    </span>
  }
  @if (text) {
    <span class="reits--action--text">
      {{ text }}
    </span>
  }
  @if (iconRight) {
    <span class="reits--action--right">
      <klt-icon [icon]="iconRight" [color]="'cta'"></klt-icon>
    </span>
  }

  @if (showLoading) {
    <klt-loading-spinner
      [show]="showLoading"
      [label]="''"
    ></klt-loading-spinner>
  }
</div>
