import { CurrencyPipe, getCurrencySymbol } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ReitMetadataResponse,
  ReitOverviewScoreboardResponse,
  ReitOverviewService,
  ReitService,
} from '@kolytics/shared-api';
import { Scoreboard } from 'libs/shared-components/src/lib/interfaces/scoreboard.interface';
import { IReitDataResponse } from '../../../interfaces';
import { NumberComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/number/number.component';
import { PercentageComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/percentage/percentage.component';
import { CurrencyComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/currency/currency.component';
import { ScoreboardComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/scoreboard/scoreboard.component';

@Component({
    selector: 'klt-overview-scoreboard',
    templateUrl: './overview-scoreboard.component.html',
    styleUrls: ['./overview-scoreboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        ScoreboardComponent,
        CurrencyComponent,
        PercentageComponent,
        NumberComponent,
    ],
})
export class OverviewScoreboardComponent {
  @Input() data!: ReitOverviewScoreboardResponse;
  @Input() metadata!: ReitMetadataResponse;

  constructor() {}
}
