import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intermediate',
  standalone: true,
  pure: false,
})
export class IntermediatePipe implements PipeTransform {
  transform(
    values: any[] | null | undefined,
    valuesToCheck: any[] | null | undefined,
  ): boolean {
    if (!values || !valuesToCheck) return false;
    if (valuesToCheck.length <= 0) return false;
    if (valuesToCheck.length !== values.length) return true;
    return false;
  }
}
