import { EChartsOption } from 'echarts';
import { YAXisOption } from 'echarts/types/dist/shared';
import * as moment from 'moment';

const isDeltaZero = (min: number, max: number): boolean => {
  return min - max === 0;
};
export const getDynamicRange = (
  min: number,
  max: number,
  step?: number,
  options?: EChartsOption,
): [number, number, number, EChartsOption | undefined] => {
  // when there is no difference between the min and max, create a difference
  if (isDeltaZero(min, max)) {
    max = min === 0 ? 4 : Math.abs(min) + 4;
  }

  if (!step) {
    step = 1000;
  }

  const aroundMin = Math.floor(min / step) * step;
  const aroundMax = Math.ceil(max / step) * step;

  const interval = (aroundMax - aroundMin) / 4;
  if (options) {
    (options.yAxis as any).max = aroundMax;
    (options.yAxis as any).min = aroundMin;
    (options.yAxis as any).interval = interval;
  }

  return [aroundMin, aroundMax, interval, options ? options : undefined];
};

export const getDynamicRangePercentage = (
  min: number,
  max: number,
  step?: number,
  options?: EChartsOption,
): [number, number, number, EChartsOption | undefined] => {
  if (!step) {
    step = 0.05;
  }

  const aroundMin = Math.floor(min / step) * step;
  const aroundMax = Math.ceil(max / step) * step;

  const interval = (aroundMax - aroundMin) / 4;
  if (options) {
    (options.yAxis as any).max = aroundMax;
    (options.yAxis as any).min = aroundMin;
    (options.yAxis as any).interval = interval;
  }

  return [aroundMin, aroundMax, interval, options ? options : undefined];
};

export const getColNameByYear = (year: number, isActualYear?: boolean) => {
  return isActualYear
    ? `${moment(year, 'YYYY').format('YY')}A`
    : moment(year, 'YYYY').format('YYF');
};

export const roundToNearestMutliple = (number: number) => {
  return roundToNearest(number);
};

export const roundToNearest = (number: number) => {
  const absNumber = Math.abs(number);
  let multiplier = 4;

  if (absNumber >= 20 && absNumber < 100) multiplier = 10;
  if (absNumber >= 100 && absNumber < 1000) multiplier = 100;
  if (absNumber >= 1000) multiplier = 500;

  let result = Math.ceil(absNumber / multiplier) * multiplier;

  // Make the result negative if the original number was negative
  if (number < 0) {
    result *= -1;
  }

  return result;
};
