import { Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol } from '@kolytics/shared-functions';

@Pipe({
    name: 'toPence',
    standalone: true,
})
export class ToPencePipe implements PipeTransform {
  transform(value?: string, shouldConvert: boolean = false): string {
    if (!value) return '';
    if (!shouldConvert) return value;

    const formattedValue = value.replace('£', '');
    return `${formattedValue}p`;
  }
}
