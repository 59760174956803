<div class="klt-toast" [ngClass]="config.type">
  <div class="toast-icon">
    <klt-icon
      [color]="'light'"
      [size]="'medium'"
      [icon]="config.main.icon"
    ></klt-icon>
  </div>
  <div
    class="toast-content"
    (click)="onOke()"
    [class.double]="config.lineCount === 2"
    >
    <div class="toast-main-content">
      <span>{{ config.main.message }}</span>
    </div>
    @if (config.extra) {
      <div class="toast-extra-content">
        <span>
          <klt-icon
            [color]="'light'"
            [size]="'medium'"
            [icon]="config.extra.icon"
          ></klt-icon>
        </span>
        <span class="message">{{ config.extra.message }}</span>
        <span>
          <klt-icon
            [color]="'light'"
            [size]="'medium'"
            [icon]="'arrow-right'"
          ></klt-icon>
        </span>
      </div>
    }
  </div>
  @if (config.showClose) {
    <div class="toast-separator"></div>
  }
  @if (config.showClose) {
    <div class="toast-close">
      <klt-icon
        [color]="'light'"
        (click)="onClose()"
        [size]="'medium'"
        [icon]="'close'"
      ></klt-icon>
    </div>
  }
</div>
