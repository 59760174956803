<div class="sectors--projection--content">
  <div class="header">
    <h3>Projections</h3>
    <klt-button class="region" type="secondary" (click)="openRegionFilter()"
      >SELECT REGION</klt-button
    >
  </div>
  <klt-sectors-projections-market-rate-growth
    [sector]="sectors && sectors.sectors.marketRateGrowth"
  ></klt-sectors-projections-market-rate-growth>
  <klt-separator></klt-separator>
  <klt-sectors-projections-occupancy-levels
    [sector]="sectors && sectors.sectors.occupancy"
  ></klt-sectors-projections-occupancy-levels>
  <klt-separator></klt-separator>
  <klt-sectors-projections-lfl-nri
    [sector]="sectors && sectors.sectors.lflNri"
  ></klt-sectors-projections-lfl-nri>
  <klt-separator></klt-separator>
  <klt-sectors-projections-cost-analysis
    [desc]="sectors && sectors.sectors.costAnalysisDescriptions"
    [sector]="sectors && sectors.sectors.costAnalysisResponse"
  ></klt-sectors-projections-cost-analysis>
  <klt-separator></klt-separator>
  <klt-sectors-projections-balance-sheet-strength
    [sector]="sectors && sectors.sectors.balanceSheetResponse"
  ></klt-sectors-projections-balance-sheet-strength>

  <klt-separator></klt-separator>
  <klt-sectors-projections-risk-return
    [sector]="sectors && sectors.sectors.riskReturn"
  ></klt-sectors-projections-risk-return>
</div>
