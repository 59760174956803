import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IconSize } from '@kolytics/shared-assets';
import { IconComponent } from '../../../../../../shared-assets/src/lib/components/icon/icon.component';


@Component({
    selector: 'klt-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    standalone: true,
    imports: [IconComponent],
})
export class ButtonComponent implements OnChanges, OnInit {
  @Input() leftIcon: any = '';
  @Input() rightIcon: any = '';
  @Input() dropdownExpanded = false;
  @Input() iconSize: IconSize = 'regular';
  @Input() iconColor: string = '';

  @Input() type:
    | 'primary'
    | 'secondary'
    | 'link'
    | 'icon'
    | 'danger'
    | 'secondary-danger'
    | string = 'primary';
  @Input() border: 'square' | 'round' | 'none' | string = 'square';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() delete = false;

  deletePending = false;
  deleteTimeout: any;

  @Output() clickDelete = new EventEmitter<void>();

  loaderColor = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.disabled?.currentValue !== undefined) {
      this._setButtonStyles();
    }
  }

  ngOnInit(): void {
    if (!this.iconColor.length) {
      this._setButtonStyles();
    }
  }

  private _setButtonStyles(): void {
    if (this.type === 'secondary' || this.type === 'link') {
      this.iconColor = 'cta';
      this.loaderColor = 'cta';
      if (this.disabled) {
        this.iconColor = 'ctaDisabled';
        this.loaderColor = 'ctaDisabled';
      }
    } else if (this.type === 'primary') {
      this.iconColor = 'light';
      this.loaderColor = 'light';
    } else if (this.type === 'icon') {
      this.iconColor = 'dark';
      this.loaderColor = 'dark';
      if (this.disabled) {
        this.iconColor = 'gray';
        this.loaderColor = 'gray';
      }
    }
  }

  onClick() {
    if (this.delete) {
      if (!this.deletePending) {
        this.deletePending = true;
        this.deleteTimeout = setTimeout(() => {
          this.deletePending = false;
          clearTimeout(this.deleteTimeout);
        }, 3000);
      } else {
        this.clickDelete.emit();
      }
    }
  }
}
