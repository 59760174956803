import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: 'klt-input-number-limit, [kltInputNumberLimit]',
    standalone: true,
})
export class InputNumberLimiterDirective {
  @Input()
  kltInputNumberLimit: number = 0;

  @HostListener('keyup')
  onKeyUp() {
    const val = this.el.nativeElement.value;
    if (val > this.kltInputNumberLimit) {
      this.el.nativeElement.value = val.substring(0, val.length - 1);
    }
  }

  constructor(private el: ElementRef) {}
}
