<klt-modal modalTitle="File upload" (closeModal)="onClose()">
  <div>
    <div class="top">
      @if (checkboxTitle) {
        <klt-checkbox
          [label]="checkboxTitle"
          (valueChanged)="checkboxChecked = $event.value"
        ></klt-checkbox>
      }
      @if (showSuccess) {
        <span class="remove-file" (click)="removeFile()">
          Remove file
        </span>
      }
    </div>
    <div class="dropzone" kltDnd (fileDropped)="onFileUploaded($event)">
      <input
        type="file"
        #fileDropRef
        multiple="false"
        id="fileDropRef"
        (change)="fileBrowseHandler($event)"
        />
        @if (showDrag && !showSuccess) {
          <klt-icon [size]="'huge'" [icon]="'upload'" [color]="'cta'"></klt-icon>
          <div class="text">
            Drag and drop your file or
            <label for="fileDropRef"><a>browse</a></label>
          </div>
        }
        @if (showProcess) {
          <klt-progress-bar [progress]="progress"></klt-progress-bar>
          <a class="delete">Cancel</a>
        }
        @if (showSuccess) {
          <div class="success">
            <klt-icon color="cta" icon="success" size="huge"></klt-icon>
            <b>Ready to upload</b>
          </div>
        }
      </div>
      @if (showUpload) {
        <div class="upload">
          <klt-button
            type="primary"
            (click)="uploadFile()"
            [loading]="uploading"
            [disabled]="uploading"
            >
            Upload file
          </klt-button>
        </div>
      }
    </div>
  </klt-modal>
