<div class="balance-sheet-nta-share">
  <klt-section title="Net Tangible Assets (NTA) / share" type="none">
    <div class="graph-box">
      <klt-graph
        [loading]="clientQuery.selectFinancialIsLoading$ | async"
        height="415px"
        [titles]="titles"
        [options]="options"
        (filter)="runFilter($event)"
      >
      </klt-graph>
    </div>
  </klt-section>
</div>
