<klt-table-v2
  [stickyTop]="true"
  [loading]="false"
  [data]="reitTableData.data"
  [columns]="reitTableData.headers"
  [columnGroups]="reitTableData.sections"
  [filteredColumnKeys]="filteredColumns"
  [top]="isPopout ? 77 : 77"
  [showTooltip]="true"
  [totalPages]="reitTableData.total"
  [page]="pageIndex"
  [pageSize]="pageSize"
  [autoHeight]="false"
  [height]="'48px'"
  (pageChange)="onPageChange($event)"
  (sort)="sort($event)"
  (actionClick)="onActionClick($event)"
  (rowClick)="onRowClickId($event.id.data)"
>
  <klt-table-toolbar>
    <div class="toolbar">
      <div class="toolbar--left">
        <klt-table-navigation></klt-table-navigation>
      </div>
      <div class="toolbar--right">
        @if (!isPopout) {
          <klt-link-action
            (click)="popout()"
            [iconRight]="'pop-out'"
            [text]="'Pop-out'"
          ></klt-link-action>
        }

        @if (!isPopout) {
          <klt-link-action
            kltFeatured
            [feature]="downloadsFeature"
            [iconLeft]="'download'"
            [text]="'Download Excel'"
            (click)="downloadExcel()"
            [showLoading]="!isPopout && excelLoading"
          ></klt-link-action>
          <klt-link-action
            *hasClaim="'permission:model-access'"
            kltFeatured
            [feature]="downloadsFeature"
            [iconLeft]="'download'"
            [text]="'Download Models'"
            (click)="downloadModels()"
            [showLoading]="!isPopout && modelLoading"
          ></klt-link-action>
        }
        <klt-button class="region" type="secondary" (click)="openRegionFilter()"
          >SELECT REGION</klt-button
        >
        <klt-button type="secondary" (click)="onOpenFilter()"
          >SELECT COLUMNS</klt-button
        >
      </div>
    </div>
  </klt-table-toolbar>
</klt-table-v2>
