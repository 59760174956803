<klt-section title="Cost analysis">
  <div class="graph-box">
    <div class="graph">
      <klt-graph
        [loading]="labels.length == 0"
        height="368px"
        [options]="options"
        [titles]="titles"
        (filter)="runFilter($event)"
      >
      </klt-graph>
      <div class="bottom">
        <b>Percentage points</b>
      </div>
    </div>
    <div class="explained">
      <div class="explained-item active none-arrow">
        <h4>Cost analysis explained</h4>
        <p [innerHTML]="desc?.costAnalysisExplained"></p>
      </div>
      <div class="explained-item" [class.active]="active === 'direct'">
        <klt-icon
          [icon]="active === 'direct' ? 'up' : 'down'"
          (click)="active = active === 'direct' ? '' : 'direct'"
        ></klt-icon>
        <h4 (click)="active = active === 'direct' ? '' : 'direct'">
          Direct costs
        </h4>
        <p [innerHTML]="desc?.directCosts"></p>
      </div>
      <div class="explained-item" [class.active]="active === 'ga'">
        <klt-icon
          [icon]="active === 'ga' ? 'up' : 'down'"
          (click)="active = active === 'ga' ? '' : 'ga'"
        ></klt-icon>
        <h4 (click)="active = active === 'ga' ? '' : 'ga'">G&A</h4>
        <p [innerHTML]="desc?.gA"></p>
      </div>
      <div class="explained-item" [class.active]="active === 'capex'">
        <klt-icon
          [icon]="active === 'capex' ? 'up' : 'down'"
          (click)="active = active === 'capex' ? '' : 'capex'"
        ></klt-icon>
        <h4 (click)="active = active === 'capex' ? '' : 'capex'">
          Capex reserve
        </h4>
        <p [innerHTML]="desc?.capexReserve"></p>
      </div>
      <div class="explained-item" [class.active]="active === 'debt'">
        <klt-icon
          [icon]="active === 'debt' ? 'up' : 'down'"
          (click)="active = active === 'debt' ? '' : 'debt'"
        ></klt-icon>
        <h4 (click)="active = active === 'debt' ? '' : 'debt'">
          Debt interest impact
        </h4>
        <p [innerHTML]="desc?.debtInterestImpact"></p>
      </div>
    </div>
  </div>
</klt-section>
