<div class="income-statement">
  <div class="income-statement-compare">
    <div class="income-statement-compare-button"></div>
    <div class="income-statement-compare-button">
      <klt-section
        type="button"
        buttonText="SELECT SCENARIOS"
        (buttonClick)="handleClick()"
        >
      </klt-section>
    </div>
  </div>

  <div class="income-statement-zone">
    <div class="income-statement-zone-item">
      <klt-income-statement-eps
        [reit]="clientFacade.financialData"
        [metadata]="metadata"
      ></klt-income-statement-eps>
    </div>
    <div class="income-statement-zone-item">
      <klt-income-statement-dps
        [reit]="clientFacade.financialData"
        [metadata]="metadata"
      ></klt-income-statement-dps>
    </div>
  </div>
  <klt-separator></klt-separator>
  <div class="income-statement-zone margin">
    <div class="income-statement-zone-item">
      <klt-income-statement-revenue
        [reit]="clientFacade.financialData"
        [metadata]="metadata"
      ></klt-income-statement-revenue>
    </div>
    <div class="income-statement-zone-item">
      <klt-income-statement-direct-costs
        [reit]="clientFacade.financialData"
        [metadata]="metadata"
      ></klt-income-statement-direct-costs>
    </div>
  </div>
  <div class="income-statement-zone">
    <div class="income-statement-zone-item">
      <klt-income-statement-indirect-costs
        [reit]="clientFacade.financialData"
        [metadata]="metadata"
      ></klt-income-statement-indirect-costs>
    </div>
    <div class="income-statement-zone-item">
      <klt-income-statement-interest-costs
        [reit]="clientFacade.financialData"
        [metadata]="metadata"
      ></klt-income-statement-interest-costs>
    </div>
  </div>
  <klt-separator></klt-separator>
  @if (!(clientQuery.selectFinancialIsLoading$ | async)) {
    <klt-income-statement-table
      [metadata]="metadata"
      [reit]="clientFacade.financialData"
    ></klt-income-statement-table>
  }
</div>
