import { Component, Inject, OnInit } from '@angular/core';
import { KltModalRefService } from '@kolytics/shared-components';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AssumptionProfileScenario } from '../../interfaces';
import { ButtonComponent } from '../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { CheckboxButtonComponent } from '../../../../../../../../libs/shared-components/src/lib/components/form/checkbox-button/checkbox-button.component';
import { AsyncPipe } from '@angular/common';
import { ModalComponent } from '../../../../../../../../libs/shared-components/src/lib/components/modal/modal.component';
import { ReitService } from '@kolytics/shared-api';

@Component({
  selector: 'klt-select-scenario-compare-dialog',
  templateUrl: './select-scenario-compare-dialog.component.html',
  styleUrls: ['./select-scenario-compare-dialog.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    CheckboxButtonComponent,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class SelectScenarioCompareDialogComponent implements OnInit {
  private _selectedIds: number[] = [];

  scenarios$!: Observable<any>;
  selectedScenarioIds$!: Observable<number[]>;

  maxSelection: number = 3;
  get disabled() {
    return this._selectedIds.length >= this.maxSelection;
  }

  isChecked(scenarioId: number) {
    return this._selectedIds.includes(scenarioId);
  }

  constructor(
    @Inject('KLT_MODAL_DATA') private data: any,
    private kltModalRefService: KltModalRefService,
    private reitService: ReitService,
    private clientFacade: ClientFacadeService,
  ) {
    this.scenarios$ = this.clientFacade.getScenarios(this.data.reitId);
    this.clientFacade
      .getSelectedScenarios(this.data.reitId)
      .pipe(tap((data) => this._selectedIds.push(...(data || []))))
      .subscribe();
  }

  ngOnInit(): void {}

  select(option: { label: string | null; selected: boolean; id?: number }) {
    const optionId = Number(option.id);
    if (option.selected) {
      this._selectedIds.push(optionId);
    } else {
      const index = this._selectedIds.findIndex((e) => e === optionId);
      this._selectedIds.splice(index, 1);
    }
  }
  onClose(): void {
    this.kltModalRefService.close(SelectScenarioCompareDialogComponent, []);
  }

  onSubmit(): void {
    this.clientFacade.setSelectedScenarios(this.data.reitId, this._selectedIds);
    this.kltModalRefService.close(SelectScenarioCompareDialogComponent, null);
  }
}
