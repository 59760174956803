import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from '../input/input.component';
import {
  MatDatepickerInputEvent,
  MatDatepickerInput,
  MatDatepicker,
} from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { ButtonComponent } from '../../common/button/button.component';
import { InputNumberLimiterDirective } from '../../../directives/input-number-limiter.directive';
import { NgClass } from '@angular/common';

@Component({
  selector: 'klt-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    InputNumberLimiterDirective,
    ButtonComponent,
    MatInput,
    MatDatepickerInput,
    MatDatepicker,
  ],
})
export class InputDateComponent extends InputComponent implements OnInit {
  @Input() defaultDate = false;
  @Input() allowClear = false;

  _dateValue: Date | null | undefined;
  get dateValue(): Date | null | undefined {
    return this._dateValue;
  }
  @Input() set dateValue(value: Date | null | undefined) {
    this._dateValue = value;
    if (value) {
      value.setHours(12);
      this.dateInputControl = {
        date: value.getDate(),
        month: value.getMonth() + 1,
        year: value.getFullYear(),
        fullDate: value,
        touched: true,
      };

      this.form.patchValue({
        formValue: this.dateInputControl.fullDate,
      });

      if (!this.formStates.labelRaised) {
        this.formStates.labelRaised = true;
      }
      if (!this.formStates.valid) {
        this.formStates.valid = true;
      }
    }
  }

  datePickerControl: string | undefined;

  dateInputControl: {
    date: number | null;
    month: number | null;
    year: number | null;
    fullDate: Date | null;
    touched?: boolean;
  } = { date: null, month: null, year: null, fullDate: null, touched: false };

  isDatePickerOpen: boolean = false;

  constructor(formBuilder: FormBuilder) {
    super(formBuilder);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.defaultDate) {
      const dateNow = new Date();
      this.dateInputControl = {
        date: dateNow.getDate(),
        month: dateNow.getMonth() + 1,
        year: dateNow.getFullYear(),
        fullDate: dateNow,
        touched: true,
      };

      this.form.patchValue({
        formValue: this.dateInputControl.fullDate,
      });
      this.onFocus();
      this.onBlur();
    }

    if (this.dateValue) {
      this.form.patchValue({
        formValue: this.dateInputControl.fullDate,
      });
    }
  }
  onSelectCalendarDate(calendarValue: MatDatepickerInputEvent<Date>): void {
    const selectedDate = calendarValue.value;

    selectedDate?.setHours(12);

    this.dateInputControl = {
      date: selectedDate?.getDate() || null,
      month: selectedDate?.getMonth() ? selectedDate.getMonth() + 1 : null,
      year: selectedDate?.getFullYear() ?? null,
      fullDate: selectedDate,
      touched: true,
    };

    this.formStates.labelRaised = true;

    this.form.patchValue({
      formValue: this.dateInputControl.fullDate,
    });
  }

  clearDate(e: Event): void {
    this.form.patchValue({
      formValue: null,
    });
    this.dateInputControl = {
      date: null,
      month: null,
      year: null,
      fullDate: null,
      touched: false,
    };
    this.formStates.focused = true;
    this.formStates.labelRaised = true;
  }

  onDatePickerClose(): void {
    this.isDatePickerOpen = false;
  }

  onDateFieldChange() {
    this.dateInputControl.touched = true;
  }

  onBlur(): void {
    const value = this.form.controls.formValue.value;
    this.lostFocus.emit({
      value: this.form.controls.formValue.value,
      status: this.form.controls.formValue.status,
    });
    if (!value) {
      this.formStates.labelRaised = false;
    }
    this.formStates.focused = false;
    if (this.type !== 'password') {
      if (this.formStates.valid && value) {
        this.displayIcon = 'tick';
      } else {
        this.displayIcon = '';
      }
    } else {
      this.displayIcon = 'eye';
    }

    // Throw error if datePickerControl values are invalid/incomplete
    if (
      this.dateInputControl.touched &&
      (!this.dateInputControl.date ||
        !this.dateInputControl.month ||
        !this.dateInputControl.year)
    ) {
      this.formStates = {
        disabled: false,
        labelRaised: true,
        focused: true,
        error: true,
        valid: false,
        selected: true,
      };
      this.form.setErrors({ invalid: true });
    }
  }

  getFormClasses(): string {
    const classArray = [];
    for (const [key, value] of Object.entries(this.formStates)) {
      if (value) {
        classArray.push(key);
      }
    }

    classArray.push('date');

    return classArray.join(' ');
  }
}
