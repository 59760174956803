import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseReit } from '../../utils/components/base-reit/base-reit.util';
import { KltModalService } from '@kolytics/shared-components';
import { KltToastService } from '../../../../../../../../libs/shared-components/src/lib/services/toast/toast.service';
import { IReitData } from '../../interfaces';
import { map } from 'rxjs/operators';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ReitStoreService } from '../../services/reit-store.service';
import { AssumptionBaseComponent } from '../../utils/components/assumption/assumption-base-component';
import { OtherTableComponent } from '../other-assumptions/other-table/other-table.component';
import { TableComponent } from '../../../../../../../../libs/shared-components/src/lib/components/table/table.component';
import {
  ReitAssumptionService,
  ReitService,
  UserAssumptionsService,
} from '@kolytics/shared-api';

@Component({
  selector: 'klt-data-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TableComponent, OtherTableComponent, NgxSpinnerModule],
})
export class TableDataComponent extends AssumptionBaseComponent {
  public reitTableData!: IReitData;
  public path = '';
  reitId!: number;
  userScenarioId = 0;

  constructor(
    protected readonly service: ReitService,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly kltModalService: KltModalService,
    protected readonly kltToastService: KltToastService,
    protected readonly router: Router,
    protected readonly cdr: ChangeDetectorRef,
    protected spinner: NgxSpinnerService,
    protected readonly reitService: ReitService,
    protected reitStoreService: ReitStoreService,
    protected reitAssumptionService: ReitAssumptionService,
    protected userAssumptionService: UserAssumptionsService,
  ) {
    super(
      kltModalService,
      kltToastService,
      router,
      reitService,
      userAssumptionService,
      spinner,
      reitStoreService,
      cdr,
      reitAssumptionService,
    );
  }

  public ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        map((params) => {
          return {
            tableInfo:
              params.name !== 'other_assumptions'
                ? JSON.parse(localStorage.getItem(params.name) || '')
                : {},
            path: params.name,
            reitId: params.reitId,
            userScenarioId: params.userScenarioId,
          };
        }),
      )
      .subscribe((data) => {
        this.path = data.path;
        this.reitId = data.reitId;
        this.userScenarioId = data.userScenarioId;
        this.reitTableData = data.tableInfo;
        if (this.path === 'other_assumptions') {
          this.reloadTableData(false);
        }
        this.cdr.detectChanges();
      });
  }

  protected valueOfReitId(): string {
    return this.reitId.toString();
  }

  protected valueOfUserScenarioId(): number | string {
    return this.userScenarioId;
  }

  onCellSave({
    value,
    row,
    cell,
  }: {
    value: string;
    row: number;
    cell: number;
  }): void {
    if (this.path === 'portfolio') {
      this.portfolioSave(value, row, cell);
    }
    if (this.path === 'company') {
      this.companySave(value, row, cell);
    }
  }

  portfolioSave(value: string, row: number, cell: number) {
    const fieldName = this.reitTableData.data[row].inputOutput;
    const subPortfolio = this.reitTableData.data[row].subPortfolioId;
    const columnName = Object.entries(this.reitTableData.headers || {})[
      cell
    ][0];
    const { id: rowId, dataType } = this.reitTableData.data[row][
      columnName
    ] as any;

    if (this.reitId === null || !fieldName || !subPortfolio || !columnName)
      return;

    this.spinner.show();
    this.highlightCell(this.reitTableData.data[row][columnName]);
    this.reitAssumptionService
      .apiReitReitIdAssumptionQueuePost(this.reitId, {
        fieldValue: parseFloat(value),
        rowId,
        scenarioId: this.userScenarioId,
        type: dataType,
      })
      .subscribe(
        (res) => {
          this.reitTableData.data[row][columnName].data =
            res.row.fieldValue.toFixed(1) +
            (dataType === 'percentage' ? '%' : '');
          this.reitTableData.data = [...this.reitTableData.data];
          this.updateTableInfoInStorage();
          this.spinner.hide();
          this.cdr.detectChanges();
        },
        () => {
          this.spinner.hide();
        },
      );
  }

  companySave(value: string, row: number, cell: number) {
    const fieldName = this.reitTableData.data[row].field;
    const columnName = Object.entries(this.reitTableData.headers || {})[
      cell
    ][0];
    const { id: rowId, dataType } = this.reitTableData.data[row][
      columnName
    ] as any;

    if (!fieldName || !columnName) return;

    this.spinner.show();
    this.highlightCell(this.reitTableData.data[row][columnName]);

    this.userAssumptionService
      .apiUserAssumptionsUserAssumptionCompanyReitIdPut(this.reitId, {
        fieldValue: parseFloat(value),
        rowId,
        scenarioId: this.userScenarioId,
        type: dataType,
      })
      .subscribe(
        (res) => {
          this.reitTableData.data[row][columnName].data =
            res.row.fieldValue.toFixed(1) +
            (dataType === 'percentage' ? '%' : '');
          this.reitTableData.data = [...this.reitTableData.data];
          this.updateTableInfoInStorage();
          this.spinner.hide();
          this.cdr.detectChanges();
        },
        () => {
          this.spinner.hide();
        },
      );
  }

  highlightCell(cell: any) {
    cell.fill = 'affected';
    setTimeout(() => {
      cell.fill = null;
      this.cdr.detectChanges();
    }, 3000);
  }

  updateTableInfoInStorage() {
    localStorage.setItem(this.path, JSON.stringify(this.reitTableData));
  }
}
