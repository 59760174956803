import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { TrackService } from '@kolytics/shared-api';
import { filter, map, pairwise, take } from 'rxjs';

interface NavigationEvent {
  snapshot: ActivatedRouteSnapshot;
  navigation: NavigationEnd;
}

@Injectable({
  providedIn: 'root',
})
export class TrackPageService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private trackService: TrackService,
  ) {}

  trackPageViews() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => {
          let snapshot = this.activatedRoute.snapshot;
          while (snapshot.firstChild) {
            snapshot = snapshot.firstChild;
          }

          const result: NavigationEvent = {
            navigation: event as NavigationEnd,
            snapshot: snapshot,
          };

          return result;
        }),
      )
      .subscribe((route: NavigationEvent) => {
        if (route.snapshot.data.track) {
          this.trackService
            .apiTrackPagePost({
              p: route.snapshot.data.trackPage,
              u: route.navigation.urlAfterRedirects,
              t: route.snapshot.data.title,
            })
            .pipe(take(1))
            .subscribe();
        }
      });
  }
}
