<div class="wrapper">
  <div class="dropzone-label">{{ title }}</div>
  <div
    class="dropzone"
    [class.error]="showError"
    kltDnd
    (fileDropped)="onFileUploaded($event)"
    >
    <input
      type="file"
      #fileDropRef
      multiple="false"
      [id]="randomId"
      (change)="fileBrowseHandler($event)"
      />
      @if (showDrag && !showSuccess && !fileName) {
        <klt-icon [size]="'huge'" [icon]="'upload'" [color]="'cta'"></klt-icon>
        <div class="text">
          Drag and drop your file or <label [for]="randomId"><a>browse</a></label>
        </div>
      }
      @if (showProcess) {
        <klt-progress-bar [progress]="progress"></klt-progress-bar>
        <a class="delete">Cancel</a>
      }
      @if (showSuccess) {
        <div class="success">
          <klt-icon color="cta" icon="success" size="huge"></klt-icon>
          <b>File uploaded</b>
        </div>
      }
      @if (!showSuccess && fileName) {
        <div class="success">
          <klt-icon color="cta" icon="success" size="huge"></klt-icon>
          <b>{{ fileName }}</b>
        </div>
      }
    </div>
    @if (error && errorLabel) {
      <div class="error-container">
        <div>{{ errorLabel }}</div>
      </div>
    }
  </div>
