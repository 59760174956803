import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ClientStoreService } from '../../store/client.store.service';

@Directive({
    selector: '[kltStickyClass]',
    standalone: true,
})
export class StickyClassDirective implements OnInit, OnDestroy {
  @Input() stickyId!: string;

  @Output() onStuck = new EventEmitter<void>();
  @Output() onUnStuck = new EventEmitter<void>();

  private observer!: IntersectionObserver;

  destroyed: boolean = false;

  constructor(
    private store: ClientStoreService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.destroyed = false;
    this.handle();
  }

  handle() {
    this.observe(this.elementRef.nativeElement);
  }

  observe(targetElement: HTMLElement) {
    const computedStyles = window.getComputedStyle(targetElement);
    const computedTop = computedStyles.getPropertyValue('top');
    let pixels = Number(computedTop.replace('px', '')) + 1;
    let marginTop = '0px';

    if (!isNaN(pixels)) {
      marginTop = `-${pixels}px`;
    }

    const options = {
      rootMargin: `${marginTop} 0px 0px 0px`,
      threshold: [1],
    };
    const observer = new IntersectionObserver(([e]) => {
      if (!this.destroyed) {
        const stuck = e.intersectionRatio < 1;
        if (stuck) {
          this.onStuck.emit();
        } else {
          this.onUnStuck.emit();
        }
        this.store.setStickyKey(this.stickyId, stuck);
        this.elementRef.nativeElement.classList.toggle('stuck', stuck);
      }
    }, options);

    observer.observe(targetElement);
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.observer?.disconnect();
    this.onUnStuck.emit();
    this.store.clearSticky();
  }
}
