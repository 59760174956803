import { Component, OnInit } from '@angular/core';
import { KltModalRefService } from '../../services';
import { ModalComponent } from '../../components/modal/modal.component';

@Component({
    selector: 'klt-modal-permission-restrict',
    templateUrl: './modal-permission-restrict.component.html',
    styleUrls: ['./modal-permission-restrict.component.scss'],
    standalone: true,
    imports: [ModalComponent],
})
export class ModalPermissionRestrictComponent implements OnInit {
  constructor(private kltModalRef: KltModalRefService) {}

  ngOnInit(): void {}

  onClose() {
    this.kltModalRef.close(ModalPermissionRestrictComponent);
  }
}
