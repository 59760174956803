import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ClientSignalState, ClientState } from './client.state';
import { ClientSignalStore, ClientStore } from './client.store';

@Injectable({ providedIn: 'root' })
export class ClientQuery extends Query<ClientState> {
  constructor(protected store: ClientStore) {
    super(store);
  }

  selectReitId$ = this.select((e) => e.reitId);
  scenarios$ = this.select((e) => e.scenarios);
  selectedScenario$ = this.select((e) => e.selectedScenario);
  selectedScenarios$ = this.select((e) => e.selectedScenarios);
  selectedAssumptionScenarios$ = this.select(
    (e) => e.selectedAssumptionScenarios,
  );

  selectedLeftScenarioId$ = this.select((e) => e.selectedLeftScenarioId);
  selectedRightScenarioId$ = this.select((e) => e.selectedRightScenarioId);

  selectFinancialIsLoading$ = this.select((e) => e.financialIsLoading);
  selectProjectionIsLoading$ = this.select((e) => e.projectionIsLoading);

  selectGraphLoading$ = (graphId: string) =>
    this.select((e) => e.graphLoading[graphId]);

  selectStickyKey$ = (elementIds: string[]) =>
    this.select([(e) => elementIds.some((o) => e.stickyKeys[o])]);
}

@Injectable({ providedIn: 'root' })
export class ClientSignalQuery extends Query<ClientSignalState> {
  constructor(protected store: ClientSignalStore) {
    super(store);
  }

  selectPortfolio$ = this.select((e) => e.portfolio);
  selectCompany$ = this.select((e) => e.company);
  selectOther$ = this.select((e) => e.other);
}
