import { Injectable } from '@angular/core';
import { GroupedDropDownOption } from '@kolytics/shared-components';
import { BehaviorSubject } from 'rxjs';
import {
  AssumptionProfileScenario,
  IAssumptionProfileScenarioResponse,
} from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReitStoreService {
  private readonly _assumptionProfile =
    new BehaviorSubject<IAssumptionProfileScenarioResponse>(
      {} as IAssumptionProfileScenarioResponse,
    );
  readonly assumptionProfile$ = this._assumptionProfile.asObservable();

  storeAssumptionProfile(val: IAssumptionProfileScenarioResponse) {
    this._assumptionProfile.next(val);
  }

  resetAssumptionValue() {
    this._assumptionProfile.next({} as IAssumptionProfileScenarioResponse);
  }

  get assumption(): IAssumptionProfileScenarioResponse {
    return this._assumptionProfile.getValue();
  }

  set assumption(val: IAssumptionProfileScenarioResponse) {
    this._assumptionProfile.next(val);
  }

  updateUserScenarioName(name: string) {
    this.assumption.result.userScenario = {
      ...this.assumption.result.userScenario,
      name: name,
    };
  }
}
