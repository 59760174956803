import { Injectable } from '@angular/core';
import { PasswordConditions } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {}

  passwordStrength(pass: string): string {
    if (!pass.length) {
      return '';
    }
    const mediumRegex = new RegExp(
      '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
    );
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&amp;*])(?=.{8,})',
    );
    if (strongRegex.test(pass)) {
      return 'Great';
    } else if (mediumRegex.test(pass)) {
      return 'Average';
    } else {
      return 'Weak';
    }
  }

  passwordValidity(password: string): PasswordConditions {
    return {
      lowercase: new RegExp('[a-z]').test(password),
      uppercase: new RegExp('[A-Z]').test(password),
      number: RegExp('[0-9]').test(password),
      length: password.length >= 8,
    };
  }
}
