import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EChartsOption } from 'echarts';
import { NgxEchartsDirective } from 'ngx-echarts';


@Component({
    selector: 'klt-graph',
    templateUrl: './graph.component.html',
    styleUrls: ['./graph.component.scss'],
    standalone: true,
    imports: [
    NgxEchartsDirective
],
})
export class GraphComponent implements OnInit {
  @Input() width!: string;

  @Input() legendGrid: boolean = false;

  @Input() titleSummary?: string | undefined = undefined;

  @Input()
  public options: EChartsOption | undefined;
  @Input()
  public height: string = '20rem';
  @Input()
  public titles: {
    name: string;
    color: string;
    opacity?: number;
    disabled: boolean;
    small?: boolean;
    text?: string;
  }[] = [];

  @Input() public showTimeSelect = true;

  private _loading!: boolean | null;
  get loading(): boolean | null {
    return this._loading;
  }
  @Input() set loading(v: boolean | null) {
    this._loading = v;
  }

  constructor(private router: Router) {}
  ngOnInit(): void {
    const pdfPage = this.router.url.startsWith('/pdf/');
    if (pdfPage && !this.width) {
      this.width = '700px';
    }
  }

  @Output()
  public readonly filter: EventEmitter<
    { name: string; color: string; disabled: boolean; text?: string }[]
  > = new EventEmitter<{ name: string; color: string; disabled: boolean }[]>();

  public clickItem(index: number) {
    const item = this.titles[index];
    item.disabled = !item.disabled;
    this.filter.emit(this.titles);
  }
}
