import { Component, Input, OnInit } from '@angular/core';
import { getCurrencySymbol } from '@kolytics/shared-functions';
import { ToPencePipe } from '../../pipes/to-pence.pipe';
import { LocaleCurrencyPipe } from '../../pipes/locale-currency.pipe';

import { NegativeFormatterDirective } from '../../directives/negative-formatter.directive';
import { NegativeFormatterPipe } from '../../pipes/negative-formatter.pipe';

@Component({
  selector: 'klt-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  standalone: true,
  imports: [
    NegativeFormatterDirective,
    LocaleCurrencyPipe,
    ToPencePipe,
    NegativeFormatterPipe,
  ],
})
export class CurrencyComponent implements OnInit {
  @Input() value?: number;
  @Input() locale?: string;
  @Input() currencyCode?: string;
  @Input() decimalPoints?: number;
  @Input() currencyFormat?: string;

  @Input() pence = false;

  ngOnInit(): void {
    this.pence = this.pence && this.currencyCode === 'GBP';
  }
}
