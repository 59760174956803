import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DisplayComponentsComponent } from '@kolytics/shared-components';
import { FeaturedGuard } from '@kolytics/shared-components';
import { ProductFeature } from '@kolytics/types/common';
import { TablePageComponent } from './table-page/table-page.component';
import { AccountResolverService } from './shared/resolvers/account-resolver.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    data: { title: 'Login' },
    loadComponent: () =>
      import('./pages/auth/login/login.component').then(
        (e) => e.LoginComponent,
      ),
  },
  {
    path: 'register/:token',
    data: { title: 'Register' },
    loadComponent: () =>
      import('./pages/auth/create-account/create-account.component').then(
        (e) => e.CreateAccountComponent,
      ),
  },
  {
    path: 'password/reset/:token',
    data: { title: 'Reset Password' },
    loadComponent: () =>
      import('./pages/auth/reset-password/reset-password.component').then(
        (e) => e.ResetPasswordComponent,
      ),
  },
  {
    path: 'password/forgot',
    data: { title: 'Forgot Password' },
    loadComponent: () =>
      import('./pages/auth/forgot-password/forgot-password.component').then(
        (e) => e.ForgotPasswordComponent,
      ),
  },
  {
    path: 'signup',
    data: { title: 'Forgot Password' },
    loadComponent: () =>
      import('./pages/auth/signup/signup.component').then(
        (e) => e.SignupComponent,
      ),
  },
  {
    path: 'on-boarding',
    loadChildren: () =>
      import('./pages/on-boarding/on-boarding.module').then(
        (m) => m.OnBoardingModule,
      ),
  },
  {
    path: 'research/:slug',
    loadComponent: () =>
      import(
        './pages/research/pages/link-download/link-download.component'
      ).then((e) => e.LinkDownloadComponent),
  },

  {
    path: '',
    loadComponent: () =>
      import('./pages/main.component').then((e) => e.MainComponent),
    children: [
      {
        path: 'data',
        loadChildren: () =>
          import('./pages/data-analytics/data-analytics.module').then(
            (m) => m.DataAnalyticsModule,
          ),
        data: { features: [ProductFeature.DataAnalytics] },
        canActivate: [FeaturedGuard],
      },
      {
        path: 'reits',
        loadChildren: () =>
          import('./pages/reits/reits.module').then((m) => m.ReitsModule),
        resolve: [AccountResolverService],
      },
      {
        path: 'preview',
        loadChildren: () =>
          import('./pages/reits/reits.module').then((m) => m.ReitsModule),
        resolve: [AccountResolverService],
      },
      {
        path: 'research',
        loadChildren: () =>
          import('./pages/research/research.module').then(
            (m) => m.ResearchModule,
          ),
        data: { features: [ProductFeature.Research] },
        canActivate: [FeaturedGuard],
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./pages/account/account.module').then((m) => m.AccountModule),
      },
    ],
  },

  {
    path: 'pdf',
    loadChildren: () =>
      import('./pages/pdf/pdf.module').then((m) => m.PdfModule),
  },

  {
    path: 'unsubscribe',
    loadChildren: () =>
      import('./pages/unsubscribe/unsubscribe.module').then(
        (m) => m.UnsubscribeModule,
      ),
  },
  {
    path: 'table-page',
    component: TablePageComponent,
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
