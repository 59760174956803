import { Pipe, PipeTransform } from '@angular/core';
import { DropDownOption } from '../interfaces/form.interface';

@Pipe({
    name: 'optionHide',
    standalone: true,
})
export class OptionHidePipe implements PipeTransform {
  transform(input: DropDownOption[], optionToHide: any): DropDownOption[] {
    return input.filter((e) => e.value !== optionToHide);
  }
}
