import { Component, Inject, OnInit } from '@angular/core';
import { KltModalRefService } from 'libs/shared-components/src/lib/services/modal/modal-ref.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
} from '@angular/forms';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { KltToastService } from 'libs/shared-components/src/lib/services/toast/toast.service';
import { SessionService } from 'apps/client/src/app/shared/services';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import { Observable } from 'rxjs';
import { ClientQuery } from 'apps/client/src/app/store/client.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OptionHidePipe } from '../../../../../../../../libs/shared-components/src/lib/pipes/option-hide.pipe';
import { ScenarioToOptionPipe } from '../../../../../../../../libs/shared-components/src/lib/pipes/scenario-to-option.pipe';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { SingleSelectionComponent } from '../../../../../../../../libs/shared-components/src/lib/components/form/single-selection/single-selection.component';
import { ModalComponent } from '../../../../../../../../libs/shared-components/src/lib/components/modal/modal.component';
import { ReitAssumptionService, ReitService } from '@kolytics/shared-api';

export interface FilterProfileData {
  availableColumns: { key: string; title: string }[];
  filteredColumns: string[];
}

@UntilDestroy()
@Component({
  selector: 'klt-select-profile-dialog',
  templateUrl: './select-profile-dialog.component.html',
  styleUrls: ['./select-profile-dialog.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    FormsModule,
    SingleSelectionComponent,
    ButtonComponent,
    AsyncPipe,
    ScenarioToOptionPipe,
    OptionHidePipe,
  ],
})
export class SelectProfileDialogComponent implements OnInit {
  loading = false;

  allScenarios: any[] = [];
  scenariosA: any[] = [];
  scenariosB: any[] = [];

  scenarios$!: Observable<any>;
  userScenarios$!: Observable<any>;

  leftScenarioId!: number;
  rightScenarioId!: number;

  constructor(
    @Inject('KLT_MODAL_DATA') private modalData: FilterProfileData,
    private kltModalRefService: KltModalRefService,
    private reitService: ReitService,
    private kltToastService: KltToastService,
    private sessionService: SessionService,
    private clientFacade: ClientFacadeService,
    private reitAssumptionService: ReitAssumptionService,
  ) {}

  ngOnInit(): void {
    this.clientFacade
      .getReitId()
      .pipe(
        take(1),
        switchMap((reitId) =>
          this.clientFacade.getSelectedLeftScenarioId(reitId).pipe(take(1)),
        ),
      )
      .subscribe((scenarioId) => {
        this.leftScenarioId = scenarioId;
      });

    this.clientFacade
      .getReitId()
      .pipe(
        take(1),
        switchMap((reitId) =>
          this.clientFacade.getSelectedRightScenarioId(reitId).pipe(take(1)),
        ),
      )
      .subscribe((scenarioId) => {
        this.rightScenarioId = scenarioId;
      });

    this.scenarios$ = this.clientFacade.getReitId().pipe(
      untilDestroyed(this),
      switchMap((reitId) => this.clientFacade.getScenarios(reitId)),
    );

    this.userScenarios$ = this.clientFacade.getReitId().pipe(
      untilDestroyed(this),
      switchMap((reitId) => this.clientFacade.getUserScenarios(reitId)),
    );
  }

  setScenarios(valueA: number, valueB: number) {
    if (!valueA && !valueB) {
      this.scenariosA = this.scenariosB = [...this.allScenarios];
    }
    if (valueA) {
      this.scenariosB = this.allScenarios.filter((x) => x.value !== valueA);
      this.scenariosA = [...this.allScenarios];
    } else if (valueB) {
      this.scenariosA = this.allScenarios.filter((x) => x.value !== valueB);
      this.scenariosB = [...this.allScenarios];
    }

    this.scenariosA = this.scenariosA.filter((e) => !e.isDefaultScenario);
  }

  onLeftScenarioChange(value: any) {
    this.leftScenarioId = value;
  }

  onRightScenarioChange(value: any) {
    this.rightScenarioId = value;
  }

  onClose(): void {
    this.kltModalRefService.close(SelectProfileDialogComponent, null);
  }

  onSubmit(): void {
    this.loading = true;
    this.clientFacade
      .getReitId()
      .pipe(
        take(1),
        tap((reitId) => {
          this.clientFacade.setSelectedLeftScenarioId(
            reitId,
            this.leftScenarioId,
          );
          this.clientFacade.setSelectedRightScenarioId(
            reitId,
            this.rightScenarioId,
          );
          this.clientFacade.setSelectedAssumptionScenarios(reitId, [
            this.leftScenarioId,
            this.rightScenarioId,
          ]);
        }),
        switchMap((reitId) =>
          this.reitAssumptionService.apiReitReitIdAssumptionComparePost(
            reitId,
            {
              userId: Number(this.sessionService.userId || 0),
              reitId: reitId,
              isDefaultScenarioA: this.getIsDefaultScenario(
                this.leftScenarioId,
              ),
              scenarioAId: this.leftScenarioId,
              isDefaultScenarioB: this.getIsDefaultScenario(
                this.rightScenarioId,
              ),
              scenarioBId: this.rightScenarioId,
            },
          ),
        ),
      )
      .subscribe(
        (data) => {
          this.loading = false;
          this.kltModalRefService.close(SelectProfileDialogComponent, data);
          this.kltToastService.success(
            'Scenario was successfully compared',
            4000,
          );
        },
        (err) => {
          this.loading = false;
        },
      );
  }

  getIsDefaultScenario(value: number) {
    return this.allScenarios.find((x) => x.value === value)?.isDefaultScenario;
  }
}
