import {
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  ModalData,
  OpenedModal,
  OpenedModalData,
} from '@kolytics/shared-components';
import { ModalGlobalsService } from './modal-globals.service';

/**
 * @name KltModalService
 * @description Main service when using the Modal
 */
@Injectable()
export class KltModalService {
  constructor(
    protected _modalStorageService: ModalGlobalsService,
    protected _componentFactoryResolver: ComponentFactoryResolver,
    protected _appRef: ApplicationRef,
    protected _injector: Injector,
  ) {}

  // New method
  openModal<T>(
    component: any,
    data?: ModalData<T>,
  ): { afterClosed: Observable<any | undefined> } {
    // We use Injector to pass data to the dialog reference
    let dialogDataInjector: Injector = this._injector;
    if (data) {
      dialogDataInjector = Injector.create({
        providers: [{ provide: 'KLT_MODAL_DATA', useValue: { ...data } }],
      });
    }

    const insertionId: number = ++this._modalStorageService.openedModals.length;
    const componentRef = this._componentFactoryResolver
      .resolveComponentFactory(component)
      .create(dialogDataInjector);

    this._appRef.attachView(componentRef.hostView);

    const clientDomElement = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    document.body.appendChild(
      this._createModalOverlay(clientDomElement, insertionId),
    );

    this._modalStorageService.openedModals.push({
      id: insertionId,
      compRef: componentRef,
      domSelectors: {
        modalOverlay: document.querySelector(
          `#klt-modal-overlay-${insertionId}`,
        ) as HTMLElement,
        modalBackdrop: document.querySelector(
          `#klt-modal-backdrop-${insertionId}`,
        ) as HTMLElement,
        modalContainer: document.querySelector(
          `#klt-modal-container-${insertionId}`,
        ) as HTMLElement,
      },
    });
    this._modalStorageService.openedModals =
      this._modalStorageService.openedModals.filter((m) => m);

    /**
     * Need to wait for DOM to finish appending the elements for the Modal
     * before adding extra features
     */
    setTimeout(() => {
      this._showModalOverlayContents(insertionId);
      if (!data?.preventBackdropClickClose) {
        this._setBackdropClickCloseEvent(insertionId);
      }
    });

    return { afterClosed: this.afterClosed() };
  }
  /**
   * @description Used when opening a modal component
   * @param component
   * @param data
   * @return afterClosed()
   */
  open(
    component: any,
    data?: OpenedModalData,
  ): { afterClosed: Observable<any | undefined> } {
    // We use Injector to pass data to the dialog reference
    let dialogDataInjector: Injector = this._injector;
    if (data) {
      dialogDataInjector = Injector.create({
        providers: [{ provide: 'KLT_MODAL_DATA', useValue: { ...data.data } }],
      });
    }

    const insertionId: number = ++this._modalStorageService.openedModals.length;
    const componentRef = this._componentFactoryResolver
      .resolveComponentFactory(component)
      .create(dialogDataInjector);

    this._appRef.attachView(componentRef.hostView);

    const clientDomElement = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    document.body.appendChild(
      this._createModalOverlay(clientDomElement, insertionId),
    );

    this._modalStorageService.openedModals.push({
      id: insertionId,
      compRef: componentRef,
      domSelectors: {
        modalOverlay: document.querySelector(
          `#klt-modal-overlay-${insertionId}`,
        ) as HTMLElement,
        modalBackdrop: document.querySelector(
          `#klt-modal-backdrop-${insertionId}`,
        ) as HTMLElement,
        modalContainer: document.querySelector(
          `#klt-modal-container-${insertionId}`,
        ) as HTMLElement,
      },
    });
    this._modalStorageService.openedModals =
      this._modalStorageService.openedModals.filter((m) => m);

    /**
     * Need to wait for DOM to finish appending the elements for the Modal
     * before adding extra features
     */
    setTimeout(() => {
      this._showModalOverlayContents(insertionId);
      if (!data?.preventBackdropClickClose) {
        this._setBackdropClickCloseEvent(insertionId);
      }
    });

    return { afterClosed: this.afterClosed() };
  }

  /**
   * @description Used to close a specific modal
   * @param modalId
   * @param returnValue
   */
  close(modalId: number, returnValue?: any): void {
    const openedModal: OpenedModal | undefined =
      this._modalStorageService.openedModals.find((m) => m.id === modalId);

    if (openedModal) {
      // Removing it from the component tree and DOM
      this._appRef.detachView(openedModal.compRef.hostView);
      openedModal.compRef.destroy();

      this._modalStorageService.openedModals.splice(modalId - 1, 1);
      this._cleanModalOverlay(openedModal);
      this._modalStorageService.afterClosed.next(returnValue);
    }
  }

  /**
   * @description Used to close all opened modals
   */
  closeAll(): void {
    const openedModals: OpenedModal[] = this._modalStorageService.openedModals;
    if (openedModals.length) {
      openedModals.forEach((openedModal) => {
        // Removing it from the component tree and DOM
        this._appRef.detachView(openedModal.compRef.hostView);
        openedModal.compRef.destroy();

        this._cleanModalOverlay(openedModal);
      });
    }
  }

  /**
   * @description Used to get return values after closing the modal
   * @return Observable<any | undefined>
   */
  afterClosed(): Observable<any | undefined> {
    return this._modalStorageService.afterClosed.asObservable();
  }

  /**
   * @description Used to created modal overlay elements
   * @param clientContent
   * @param insertionId
   * @private
   * @return HTMLElement
   */
  private _createModalOverlay(
    clientContent: HTMLElement,
    insertionId: number,
  ): HTMLElement {
    // Modal overlay elements
    const modalOverlay = document.createElement('div');
    const modalBackdrop = document.createElement('div');
    const modalContainer = document.createElement('div');

    // ID
    modalOverlay.id = `klt-modal-overlay-${insertionId}`;
    modalBackdrop.id = `klt-modal-backdrop-${insertionId}`;
    modalContainer.id = `klt-modal-container-${insertionId}`;

    // Classes
    modalOverlay.classList.add('klt-modal-overlay');
    modalBackdrop.classList.add('klt-modal-backdrop');
    modalContainer.classList.add('klt-modal-container');

    clientContent.classList.add('modal-m-auto');
    // Append elements

    modalContainer.appendChild(clientContent);
    modalOverlay.appendChild(modalBackdrop);
    modalOverlay.appendChild(modalContainer);

    return modalOverlay;
  }

  /**
   * @description Used to show the modal when everything is on place
   * @param modalId
   * @private
   */
  private _showModalOverlayContents(modalId: number): void {
    const modal: OpenedModal | undefined =
      this._modalStorageService.openedModals.find((m) => m.id === modalId);

    if (modal) {
      modal.domSelectors.modalBackdrop.classList.add('visible');
      modal.domSelectors.modalContainer.classList.add('visible');
    }
  }

  /**
   * @description Used to set click-close dialog event when Backdrop is clicked
   * @param modalId
   * @private
   */
  private _setBackdropClickCloseEvent(modalId: number): void {
    const modal: OpenedModal | undefined =
      this._modalStorageService.openedModals.find((m) => m.id === modalId);

    if (modal) {
      modal.domSelectors.modalBackdrop.addEventListener('click', () => {
        this.close(modalId);
      });
    }
  }

  /**
   * @description Used to properly remove the modal elements from the DOM
   * @param openedModal
   * @protected
   */
  protected _cleanModalOverlay(openedModal: OpenedModal): void {
    openedModal.domSelectors.modalContainer.classList.remove('visible');
    openedModal.domSelectors.modalBackdrop.classList.remove('visible');

    /**
     * Need to wait for DOM to finish removing classes
     * before we can fully remove the ModalOverlay element
     */
    setTimeout(
      () => openedModal.domSelectors.modalOverlay.remove(),
      this._modalStorageService.transitionMaxDuration,
    );
  }
}
