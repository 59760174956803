<div class="wrapper" [ngClass]="getFormClasses()">
  <quill-editor
    #editor
    (onEditorCreated)="getEditorInstance($event)"
    [modules]="modules"
    [sanitize]="sanitize"
    [placeholder]="placeholder"
    [ngModel]="value"
    >
  </quill-editor>
</div>
@if (showCharacterCount) {
  <div class="wrapper-character">
    <span [class.max-count]="characters >= maxCharacters"
      >{{ characters }} / {{ maxCharacters }} characters</span
      >
    </div>
  }
  @if (error) {
    <div class="error" [@dropdownAnimation]>
      <span>{{ errorMessage }}</span>
    </div>
  }
