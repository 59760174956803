import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconSize } from '@kolytics/shared-assets';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IconComponent } from '../../../../../../shared-assets/src/lib/components/icon/icon.component';


@Component({
    selector: 'klt-input-expand',
    templateUrl: './input-expand.component.html',
    styleUrls: ['./input-expand.component.scss'],
    standalone: true,
    imports: [
    FormsModule,
    ReactiveFormsModule,
    IconComponent
],
})
export class InputExpandComponent implements OnInit, OnDestroy {
  @ViewChild('input', { static: false })
  input!: ElementRef;

  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() expanded = false;
  @Input() theme: 'dark' | 'light' = 'light';
  @Input() layout: 'default' | 'alt' = 'default';
  @Input() iconSize: IconSize = 'medium';

  @Output() valueCleared = new EventEmitter<any>();
  @Output() valueChanged = new EventEmitter<string>();
  @Output() expand = new EventEmitter<boolean>();

  @HostListener('document:click', ['$event'])
  clickOut(event: PointerEvent) {
    if (
      !this.elementRef.nativeElement.contains(event.target) &&
      !this.form.value.formValue &&
      this.expanded
    ) {
      this.toggle();
    }
  }

  closeIcon: any = 'close';
  closeIconColor: any = '';
  closeIconSize: any = 'tiny';

  form: FormGroup = new FormGroup({});
  formSubscription: Subscription = new Subscription();

  constructor(
    private elementRef: ElementRef,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      formValue: new FormControl(''),
    });

    this.formSubscription = this.form.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((formValue: { formValue: string }) => {
        this.valueChanged.emit(formValue.formValue);
      });
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  toggle(): void {
    this.expanded = !this.expanded;
    if (!this.expanded) {
      this.form.reset();
    } else {
      this.input.nativeElement.focus();
    }
    this.expand.emit(this.expanded);
  }

  clear(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.input.nativeElement.value = '';
    this.form.get('formValue')?.setValue('');
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.valueCleared.emit();
  }

  setValue(value: string) {
    this.form.patchValue({ formValue: value });
  }
}
