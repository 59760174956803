<klt-modal modalTitle="Reset to last save" (closeModal)="onClose()">
  <div class="reset-to-last-save-modal-content">
    <div class="content-row confirm">
      Are you sure you want to reset your profile <br /><br />
      to the last save?
    </div>
    <div class="content-row confirm-button">
      <klt-button type="primary" (click)="onRemove()"
        >RESET TO LAST SAVE</klt-button
      >
    </div>
    <div class="content-row cancel-button">
      <b (click)="onClose()">Cancel</b>
    </div>
  </div>
</klt-modal>
