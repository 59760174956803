import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { formatNumber, formatPercentage } from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { EChartsOption } from 'echarts';
import { ISectorProjectionRiskReturnResponse } from '../../../interfaces';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-sectors-projections-risk-return',
  templateUrl: './sectors-projections-risk-return.component.html',
  styleUrls: ['./sectors-projections-risk-return.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SectionComponent, GraphComponent],
})
export class SectorsProjectionsRiskReturnComponent implements OnInit {
  labels: string[] = [];
  desc?: string = '';

  @Input()
  set sector(riskReturn: ISectorProjectionRiskReturnResponse | undefined) {
    if (riskReturn) {
      this.desc = riskReturn.description;
      this.initGraph(riskReturn);
    }
  }

  public options: EChartsOption | undefined = {
    grid: {
      backgroundColor: '#ffffff',
      containLabel: true,
      show: true,
      borderColor: '#ffffff',
      borderWidth: 0,
      height: '86%',
      width: '90%',
      top: '6',
      left: '70',
    },
    xAxis: {
      type: 'value',
      scale: false,
      axisLabel: {
        formatter: (value: any, index: number) => {
          return formatNumber(value, 1);
        },
      },
      splitLine: {
        show: false,
      },
    },

    yAxis: [
      {
        type: 'value',
        position: 'left',
        // max:100,
        // min:0,
        // interval: 5,
        axisLabel: {
          formatter: (value: any, index: number) => {
            return formatPercentage(value, 1);
          },
        },
      },
    ],
    series: [],
    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: function (params: any) {
        return `<div style="background: ${
          params.color
        }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);">${params.seriesName.toUpperCase()}-${formatNumber(
          params.data[0],
          2,
        )}(${formatPercentage(params.data[1], 1)})</div>`;
      },
    },
  };
  public series: any[] = [];

  public titles: {
    name: string;
    color: string;
    disabled: boolean;
    small?: boolean;
  }[] = [];

  constructor(protected readonly cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    // this.runFilter(this.titles);
  }

  public runFilter(
    data: { name: string; color: string; disabled: boolean; small?: boolean }[],
  ): void {
    this.titles = data;
    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter((e) =>
      filter.includes(e.name.toLowerCase()),
    );
    (this.options as EChartsOption).series = [...(series as any)];
    const temp = Object.assign({}, this.options);
    // this.options = undefined;
    setTimeout(() => {
      this.options = temp;
      this.cdr.detectChanges();
    }, 0);
  }

  initGraph(sector: ISectorProjectionRiskReturnResponse) {
    let xOffset: number = 0.3;

    this.labels = [];
    this.titles = [];
    this.series = [];

    ((this.options as EChartsOption).xAxis as any).max = (
      sector.line.xdata + xOffset
    ).toFixed(2);

    let maxYValue = 0;

    sector.series.forEach((s, index) => {
      if (s.ydata > maxYValue) {
        maxYValue = s.ydata;
      }
      this.titles.push({
        name: s.name,
        color:
          colorPallete[GraphId.SectorProjectionRiskReturn][
            index % colorPallete[GraphId.SectorProjectionRiskReturn].length
          ],
        disabled: false,
        small: true,
      });

      const markLineOpt = {
        animation: false,
        label: {
          align: 'right',
        },
        lineStyle: {
          type: 'solid',
          color: '#000000',
        },

        data: [
          [
            {
              coord: [0, sector.line.ydata],
              symbol: 'none',
            },
            {
              coord: [
                (sector.line.xdata + xOffset).toFixed(2),
                sector.line.ydata +
                  sector.line.betaData * (sector.line.xdata + xOffset),
              ],
              symbol: 'none',
            },
          ],
        ],
      };

      this.series.push({
        name: s.name,
        type: 'scatter',
        color:
          colorPallete[GraphId.SectorProjectionRiskReturn][
            index % colorPallete[GraphId.SectorProjectionRiskReturn].length
          ],
        emphasis: {
          focus: 'series',
        },
        // prettier-ignore
        data: [[s.betaData, s.ydata]],
        markLine: markLineOpt,
      });
    });

    this.runFilter(this.titles);
  }
}
