import { Pipe, PipeTransform } from '@angular/core';
import { DropDownOption } from '../interfaces';

@Pipe({
    name: 'scenarioToOption',
    standalone: true,
})
export class ScenarioToOptionPipe implements PipeTransform {
  transform(
    input: { name: string; scenarioId: number; isDefault: boolean }[],
  ): DropDownOption[] {
    const result: DropDownOption[] = [];
    input.forEach((e) => {
      result.push({
        label: e.name,
        value: e.scenarioId,
      });
    });

    return result;
  }
}
