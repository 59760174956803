import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@kolytics/shared-functions';

@Pipe({
  name: 'localeNumber',
  standalone: true,
})
export class LocaleNumberPipe implements PipeTransform {
  transform(
    value?: number,
    decimalPoints?: number,
    minimumDecimalPoints: number = 0,
    locale?: string,
  ): string | undefined {
    if (!value || isNaN(value)) return '';
    return formatNumber(value, decimalPoints, locale, minimumDecimalPoints);
  }
}
