import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KltModalService } from '@kolytics/shared-components';
import { extractQueryRegionIds } from '@kolytics/shared-functions';
import { firstValueFrom, first } from 'rxjs';
import { SelectRegionDialogComponent } from '../dialogs/select-region-dialog/select-region-dialog.component';
import { SelectDialogItem } from '../utils/components/base-reit/base-reit.util';
import { RegionService } from '@kolytics/shared-api';

@Injectable({
  providedIn: 'root',
})
export class SectorRegionService {
  regions: any[] = [];
  regionsIds: number[] = [];
  selectedRegionKeys!: string[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private kltModalService: KltModalService,
    private regionService: RegionService,
  ) {}

  async init() {
    await this.getRegions();
    this.regionsIds = extractQueryRegionIds(
      this.activatedRoute.snapshot.queryParams,
    );
    this.getRegionParams();
  }

  async getRegions(sectorId?: number) {
    const data = await firstValueFrom(
      this.regionService.apiRegionGet(sectorId),
    );
    this.regions = data.data;
  }

  getRegionParams() {
    const regionQueryParams = this.activatedRoute.snapshot.queryParams.regions;
    if (typeof regionQueryParams === 'string') {
      const item = this.regions.find((e) => e.id === Number(regionQueryParams));
      if (item) {
        this.selectedRegionKeys = [item.code];
      }
    } else if (Array.isArray(regionQueryParams)) {
      this.selectedRegionKeys = this.regions
        .filter((e) => this.regionsIds.includes(e.id))
        .map((e) => e.code);
    }
  }

  public async openRegionFilter(
    sectorId?: number,
    callback?: Function,
  ): Promise<void> {
    await this.getRegions(sectorId);

    const availableColumns: SelectDialogItem[] = this.regions.map((e) => {
      return {
        key: e.code,
        title: e.name,
      } as SelectDialogItem;
    });

    this.kltModalService
      .open(SelectRegionDialogComponent, {
        data: {
          availableColumns: availableColumns,
          filteredColumns:
            this.selectedRegionKeys ?? availableColumns.map((e) => e.key),
        },
      })
      .afterClosed.pipe(first())
      .subscribe((keys?: string[]) => {
        if (!keys) return;

        this.selectedRegionKeys = keys;
        this.regionsIds = this.regions
          .filter((e) => keys.includes(e.code))
          .map((r) => r.id);

        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {
            regions: this.regionsIds,
          },
          queryParamsHandling: 'merge',
        });

        if (callback) {
          callback();
        }
      });
  }
}
