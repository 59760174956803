import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[kltNegativeFormatter]',
  standalone: true,
})
export class NegativeFormatterDirective {
  _value?: number;
  get value(): number | undefined {
    return this._value;
  }
  @Input() set value(v: number | undefined) {
    this._value = v;
    this.formatValue();
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  formatValue() {
    let isNegative = false;
    if (typeof this.value === 'string')
      isNegative = String(this.value).startsWith('-');
    if (typeof this.value === 'number') isNegative = Number(this.value) < 0;

    if (isNegative) {
      this.renderer.addClass(this.elementRef.nativeElement, 'warn');
    }
  }
}
