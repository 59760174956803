import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from '../../../../../apps/client/src/app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class UnauthorizeInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        const statusCode = err.status;
        const error = err?.error?.error || err?.error?.message;
        const shouldLogout =
          statusCode === 401 ||
          statusCode === 403 ||
          (statusCode === 400 && error === 'invalid_userId');
        if (shouldLogout) {
          this.sessionService.clear();
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: this.router.url },
          });
        }

        return throwError(err);
      }),
    );
  }
}
