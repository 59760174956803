import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class TrialExpirationInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private sessionService: SessionService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        const statusCode = err.status;
        const message = err && err?.error?.message;

        const shouldLogout =
          this.sessionService.isLoggedIn() &&
          statusCode === 400 &&
          message === 'trial_expired';

        if (shouldLogout) {
          this.sessionService.clear();
          this.router.navigate(['/login'], {
            queryParams: { errorCode: message },
          });
        }

        return throwError(err);
      }),
    );
  }
}
