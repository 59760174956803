import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  KltModalService,
  TableColumnGroup,
  TableColumns,
  TableData,
} from '@kolytics/shared-components';
import { KltToastService } from 'libs/shared-components/src/lib/services/toast/toast.service';
import {
  IAssumptionProfileScenarioResponse,
  IReitData,
  SpreadsArrangementFee,
} from '../../../../interfaces';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssumptionBaseComponent } from '../../../../utils/components/assumption/assumption-base-component';
import { ReitStoreService } from '../../../../services/reit-store.service';
import { TableV2Component } from '../../../../../../../../../../libs/shared-components/src/lib/components/table-v2/table-v2.component';
import {
  ReitAssumptionService,
  ReitService,
  UserAssumptionsService,
} from '@kolytics/shared-api';

@Component({
  selector: 'klt-other-market-table',
  templateUrl: './other-market-table.component.html',
  styleUrls: ['./other-market-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TableV2Component],
})
export class OtherMarketTableComponent
  extends AssumptionBaseComponent
  implements OnInit
{
  public reitTableData: IReitData = {} as IReitData;
  @Input()
  set spreadsArrangementFee(value: IAssumptionProfileScenarioResponse | null) {
    if (value) {
      this.bindingSpreadsArrangementFee(value);
    }
  }
  @Input()
  reitId!: number;
  @Input()
  userScenarioId!: number;

  @Output() cellSaved = new EventEmitter<any>();

  colsDefName = {
    userMarketName: 'market_user',
    userBorrowingName: 'borrowing_user',
    baseMarketName: 'market_base',
    baseBorrowingName: 'borrowing_base',
  };

  constructor(
    protected readonly kltModalService: KltModalService,
    protected readonly kltToastService: KltToastService,
    protected readonly router: Router,
    protected reitService: ReitService,
    protected ref: ChangeDetectorRef,
    protected spinner: NgxSpinnerService,
    protected reitStoreService: ReitStoreService,
    protected reitAssumptionService: ReitAssumptionService,
    protected userAssumptionService: UserAssumptionsService,
  ) {
    super(
      kltModalService,
      kltToastService,
      router,
      reitService,
      userAssumptionService,
      spinner,
      reitStoreService,
      ref,
      reitAssumptionService,
    );
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  protected valueOfReitId(): string {
    return this.reitId.toString();
  }

  protected valueOfUserScenarioId(): number | string {
    return this.userScenarioId;
  }

  bindingSpreadsArrangementFee(
    spreadsArrangementFee: IAssumptionProfileScenarioResponse,
  ) {
    const { sections, header } = this.createSectionsAndHeaders(
      spreadsArrangementFee,
    );
    const stickLeftColumns: TableColumns = {
      market_user: {
        title: 'Market',
        fill:
          (spreadsArrangementFee.result.userAssumptionOther?.marketData
            .length ||
            spreadsArrangementFee.result.assumptionOther?.marketData.length) > 0
            ? 'purple'
            : 'dark',
        sortable: false,
        type: 'text',
      },
    };
    this.reitTableData = {
      hideSection: true,
      sections: sections,
      headers: header,
      data: this.createScenarioData(spreadsArrangementFee),
      total: 0,
      stickLeftColumns: stickLeftColumns,
    };
  }

  onCellSave({
    value,
    row,
    column,
  }: {
    value: string;
    row: number;
    column: number;
  }): void {
    this.cellSaved.emit({
      value,
      row,
      column,
      reitTableData: this.reitTableData,
      methodName: 'GetSpread',
    });
  }

  createSectionsAndHeaders(data: IAssumptionProfileScenarioResponse) {
    const userAssumptionsOther =
      data.result.userAssumptionOther?.marketData ||
      data.result.assumptionOther?.marketData;
    const baseAssumptionsOther =
      data.result.baseAssumptionOther?.marketData ||
      data.result.userBaseAssumptionOther.marketData;

    const sections: TableColumnGroup[] = [
      { sectionTitle: '', columnDefs: ['market_base'], sticky: true },
    ];
    const header: TableColumns = {};
    if (userAssumptionsOther.length > 0) {
      sections.push({
        sectionTitle: data.result.userScenario.name,
        columnDefs: [this.colsDefName.userBorrowingName],
        spacing: true,
        spacingFill: 'light',
      });
      header[this.colsDefName.userMarketName] = {
        title: 'Market',
        fill: 'purple',
        sortable: true,
        type: 'text',
        sticky: true,
      };
      header[this.colsDefName.userBorrowingName] = {
        title: 'Borrowing spreads',
        fill: 'purple',
        sortable: true,
        type: 'text',
        colspan: 1,
        style: { center: true },
      };
    }

    if (baseAssumptionsOther.length > 0) {
      sections.push({
        sectionTitle: data.result.userBaseScenario?.name || 'Base',
        columnDefs: [
          this.colsDefName.baseMarketName,
          this.colsDefName.baseBorrowingName,
        ],
      });
      header[this.colsDefName.baseBorrowingName] = {
        title: 'Borrowing spreads',
        fill: 'forecast',
        sortable: true,
        type: 'text',
        colspan: 1,
        style: { center: true },
      };
    }

    return { sections, header };
  }

  createScenarioData(data: IAssumptionProfileScenarioResponse): TableData[] {
    const userAssumptionsOther =
      data.result.userAssumptionOther?.marketData ||
      data.result.assumptionOther?.marketData;
    const baseAssumptionsOther =
      data.result.baseAssumptionOther?.marketData ||
      data.result.userBaseAssumptionOther.marketData;
    if (baseAssumptionsOther.length > 0) {
      return baseAssumptionsOther.map((item) => {
        const tableItem: TableData = {};
        if (userAssumptionsOther.length > 0) {
          let userItem = userAssumptionsOther.find(
            (x) => x.market === item.market,
          );
          if (!userItem) {
            userItem = { ...item, value: 0 };
          }
          tableItem[this.colsDefName.userMarketName] = {
            data: userItem.market,
            fill: 'none',
          };
          tableItem[this.colsDefName.userBorrowingName] = {
            data:
              userItem.type === 'percentage'
                ? (userItem.value * 100).toFixed(2)
                : userItem.value.toFixed(2),
            editable: userItem.editable,
            type: userItem.type,
            rowId: userItem.rowId,
          };
        }
        tableItem[this.colsDefName.baseMarketName] = { data: item.market };
        tableItem[this.colsDefName.baseBorrowingName] = {
          data:
            item.type === 'percentage'
              ? (item.value * 100).toFixed(2)
              : item.value.toFixed(2),
          editable: item.editable,
          style: { center: true },
        };
        return tableItem;
      });
    }
    return [];
  }
}
