<div class="income-statement-direct-costs">
  <klt-section title="Direct costs" type="none">
    <div class="graph-box">
      <klt-graph
        [loading]="clientQuery.selectFinancialIsLoading$ | async"
        (filter)="runFilter($event)"
        [options]="options"
        [titles]="titles"
        [height]="height"
      ></klt-graph>
    </div>
  </klt-section>
</div>
