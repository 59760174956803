import { Component, Input } from '@angular/core';
import { IconSize } from '../../interfaces';
import { SvgIconComponent } from 'angular-svg-icon';


@Component({
    selector: 'klt-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    standalone: true,
    imports: [SvgIconComponent],
})
export class IconComponent {
  @Input() color = 'dark';
  @Input() size: IconSize = 'regular';
  @Input() icon = '';
  @Input() pathStyle = false;
}
