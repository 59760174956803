import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Role } from '@kolytics/types/common';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private readonly RoleClaim =
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

  constructor(private jwtService: JwtHelperService) {}

  getDecodedToken() {
    const token = this.jwtService.tokenGetter()?.toString();
    const decodedToken = this.jwtService.decodeToken(token);
    return decodedToken;
  }

  hasAdminRole(decodedToken: any) {
    const role = decodedToken[this.RoleClaim];
    const isArray =
      Array.isArray(role) &&
      ((role as string[]).includes(Role.Admin) ||
        (role as string[]).includes(Role.SuperAdmin));
    const isString = role === Role.Admin || role === Role.SuperAdmin;
    return isArray || isString;
  }

  hasFeature(feature: string) {
    const decodedToken = this.getDecodedToken();
    if (!decodedToken) return false;

    if (this.hasAdminRole(decodedToken)) {
      return true;
    }

    if (!feature) {
      return false;
    }

    const features = decodedToken['features'] as string[];
    if (!features) {
      return false;
    }

    return features.includes(feature);
  }
}
