import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  KltModalService,
  TableColumnGroup,
  TableColumns,
  TableData,
} from '@kolytics/shared-components';

import { KltToastService } from 'libs/shared-components/src/lib/services/toast/toast.service';
import {
  CompareFinancialScenarioResponse,
  IReitData,
  IReitDataResponse,
  IReitFinancialCommonTableDataItem,
} from '../../../../interfaces';
import { BaseReit } from '../../../../utils/components/base-reit/base-reit.util';
import { getColNameByYear } from '../../../../utils';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { ClientSignalState } from 'apps/client/src/app/store/client.state';
import { ClientStore } from 'apps/client/src/app/store/client.store';
import {
  ClientQuery,
  ClientSignalQuery,
} from 'apps/client/src/app/store/client.query';
import { snapshotManager } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProductFeature } from '@kolytics/types/common';
import {
  ExportService,
  ReitMetadataResponse,
  ReitService,
} from '@kolytics/shared-api';
import { financialBalanceSheet } from 'apps/client/src/app/shared/constants/mock-data';
import { FinancialsCompareComponent } from '../../../financials/financials-compare/financials-compare.component';
import { LoadingSpinnerComponent } from '../../../../../../../../../../libs/shared-components/src/lib/components/loading-spinner/loading-spinner.component';
import { FeaturedDirective } from '../../../../../../../../../../libs/shared-components/src/lib/directives/featured.directive';
import { LinkActionComponent } from '../../../../components/link-action/link-action.component';
import {
  TableToolbarDirective,
  TableComponent,
} from '../../../../../../../../../../libs/shared-components/src/lib/components/table/table.component';
import { TableV2Component } from '../../../../../../../../../../libs/shared-components/src/lib/components/table-v2/table-v2.component';

@UntilDestroy()
@Component({
  selector: 'klt-balance-sheet-table',
  templateUrl: './balance-sheet-table.component.html',
  styleUrls: ['./balance-sheet-table.component.scss'],
  standalone: true,
  imports: [
    TableV2Component,
    TableToolbarDirective,
    LinkActionComponent,
    FeaturedDirective,
    LoadingSpinnerComponent,
    FinancialsCompareComponent,
    TableComponent,
  ],
})
export class BalanceSheetTableComponent extends BaseReit implements OnInit {
  @Input() metadata!: ReitMetadataResponse;
  @Input()
  public set reit(value: IReitDataResponse | null) {
    if (value) {
      this.reitData = value;

      this.reitId = value.reits.reit.id;

      this.reset(
        value.reits.reitFinancials.balanceSheetTableDataSelected.data,
        value.reits.reitFinancials.balanceSheetTableData.data,
        value.reits.reitFinancials.balanceSheetTableDataSelected.name,
        value.reits.reitFinancials.balanceSheetTableData.name,
      );
    }
  }

  @Input() decimalPoints = 1;
  @Input() showToolbar = true;
  @Input() showTopHeader = true;
  @Input() stickyTable = true;
  @Input() useOldTable = false;

  reitId!: number;

  tableData: TableData[] = [];
  allData: TableData[] = [];
  headers: TableColumns | undefined;
  sections: TableColumnGroup[] = [];
  excelLoading = false;
  reitData: IReitDataResponse = {} as IReitDataResponse;

  isPopup: boolean = false;
  downloadsFeature = ProductFeature.Downloads;

  public reitTableData: IReitData = {} as IReitData;
  private readonly baseScenarioName = 'Base';
  private readonly goodScenarioName = 'Good';

  constructor(
    protected readonly service: ReitService,
    protected readonly kltModalService: KltModalService,
    protected readonly kltToastService: KltToastService,
    protected readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private clientFacade: ClientFacadeService,

    private clientStore: ClientStore,
    private clientQuery: ClientQuery,
    private clientSignalQuery: ClientSignalQuery,
    private exportService: ExportService,
  ) {
    super(kltModalService, kltToastService, router);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.isPopup =
      this.activatedRoute.snapshot.queryParams['mode'] === 'popout';
    if (this.isPopup) {
      this.reitId = this.activatedRoute.snapshot.params['reitId'];

      this.clientFacade.financial$.pipe(take(1)).subscribe((value) => {
        this.reset(
          value.reits.reitFinancials.balanceSheetTableDataSelected.data,
          value.reits.reitFinancials.balanceSheetTableData.data,
          value.reits.reitFinancials.balanceSheetTableDataSelected.name,
          value.reits.reitFinancials.balanceSheetTableData.name,
        );
      });

      this.clientFacade.localStorageUpdates$
        .pipe(untilDestroyed(this))
        .subscribe((state: ClientSignalState) => {
          if (!state) return;

          const oldState = this.clientSignalQuery.getValue();

          const selectedScenarioIds =
            this.clientQuery.getValue().selectedScenarios[this.reitId];
          const selectedAssumptionScenarioIds =
            this.clientQuery.getValue().selectedAssumptionScenarios[
              this.reitId
            ];

          const scenarioIds = [
            ...selectedScenarioIds,
            ...selectedAssumptionScenarioIds,
          ];

          const pScenarioId = state.portfolio[this.reitId]?.scenarioId;
          const pUpdatedAt = state.portfolio[this.reitId]?.updatedAt;

          const cScenarioId = state.company[this.reitId]?.scenarioId;
          const cUpdatedAt = state.company[this.reitId]?.scenarioId;

          const oScenarioId = state.other[this.reitId]?.scenarioId;
          const oUpdatedAt = state.other[this.reitId]?.scenarioId;

          if (this.reitId) {
            if (
              (scenarioIds.includes(pScenarioId) &&
                pUpdatedAt !== oldState.portfolio[this.reitId]?.updatedAt) ||
              (scenarioIds.includes(cScenarioId) &&
                cUpdatedAt !== oldState.company[this.reitId]?.updatedAt) ||
              (scenarioIds.includes(oScenarioId) &&
                oUpdatedAt !== oldState.other[this.reitId]?.updatedAt)
            ) {
              snapshotManager.setStoresSnapshot(
                { clientSignals: state },
                { skipStorageUpdate: true },
              );
              this.refreshPopoutData();
            }
          }
        });
    }
  }

  refreshPopoutData() {
    this.clientFacade
      .getReitId()
      .pipe(
        take(1),
        tap((reitId: number) => (this.reitId = reitId)),
        switchMap(() => this.clientFacade.financial$.pipe(take(1))),
      )
      .subscribe((value) => {
        this.reset(
          value.reits.reitFinancials.balanceSheetTableDataSelected.data,
          value.reits.reitFinancials.balanceSheetTableData.data,
          value.reits.reitFinancials.balanceSheetTableDataSelected.name,
          value.reits.reitFinancials.balanceSheetTableData.name,
        );
      });
  }

  public reset(
    itemsA: IReitFinancialCommonTableDataItem[],
    itemsB: IReitFinancialCommonTableDataItem[],
    nameA: string,
    nameB: string,
  ): void {
    this.tableData = [];
    const fieldNameList = this.getAllFieldName(itemsA);
    const itemAData = this.createBalanceSheetGroupByFieldName(itemsA);
    const itemBData = this.createBalanceSheetGroupByFieldName(itemsB);
    for (let i = 0; i < fieldNameList.length; i++) {
      const fieldName = fieldNameList[i];
      const aItem = itemAData[fieldName];
      const bItem = itemBData[fieldName];
      if (i === 0) {
        const { sections, headers } = this.createSectionsAndHeaders(
          aItem,
          bItem,
          nameA,
          nameB,
        );
        this.sections = sections;
        this.headers = headers;
      }
      const cashData = this.createBalanceSheetData(
        fieldName,
        aItem,
        bItem,
        nameA,
        nameB,
      );
      this.tableData.push(...cashData);
    }

    const stickLeftColumns: TableColumns = {
      Field: {
        title: 'Field',
        fill: 'purple',
        sortable: true,
        type: 'text',
      },
    };

    this.reitTableData = {
      sections: this.sections,
      headers: this.headers,
      data: this.tableData,
      stickLeftColumns: stickLeftColumns,
    } as IReitData;
  }

  closeCompareDialog(value: CompareFinancialScenarioResponse) {
    this.reset(
      value.result.balanceSheetTableDataScenarioA,
      value.result.balanceSheetTableDataScenarioB,
      value.result.scenarioA.name,
      value.result.scenarioB.name,
    );
  }

  getAllFieldName(data: IReitFinancialCommonTableDataItem[]) {
    return [...new Set(data.map((x) => x.fieldName))];
  }

  createBalanceSheetGroupByFieldName(
    data: IReitFinancialCommonTableDataItem[],
  ) {
    return this.groupBy(data, (x) => x.fieldName);
  }

  createSectionsAndHeaders(
    itemA: IReitFinancialCommonTableDataItem[],
    ItemB: IReitFinancialCommonTableDataItem[],
    nameA: string,
    nameB: string,
  ) {
    const defsA: string[] = [];
    itemA.forEach((item) => {
      const def = this.generateColumnDefs(item.year, nameA);
      if (!defsA.includes(def)) {
        defsA.push(def);
      }
    });

    const sections: TableColumnGroup[] = [
      {
        sectionTitle: nameA,
        columnDefs: defsA,
        spacing: true,
        spacingFill: 'light',
      },
      {
        sectionTitle: nameB,
        columnDefs: ItemB.map((x) => this.generateColumnDefs(x.year, nameB)),
      },
    ];
    const headers = this.createHeaderGroup(itemA, ItemB, nameA, nameB);
    return { sections, headers };
  }

  generateColumnDefs(year: number, scenarioName: string) {
    const colName = getColNameByYear(year);
    return `${colName}_${scenarioName}`;
  }

  createHeaderGroup(
    itemA: IReitFinancialCommonTableDataItem[],
    itemB: IReitFinancialCommonTableDataItem[],
    nameA: string,
    nameB: string,
  ) {
    let header: TableColumns = {};

    if (!this.useOldTable) {
      header = {
        Field: {
          title: 'Field',
          fill: 'purple',
          sortable: true,
          type: 'text',
          sticky: true,
        },
      };
    }

    itemA.forEach((row) => {
      const yearLabel = this.generateColumnDefs(row.year, nameA);
      header[yearLabel] = {
        title: getColNameByYear(row.year, row.isActualYear),
        fill: this.getBackgroundColorByNameAndYear(row.isActualYear),
        sortable: false,
        type: 'text',
      };
    });

    itemB.forEach((row) => {
      const yearLabel = this.generateColumnDefs(row.year, nameB);
      header[yearLabel] = {
        title: getColNameByYear(row.year, row.isActualYear),
        fill: 'dark-light',
        sortable: false,
        type: 'text',
      };
    });
    return header;
  }

  getBackgroundColorByName(name: string) {
    return name === this.baseScenarioName ? 'dark' : 'purple';
  }

  getBackgroundColorByNameAndYear(isActualYear?: boolean) {
    return isActualYear ? 'purple' : 'purpleDisabled';
  }

  getDecimalPoints = (
    fieldName?: string,
    defaultDecimalPoints?: number,
    countryDecimalPoints?: number,
  ) => {
    const exludedFields = ['NTA / Share', 'Proportional LTV', 'Overall LTV'];

    const countrySpecific = ['NTA / Share'];

    if (!fieldName) return defaultDecimalPoints;
    if (!countryDecimalPoints) return defaultDecimalPoints;

    if (countrySpecific.includes(fieldName)) return countryDecimalPoints;
    if (exludedFields.includes(fieldName)) return defaultDecimalPoints;
    return 0;
  };

  createBalanceSheetData(
    fieldName: string,
    itemAData: IReitFinancialCommonTableDataItem[],
    itemBData: IReitFinancialCommonTableDataItem[],
    nameA: string,
    nameB: string,
  ): TableData[] {
    const tbData: TableData[] = [];
    const highlight = this.isHighlightRow(fieldName);
    const value: TableData = {
      Field: {
        data: fieldName,
        highlight: highlight,
        tooltip: financialBalanceSheet.data[fieldName]?.tooltip,
      },
    };
    if (!itemAData) {
      itemAData = [...itemBData];
      itemAData.forEach((item) => {
        item.fieldValue = 0;
      });
    }
    if (!itemBData) {
      itemBData = [...itemAData];
      itemBData.forEach((item) => {
        item.fieldValue = 0;
      });
    }
    itemAData.map((item: IReitFinancialCommonTableDataItem) => {
      const yearLabel = this.generateColumnDefs(item.year, nameA);
      value[yearLabel] = {
        data: item.fieldValue.toFixed(
          this.getDecimalPoints(
            item.fieldName,
            this.decimalPoints,
            this.metadata.decimalPoints,
          ),
        ),
        number: item.fieldValue,
        decimalPoints: this.getDecimalPoints(
          item.fieldName,
          this.decimalPoints,
          this.metadata.decimalPoints,
        ),
        highlight: highlight,
      } as any;
    });
    itemBData.map((item: IReitFinancialCommonTableDataItem) => {
      const yearLabel = this.generateColumnDefs(item.year, nameB);
      value[yearLabel] = {
        data: item.fieldValue.toFixed(
          this.getDecimalPoints(
            item.fieldName,
            this.decimalPoints,
            this.metadata.decimalPoints,
          ),
        ),
        number: item.fieldValue,
        decimalPoints: this.getDecimalPoints(
          item.fieldName,
          this.decimalPoints,
          this.metadata.decimalPoints,
        ),
        highlight: highlight,
      } as any;
    });
    tbData.push(value);
    return tbData;
  }

  isHighlightRow(fieldName: string) {
    const listFieldNameHasBorder = ['current', 'assets', 'total'];
    if (
      listFieldNameHasBorder.find((x) =>
        fieldName.toLocaleLowerCase().includes(x),
      )
    ) {
      return true;
    }
    return false;
  }

  popout(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/reits/popout/balance/${this.reitId}`], {
        queryParams: {
          mode: 'popout',
        },
      }),
    );

    window.open(
      url,
      '_blank',
      'location=yes,height=800,width=600,scrollbars=yes,status=yes',
    );
  }

  storeTableInfoInStorage(key: string) {
    localStorage.setItem(key, JSON.stringify(this.reitTableData));
  }

  downloadExcel() {
    this.excelLoading = true;
    this.exportService
      .apiExportFinancialsReitIdGet(this.reitData.reits.reit.id)
      .subscribe(
        (data: Blob) => {
          const file = new Blob([data], { type: 'text/csv' });
          const fileURL = URL.createObjectURL(file);

          var a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = `${this.reitData.reits.reit.name} financials.xlsx`;
          document.body.appendChild(a);
          a.click();

          this.excelLoading = false;
        },
        (err) => {
          this.excelLoading = false;
        },
      );
  }
}
