<klt-modal modalTitle="Compare" (closeModal)="onClose()">
  <form>
    <div class="modal-content">
      <div class="form-input">
        <klt-single-selection
          label="Scenario"
          [items]="
            userScenarios$
              | async
              | scenarioToOption
              | optionHide: rightScenarioId
          "
          [selectedValue]="leftScenarioId"
          (selectionChange)="onLeftScenarioChange($event)"
        ></klt-single-selection>
      </div>

      <div class="form-input">
        <klt-single-selection
          label="Comparison Scenario"
          [items]="
            scenarios$ | async | scenarioToOption | optionHide: leftScenarioId
          "
          [selectedValue]="rightScenarioId"
          (selectionChange)="onRightScenarioChange($event)"
        ></klt-single-selection>
      </div>
    </div>

    <div class="actions-wrapper">
      <klt-button type="secondary" (click)="onClose()">CANCEL</klt-button>
      <klt-button type="primary" (click)="onSubmit()" [loading]="loading"
        >COMPARE</klt-button
      >
    </div>
  </form>
</klt-modal>
