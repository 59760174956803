<div class="components--wrapper">
  <!-- Input Fields -->
  <div class="row">
    <div class="column">
      <h5>Input</h5>
      <div class="component-box">
        <klt-input
          [label]="'Label'"
          [type]="'email'"
          info="Information line"
        ></klt-input>
      </div>
      <div class="component-box">
        <klt-input [label]="'Disabled'" [disabled]="true"></klt-input>
      </div>
      <div class="component-box">
        <klt-input
          [label]="'With Error'"
          [error]="true"
          [errorLabel]="'Error Message'"
        ></klt-input>
      </div>
    </div>

    <div class="column">
      <h5>Search expanding Input</h5>
      <div class="component-box">
        <klt-input-expand
          [placeholder]="'Placeholder'"
          [theme]="'light'"
        ></klt-input-expand>
      </div>
    </div>

    <div class="column">
      <h5>Single selection Input</h5>
      <div class="component-box">
        <klt-single-selection
          [label]="'Label'"
          [items]="singleSelectionInputOptions"
        ></klt-single-selection>
      </div>
    </div>

    <div class="column">
      <h5>Checkbox (Regular size)</h5>
      <div class="component-box">
        <klt-checkbox [label]="'Label'"></klt-checkbox>
      </div>
      <div class="component-box">
        <klt-checkbox [label]="'Disabled'" [disabled]="true"></klt-checkbox>
      </div>
    </div>

    <div class="column">
      <h5>Checkbox (Large size)</h5>
      <div class="component-box">
        <klt-checkbox [label]="'Label'" [sizeLarge]="true"></klt-checkbox>
      </div>
      <div class="component-box">
        <klt-checkbox
          [label]="'Disabled'"
          [sizeLarge]="true"
          [disabled]="true"
        ></klt-checkbox>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="column">
      <h5>Buttons</h5>
      <div class="component-box">
        <h6><i>Primary</i></h6>
        <klt-button [type]="'primary'">Label</klt-button>
      </div>
      <div class="component-box">
        <h6><i>Secondary</i></h6>
        <klt-button [type]="'secondary'">Label</klt-button>
      </div>
      <div class="component-box">
        <h6><i>Text links</i></h6>
        <klt-button [type]="'link'">Label</klt-button>
      </div>
    </div>
    <div class="column">
      <h5>Buttons (With icons)</h5>
      <div class="component-box">
        <h6><i>Primary</i></h6>
        <klt-button [type]="'primary'" [rightIcon]="'plus'">Label</klt-button>
      </div>
      <div class="component-box">
        <h6><i>Secondary</i></h6>
        <klt-button [type]="'secondary'" [leftIcon]="'plus'">Label</klt-button>
      </div>
      <div class="component-box">
        <h6><i>Text links</i></h6>
        <klt-button [type]="'link'" [rightIcon]="'plus'" [leftIcon]="'upload'"
          >Label</klt-button
        >
      </div>
    </div>
    <div class="column">
      <h5>Checkbox Button (Small)</h5>
      <div class="component-box">
        <h6><i>Inactive</i></h6>
        <klt-checkbox-button
          [label]="'Label'"
          [checked]="false"
        ></klt-checkbox-button>
      </div>
      <div class="component-box">
        <h6><i>Selected</i></h6>
        <klt-checkbox-button
          [label]="'Label'"
          [checked]="true"
        ></klt-checkbox-button>
      </div>
      <div class="component-box">
        <h6><i>Disabled</i></h6>
        <klt-checkbox-button
          [label]="'Label'"
          [disabled]="true"
        ></klt-checkbox-button>
      </div>
    </div>
    <div class="column">
      <h5>Checkbox Button (Large)</h5>
      <div class="component-box">
        <h6><i>Inactive</i></h6>
        <klt-checkbox-button
          [label]="'Label'"
          [description]="'Description'"
          size="large"
          [checked]="false"
        ></klt-checkbox-button>
      </div>
      <div class="component-box">
        <h6><i>Selected</i></h6>
        <klt-checkbox-button
          [label]="'Label'"
          [description]="'Description'"
          size="large"
          [checked]="true"
        ></klt-checkbox-button>
      </div>
      <div class="component-box">
        <h6><i>Disabled</i></h6>
        <klt-checkbox-button
          [label]="'Label'"
          [description]="'Description'"
          size="large"
          [disabled]="true"
        ></klt-checkbox-button>
      </div>
    </div>
  </div>

  <!-- Dropdown options panel -->
  <div class="row">
    <div class="column">
      <h5>Dropdown Options (Multi)</h5>
      <div class="component-box">
        <div class="dropdown">
          <klt-dropdown-options
            [type]="'multi'"
            [open]="true"
            [groupedOptions]="dropdownMultiOptions"
          ></klt-dropdown-options>
        </div>
      </div>
    </div>

    <div class="column">
      <h5>Dropdown Options (Single)</h5>
      <div class="component-box">
        <div class="dropdown">
          <klt-dropdown-options
            [type]="'list'"
            [open]="true"
            [options]="dropdownSingleOptions"
          ></klt-dropdown-options>
        </div>
      </div>
    </div>
  </div>

  <!-- Chips Fields -->
  <div class="row">
    <div class="column">
      <h5>Choice Chip</h5>
      <div class="component-box chip">
        <klt-chip [label]="'Label'" [icon]="'download'"></klt-chip>
        <klt-chip [label]="'Label'"></klt-chip>
      </div>

      <h5>Choice Chip (disabled)</h5>
      <div class="component-box chip">
        <klt-chip
          [label]="'Label'"
          [icon]="'download'"
          [disabled]="true"
        ></klt-chip>
        <klt-chip [label]="'Label'" [disabled]="true"></klt-chip>
      </div>

      <h5>Choice Chip (selected disabled)</h5>
      <div class="component-box chip">
        <klt-chip
          [label]="'Label'"
          [icon]="'download'"
          [disabled]="true"
          [selected]="true"
        ></klt-chip>
        <klt-chip
          [label]="'Label'"
          [disabled]="true"
          [selected]="true"
        ></klt-chip>
      </div>
    </div>

    <div class="column">
      <h5>Labelled choice chips</h5>
      <div class="component-box">
        <klt-chip [label]="'Label'" [isNew]="true"></klt-chip>
      </div>

      <h5>Labelled choice chips (disabled)</h5>
      <div class="component-box">
        <klt-chip [label]="'Label'" [isNew]="true" [disabled]="true"></klt-chip>
      </div>

      <h5>Labelled choice chips (selected disabled)</h5>
      <div class="component-box">
        <klt-chip
          [label]="'Label'"
          [isNew]="true"
          [disabled]="true"
          [selected]="true"
        ></klt-chip>
      </div>
    </div>
  </div>

  <!-- Icons -->
  <div class="row row-icon">
    <h5>Icons</h5>
    <div class="column">
      <h6>arrow-down</h6>
      <klt-icon
        [size]="'regular'"
        [icon]="'arrow-down'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>arrow-left</h6>
      <klt-icon
        [size]="'regular'"
        [icon]="'arrow-left'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>arrow-right</h6>
      <klt-icon
        [size]="'regular'"
        [icon]="'arrow-right'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>arrow-up</h6>
      <klt-icon
        [size]="'regular'"
        [icon]="'arrow-up'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>calendar</h6>
      <klt-icon
        [size]="'regular'"
        [icon]="'calendar'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>close</h6>
      <klt-icon [size]="'regular'" [icon]="'close'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>dashboard</h6>
      <klt-icon
        [size]="'regular'"
        [icon]="'dashboard'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>delete</h6>
      <klt-icon [size]="'regular'" [icon]="'delete'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>down</h6>
      <klt-icon [size]="'regular'" [icon]="'down'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>download</h6>
      <klt-icon
        [size]="'regular'"
        [icon]="'download'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>edit</h6>
      <klt-icon [size]="'regular'" [icon]="'edit'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>email</h6>
      <klt-icon [size]="'regular'" [icon]="'email'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>error</h6>
      <klt-icon [size]="'regular'" [icon]="'error'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>eye-off</h6>
      <klt-icon
        [size]="'regular'"
        [pathStyle]="true"
        [icon]="'eye-off'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>eye</h6>
      <klt-icon [size]="'regular'" [icon]="'eye'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>left</h6>
      <klt-icon [size]="'regular'" [icon]="'left'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>lock</h6>
      <klt-icon [size]="'regular'" [icon]="'lock'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>log-out</h6>
      <klt-icon
        [size]="'regular'"
        [icon]="'log-out'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>more-horizontal</h6>
      <klt-icon
        [size]="'regular'"
        [pathStyle]="true"
        [icon]="'more-horizontal'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>more-vertical</h6>
      <klt-icon
        [size]="'regular'"
        [pathStyle]="true"
        [icon]="'more-vertical'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>pin</h6>
      <klt-icon [size]="'regular'" [icon]="'pin'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>profile</h6>
      <klt-icon
        [size]="'regular'"
        [icon]="'profile'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>reit</h6>
      <klt-icon [size]="'regular'" [icon]="'reit'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>research</h6>
      <klt-icon
        [size]="'regular'"
        [icon]="'research'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>right</h6>
      <klt-icon [size]="'regular'" [icon]="'right'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>search</h6>
      <klt-icon [size]="'regular'" [icon]="'search'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>settings</h6>
      <klt-icon
        [size]="'regular'"
        [icon]="'settings'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>sign-in</h6>
      <klt-icon
        [size]="'regular'"
        [icon]="'sign-in'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>success</h6>
      <klt-icon
        [size]="'regular'"
        [icon]="'success'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>tick</h6>
      <klt-icon [size]="'regular'" [icon]="'tick'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>trash</h6>
      <klt-icon [size]="'regular'" [icon]="'trash'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>up</h6>
      <klt-icon [size]="'regular'" [icon]="'up'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>upload</h6>
      <klt-icon [size]="'regular'" [icon]="'upload'" [color]="'cta'"></klt-icon>
    </div>
    <div class="column">
      <h6>warning</h6>
      <klt-icon
        [size]="'regular'"
        [pathStyle]="true"
        [icon]="'warning'"
        [color]="'cta'"
      ></klt-icon>
    </div>
    <div class="column">
      <h6>web</h6>
      <klt-icon [size]="'regular'" [icon]="'web'" [color]="'cta'"></klt-icon>
    </div>
  </div>

  <!-- Text editor -->
  <div class="row">
    <div class="column">
      <div class="component-box">
        <klt-text-editor></klt-text-editor>
      </div>
    </div>
  </div>
</div>
