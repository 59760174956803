<div [formGroup]="form" class="wrapper" [ngClass]="getFormClasses()">
  @if (type === 'textarea') {
    <textarea
      #textarea
      class="input"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [name]="name"
      [placeholder]="formStates.labelRaised ? placeholder : ''"
      [maxlength]="maxlength"
      (keydown.tab)="noTab ? disableTab($event) : null"
      [pattern]="pattern"
      [tabindex]="disabled ? -1 : tabIndex"
      [required]="required"
      formControlName="formValue"
      >
    </textarea>
  }
  @if (type === 'phone') {
    <div
      class="countries"
      (click)="showCountry = !showCountry"
      >
      <div class="select">
        <klt-icon
          [icon]="'country/' + selectedCountry.code.toLowerCase()"
          color="dark"
          size="small"
        ></klt-icon>
        <b>{{ selectedCountry.code }}</b>
        <klt-icon icon="down" color="dark" size="xsmall"></klt-icon>
      </div>
      <div class="separator"></div>
      <b class="prefix">{{ selectedCountry.dial_code }}</b>
    </div>
  }
  @if (
    type === 'text' ||
    type === 'password' ||
    type === 'email' ||
    type === 'phone' ||
    type === 'number'
    ) {
    <input
      #input
      class="input"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [name]="name"
      [class.phone]="type === 'phone'"
      [placeholder]="
        formStates.labelRaised ? placeholder : type === 'phone' ? label : ''
      "
      [type]="passwordVisible ? 'text' : type"
      [maxlength]="maxlength"
      (keydown.tab)="noTab ? disableTab($event) : null"
      [pattern]="pattern"
      [tabindex]="disabled ? -1 : tabIndex"
      (keydown.enter)="enterKeySubmit($event)"
      [required]="required"
      formControlName="formValue"
      />
    }
    @if (!hideNotch) {
      <div class="notch">
        <div class="lead"></div>
        <div class="main">
          <label [class.phone]="type === 'phone'">{{ label }}</label>
        </div>
        <div class="trail"></div>
      </div>
    }
    @if (!hideStateIndicator) {
      <div class="icon">
        @if (rightIcon) {
          <klt-icon
            [size]="rightIconSize"
            [icon]="rightIcon"
          ></klt-icon>
        }
        @if (!rightIcon) {
          @if (displayIcon === 'clear') {
            <klt-icon
              [size]="'regular'"
              icon="close"
              (mousedown)="clear($event)"
            ></klt-icon>
          }
          @if (displayIcon === 'tick') {
            <klt-icon
              [size]="'regular'"
              icon="tick"
              color="cta"
            ></klt-icon>
          }
          @if (displayIcon === 'lock') {
            <klt-icon
              [size]="'regular'"
              icon="lock"
            ></klt-icon>
          }
          @if (displayIcon === 'eye') {
            <klt-icon
              [size]="'regular'"
              [pathStyle]="true"
              (mousedown)="togglePasswordVisibility($event)"
              [icon]="passwordVisible ? 'eye' : 'eye-off'"
              [color]="passwordVisible ? 'cta' : 'darkGray'"
              >
            </klt-icon>
          }
        }
        <!-- <div class="verifying" *ngIf="verifying">
        <ark-spinner></ark-spinner>
      </div>  -->
    </div>
  }
  @if (type === 'phone') {
    <div
      class="countries-list"
      [class.show]="showCountry"
      >
      <div class="search">
        <klt-input-expand
          layout="alt"
          iconSize="small"
          placeholder="Search country"
          (valueChanged)="searchCountry = $event; filterCountry()"
          (valueCleared)="searchCountry = ''; filterCountry()"
        ></klt-input-expand>
      </div>
      <div class="list">
        @for (country of filterCountries; track country) {
          <div
            class="list-item"
            [class.selected]="country.code === selectedCountry.code"
            (click)="selectedCountry = country; showCountry = false"
            >
            <klt-icon
              [icon]="'country/' + country.code.toLowerCase()"
              color="dark"
              size="small"
            ></klt-icon>
            <b>{{ country.name }}</b>
          </div>
        }
      </div>
    </div>
  }
</div>
@if (formStates.error) {
  <div class="error" [@dropdownAnimation]>
    <span>{{ errorMessage }}</span>
  </div>
}
@if (info) {
  <div class="info">
    <span>{{ info }}</span>
  </div>
}
