import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AssumptionProfileItem } from '../../interfaces';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { ReitStoreService } from '../../services/reit-store.service';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { ClaimService } from 'libs/shared-components/src/lib/services/jwt/claim.service';
import { AssumptionItemComponent } from './assumption-item/assumption-item.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-assumptions',
  templateUrl: './assumptions.component.html',
  styleUrls: ['./assumptions.component.scss'],
  standalone: true,
  imports: [NgClass, AssumptionItemComponent],
})
export class AssumptionsComponent implements OnInit {
  assumptions: AssumptionProfileItem[] = [];

  selectedAssumption?: AssumptionProfileItem;
  constructor(
    private router: Router,
    private clientFacade: ClientFacadeService,
  ) {}

  ngOnInit(): void {
    this.getAssumptionProfile();
  }

  getAssumptionProfile() {
    const codec = new HttpUrlEncodingCodec();
    const path = codec.decodeValue(document.location.pathname);
    const pathSplit = `${path}`.split('/');
    //const reitId = pathSplit[2];
    let isCreate = '';
    let scenarioName = '';
    if (pathSplit.length === 6) {
      isCreate = pathSplit[4];
      scenarioName = pathSplit[5];
    }

    const rawStore = this.clientFacade.storeQuery.getValue();
    const reitId = rawStore.reitId;
    const scenarios = rawStore.scenarios[rawStore.reitId];

    // const allowCreate = this.claimService.hasClaim('assumption:create');
    const allowCreate = true;
    if (scenarios.length > 0) {
      const userScenarios = scenarios.filter((x) => !x.isDefault);

      if (!allowCreate) {
        const baseScenario = scenarios.find(
          (e) => e.name.toLocaleLowerCase() === 'base',
        );
        const goodScenario = scenarios.find(
          (e) => e.name.toLocaleLowerCase() === 'good',
        );
        this.clientFacade.setSelectedLeftScenarioId(
          reitId,
          Number(baseScenario?.scenarioId),
        );
        this.clientFacade.setSelectedRightScenarioId(
          reitId,
          Number(goodScenario?.scenarioId),
        );
        this.router.navigate([
          `/reits/${reitId}/assumptions/sub/${baseScenario?.scenarioId}/portfolio`,
        ]);
      } else {
        if (userScenarios.length > 0 && !isCreate && allowCreate) {
          const latestUserScenarioId = Math.max.apply(
            Math,
            userScenarios.map((e) => e.scenarioId),
          );
          const initialScenario = userScenarios.find(
            (e) => e.scenarioId === latestUserScenarioId,
          );
          this.clientFacade.setSelectedLeftScenarioId(
            reitId,
            latestUserScenarioId,
          );
          this.clientFacade.setSelectedRightScenarioId(
            reitId,
            Number(initialScenario?.initialScenarioId),
          );
          this.router.navigate([
            `/reits/${reitId}/assumptions/sub/${latestUserScenarioId}/portfolio`,
          ]);
        } else {
          this.assumptions.push(
            ...scenarios.map((s) => {
              return {
                title: s.name,
                description: this.setDescriptionByName(
                  s.name.toLocaleLowerCase(),
                  s.isDefault,
                ),
                image: this.setImageByName(s.name.toLocaleLowerCase()),
                isDefaultScenario: s.isDefault,
                scenarioId: s.scenarioId,
                scenarioName: scenarioName,
              };
            }),
          );
        }
      }

      if (allowCreate) {
        if (userScenarios.length > 0 && !isCreate) {
          const latestUserScenarioId = Math.max.apply(
            Math,
            userScenarios.map((e) => e.scenarioId),
          );
          const initialScenario = userScenarios.find(
            (e) => e.scenarioId === latestUserScenarioId,
          );
          this.clientFacade.setSelectedLeftScenarioId(
            reitId,
            latestUserScenarioId,
          );
          this.clientFacade.setSelectedRightScenarioId(
            reitId,
            Number(initialScenario?.initialScenarioId),
          );
          this.router.navigate([
            `/reits/${reitId}/assumptions/sub/${latestUserScenarioId}/portfolio`,
          ]);
        }
      } else {
      }
    }
  }

  onAssumptionSeleted(assumption: AssumptionProfileItem) {
    this.selectedAssumption = assumption;
  }

  setImageByName(name: string) {
    switch (name) {
      case 'base':
        return 'dog';
      case 'good':
        return 'Bull';
      case 'poor':
        return 'bear';
      default:
        return 'user';
    }
  }

  setDescriptionByName(name: string, isDefault: boolean) {
    if (!isDefault) return '';
    switch (name) {
      case 'base':
        return 'Our central case based on broadly consensus macro and company specific factors';
      case 'good':
        return 'Realistic upside potential assuming slightly better macro and/or company specific factors';
      default:
        return 'Realistic downside assuming slightly worse macro and/or company specific factors';
    }
  }
}
