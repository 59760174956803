import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  DropDownAction,
  DropDownOption,
  KltModalService,
  TableColumnGroup,
  TableColumns,
  TableData,
} from '@kolytics/shared-components';
import { KltToastService } from 'libs/shared-components/src/lib/services/toast/toast.service';
import {
  IAssumptionProfileScenarioResponse,
  IReitData,
} from '../../../../interfaces';
import { Router } from '@angular/router';
import {
  RemoveScenarioModalComponent,
  RenameScenarioModalComponent,
  ResetScenarioModalComponent,
  ResetToLastSaveModalComponent,
  CreateScenarioModalComponent,
} from '../../../../modals';
import { first, tap } from 'rxjs/operators';
import { autoDestroy, followDestroy } from '@kolytics/shared-functions';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssumptionBaseComponent } from '../../../../utils/components/assumption/assumption-base-component';
import { ReitStoreService } from '../../../../services/reit-store.service';
import { TableV2Component } from '../../../../../../../../../../libs/shared-components/src/lib/components/table-v2/table-v2.component';
import {
  ReitAssumptionService,
  ReitService,
  UserAssumptionsService,
} from '@kolytics/shared-api';

@Component({
  selector: 'klt-other-capex-table',
  templateUrl: './other-capex-table.component.html',
  styleUrls: ['./other-capex-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TableV2Component],
})
@autoDestroy()
export class OtherCapexTableComponent
  extends AssumptionBaseComponent
  implements OnInit
{
  public reitTableData: IReitData = {} as IReitData;

  @Input()
  public set structuralCAPEXReserve(
    value: IAssumptionProfileScenarioResponse | null,
  ) {
    if (value) {
      this.bindingStructuralCAPEXReserve(value);
    }
  }
  @Input()
  showToolbar = true;
  @Input()
  reitId!: number;
  @Input()
  userScenarioId!: number;

  @Output() cellSaved = new EventEmitter<any>();

  colsDefName = {
    userStructuralName: 'structural_capex_user',
    userCapexName: 'capex_user',
    baseStructuralName: 'structural_capex_base',
    baseCapexName: 'capex_base',
  };

  constructor(
    protected readonly kltModalService: KltModalService,
    protected readonly kltToastService: KltToastService,
    protected readonly router: Router,
    protected readonly reitService: ReitService,
    protected readonly ref: ChangeDetectorRef,
    protected readonly spinner: NgxSpinnerService,
    protected reitStoreService: ReitStoreService,
    protected reitAssumptionService: ReitAssumptionService,
    protected userAssumptionService: UserAssumptionsService,
  ) {
    super(
      kltModalService,
      kltToastService,
      router,
      reitService,
      userAssumptionService,
      spinner,
      reitStoreService,
      ref,
      reitAssumptionService,
    );
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  protected valueOfReitId(): string {
    return this.reitId.toString();
  }

  protected valueOfUserScenarioId(): number | string {
    return this.userScenarioId;
  }

  bindingStructuralCAPEXReserve(
    structuralCAPEXReserve: IAssumptionProfileScenarioResponse,
  ) {
    const { sections, header } = this.createSectionsAndHeaders(
      structuralCAPEXReserve,
    );
    const stickLeftColumns: TableColumns = {
      structural_capex_user: {
        title: 'Structural CAPEX reserve',
        fill:
          (structuralCAPEXReserve.result.userAssumptionOther?.capexData
            .length ||
            structuralCAPEXReserve.result.assumptionOther?.capexData.length) > 0
            ? 'purple'
            : 'dark',
        sortable: false,
        type: 'text',
      },
    };
    this.reitTableData = {
      sections: sections,
      headers: header,
      data: this.createScenarioData(structuralCAPEXReserve),
      total: 0,
      stickLeftColumns: stickLeftColumns,
    };
  }

  onCellSave({
    value,
    row,
    column,
  }: {
    value: string;
    row: any;
    column: any;
  }): void {
    this.cellSaved.emit({
      value,
      row,
      column,
      reitTableData: this.reitTableData,
      methodName: 'Capex',
    });
  }

  createSectionsAndHeaders(data: IAssumptionProfileScenarioResponse) {
    const userAssumptionsOther =
      data.result.userAssumptionOther?.capexData ||
      data.result.assumptionOther?.capexData;
    const baseAssumptionsOther =
      data.result.baseAssumptionOther?.capexData ||
      data.result.userBaseAssumptionOther.capexData;

    const sections: TableColumnGroup[] = [
      { sectionTitle: 'userStructuralName', columnDefs: [], sticky: true },
    ];
    const header: TableColumns = {};
    if (userAssumptionsOther.length > 0) {
      sections.push({
        sectionTitle: data.result.userScenario.name,
        columnDefs: [this.colsDefName.userCapexName],
        spacing: true,
        spacingFill: 'light',
      });
      header[this.colsDefName.userStructuralName] = {
        title: 'Structural CAPEX reserve',
        fill: 'purple',
        sortable: true,
        type: 'text',
        sticky: true,
      };
      header[this.colsDefName.userCapexName] = {
        title: 'CAPEX',
        fill: 'purple',
        sortable: true,
        type: 'text',
        colspan: 1,
        style: { center: true },
      };
    }

    if (baseAssumptionsOther.length > 0) {
      sections.push({
        sectionTitle: data.result.userBaseScenario?.name || 'Base',
        columnDefs: [
          this.colsDefName.baseStructuralName,
          this.colsDefName.baseCapexName,
        ],
      });
      header[this.colsDefName.baseCapexName] = {
        title: 'CAPEX',
        fill: 'forecast',
        sortable: true,
        type: 'text',
        colspan: 1,
        style: { center: true },
      };
    }

    return { sections, header };
  }

  createScenarioData(data: IAssumptionProfileScenarioResponse): TableData[] {
    const userAssumptionsOther =
      data.result.userAssumptionOther?.capexData ||
      data.result.assumptionOther?.capexData;
    const baseAssumptionsOther =
      data.result.baseAssumptionOther?.capexData ||
      data.result.userBaseAssumptionOther.capexData;
    if (baseAssumptionsOther.length > 0) {
      return baseAssumptionsOther.map((item) => {
        const tableItem: TableData = {};
        if (userAssumptionsOther.length > 0) {
          let userItem = userAssumptionsOther.find(
            (x) => x.subPortfolioName === item.subPortfolioName,
          );
          if (!userItem) {
            userItem = { ...item, value: 0 };
          }
          tableItem[this.colsDefName.userStructuralName] = {
            data: userItem.subPortfolioName,
            fill: 'none',
          };
          tableItem[this.colsDefName.userCapexName] = {
            data:
              userItem.type === 'percentage'
                ? (userItem.value * 100).toFixed(2)
                : userItem.value.toFixed(2),
            editable: userItem.editable,
            type: userItem.type,
            rowId: userItem.rowId,
          };
        }
        tableItem[this.colsDefName.baseStructuralName] = {
          data: item.subPortfolioName,
        };
        tableItem[this.colsDefName.baseCapexName] = {
          data:
            item.type === 'percentage'
              ? (item.value * 100).toFixed(2)
              : item.value.toFixed(2),
          editable: item.editable,
          style: { center: true },
        };
        return tableItem;
      });
    }
    return [];
  }

  public popout(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/reits/table`], {
        queryParams: {
          name: 'other_assumptions',
          reitId: this.reitId,
          userScenarioId: this.userScenarioId,
        },
      }),
    );

    window.open(url, '_blank');
  }
}
