import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { snapshotManager } from '@datorama/akita';
import {
  ReitMetadataResponse,
  ReitProjectionService,
  ReitService,
} from '@kolytics/shared-api';
import { DropDownOption } from '@kolytics/shared-components';
import { autoDestroy, followDestroy } from '@kolytics/shared-functions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import { GRAPH_ID } from '@kolytics/types/graph';
import {
  ClientQuery,
  ClientSignalQuery,
} from 'apps/client/src/app/store/client.query';
import { ClientSignalState } from 'apps/client/src/app/store/client.state';
import { ClientStore } from 'apps/client/src/app/store/client.store';
import { ClientStoreService } from 'apps/client/src/app/store/client.store.service';
import { forkJoin, Observable } from 'rxjs';
import {
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { IReitDataResponse } from '../../interfaces';
import { ScenarioToOptionPipe } from '../../../../../../../../libs/shared-components/src/lib/pipes/scenario-to-option.pipe';
import { ProjectionDebtMaturitiesComponent } from './projection-debt-maturities/projection-debt-maturities.component';
import { ProjectionBalanceSheetStrengthComponent } from './projection-balance-sheet-strength/projection-balance-sheet-strength.component';
import { ProjectionAffoDpsYieldComponent } from './projection-affo-dps-yield/projection-affo-dps-yield.component';
import { ProjectionLflNriComponent } from './projection-lfl-nri/projection-lfl-nri.component';
import { ProjectionTarComponent } from './projection-tar/projection-tar.component';
import { SeparatorComponent } from '../../../../../../../../libs/shared-components/src/lib/components/separator/separator.component';
import { ProjectionGavComponent } from './projection-gav/projection-gav.component';
import { SingleSelectionComponent } from '../../../../../../../../libs/shared-components/src/lib/components/form/single-selection/single-selection.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-projections',
  templateUrl: './projections.component.html',
  styleUrls: ['./projections.component.scss'],
  standalone: true,
  imports: [
    SingleSelectionComponent,
    ProjectionGavComponent,
    SeparatorComponent,
    ProjectionTarComponent,
    ProjectionLflNriComponent,
    ProjectionAffoDpsYieldComponent,
    ProjectionBalanceSheetStrengthComponent,
    ProjectionDebtMaturitiesComponent,
    AsyncPipe,
    ScenarioToOptionPipe,
  ],
})
@autoDestroy()
@UntilDestroy()
export class ProjectionsComponent implements OnInit {
  reitId!: number;
  scenarioAId!: number;
  reit!: IReitDataResponse;
  selectedScenarioData!: any;

  scenarios$!: Observable<any>;
  selectedScenario$!: Observable<number>;

  projectionIsLoading$!: Observable<boolean>;

  updated = false;

  metadata!: ReitMetadataResponse;

  constructor(
    private clientFacade: ClientFacadeService,
    private store: ClientStoreService,
    private query: ClientQuery,
    private clientSignalQuery: ClientSignalQuery,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly service: ReitService,
    private reitProjectionService: ReitProjectionService,
    protected readonly cdr: ChangeDetectorRef,
    private clientStore: ClientStoreService,
  ) {}

  ngOnInit(): void {
    this.metadata = this.activatedRoute.snapshot.data.metadata.data;
    this.store.setProjectionLoading(true);
    this.projectionIsLoading$ = this.query.selectProjectionIsLoading$;
    this.scenarios$ = this.clientFacade
      .getReitId()
      .pipe(untilDestroyed(this))
      .pipe(
        switchMap((reitId: number) => this.clientFacade.getScenarios(reitId)),
      );

    this.selectedScenario$ = this.clientFacade
      .getReitId()
      .pipe(untilDestroyed(this))
      .pipe(
        switchMap(() => this.clientFacade.getSelectedScenario(this.reitId)),
        tap((scenarioAId) => {
          this.scenarioAId = scenarioAId;
          this.scenarios$.subscribe((data) => {
            const s = [...data];
            this.selectedScenarioData = s.filter(
              (f) => f.scenarioId === scenarioAId,
            )[0];
          });
        }),
      );

    this.clientFacade
      .getReitId()
      .pipe(
        untilDestroyed(this),
        tap((reitId: number) => (this.reitId = reitId)),
        switchMap((reitId: number) =>
          this.clientFacade.getSelectedScenario(reitId),
        ),
        switchMap((selectedScenarioId: number) =>
          this.reitProjectionService.apiReitReitIdProjectionScenarioIdGet(
            this.reitId,
            selectedScenarioId,
          ),
        ),
        tap(() => this.store.setProjectionLoading(false)),
      )
      .subscribe((data) => {
        this.reit = data;
      });

    this.clientFacade.localStorageUpdates$
      .pipe(untilDestroyed(this))
      .subscribe((state: ClientSignalState) => {
        if (!state) return;
        const oldState = this.clientSignalQuery.getValue();

        const selectedScenarioIds =
          this.query.getValue().selectedScenarios[this.reitId];
        const selectedAssumptionScenarioIds =
          this.query.getValue().selectedAssumptionScenarios[this.reitId];

        const scenarioIds = [
          ...selectedScenarioIds,
          ...selectedAssumptionScenarioIds,
        ];

        const pScenarioId = state.portfolio[this.reitId]?.scenarioId;
        const pUpdatedAt = state.portfolio[this.reitId]?.updatedAt;

        const cScenarioId = state.company[this.reitId]?.scenarioId;
        const cUpdatedAt = state.company[this.reitId]?.scenarioId;

        const oScenarioId = state.other[this.reitId]?.scenarioId;
        const oUpdatedAt = state.other[this.reitId]?.scenarioId;

        if (this.reitId && this.scenarioAId) {
          if (
            (scenarioIds.includes(pScenarioId) &&
              pUpdatedAt !== oldState.portfolio[this.reitId]?.updatedAt) ||
            (scenarioIds.includes(cScenarioId) &&
              cUpdatedAt !== oldState.company[this.reitId]?.updatedAt) ||
            (scenarioIds.includes(oScenarioId) &&
              oUpdatedAt !== oldState.other[this.reitId]?.updatedAt)
          ) {
            snapshotManager.setStoresSnapshot(
              { clientSignals: state },
              { skipStorageUpdate: true },
            );
            this.refreshData();
          }
        }
      });
  }

  refreshData() {
    this.store.setProjectionLoading(true);
    this.clientFacade
      .getReitId()
      .pipe(
        take(1),
        tap((reitId: number) => (this.reitId = reitId)),
        switchMap((reitId: number) =>
          this.clientFacade.getSelectedScenario(reitId).pipe(take(1)),
        ),
        switchMap((selectedScenarioId: number) =>
          this.reitProjectionService.apiReitReitIdProjectionScenarioIdGet(
            this.reitId,
            selectedScenarioId,
          ),
        ),
        tap(() => this.store.setProjectionLoading(false)),
      )
      .subscribe((data) => {
        this.reit = data;
        this.updated = true;
        setTimeout(() => {
          this.updated = false;
        }, 1000);
      });
  }

  onScenarioChange(option: any) {
    this.store.setProjectionLoading(true);
    this.store.setGraphLoading(GRAPH_ID.PROJECTIONS.PREM_DISC_TO_GAV, true);
    this.clientFacade.setSelectedScenario(this.reitId, option);
  }
}
