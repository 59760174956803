import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ReitOverviewScoreboardResponse,
  ReitOverviewService,
} from '@kolytics/shared-api';
import { autoDestroy, followDestroy } from '@kolytics/shared-functions';
import { ClientStoreService } from 'apps/client/src/app/store/client.store.service';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  IReitOverview,
  IReitOverviewPropertyValueByLocation,
  IReitOverviewPropertyValueByType,
} from '../../interfaces';

import { AsyncPipe } from '@angular/common';
import { OverviewTeamComponent } from './overview-team/overview-team.component';
import { OverviewCompanyComponent } from './overview-company/overview-company.component';
import { OverviewInvestabilityComponent } from './overview-investability/overview-investability.component';
import { OverviewPropertyValueTypeComponent } from './overview-property-value-type/overview-property-value-type.component';
import { OverviewPropertyValueLocationComponent } from './overview-property-value-location/overview-property-value-location.component';
import { OverviewReitUpdateComponent } from './overview-reit-update/overview-reit-update.component';
import { OverviewInvestmentComponent } from './overview-investment/overview-investment.component';
import { OverviewGvaComponent } from './overview-gva/overview-gva.component';
import { OverviewPerformanceComponent } from './overview-performance/overview-performance.component';
import { SeparatorComponent } from '../../../../../../../../libs/shared-components/src/lib/components/separator/separator.component';
import { OverviewScoreboardComponent } from './overview-scoreboard/overview-scoreboard.component';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  standalone: true,
  imports: [
    OverviewScoreboardComponent,
    SeparatorComponent,
    OverviewPerformanceComponent,
    OverviewGvaComponent,
    OverviewInvestmentComponent,
    OverviewReitUpdateComponent,
    OverviewPropertyValueLocationComponent,
    OverviewPropertyValueTypeComponent,
    OverviewInvestabilityComponent,
    OverviewCompanyComponent,
    OverviewTeamComponent,
    AsyncPipe,
  ],
})
@autoDestroy()
export class OverviewComponent implements OnInit {
  scoreboardData!: ReitOverviewScoreboardResponse;
  metadata!: { [key: string]: string };

  reitId!: number;
  websiteUrl: string = '';
  companyBackground: string = '';
  investabilityDescription: string = '';

  countryCode!: string;

  reitOverview!: IReitOverview;
  propertyByType!: IReitOverviewPropertyValueByType[];
  propertyByLocation!: IReitOverviewPropertyValueByLocation[];

  constructor(
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly reitOverviewService: ReitOverviewService,
  ) {}
  ngOnInit(): void {
    const data = this.activatedRoute.snapshot.data.overview;
    this.scoreboardData = data.data;
    this.metadata = data.metadata;
  }

  public reit$ = this.activatedRoute.parent?.params.pipe(
    map((params) => params.id),
    switchMap((id) => this.reitOverviewService.apiReitReitIdOverviewGet(id)),
    tap(({ reits }) => {
      this.countryCode = reits.reit.currency.code;

      this.reitId = reits.reit.id;
      this.websiteUrl = reits?.reit.websiteUrl ?? '';
      this.companyBackground = reits?.reit?.companyBackground ?? '';
      this.investabilityDescription =
        reits?.reit?.investabilityDescription ?? '';

      this.reitOverview = reits.reitOverview;
      this.propertyByType = reits.reitOverview.propertyValueByType;
      this.propertyByLocation = reits.reitOverview.propertyValueByLocation;
    }),
    followDestroy(this),
  );
}
