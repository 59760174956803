import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { formatPercentage } from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { EChartsOption } from 'echarts';
import {
  ISectorOverviewHistoricalReturnsResponse,
  ISectorOverviewInvestabilityResponse,
} from '../../../interfaces';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-historical-sector',
  templateUrl: './historical-sector.component.html',
  styleUrls: ['./historical-sector.component.scss'],
  standalone: true,
  imports: [SectionComponent, GraphComponent],
})
export class HistoricalSectorComponent implements OnInit {
  sector: string = '';

  @Input()
  set data(data: ISectorOverviewHistoricalReturnsResponse | undefined) {
    if (data) {
      this.initGraph(data);
    }
  }

  @Input()
  set sectorName(name: string | undefined) {
    if (name) {
      this.sector = name;
    }
  }

  @Input() public options: EChartsOption | undefined = {
    grid: {
      backgroundColor: '#fff',
      show: true,
      borderWidth: 0,
      height: '85%',
      // width: '95%',
      top: '10',
      left: '70',
    },
    animation: true,
    xAxis: {
      data: [],
    },
    yAxis: {
      axisLabel: {
        formatter: (value: any, index: number) => {
          return formatPercentage(value, 1);
        },
      },
    },
    series: [],
    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: function (params: any) {
        if (params.componentType && params.componentType === 'series') {
          return `<div style="background: ${
            params.color
          }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);">${
            params.seriesName
          }-${params.name}: (${formatPercentage(params.data, 1)})</div>`;
        }
        return '';
      },
    },
  };

  series: any[] = [];

  lines = [];

  titles: {
    name: string;
    color: string;
    disabled: boolean;
    opacity?: number;
  }[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  runFilter(
    data: {
      name: string;
      color: string;
      disabled: boolean;
      opacity?: number;
    }[],
  ): void {
    this.titles = data;
    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter((e) =>
      filter.includes(e.name.toLowerCase()),
    );

    (this.options as EChartsOption).series = [
      ...this.lines,
      ...(series as any),
    ];

    const temp = Object.assign({}, this.options);

    this.options = undefined;

    setTimeout(() => {
      this.options = temp;
      this.cdr.detectChanges();
    }, 0);
  }

  initGraph(data: ISectorOverviewHistoricalReturnsResponse): void {
    ((this.options as EChartsOption).xAxis as any).data =
      data.allSectorHistoricalReturns.map((o) => o.label);
    this.titles = [
      {
        name: `${this.sector} Dividend`,
        color: colorPallete[GraphId.SectorOverviewHistoricalSectorReturns][1],
        opacity: 0.5,
        disabled: false,
      },
      {
        name: `${this.sector} Capital Return`,
        color: colorPallete[GraphId.SectorOverviewHistoricalSectorReturns][1],
        disabled: false,
      },
      {
        name: 'All Sectors Dividend',
        color: colorPallete[GraphId.SectorOverviewHistoricalSectorReturns][0],
        opacity: 0.5,
        disabled: false,
      },
      {
        name: 'All Sectors Capital Return',
        color: colorPallete[GraphId.SectorOverviewHistoricalSectorReturns][0],
        disabled: false,
      },
    ];

    this.series = [
      {
        data: data.allSectorHistoricalReturns.map((obj) => obj.dividend),
        type: 'bar',
        stack: 'one',
        color: '#8c648c80',
        name: 'All Sectors Dividend',
        itemStyle: {
          borderRadius: 2,
        },
        barWidth: 14,
      },
      {
        data: data.allSectorHistoricalReturns.map((obj) => obj.capitalReturn),
        type: 'bar',
        stack: 'one',
        color: '#8c648c',
        name: 'All Sectors Capital Return',
        itemStyle: {
          borderRadius: 2,
        },
      },
      {
        data: data.selectedSectorHistoricalReturns.map((obj) => obj.dividend),
        type: 'bar',
        stack: 'two',
        color: '#d2b96480',
        name: `${this.sector} Dividend`,
        itemStyle: {
          borderRadius: 2,
        },
        barWidth: 14,
      },
      {
        data: data.selectedSectorHistoricalReturns.map(
          (obj) => obj.capitalReturn,
        ),
        type: 'bar',
        stack: 'two',
        color: '#d2b964',
        name: `${this.sector} Capital Return`,
        itemStyle: {
          borderRadius: 2,
        },
      },
    ];
    this.runFilter(this.titles);
  }
}
