<klt-section title="Net cash flows from operating activities" type="none">
  <div class="graph-box">
    <klt-graph
      [loading]="clientQuery.selectFinancialIsLoading$ | async"
      (filter)="runFilter($event)"
      [options]="options"
      [titles]="titles"
      height="calc(var(--size--spacing-x67-5) + var(--size--spacing-x2-5))"
    ></klt-graph>
  </div>
</klt-section>
