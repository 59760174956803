import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { KltModalService } from '@kolytics/shared-components';
import { take } from 'rxjs/operators';
import { SelectProfileDialogComponent } from '../../dialogs';
import { IScenarios } from '../../interfaces';
import { OtherTableComponent } from './other-table/other-table.component';
import { OtherCompareComponent } from './other-compare/other-compare.component';

@Component({
    selector: 'klt-other-assumptions',
    templateUrl: './other-assumptions.component.html',
    styleUrls: ['./other-assumptions.component.scss'],
    standalone: true,
    imports: [OtherCompareComponent, OtherTableComponent],
})
export class OtherAssumptionsComponent implements OnInit {
  scenarios!: IScenarios;

  tableData: any;
  constructor(protected readonly kltModalService: KltModalService) {}

  ngOnInit(): void {}

  public openDialog(): void {
    this.kltModalService
      .open(SelectProfileDialogComponent, {
        data: {},
      })
      .afterClosed.pipe(take(1))
      .subscribe((value) => {
        this.tableData = value;
      });
  }

  onSelectScenarios(value: IScenarios): void {
    this.scenarios = value;
  }
}
