import { Component, OnInit } from '@angular/core';
import { DropDownOption, GroupedDropDownOption } from '../../interfaces';
import { TextEditorComponent } from '../../components/text-editor/text-editor.component';
import { IconComponent } from '../../../../../shared-assets/src/lib/components/icon/icon.component';
import { ChipComponent } from '../../components/form/chip/chip.component';
import { DropdownOptionsComponent } from '../../components/form/dropdown-options/dropdown-options.component';
import { CheckboxButtonComponent } from '../../components/form/checkbox-button/checkbox-button.component';
import { ButtonComponent } from '../../components/common/button/button.component';
import { CheckboxComponent } from '../../components/form/checkbox/checkbox.component';
import { SingleSelectionComponent } from '../../components/form/single-selection/single-selection.component';
import { InputExpandComponent } from '../../components/form/input-expand/input-expand.component';
import { InputComponent } from '../../components/form/input/input.component';

@Component({
    selector: 'klt-display-components',
    templateUrl: './display-components.component.html',
    styleUrls: ['./display-components.component.scss'],
    standalone: true,
    imports: [
        InputComponent,
        InputExpandComponent,
        SingleSelectionComponent,
        CheckboxComponent,
        ButtonComponent,
        CheckboxButtonComponent,
        DropdownOptionsComponent,
        ChipComponent,
        IconComponent,
        TextEditorComponent,
    ],
})
export class DisplayComponentsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  singleSelectionInputOptions: DropDownOption[] = [
    { label: 'Test text 1', value: '1' },
    { label: 'Test text 2', value: '2' },
    { label: 'Test text 3', value: '3' },
    { label: 'Test text 4', value: '4' },
    { label: 'Test text 5', value: '5' },
  ];

  dropdownMultiOptions: GroupedDropDownOption[] = [
    {
      group: 'COMPARISON SCENARIOS',
      options: [
        { label: 'Base', value: 'base', selected: false },
        { label: 'Good', value: 'good', selected: false },
        { label: 'Poor', value: 'poor', selected: false },
      ],
    },
    {
      group: 'SECTIONS',
      options: [
        { label: 'Overview', value: 'overview', selected: false },
        { label: 'Option2', value: 'option2', selected: false },
      ],
    },
  ];

  dropdownSingleOptions: DropDownOption[] = [
    { label: 'Hammerson', value: 'hammerson', selected: false },
    { label: 'Land Securities', value: 'landSecurities', selected: false },
    { label: 'British Land', value: 'britishLand', selected: false },
    {
      label: 'Kite Realty Group Trust',
      value: 'KiteRealtyGroupTrust',
      selected: false,
    },
    { label: 'Hammerson', value: 'hammerson', selected: false },
    { label: 'Land Securities', value: 'landSecurities', selected: false },
  ];
}
