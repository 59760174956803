import { Component, Input } from '@angular/core';
import { MinusRemovalPipe } from '../../pipes/minus-removal.pipe';
import { LocaleNumberPipe } from '../../pipes/locale-number.pipe';

import { NegativeFormatterDirective } from '../../directives/negative-formatter.directive';
import { NegativeFormatterPipe } from '../../pipes/negative-formatter.pipe';

@Component({
  selector: 'klt-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
  standalone: true,
  imports: [
    NegativeFormatterDirective,
    LocaleNumberPipe,
    MinusRemovalPipe,
    NegativeFormatterPipe,
  ],
})
export class NumberComponent {
  @Input() value?: number;
  @Input() decimalPoints?: number;
  @Input() minimumDecimalPoints: number = 0;
  @Input() locale?: string;
}
