import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { formatPercentage } from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { EChartsOption } from 'echarts/types/dist/shared';

import {
  ISectorOverviewMCapResponse,
  ISectorOverviewPortfolioCompositionResponse,
} from '../../../interfaces';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-portfolio-composition',
  templateUrl: './portfolio-composition.component.html',
  styleUrls: ['./portfolio-composition.component.scss'],
  standalone: true,
  imports: [SectionComponent, GraphComponent],
})
export class PortfolioCompositionComponent implements OnInit {
  labelSize = '10px';
  labelMargin = 22;
  gridLeft = 60;
  legendSize = '20px';

  loading: boolean = true;
  series: any[] = [];
  titles: { name: string; color: string; disabled: boolean }[] = [];

  @Input()
  set sector(sector: ISectorOverviewPortfolioCompositionResponse | undefined) {
    if (sector) {
      this.initGraph(sector);
    }
  }

  options: any | undefined = {
    grid: {
      containLabel: true,
      backgroundColor: '#fafafa',
      show: true,
      borderColor: '#fff',
      borderWidth: 0,
      // height: '80%',
      // width: '95%',
      top: '10',
      // left: '70',
      // containLabel: true
    },
    xAxis: {
      type: 'value',
      // splitArea: {
      //   show: true,
      //   areaStyle: {
      //     color: ['#fafafa', '#fff'],
      //     opacity: 0.5,
      //   },
      // },
      axisLabel: {
        // margin: 22,
        formatter: (value: any, index: number) => {
          return formatPercentage(value, 2);
        },
      },
    },
    yAxis: {
      axisLine: {
        onZero: false,
      },
      type: 'category',
      axisLabel: {
        formatter: (value: any, index: number) => {
          //value = value.split(/(\s+)/).join('\r\n');
          return value;
        },
      },
    },
    series: [],
    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params: any) {
        if (params.componentType && params.componentType === 'series') {
          return `<div style="background: ${
            params.color
          }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);">${params.seriesName.toUpperCase()}-${
            params.name
          }: (${formatPercentage(params.data, 2)})</div>`;
        }
        return '';
      },
    },
  };

  lines = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  runFilter(data: { name: string; color: string; disabled: boolean }[]): void {
    this.titles = data;
    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter((e) =>
      filter.includes(e.name.toLowerCase()),
    );
    (this.options as EChartsOption).series = [
      ...this.lines,
      ...(series as any),
    ];

    const temp = Object.assign({}, this.options);

    // this.options = undefined;

    setTimeout(() => {
      this.options = temp;
      this.loading = false;
      this.cdr.detectChanges();
    }, 0);
  }

  initGraph(sector: ISectorOverviewPortfolioCompositionResponse) {
    this.titles = [];
    this.series = [];

    ((this.options as EChartsOption).yAxis as any).data = sector.reits;
    sector.data.forEach((s, index) => {
      //set titles
      this.titles.push({
        name: s.sectorName.toLowerCase(),
        color:
          colorPallete[GraphId.SectorOverviewPortfolioComposition][
            index %
              colorPallete[GraphId.SectorOverviewPortfolioComposition].length
          ],
        disabled: false,
      });

      //set data
      this.series.push({
        data: s.values,
        type: 'bar',
        color:
          colorPallete[GraphId.SectorOverviewPortfolioComposition][
            index %
              colorPallete[GraphId.SectorOverviewPortfolioComposition].length
          ],
        name: s.sectorName.toLowerCase(),
      });
    });
    this.series.reverse();
    this.runFilter(this.titles);
  }
}
