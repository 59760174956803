@if (!pathStyle) {
  <svg-icon
    [applyClass]="true"
    class="icon color-{{ color }} size-{{ size }}"
    [stretch]="true"
    src="../../assets/icons/{{ icon }}.svg"
  ></svg-icon>
}
@if (pathStyle) {
  <svg-icon
    [applyClass]="true"
    class="svg-path-{{ color }} size-{{ size }}"
    [stretch]="true"
    src="../../assets/icons/{{ icon }}.svg"
  ></svg-icon>
}
