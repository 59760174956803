import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import { AssumptionProfileItem } from '../../../interfaces';
import { IconComponent } from '../../../../../../../../../libs/shared-assets/src/lib/components/icon/icon.component';
import { ReitAssumptionService, ReitService } from '@kolytics/shared-api';

@Component({
  selector: 'app-assumption-item',
  templateUrl: './assumption-item.component.html',
  styleUrls: ['./assumption-item.component.scss'],
  standalone: true,
  imports: [IconComponent],
})
export class AssumptionItemComponent implements OnInit {
  @Input()
  assumptionProfileItem: AssumptionProfileItem = {} as AssumptionProfileItem;

  @Input() selectedScenarioId?: number;

  @Input() disabled: boolean = false;
  checked: boolean = false;

  @Output() assumptionSelected = new EventEmitter<AssumptionProfileItem>();

  constructor(
    private router: Router,
    private reitService: ReitService,
    private reitAssumptionService: ReitAssumptionService,
    private clientFacade: ClientFacadeService,
  ) {}

  ngOnInit(): void {}

  handleClickItem(): void {
    if (this.selectedScenarioId === this.assumptionProfileItem.scenarioId) {
      return;
    }
    this.checked = !this.checked;
    this.assumptionSelected.emit(this.assumptionProfileItem);
    const path = document.location.pathname;
    const reitId = `${path}`.split('/')[2];

    if (reitId && this.assumptionProfileItem.scenarioId) {
      this.reitAssumptionService
        .apiReitReitIdAssumptionPost(Number(reitId), {
          reitId: reitId,
          isDefaultScenario: this.assumptionProfileItem.isDefaultScenario,
          initialScenarioId: this.assumptionProfileItem.scenarioId,
          scenarioName: this.assumptionProfileItem.scenarioName,
        })
        .subscribe((data) => {
          this.router.navigate([
            `/reits/${reitId}/assumptions/sub/${data.result.id}/portfolio`,
          ]);

          this.clientFacade.addNewScenario(Number(reitId), {
            name: data.result.name,
            isDefault: false,
            scenarioId: data.result.id,
            initialScenarioId: data.result.initialScenarioId,
          });

          this.clientFacade.setSelectedLeftScenarioId(
            Number(reitId),
            data.result.id,
          );
          this.clientFacade.setSelectedRightScenarioId(
            Number(reitId),
            this.assumptionProfileItem.scenarioId,
          );
          this.clientFacade.setSelectedScenarios(Number(reitId), [
            data.result.id,
            this.assumptionProfileItem.scenarioId,
          ]);
          this.clientFacade.setSelectedAssumptionScenarios(Number(reitId), [
            data.result.id,
            this.assumptionProfileItem.scenarioId,
          ]);
        });
    }
  }
}
