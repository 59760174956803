import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetatagsService {
  constructor(private title: Title) {}

  updateTitle(title: any) {
    if (title) {
      this.title.setTitle(`Kolytics | ${title}`);
    } else {
      this.title.setTitle(`Kolytics`);
    }
  }
}
