import { Component, Input } from '@angular/core';
import { MinusRemovalPipe } from '../../pipes/minus-removal.pipe';
import { LocalePercentagePipe } from '../../pipes/locale-percentage.pipe';

import { NegativeFormatterDirective } from '../../directives/negative-formatter.directive';
import { NegativeFormatterPipe } from '../../pipes/negative-formatter.pipe';

@Component({
  selector: 'klt-percentage',
  templateUrl: './percentage.component.html',
  styleUrls: ['./percentage.component.scss'],
  standalone: true,
  imports: [
    NegativeFormatterDirective,
    LocalePercentagePipe,
    MinusRemovalPipe,
    NegativeFormatterPipe,
  ],
})
export class PercentageComponent {
  @Input() value?: number;
  @Input() decimalPoints?: number;
  @Input() locale?: string;
}
