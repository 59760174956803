<klt-modal
  [modalTitle]="data?.modalTitle ?? 'Remove REIT'"
  (closeModal)="onClose()"
>
  <div class="remove-modal-content">
    <div class="content-row title">
      <h3>
        <b>{{ data?.title }}</b>
      </h3>
    </div>
    <div class="content-row confirm" [innerHTML]="data?.description"></div>
    <div class="content-row remove-button">
      <klt-button class="error" type="primary" (click)="onRemove()"
        >REMOVE REIT</klt-button
      >
    </div>
    <div class="content-row cancel-button">
      <b (click)="onClose()">Cancel</b>
    </div>
  </div>
</klt-modal>
