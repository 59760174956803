<klt-modal modalTitle="Reset to base scenario" (closeModal)="onClose()">
  <div class="reset-scenario-modal-content">
    <div class="content-row confirm">
      Are you sure you want to reset your profile <br /><br />
      to the base scenario assumption?
    </div>
    <div class="content-row confirm-button">
      <klt-button [loading]="loading" type="primary" (click)="onReset()"
        >RESET SCENARIO</klt-button
      >
    </div>
    <div class="content-row cancel-button">
      <b (click)="onClose()">Cancel</b>
    </div>
  </div>
</klt-modal>
