import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { EChartsOption } from 'echarts';
import {
  ISectorProjLabelsResponse,
  ISectorProjLflNriSeriesResponse,
  ISeries,
} from '../../../interfaces';
import { formatNumber } from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { getColNameByYear } from '../../../utils';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-sectors-projections-lfl-nri',
  templateUrl: './sectors-projections-lfl-nri.component.html',
  styleUrls: ['./sectors-projections-lfl-nri.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SectionComponent, GraphComponent],
})
export class SectorsProjectionsLflNriComponent implements OnInit {
  @Input() height = '415px';
  @Input() selectedScenarioName = 'base';

  @Input()
  //marketRateGrowth: { series: ISectorProjSeriesResponse[]; label: ISectorProjLabelsResponse[]; } | undefined
  set sector(lflNri: Record<string, any>[] | undefined) {
    if (lflNri) {
      this.reset(lflNri);
    }
  }

  @Input() loading: boolean | null = false;
  @Input() public options: EChartsOption | undefined = {
    grid: {
      show: true,
      borderWidth: 0,
      height: '86%',
      width: '90%',
      top: '6',
      left: '70',
    },
    xAxis: {
      type: 'category',
      data: [],
      boundaryGap: false,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        margin: 22,
      },
      splitArea: {
        show: true,
      },
      axisPointer: {
        show: true,
        type: 'none',
        label: {
          show: false,
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: any) => {
          return formatNumber(value, 1);
        },
        margin: 20,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
      },
    },
    series: [],
    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: function (params: any) {
        if (params.componentType && params.componentType === 'series') {
          return `<div style='background: ${
            params.color
          }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);'>${params.seriesName.toUpperCase()}-${
            params.name
          }: (${formatNumber(params.value, 1)})</div>`;
        }
        return '';
      },
    },
  };

  public series: any[] = [];

  public lines = [];

  public titles: {
    name: string;
    color: string;
    disabled: boolean;
    text?: string;
  }[] = [];

  constructor(protected readonly cdr: ChangeDetectorRef) {}
  public ngOnInit(): void {
    this.runFilter(this.titles);
  }

  protected _keys: string[] = [];
  private excludedKeys = ['year', 'label', 'base_year'];
  protected getTitles(): {
    name: string;
    color: string;
    disabled: boolean;
    small?: boolean;
  }[] {
    return this._keys.map((key, index) => {
      return {
        name: key.toUpperCase(),
        color:
          colorPallete[GraphId.SectorProjectionIndexedMarketRateGrowth][
            index %
              colorPallete[GraphId.SectorProjectionIndexedMarketRateGrowth]
                .length
          ],
        disabled: false,
        small: true,
      };
    });
  }

  protected setDataForXAxis(
    options: EChartsOption | undefined,
    value: { [name: string]: number }[],
  ) {
    const labels = value.map((x) => x.label);
    if (labels.length > 0) {
      ((options as EChartsOption).xAxis as any).data = labels;
    } else {
      const years = value.map((x) => getColNameByYear(x.year));
      ((options as EChartsOption).xAxis as any).data = years;
    }
  }

  protected reset(value: Record<string, number>[]): void {
    this.setDataForXAxis(this.options, value);

    this._keys = Object.entries(value[0])
      .map((item) => item[0])
      .filter((e) => !this.excludedKeys.includes(e));

    this.series = [];

    this._keys.forEach((key, index) => {
      this.series.push(...this.getSeries(value, key, index));
    });

    this.titles = this.getTitles();
    this.runFilter(this.titles);
  }

  protected getSeries(
    value: Record<string, number>[],
    name: string,
    index: number,
  ) {
    return [
      {
        data: value.map((e) => (e.year <= e.base_year ? e[name] : undefined)),
        type: 'line',
        smooth: true,
        showSymbol: false,
        color:
          colorPallete[GraphId.SectorProjectionIndexedMarketRateGrowth][
            index %
              colorPallete[GraphId.SectorProjectionIndexedMarketRateGrowth]
                .length
          ],
        z: 2,
        name: name.toUpperCase(),
      },
      {
        data: value.map((e) => (e.year >= e.base_year ? e[name] : undefined)),
        type: 'line',
        smooth: true,
        showSymbol: false,
        color:
          colorPallete[GraphId.SectorProjectionIndexedMarketRateGrowth][
            index %
              colorPallete[GraphId.SectorProjectionIndexedMarketRateGrowth]
                .length
          ],
        lineStyle: {
          type: 'dashed',
        },
        z: 2,
        name: name.toUpperCase(),
      },
    ];
  }

  public runFilter(
    data: { name: string; color: string; disabled: boolean }[],
  ): void {
    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter((e) =>
      filter.includes(e.name.toLowerCase()),
    );
    (this.options as EChartsOption).series = [
      ...(series as any),
      ...this.lines,
    ];
    const temp = Object.assign({}, this.options);
    //this.options = undefined;
    setTimeout(() => {
      this.options = temp;
      this.cdr.detectChanges();
    }, 0);
  }
}
