import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { IconComponent } from '../../../../../../shared-assets/src/lib/components/icon/icon.component';

@Component({
    selector: 'klt-toggle-switch',
    templateUrl: './toggle-switch.component.html',
    styleUrls: ['./toggle-switch.component.scss'],
    standalone: true,
    imports: [IconComponent],
})
export class ToggleSwitchComponent {
  private _checked: boolean = false;
  private _disabled: boolean = false;
  private _reverse: boolean = false;

  @Input() size = 'medium';
  @Input() labelOn = '';
  @Input() labelOff = '';

  @Output() change = new EventEmitter<boolean>();

  @Input() set checked(v: boolean) {
    this._checked = v;
  }

  get checked() {
    return this._checked;
  }

  @Input() set disabled(v: boolean) {
    this._disabled = v;
  }

  get disabled() {
    return this._disabled;
  }

  @Input() set reverse(v: boolean) {
    this._reverse = v;
  }

  get reverse() {
    return this._reverse;
  }

  @HostListener('click')
  onToggle() {
    if (this.disabled) {
      return;
    }
    this.checked = !this.checked;
    this.change.emit(this.checked);
  }
}
