<div
  [formGroup]="form"
  class="wrapper {{ inputClass }}"
  [ngClass]="getFormClasses()"
>
  <div class="date-fields">
    <input
      class="input"
      type="number"
      min="1"
      max="31"
      [kltInputNumberLimit]="31"
      [placeholder]="
        formStates.focused || dateInputControl.fullDate ? 'DD' : ''
      "
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="dateInputControl.date"
      (ngModelChange)="onDateFieldChange()"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
    @if (formStates.focused || dateInputControl.fullDate) {
      <span>/</span>
    }
    <input
      class="input"
      type="number"
      min="1"
      max="12"
      [kltInputNumberLimit]="12"
      [placeholder]="
        formStates.focused || dateInputControl.fullDate ? 'MM' : ''
      "
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="dateInputControl.month"
      (ngModelChange)="onDateFieldChange()"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
    @if (formStates.focused || dateInputControl.fullDate) {
      <span>/</span>
    }
    <input
      class="input"
      type="number"
      min="1"
      max="9999"
      [kltInputNumberLimit]="9999"
      [placeholder]="
        formStates.focused || dateInputControl.fullDate ? 'YYYY' : ''
      "
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="dateInputControl.year"
      (ngModelChange)="onDateFieldChange()"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
  </div>
  <div class="notch">
    <div class="lead"></div>
    <div class="main">
      <label>{{ label }}</label>
    </div>
    <div class="trail"></div>
  </div>
  <div class="icon">
    <klt-button
      type="icon"
      rightIcon="calendar"
      (click)="picker.open()"
    ></klt-button>
    @if (allowClear) {
      <klt-button
        type="icon"
        rightIcon="close"
        (click)="clearDate($event)"
      ></klt-button>
    }
  </div>
  <div class="picker">
    <input
      matInput
      [matDatepicker]="picker"
      placeholder="Choose a date"
      [value]="dateValue"
      (dateChange)="onSelectCalendarDate($event)"
    />
    <mat-datepicker #picker [xPosition]="'end'"></mat-datepicker>
  </div>
</div>

@if (formStates.error) {
  <div class="error" [@dropdownAnimation]>
    <span>Please enter a valid date.</span>
  </div>
}
@if (info) {
  <div class="info">
    <span>{{ info }}</span>
  </div>
}
