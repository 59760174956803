import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'equal',
  standalone: true,
  pure: false,
})
export class EqualPipe implements PipeTransform {
  transform(value: any, valueToCheck: any): boolean {
    return value === valueToCheck;
  }
}
