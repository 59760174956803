import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { EChartsOption } from 'echarts';
import { IReitDataResponse } from '../../../interfaces';
import { BaseGraphComponent } from 'apps/client/src/app/shared/components/base-graph/base-graph.component';
import { CurrencyPipe, AsyncPipe } from '@angular/common';
import { ClientQuery } from 'apps/client/src/app/store/client.query';
import { ReitMetadataResponse } from '@kolytics/shared-api';
import { formatCurrency } from '@kolytics/shared-functions';
import { GraphId } from '@kolytics/types/graph';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-income-statement-interest-costs',
  templateUrl: './income-statement-interest-costs.component.html',
  styleUrls: ['./income-statement-interest-costs.component.scss'],
  standalone: true,
  imports: [SectionComponent, GraphComponent, AsyncPipe],
})
export class IncomeStatementInterestCostsComponent extends BaseGraphComponent {
  @Input() metadata!: ReitMetadataResponse;
  @Input() height =
    'calc(var(--size--spacing-x67-5) + var(--size--spacing-x2-5))';

  @Input()
  public set reit(value: IReitDataResponse) {
    if (value) {
      this._reit = value;
      this.reset(value.reits.reitFinancials.interestCostsGraph);
    }
  }

  @Input() public _options: EChartsOption | undefined = {
    grid: {
      show: true,
      borderWidth: 0,
      containLabel: true,
      top: 10,
      right: 10,
      bottom: 0,
      left: 5,
    },
    xAxis: {
      type: 'category',
      data: ['17A', '18A', '19A', '20A', '21F', '22F', '23F', '24F', '25F'],
      boundaryGap: false,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        margin: 22,
        formatter: (value: any) => {
          if (value === '17A') {
            return '     17A';
          }
          if (value === '25F') {
            return '25F       ';
          }
          return value;
        },
      },
      splitArea: {
        show: true,
      },
      axisPointer: {
        show: true,
        type: 'none',
        label: {
          show: false,
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: any, index: number) => {
          return formatCurrency(
            value,
            0,
            this.metadata.culture,
            this.metadata.currencyCode,
            undefined,
            this.metadata.currencyFormat,
          );
        },
        margin: 20,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
      },
    },
    series: [],
    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: (params: any) => {
        return this.tooltipFormatter(
          params,
          0,
          this.metadata.culture,
          this.metadata.currencyCode,
          undefined,
          this.metadata.currencyFormat,
        );
      },
    },
  };

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected currencyPipe: CurrencyPipe,
    public clientQuery: ClientQuery,
  ) {
    super(cdr, currencyPipe);
    this.graphType = 'line';
    this.options = this._options;
    this.graphId = GraphId.FinancialInterestCosts;
  }
}
