<div class="wrapper--pagination">
  <klt-button
    type="icon"
    leftIcon="left"
    [disabled]="page === 1"
    (click)="onPrevious()"
  ></klt-button>
  <input
    #inputElement
    class="index"
    [value]="page"
    (input)="onChange($event)"
    (keydown.enter)="onSubmit()"
  />
  <klt-button
    type="icon"
    leftIcon="right"
    [disabled]="page >= totalPages"
    (click)="onNext()"
  ></klt-button>
  <span class="total">of {{ totalPages }}</span>
</div>
