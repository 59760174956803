import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DropDownAction,
  DropDownOption,
  KltModalService,
} from '@kolytics/shared-components';
import { followDestroy } from '@kolytics/shared-functions';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import { ClientSignalStore } from 'apps/client/src/app/store/client.store';
import { KltToastService } from 'libs/shared-components/src/lib/services/toast/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import {
  IAssumptionProfileScenarioResponse,
  IReitData,
} from '../../../interfaces';
import {
  CreateScenarioModalComponent,
  RemoveScenarioModalComponent,
  RenameScenarioModalComponent,
  ResetScenarioModalComponent,
  ResetToLastSaveModalComponent,
} from '../../../modals';
import { ReitStoreService } from '../../../services/reit-store.service';
import { AssumptionBaseComponent } from '../../../utils/components/assumption/assumption-base-component';
import { OtherMarketTableComponent } from './other-market-table/other-market-table.component';
import { OtherErvTableComponent } from './other-erv-table/other-erv-table.component';
import { OtherCapexTableComponent } from './other-capex-table/other-capex-table.component';
import { DropdownActionsComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/form/dropdown-actions/dropdown-actions.component';
import { ButtonComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { DropdownWrapperComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/form/dropdown-wrapper/dropdown-wrapper.component';
import { LoadingSpinnerComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/loading-spinner/loading-spinner.component';
import { LinkActionComponent } from '../../../components/link-action/link-action.component';
import { TableToolbarDirective } from '../../../../../../../../../libs/shared-components/src/lib/components/table/table.component';
import {
  ReitAssumptionService,
  ReitService,
  UserAssumptionsService,
} from '@kolytics/shared-api';

@UntilDestroy()
@Component({
  selector: 'klt-other-table',
  templateUrl: './other-table.component.html',
  styleUrls: ['./other-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TableToolbarDirective,
    LinkActionComponent,
    LoadingSpinnerComponent,
    DropdownWrapperComponent,
    ButtonComponent,
    DropdownActionsComponent,
    OtherCapexTableComponent,
    OtherErvTableComponent,
    OtherMarketTableComponent,
  ],
})
export class OtherTableComponent
  extends AssumptionBaseComponent
  implements OnInit
{
  public more: boolean = false;
  public moreOptions: DropDownOption[] = [
    //{ label: 'Reset to last save', value: 0 },
    { label: 'Reset scenario', value: 1 },
    { label: 'Rename scenario', value: 2 },
    { label: 'Delete scenario', value: 3 },
  ];
  public moreActions: DropDownAction[] = [
    {
      label: 'Create scenario',
      value: 'create',
      icon: 'plus',
      color: 'cta',
    },
  ];
  public changeCount = 2;

  public reitTableData!: IReitData;
  assumptionOther!: IAssumptionProfileScenarioResponse;
  @Input() showToolbar = true;
  @Input() reitId!: number;
  @Input() scenarioAId!: number;
  @Input() scenarioBId!: number;

  private _updatedData: any;
  get updatedData(): any {
    return this._updatedData;
  }
  @Input() set updatedData(v: any) {
    this._updatedData = v;

    if (v) {
      this.assumptionOther = v;
    }
  }

  constructor(
    protected readonly kltModalService: KltModalService,
    protected readonly kltToastService: KltToastService,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected reitService: ReitService,
    protected ref: ChangeDetectorRef,
    protected spinner: NgxSpinnerService,
    protected reitStoreService: ReitStoreService,
    protected cdr: ChangeDetectorRef,
    protected clientFacade: ClientFacadeService,
    protected clientSignalStore: ClientSignalStore,
    protected userAssumptionService: UserAssumptionsService,
    protected reitAssumptionService: ReitAssumptionService,
  ) {
    super(
      kltModalService,
      kltToastService,
      router,
      reitService,
      userAssumptionService,
      spinner,
      reitStoreService,
      cdr,
      reitAssumptionService,
    );
  }

  protected valueOfReitId(): string {
    return this.reitId.toString();
  }
  protected valueOfUserScenarioId(): string | number {
    return this.scenarioAId;
  }

  public ngOnInit(): void {
    this.setReitId();
    this.initiateAssumptionScenario();
    this.listeningStorageUpdates(
      this.reitId,
      this.scenarioAId,
      'other',
      () => this.refreshData(this.scenarioAId, false),
      () => this.initiateAssumptionScenario(false),
    );
  }

  setReitId() {
    const queryReitId = this.activatedRoute.snapshot.params['reitId'];
    const scenarioAId = this.activatedRoute.snapshot.params['scenarioA'];
    const scenarioBId = this.activatedRoute.snapshot.params['scenarioB'];
    this.isPopup = queryReitId && scenarioAId && scenarioBId;

    if (this.isPopup) {
      this.reitId = queryReitId;
      this.scenarioAId = scenarioAId;
      this.scenarioBId = scenarioBId;
    } else {
      const path = document.location.pathname;
      const pathSplit = path.split('/');
      if (!this.reitId) {
        this.reitId = Number(pathSplit[2]);
      }
      if (!this.scenarioAId) {
        this.scenarioAId = Number(pathSplit[pathSplit.length - 2]);
      }
    }
  }

  popout(): void {
    const key = 'other';
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        `/reits/popout/other/${this.reitId}/${this.scenarioAId}/${this.scenarioBId}`,
      ]),
    );

    window.open(
      url,
      '_blank',
      'location=yes,height=800,width=600,scrollbars=yes,status=yes',
    );
  }

  public toggleMore(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.more = !this.more;
  }

  public save(): void {
    if (this.changeCount > 0) {
      this.kltToastService.success('Changes save', 4000);
      this.changeCount = 0;
    }
  }

  public solveOption(option: DropDownOption): void {
    switch (option.value) {
      case 0:
        this.resetToLastSave();
        break;
      case 1:
        this.resetScenario();
        break;
      case 2:
        this.renameScenario();
        break;
      case 3:
        this.deleteScenario();
        break;
    }
  }

  public solveAction(action: DropDownAction): void {
    switch (action.value) {
      case 'create':
        this.createScenario();
        break;
    }
  }

  protected resetToLastSave(): void {
    this.kltModalService
      .open(ResetToLastSaveModalComponent)
      .afterClosed.pipe(
        first(),
        tap((value: boolean) => {
          if (value) {
            this.kltToastService.success('Last save reset', 4000);
          }
        }),
        followDestroy(this),
      )
      .subscribe();
  }

  protected resetScenario(): void {
    this.kltModalService
      .open(ResetScenarioModalComponent, { data: { reitId: this.reitId } })
      .afterClosed.pipe(first(), followDestroy(this))
      .subscribe(() => {
        this.initiateAssumptionScenario(false);
        this.clientSignalStore.update((state) => ({
          other: {
            ...state.other,
            [this.reitId]: {
              scenarioId: Number(this.scenarioAId),
              updatedAt: Date.now(),
              resetted: true,
            },
          },
        }));
      });
  }

  protected renameScenario(): void {
    this.kltModalService
      .open(RenameScenarioModalComponent, { data: { reitId: this.reitId } })
      .afterClosed.pipe(
        first(),
        tap((data: { name: string; scenarioId: string }) => {
          if (data.name) {
            if (data.name === 'REIT') {
              this.kltToastService.error(
                'This name is already used for a scenario',
                4000,
              );
            } else {
              this.updateSectionTitle(data);
              this.kltToastService.success('Scenario renamed', 4000);
            }
          }
        }),
        followDestroy(this),
      )
      .subscribe();
  }

  protected deleteScenario(): void {
    this.kltModalService
      .open(RemoveScenarioModalComponent, { data: { reitId: this.reitId } })
      .afterClosed.pipe(
        first(),
        tap((value: boolean) => {
          if (value) {
            this.kltToastService.success('Scenario deleted', 4000);
            this.router.navigate([`/reits/${this.reitId}/assumptions`]);
          }
        }),
        followDestroy(this),
      )
      .subscribe();
  }

  protected createScenario(): void {
    this.kltModalService
      .open(CreateScenarioModalComponent)
      .afterClosed.pipe(
        first(),
        tap((value: string | undefined) => {
          if (value) {
            if (value === 'REIT') {
              this.kltToastService.error(
                'This name is already used for a scenario',
                4000,
              );
            } else {
              this.router.navigate([
                `/reits/${this.reitId}/assumptions/create/${value}`,
              ]);
            }
          }
        }),
        followDestroy(this),
      )
      .subscribe();
  }

  updateSectionTitle(data: { name: string; scenarioId: string }) {
    if (this.assumptionOther) {
      this.assumptionOther.result.userScenario.name = data.name;
      this.clientFacade.renameScenario(this.reitId, data.name, data.scenarioId);
      this.refreshData(this.scenarioAId, false), this.ref.markForCheck();

      this.clientSignalStore.update((state) => ({
        status: {
          ...state.status,
          [this.reitId]: {
            shouldUpdate: true,
          },
        },
      }));
    }
  }

  initiateAssumptionScenario(notifyUpdates: boolean = false) {
    this.clientFacade
      .getReitId()
      .pipe(
        take(1),
        switchMap((reitId: number) =>
          this.clientFacade.getSelectedLeftScenarioId(reitId).pipe(
            take(1),
            switchMap((left) =>
              this.clientFacade.getSelectedRightScenarioId(reitId).pipe(
                take(1),
                map((right) => [left, right]),
              ),
            ),
          ),
        ),
        switchMap(([left, right]) =>
          this.reitAssumptionService.apiReitReitIdAssumptionComparePost(
            Number(this.reitId),
            {
              isDefaultScenarioA: true,
              isDefaultScenarioB: true,
              reitId: Number(this.reitId),
              scenarioAId: left,
              scenarioBId: right,
              userId: 1,
            },
          ),
        ),
      )
      .subscribe((data: any) => {
        this.scenarioAId = data.result.userScenario.scenarioId;
        this.scenarioBId = data.result.userBaseScenario.scenarioId;

        this.assumptionOther = data;

        if (notifyUpdates) {
          this.clientSignalStore.update((state) => ({
            other: {
              ...state.other,
              [this.reitId]: {
                scenarioId: this.scenarioAId,
                updatedAt: Date.now(),
              },
            },
          }));
        }
      });
  }

  onUpdateUserScenarioName(name: string) {
    this.reitStoreService.updateUserScenarioName(name);
  }

  refreshData(scenarioId: number, notifyUpdates: boolean) {
    this.clientFacade
      .getReitId()
      .pipe(
        take(1),
        switchMap((reitId: number) =>
          this.clientFacade.getSelectedLeftScenarioId(reitId).pipe(
            take(1),
            switchMap((left) =>
              this.clientFacade.getSelectedRightScenarioId(reitId).pipe(
                take(1),
                map((right) => [left, right]),
              ),
            ),
          ),
        ),
        switchMap(([left, right]) =>
          this.reitAssumptionService.apiReitReitIdAssumptionComparePost(
            Number(this.reitId),
            {
              isDefaultScenarioA: true,
              isDefaultScenarioB: true,
              reitId: Number(this.reitId),
              scenarioAId: left,
              scenarioBId: right,
              userId: 1,
            },
          ),
        ),
      )
      .subscribe((data: any) => {
        this.scenarioAId = data.result.userScenario.scenarioId;
        this.scenarioBId = data.result.userBaseScenario.scenarioId;

        this.assumptionOther = data;

        if (notifyUpdates) {
          this.clientSignalStore.update((state) => ({
            other: {
              ...state.other,
              [this.reitId]: {
                scenarioId: Number(scenarioId),
                updatedAt: Date.now(),
              },
            },
          }));
        }
      });
  }

  onCellSave({
    value,
    row,
    column,
    reitTableData,
    methodName,
  }: {
    value: string;
    row: any;
    column: any;
    reitTableData: IReitData;
    methodName?: string;
  }) {
    // const columnName = Object.entries(reitTableData.headers || {})[column][0];

    const { rowId, type } = row[column] as any;

    if (this.valueOfReitId() === null || !column) return;

    this.userAssumptionService
      .apiUserAssumptionsUserAssumptionOtherReitIdPut(
        Number(this.valueOfReitId()),
        {
          fieldValue: parseFloat(value.replace('%', '')),
          rowId: rowId,
          scenarioId: this.scenarioAId,
          type: type,
          methodName: methodName,
        },
      )
      .subscribe(
        (data: any) => {
          const fValue =
            type === 'percentage'
              ? parseFloat(value) + '%'
              : parseFloat(value) + '';
          reitTableData.data = reitTableData.data.map((item, index) =>
            index === row
              ? {
                  ...item,
                  [column]: {
                    ...item[column],
                    data: fValue,
                  },
                }
              : item,
          );

          this.queueRunning = data.assumptionQueueState === 2;
          this.queueCount = data.queueCount;

          if (this.queueState === 1) {
            console.log('[Queue] Timer started');
            this.queueInterval = setInterval(() => {
              this.checkQueue(this.scenarioAId);
            }, 1000);
          }
          this.queueState = data.assumptionQueueState;
        },
        (err) => {
          this.queueRunning = false;
        },
      );
  }

  checkQueue(scenarioId: number) {
    super.checkQueue(this.reitId, scenarioId, 3, () => {
      this.refreshData(scenarioId, true);
    });
  }
}
