import { Component, Input, OnInit } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import {
  navigationOverlayAnimation,
  navigationProfileAnimation,
} from '../../shared/animations/navigation.animation';
import {
  GlobalService,
  NavigationService,
  SessionService,
} from '../../shared/services';
import {
  DropdownHighlightData,
  DropdownHighlightGroup,
  DropdownHighlightSingleLineData,
  DropDownHighlightSingleLineItem,
} from '../../../../../../libs/shared-components/src/lib/interfaces/dropdown-highlight.interface';
import { autoDestroy, followDestroy } from '@kolytics/shared-functions';
import { ClientQuery } from '../../store/client.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PreviewService } from '../../shared/services/preview.service';
import { IconComponent } from '../../../../../../libs/shared-assets/src/lib/components/icon/icon.component';
import { FeaturedDirective } from '../../../../../../libs/shared-components/src/lib/directives/featured.directive';
import { HasClaimDirective } from '../../../../../../libs/shared-components/src/lib/directives/has-claim.directive';
import { NgClass, DatePipe } from '@angular/common';
import { UserService } from '@kolytics/shared-api';
import { take } from 'rxjs';

@Component({
  selector: 'klt-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [navigationProfileAnimation(), navigationOverlayAnimation()],
  standalone: true,
  imports: [
    RouterLink,
    HasClaimDirective,
    FeaturedDirective,
    RouterLinkActive,
    IconComponent,
    NgClass,
    DatePipe,
  ],
})
@autoDestroy()
@UntilDestroy()
export class NavigationComponent implements OnInit {
  user: any;
  isPreview: boolean = false;
  currentRoute = '';
  nav: Array<any> = [];
  profileNav: any; // ProfileNavigation;
  isProfileOpen = false;
  searchValue = '';
  isVisible = false;
  searchItems: DropdownHighlightData = {
    type: 'group',
    data: [
      {
        title: 'REITs',
        items: [
          { label: 'Hammerson', value: 'Hammerson' },
          { label: 'Hamborner', value: 'Hamborner' },
        ],
      },
      {
        title: 'SECTORS',
        items: [
          { label: 'Hampstead Village', value: 'Hampstead Village' },
          { label: 'West Ham', value: 'West Ham' },
        ],
      },
    ],
  };
  recentSearches: DropdownHighlightGroup = {
    type: 'group',
    data: [
      {
        title: 'Recent',
        items: [],
      },
    ],
  };

  scrolling: boolean = false;
  constructor(
    private query: ClientQuery,
    protected readonly router: Router,
    protected readonly global: GlobalService,
    protected readonly navigationService: NavigationService,
    protected readonly previewService: PreviewService,
    private session: SessionService,
    private userService: UserService,
  ) {
    this.nav = this.global.get('navigation');
    this.profileNav = this.global.get('profile');
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.scrolling = false;
        this.currentRoute = `/${val.urlAfterRedirects.split('/')[1]}`;
      }
    });
  }
  ngOnInit(): void {
    this.isPreview = this.previewService.isPreview();
    this.query
      .selectStickyKey$(['reit-sticky', 'reit-tab-sticky'])
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.scrolling = data[0];
      });

    this.userService
      .apiMyAccountGet()
      .pipe(take(1))
      .subscribe((data) => {
        this.user = data;
      });
  }

  toggleProfile(): void {
    this.isProfileOpen = !this.isProfileOpen;
  }

  closeProfile(): void {
    this.isProfileOpen = false;
  }

  navigate(route: string): void {
    if (route.indexOf('mailto:') !== -1) {
      window.open(route, '_blank');
    } else {
      this.router.navigate([route]).then();
    }
    this.closeProfile();
  }

  async logout(): Promise<any> {
    this.session.logout();
    this.router.navigate(['/login']);
  }

  async search(query: string): Promise<void> {
    this.searchValue = query;
    this.isVisible = true;
    // this.searchValueQuery = typeof(query) === 'string' ? query : query?.formValue;

    // if (this.searchValueQuery) {
    //   this.data.values = this.valuesForSearch?.filter(value => value.name?.toUpperCase().includes(this.searchValueQuery.toUpperCase()));
    // } else {
    //   this.data.values = this.valuesForSearch;
    // }
  }

  handleSelect(rowSelected: DropDownHighlightSingleLineItem): void {
    this.isVisible = false;
    if (
      this.recentSearches.data[0].items.filter(
        (item: DropDownHighlightSingleLineItem) =>
          item.value === rowSelected.value,
      ).length < 1
    ) {
      this.recentSearches = {
        ...this.recentSearches,
        data: [
          {
            ...this.recentSearches.data[0],
            items: [...this.recentSearches.data[0].items, rowSelected],
          },
        ],
      };
    }
  }

  handleClickButton() {
    this.isVisible = true;
  }
}
