import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment/moment';
import { LinkActionComponent } from '../../../components/link-action/link-action.component';
import { AsHtmlDirective } from '../../../../../../../../../libs/shared-components/src/lib/directives/as-html.directive';

import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';
import { ReitService, ReitUpdatesService } from '@kolytics/shared-api';

@Component({
  selector: 'klt-overview-sector-update',
  templateUrl: './overview-sector-update.component.html',
  styleUrls: ['./overview-sector-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SectionComponent, AsHtmlDirective, LinkActionComponent],
})
export class OverviewSectorUpdateComponent implements OnInit {
  @Input()
  public showTitleScroll = true;

  @Input()
  public showPagination = true;

  @Input()
  public itemsPerPage = 3;

  @Input() public reits: {
    title: string;
    date: string;
    description: string;
    reitName: string;
    showMore?: boolean;
  }[] = [];
  public paginateReits: {
    title: string;
    date: string;
    description: string;
    reitName: string;
    showMore?: boolean;
  }[] = [];
  public pageActive = 1;

  constructor(
    private reitService: ReitService,
    private reitUpdateService: ReitUpdatesService,
    private changeDetectorRef: ChangeDetectorRef,
    protected readonly activatedRoute: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    if (this.reits.length <= 0) {
      const reitId =
        this.activatedRoute.snapshot.parent?.paramMap.get('id') || null;
      const source = !!reitId
        ? this.reitUpdateService.apiReitUpdatesSectorIdGet(
            Number(reitId),
            undefined,
            undefined,
            1,
            10,
          )
        : this.reitUpdateService.apiReitUpdatesGet(1, 10);
      source.subscribe((res) => {
        this.reits = res.data.map((item: any) => ({
          date: moment(item.publicationDate).format('DD MMM YYYY'),
          title: item.title,
          description: item.description,
          reitName: item.reit?.name,
        }));
        this.pagination();
      });
    } else {
      this.pagination();
    }
  }

  public pagination(): void {
    this.paginateReits = this.reits.filter(
      (e, i) =>
        i >= (this.pageActive - 1) * this.itemsPerPage &&
        i <= this.pageActive * this.itemsPerPage - 1,
    );
    this.changeDetectorRef.detectChanges();
  }
}
