import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { KltModalService } from '@kolytics/shared-components';
import { first, take } from 'rxjs/operators';
import { SelectProfileDialogComponent } from '../../dialogs';
import { IScenarios } from '../../interfaces';
import { CompanyTableComponent } from './company-table/company-table.component';
import { CompanyCompareComponent } from './company-compare/company-compare.component';

@Component({
    selector: 'klt-portfolio-assumptions',
    templateUrl: './company-assumptions.component.html',
    styleUrls: ['./company-assumptions.component.scss'],
    standalone: true,
    imports: [CompanyCompareComponent, CompanyTableComponent],
})
export class CompanyAssumptionsComponent implements OnInit {
  scenarios!: IScenarios;
  tableData: any;
  constructor(protected readonly kltModalService: KltModalService) {}

  ngOnInit(): void {}

  public openDialog(): void {
    this.kltModalService
      .open(SelectProfileDialogComponent, {
        data: {},
      })
      .afterClosed.pipe(take(1))
      .subscribe((value) => {
        this.tableData = value;
      });
  }

  onSelectScenarios(value: IScenarios): void {
    this.scenarios = value;
  }
}
