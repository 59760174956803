import * as Sentry from '@sentry/angular';

export const SentryConfig: Sentry.BrowserOptions = {
  debug: false,
  enabled: true,
  dsn: 'https://eaefac54780a473288c40f3a4f5a3989@o560603.ingest.sentry.io/6179821',
  tracesSampleRate: 0.2,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
};
