import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import {
  FeatureService,
  KltModalService,
  PaginationPageChange,
  TableData,
  TableSort,
} from '@kolytics/shared-components';
import { KltToastService } from 'libs/shared-components/src/lib/services/toast/toast.service';
import { allReitTableData } from '../../../../shared/constants/mock-data';
import { IReitData } from '../../interfaces';
import { BaseReit } from '../../utils/components/base-reit/base-reit.util';
import { catchError, first, take, tap } from 'rxjs/operators';
import {
  followDestroy,
  formatNumber,
  formatPercentage,
} from '@kolytics/shared-functions';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, interval, Subscription } from 'rxjs';
import { ProductFeature } from '@kolytics/types/common';
import { ExportService, ReitService } from '@kolytics/shared-api';
import { SharePriceHistory } from 'libs/shared-api/src/lib/model/sharePriceHistory';
import { RemoveModalComponent } from '@kolytics/shared-components/modals';
import { ButtonComponent } from '../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { FeaturedDirective } from '../../../../../../../../libs/shared-components/src/lib/directives/featured.directive';
import { LinkActionComponent } from '../../components/link-action/link-action.component';

import { ReitTableNavigationComponent } from '../../../../../../../../libs/shared-components/src/lib/components/table-navigation/table-navigation.component';
import { TableToolbarDirective } from '../../../../../../../../libs/shared-components/src/lib/components/table/table.component';
import { TableV2Component } from '../../../../../../../../libs/shared-components/src/lib/components/table-v2/table-v2.component';
import { SectorRegionService } from '../../services/sector-region.service';

@Component({
  selector: 'klt-all-reits',
  templateUrl: './all-reits.component.html',
  styleUrls: ['./all-reits.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TableV2Component,
    TableToolbarDirective,
    ReitTableNavigationComponent,
    LinkActionComponent,
    FeaturedDirective,
    ButtonComponent,
  ],
})
export class AllReitsComponent extends BaseReit implements OnDestroy {
  downloadsFeature = ProductFeature.Downloads;

  public reitTableData: IReitData = allReitTableData as IReitData;
  pageIndex = 1;
  pageSize = 1000;
  orderBy = '';
  orderByDirection = '';
  excelLoading = false;

  isPopout = false;
  decimalPoints = 1;

  loading = true;

  chartTimeouts: any[] = [];

  constructor(
    protected readonly kltModalService: KltModalService,
    protected readonly kltToastService: KltToastService,
    protected readonly reitService: ReitService,
    private exportService: ExportService,
    protected readonly router: Router,
    protected readonly cdr: ChangeDetectorRef,
    private sectorRegionService: SectorRegionService,
  ) {
    super(kltModalService, kltToastService, router);
  }

  async ngOnInit() {
    await this.sectorRegionService.init();

    this.isPopout = this.router.url.indexOf('/popout/') > -1;
    this.reitTableData.data = [
      {
        company: { data: 'Loading...' },
        sector: { data: 'Loading...' },
        country: { data: 'Loading...' },
        price: { data: 'Loading...' },
        pdToNav: { data: 'Loading...' },
        dailyMove: { data: 'Loading...' },
        wHigh: { data: 'Loading...' },
        wLow: { data: 'Loading...' },
        wHistory: { data: '', chart: '' },
        mcap: { data: 'Loading...' },
        liquidity: { data: 'Loading...' },
        impIrr: { data: 'Loading...' },
        impValue: { data: 'Loading...' },
        affo: { data: 'Loading...' },
        dividendYield: { data: 'Loading...' },
        ltv: { data: 'Loading...' },
        ebitda: { data: 'Loading...' },
        kolyticsRating: { data: 'Loading...' },

        'action-my-reit': {
          data: '',
          action: { name: 'plus', icon: 'plus', style: 'circle' },
        },
      },
    ];
    this.cdr.detectChanges();
    this.fetchAllReit();
  }

  ngOnDestroy(): void {
    this.chartTimeouts.forEach((timeout) => clearTimeout(timeout));
  }

  sort(e: TableSort | undefined) {
    this.orderBy = e?.sortedBy ?? '';
    this.orderByDirection = e?.state ?? '';
    this.fetchAllReit();
  }

  public fetchAllReit(): void {
    this.reitService
      .apiReitAllGet(
        this.pageIndex,
        this.pageSize,
        this.orderBy,
        this.orderByDirection,
        this.sectorRegionService.regionsIds,
      )
      .pipe(
        tap((res) => {
          const data = res.data;

          this.reitTableData = {
            ...(allReitTableData as IReitData),
            data: data!.map((item) => ({
              id: { data: '' + item.reitId },
              company: { data: item.reitName },
              sector: { data: item.sectorName },
              country: { data: item.reitCountry },
              price: {
                data: item.sharePrice + '',
              },
              pdToNav: {
                data: formatPercentage(item.pdToNav, this.decimalPoints),
                color: 'warn',
              },
              dailyMove: {
                data: formatPercentage(item.dailyMove, this.decimalPoints),
                color: 'warn',
              },
              wHigh: {
                data: formatPercentage(item.vs52wHigh, this.decimalPoints),
                color: 'warn',
              },
              wLow: {
                data: formatPercentage(item.vs52wLow, this.decimalPoints),
                color: 'warn',
              },
              wHistory: {
                data: '',
                chart: '',
                type: 'chart',
              },
              mcap: { data: formatNumber(item.mcap, 0) },
              liquidity: { data: formatNumber(item.liquidity, 0) },
              impIrr: {
                data: formatPercentage(item.impliedIrr, this.decimalPoints),
                color: 'warn',
              },
              impValue: {
                data: formatNumber(item.impliedSf, this.decimalPoints),
              },
              affo: {
                data: formatPercentage(item.affoYield, this.decimalPoints),
                color: 'warn',
              },
              dividendYield: {
                data: formatPercentage(item.dividentYield, this.decimalPoints),
                color: 'warn',
              },
              ltv: {
                data: formatPercentage(item.ltv, this.decimalPoints),
                color: 'success',
              },
              ebitda: {
                data: formatNumber(item.debtEbitda, this.decimalPoints) + 'x',
              },
              kolyticsRating: { data: item.kolyticsRating, color: 'success' },

              'action-my-reit': {
                data: '',
                action: {
                  name: item.inMyReits ? 'remove' : 'plus',
                  icon: item.inMyReits ? 'minus' : 'plus',
                  style: 'circle',
                  feature: ProductFeature.MyReits,
                },
              },
            })),
            total: 0,
          };
          super.ngOnInit();
          this.cdr.detectChanges();
        }),
      )
      .subscribe(() => {
        this.loading = false;
      });
  }

  public openRegionFilter(): void {
    this.sectorRegionService.openRegionFilter(undefined, () => {
      this.fetchAllReit();
    });
  }

  onPageChange(e: PaginationPageChange) {
    this.pageIndex = e.pageIndex;
    this.fetchAllReit();
  }

  public onActionClick(action: { name: string; data: TableData }): void {
    const itemAction = action.data[action.name].action;
    switch (itemAction?.name) {
      case 'remove':
        this.removeRow(action.data);
        break;
      case 'plus':
        this.add(action.data);
        break;
    }
  }

  public popout(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/reits/popout/reits/all`]),
    );

    window.open(url, '_blank');
  }

  protected removeRow(item: TableData): void {
    this.reitService
      .apiReitMyReitsReitIdDelete(Number(item['id'].data))
      .pipe(take(1))
      .subscribe(() => {
        item['action-my-reit'].action = {
          name: 'plus',
          icon: 'plus',
          style: 'circle',
          feature: ProductFeature.MyReits,
        };
        this.reitTableData.data = [...this.reitTableData.data];
        this.kltToastService.success(`${item['company'].data} removed`, 4000);
      });
  }

  protected add(item: TableData): void {
    this.reitService
      .apiReitMyReitsReitIdPost(Number(item['id'].data))
      .pipe(
        tap(() => {
          this.kltToastService.success(`${item['company'].data} added`, 4000);
        }),
        catchError((err) => {
          this.kltToastService.error(
            `${item['company'].data} add failed`,
            4000,
          );
          return EMPTY;
        }),
      )
      .subscribe(() => {
        item['action-my-reit'].action = {
          name: 'remove',
          icon: 'minus',
          style: 'circle',
          feature: ProductFeature.MyReits,
        };
        this.reitTableData.data = [...this.reitTableData.data];
      });
  }

  public onRowClickId(id?: string) {
    this.router.navigate([`reits/${id}/overview`]);
  }

  downloadExcel() {
    this.excelLoading = true;
    this.exportService.apiExportReitsGet().subscribe(
      (data: Blob) => {
        const file = new Blob([data], { type: 'text/csv' });
        const fileURL = URL.createObjectURL(file);

        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'High-level PropCo Metrics.xlsx';
        document.body.appendChild(a);
        a.click();

        this.excelLoading = false;
      },
      (err) => {
        this.excelLoading = false;
      },
    );
  }
}
