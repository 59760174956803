import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { KltModalRefService } from '@kolytics/shared-components';
import { ButtonComponent } from '../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { InputComponent } from '../../../../../../../../libs/shared-components/src/lib/components/form/input/input.component';
import { ModalComponent } from '../../../../../../../../libs/shared-components/src/lib/components/modal/modal.component';
import { ReitAssumptionService, ReitService } from '@kolytics/shared-api';

@Component({
  selector: 'klt-rename-scenario-modal',
  templateUrl: './rename-scenario-modal.component.html',
  styleUrls: ['./rename-scenario-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalComponent, InputComponent, ButtonComponent],
})
export class RenameScenarioModalComponent implements OnInit {
  public name = '';
  scenarioId = '';

  constructor(
    protected readonly kltModalRefService: KltModalRefService,
    private reitService: ReitService,
    private reitAssumptionService: ReitAssumptionService,
    @Inject('KLT_MODAL_DATA') private data: { reitId: number },
  ) {}

  ngOnInit(): void {
    this.setUserScenarioId();
  }

  setUserScenarioId() {
    const path = document.location.pathname;
    const pathSplit = `${path}`.split('/');
    this.scenarioId = pathSplit[pathSplit.length - 2];
  }

  public onClose(): void {
    this.kltModalRefService.close(RenameScenarioModalComponent, undefined);
  }

  public onRename(): void {
    if (this.name) {
      this.reitAssumptionService
        .apiReitReitIdAssumptionRenameUserScenarioIdScenarioNameGet(
          Number(this.scenarioId),
          this.name,
          this.data.reitId,
        )
        .subscribe(() => {
          this.kltModalRefService.close(RenameScenarioModalComponent, {
            name: this.name,
            scenarioId: this.scenarioId,
          });
        });
    }
  }
}
