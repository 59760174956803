import { Component } from '@angular/core';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import { combineLatest, forkJoin, merge, zip } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { IAssumptionProfileScenarioResponse } from '../../interfaces';
import { ReitStoreService } from '../../services/reit-store.service';
import { RouterOutlet } from '@angular/router';
import { HeaderTabComponent } from '../../components/header-tab/header-tab.component';

@Component({
  selector: 'app-assumptions-all',
  templateUrl: './assumptions-all.component.html',
  styleUrls: ['./assumptions-all.component.scss'],
  standalone: true,
  imports: [HeaderTabComponent, RouterOutlet],
})
export class AssumptionsAllComponent {
  public links: { path: string; text: string }[] = [];
  public state: IAssumptionProfileScenarioResponse =
    {} as IAssumptionProfileScenarioResponse;

  constructor() {}

  public ngOnInit(): void {
    const path = document.location.pathname;
    const pathSplit = `${path}`.split('/');
    const reitId = pathSplit[2];
    const scenarioId = pathSplit[pathSplit.length - 2];
    this.setLinks(reitId, scenarioId);
    this.getAssumptionScenario();
  }

  setLinks(reitId: string, scenarioId: string) {
    this.links = [
      {
        path: `/reits/${reitId}/assumptions/sub/${scenarioId}/portfolio`,
        text: 'Portfolio',
      },
      {
        path: `/reits/${reitId}/assumptions/sub/${scenarioId}/company`,
        text: 'Company',
      },
      {
        path: `/reits/${reitId}/assumptions/sub/${scenarioId}/other`,
        text: 'Other',
      },
    ];
  }

  getAssumptionScenario() {
    const path = document.location.pathname;
    const pathSplit = `${path}`.split('/');
    const reitId = pathSplit[2];
    const scenarioId = pathSplit[pathSplit.length - 2];
  }
}
