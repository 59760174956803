import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { dropdownAnimation } from '../../../animations';
import {
  DropDownOption,
  DropDownType,
  GroupedDropDownOption,
} from '../../../interfaces';
import { ButtonComponent } from '../../common/button/button.component';
import { CheckboxButtonComponent } from '../checkbox-button/checkbox-button.component';
import { NgTemplateOutlet, LowerCasePipe } from '@angular/common';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'klt-dropdown-options',
  templateUrl: './dropdown-options.component.html',
  styleUrls: ['./dropdown-options.component.scss'],
  animations: [dropdownAnimation()],
  standalone: true,
  imports: [
    NgTemplateOutlet,
    FormsModule,
    ReactiveFormsModule,
    CheckboxButtonComponent,
    ButtonComponent,
    LowerCasePipe
],
})
export class DropdownOptionsComponent implements OnInit {
  private _open: boolean = false;
  get open(): boolean {
    return this._open;
  }
  @Input() set open(v: boolean) {
    this._open = v;

    if (!v) {
      this.filterControl.setValue(null);
      this.filteredOptions = [...this.options];
    }
  }

  private _options: DropDownOption[] = [];
  get options(): DropDownOption[] {
    return this._options;
  }
  @Input() set options(v: DropDownOption[]) {
    this._options = v;

    if (v && v.length > 0) {
      this.filteredOptions = [...v];
    }
  }

  @Input() groupedOptions: GroupedDropDownOption[] = [];

  @Input() type: DropDownType = 'list';
  @Input() minimum = 1;
  @Input() allowEdit = false;
  @Input() allowAddNew = false;
  @Input() label = '';
  @Input() allowFilter = false;
  @Input() allowHttpFilter = false;

  @Output() saved = new EventEmitter<DropDownOption[]>();
  @Output() closed = new EventEmitter<void>();
  @Output() cleared = new EventEmitter<void>();

  @Output() changed = new EventEmitter<DropDownOption[]>();
  @Output() selected = new EventEmitter<DropDownOption>();
  @Output() afterSaving = new EventEmitter<DropDownOption>();
  @Output() beforeSaving = new EventEmitter<void>();
  @Output() filterOptionFocus = new EventEmitter<void>();
  @Output() filterOptionBlur = new EventEmitter<void>();
  @Output() httpFilterChanged = new EventEmitter<string>();

  @Output() adding = new EventEmitter<boolean>();
  @Output() editing = new EventEmitter<boolean>();

  filterControl = new FormControl('');
  timeoutFilter: any = null;
  filteredOptions: DropDownOption[] = [];

  get empty(): boolean {
    if (this.type !== 'multi' && this.type !== 'groupDropdown') {
      return this.filteredOptions.filter((e) => e.selected)?.length <= 0;
    } else {
      const opt: DropDownOption[] = [];
      this.groupedOptions.forEach((group) => {
        group.options.forEach((o) => opt.push(o));
      });
      return opt.filter((e) => e.selected)?.length <= 0;
    }
  }
  get valid(): boolean {
    return (
      this.filteredOptions.filter((e) => e.selected)?.length >= this.minimum
    );
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['options']?.currentValue.length && this.allowFilter) {
  //     this.copyOptions = [...this.options];
  //   }
  // }

  ngOnInit(): void {
    this.filterControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((text: any) => {
        this.httpFilterChanged.emit(text);
      });
  }

  onFilterOption(event: any) {
    clearTimeout(this.timeoutFilter);
    this.timeoutFilter = setTimeout(() => {
      if (event.keyCode != 13) {
        const query = event.target.value.toLowerCase();
        if (this.allowFilter) {
          this.filteredOptions = this.options.filter((x) =>
            x.label.toLowerCase().includes(query),
          );
          //$this.options = $this.copyOptions.filter(x => x.label.toLowerCase().includes(query));
        } else {
          this.filteredOptions = this.options.filter((x) =>
            x.label.toLowerCase().includes(query),
          );
          //this.httpFilterChanged.emit(query);
        }
      }
    }, 500);
  }

  select(option: DropDownOption): void {
    option.selected = !option.selected;
    if (option.selected) {
      this.selected.emit(option);
    }
    this.changed.emit(this.filteredOptions.filter((e) => e.selected));
  }

  selectOne(option: DropDownOption): void {
    if (!option.edit) {
      this.resetEditOption();
      this.clear();
      option.selected = true;

      this.filterControl.setValue(null);
      this.selected.emit(option);
      this.closed.emit();
    }
  }

  addNewItem() {
    this.adding.emit(true);
    this.resetEditOption();
    const valueOption = Math.max(...this.options.map((o) => o.value), 0) + 1;
    if (!this.isEmptyOption()) {
      this.options.push({
        label: '',
        value: valueOption,
        edit: true,
        selected: false,
      });
    }
    this.filteredOptions = [...this.options];
    this.setFocusInputText(valueOption);
    this.beforeSaving.emit();
  }

  editItem(item: DropDownOption, e: Event) {
    this.editing.emit(true);
    this.preventCloseDropdownOption(e);
    this.resetEditOption();
    for (const op of this.options) {
      if (op.value === item.value) {
        op.edit = true;
      } else {
        op.edit = false;
      }
    }
    this.beforeSaving.emit();
  }

  saveEditItem(item: DropDownOption, e: Event) {
    //this.preventCloseDropdownOption(e);
    const labelValue = (<HTMLInputElement>(
      document.getElementById('dr' + item.value)
    )).value;
    if (labelValue) {
      item.label = labelValue;
      item.name = item.label;
      item.value = item.value ? item.value : item.name;
      item.edit = false;
      this.afterSaving.emit(item);
    } else {
      this.setFocusInputText(item.value);
    }

    this.editing.emit(false);
    this.adding.emit(false);
  }

  preventCloseDropdownOption(e: Event) {
    e.stopPropagation();
    e.preventDefault();
  }

  resetEditOption() {
    this.options.forEach((e) => (e.edit = false));
    this.options = this.options.filter((x) => x.label);

    this.filteredOptions = [...this.options];
  }

  isEmptyOption() {
    return this.filteredOptions.findIndex((x) => x.edit && !x.label) !== -1;
  }

  setFocusInputText(value: any) {
    setTimeout(() => {
      (<HTMLInputElement>document.getElementById('dr' + value))?.focus();
    }, 100);
  }

  save(): void {
    this.saved.emit(this.filteredOptions.filter((e) => e.selected));
    this.closed.emit();
  }
  filterClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  onFilterFocus() {
    this.filterOptionFocus.emit();
  }

  onFilterBlur() {
    this.filterOptionBlur.emit();
  }

  clear(): void {
    this.options.forEach((e) => (e.selected = false));
    if (this.type !== 'multi' && this.type !== 'groupDropdown') {
      this.options.forEach((e) => (e.selected = false));
    } else {
      this.groupedOptions.forEach((group) => {
        group.options.forEach((o) => (o.selected = false));
      });
    }

    this.filteredOptions = [...this.options];
    this.cleared.emit();
  }
}
