import { Params } from '@angular/router';

export const extractQueryRegionIds = (
  activatedRouteSnapshotQueryParams: Params,
): number[] => {
  const regions = activatedRouteSnapshotQueryParams['regions'];
  if (typeof regions === 'string') return [Number(regions)];
  if (Array.isArray(regions)) return regions.map((e) => Number(e));
  return [];
};
