@if (titleSummary) {
  <div
    class="title-select with-info"
    [class.with-time-select]="showTimeSelect"
    >
    <div class="value-left">
      @for (item of titles; track item; let i = $index) {
        <div
          class="title-select-item"
          [style]="'opacity:' + item.opacity"
          [style.color]="item.color"
          [class.small]="item.small"
          [class.un-choose]="item.disabled"
          (click)="clickItem(i)"
          >
          <div
            class="title-select-item-dot"
            [style.background-color]="item.color"
          ></div>
          <div class="title-select-item-title">
            <b>{{ item.text ? item.text : item.name }}</b>
          </div>
        </div>
      }
    </div>
    @if (titleSummary) {
      <div
        class="value-right"
        [innerHTML]="titleSummary"
      ></div>
    }
  </div>
}

@if (!titleSummary && !legendGrid) {
  <div
    class="title-select"
    [class.with-time-select]="showTimeSelect"
    >
    @for (item of titles; track item; let i = $index) {
      <div
        class="title-select-item"
        [style]="'opacity:' + item.opacity"
        [style.color]="item.color"
        [class.small]="item.small"
        [class.un-choose]="item.disabled"
        (click)="clickItem(i)"
        >
        <div
          class="title-select-item-dot"
          [style.background-color]="item.color"
        ></div>
        <div class="title-select-item-title">
          <b>{{ item.text ? item.text : item.name }}</b>
        </div>
      </div>
    }
  </div>
}

@if (!titleSummary && legendGrid) {
  <div
    class="title-select grid-legend"
    [class.with-time-select]="showTimeSelect"
    >
    <div style="display: flex; flex-wrap: wrap">
      @for (item of titles; track item; let i = $index) {
        <div
          style="flex-basis: 50%"
          class="title-select-item"
          [style]="'opacity:' + item.opacity"
          [style.color]="item.color"
          [class.small]="item.small"
          [class.un-choose]="item.disabled"
          (click)="clickItem(i)"
          >
          <div
            class="title-select-item-dot"
            [style.background-color]="item.color"
          ></div>
          <div class="title-select-item-title">
            <b>{{ item.text ? item.text : item.name }}</b>
          </div>
        </div>
      }
    </div>
  </div>
}

@if (options) {
  <div
    echarts
    [options]="options"
    class="graph-chart"
    style="height:{{ height }};width: 100%;"
    [style.width]="width"
  ></div>
}

@if (loading) {
  <div class="graph-loader">
    <div class="inner">
      <div class="loader"></div>
    </div>
  </div>
}
