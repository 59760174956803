<div
  #wrapper
  class="wrapper {{ wrapperClass }}"
  [formGroup]="form"
  [ngClass]="getFormClasses()"
  [class.expanded]="expanded"
  (click)="toggle($event)"
  >
  <input
    #input
    class="input {{ inputClass }}"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (keydown.tab)="noTab ? disableTab($event) : null"
    [tabindex]="disabled ? -1 : tabIndex"
    [required]="required"
    [maxlength]="maxlength"
    [placeholder]="expanded ? placeholder : ''"
    formControlName="formValue"
    readonly
    />
    <div class="notch">
      <div class="lead"></div>
      <div class="main start">
        <label class="{{ labelClass }}">{{ label }}</label>
      </div>
      <div class="trail"></div>
    </div>
    <div class="icon">
      <!-- <ark-icon
      [class.expanded]="expanded"
      icon="down"
      color="dark"
    size="small"></ark-icon> -->
    <klt-icon
      [class.expanded]="expanded"
      [size]="'regular'"
      [color]="iconColor"
      icon="down"
    ></klt-icon>
  </div>
</div>

<div class="options">
  <klt-dropdown-options
    [type]="'list'"
    [open]="expanded"
    [options]="filteredOptions"
    [allowAddNew]="allowAddNew"
    [allowEdit]="allowEdit"
    [allowFilter]="allowFilter"
    [allowHttpFilter]="allowHttpFilter"
    [label]="label"
    (editing)="editing = $event"
    (adding)="adding = $event"
    (closed)="onClosed()"
    (selected)="select($event)"
    (changed)="optionsChanged($event)"
    (beforeSaving)="beforeSavingItem()"
    (afterSaving)="afterSavingItem($event)"
    (filterOptionFocus)="onFilterOptionFocus()"
    (filterOptionBlur)="onFilterOptionBlur()"
    (httpFilterChanged)="httpFilterChanged.emit($event)"
  ></klt-dropdown-options>
</div>

@if (formStates.error) {
  <div class="error" [@dropdownAnimation]>
    <span>{{ errorMessage }}</span>
  </div>
}
@if (info) {
  <div class="info">
    <span>{{ info }}</span>
  </div>
}
