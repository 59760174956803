<klt-section title="Debt maturity profile">
  <div class="graph-box">
    <klt-graph
      height="348px"
      [options]="options"
      [titles]="titles"
      (filter)="runFilter($event)"
    >
    </klt-graph>
  </div>
</klt-section>
