import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, map, mergeMap, share, switchMap, tap } from 'rxjs/operators';
import { autoDestroy, followDestroy } from '@kolytics/shared-functions';
import { KltModalService } from '@kolytics/shared-components';
import { ReitStoreService } from '../../services/reit-store.service';
import { SelectScenarioCompareDialogComponent } from '../../dialogs/select-scenario-compare-dialog/select-scenario-compare-dialog.component';
import { ClientQuery } from 'apps/client/src/app/store/client.query';
import { forkJoin } from 'rxjs';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import { ReitMetadataResponse } from '@kolytics/shared-api';
import { BalanceSheetTableComponent } from './components/balance-sheet-table/balance-sheet-table.component';
import { AsyncPipe } from '@angular/common';
import { BalanceSheetProportionalLtvComponent } from './components/balance-sheet-proportional-ltv/balance-sheet-proportional-ltv.component';
import { BalanceSheetPremGavComponent } from './components/balance-sheet-prem-gav/balance-sheet-prem-gav.component';
import { SeparatorComponent } from '../../../../../../../../libs/shared-components/src/lib/components/separator/separator.component';
import { BalanceSheetNtaShareComponent } from './components/balance-sheet-nta-share/balance-sheet-nta-share.component';
import { SectionComponent } from '../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
    selector: 'klt-balance-sheet',
    templateUrl: './balance-sheet.component.html',
    styleUrls: ['./balance-sheet.component.scss'],
    standalone: true,
    imports: [
    SectionComponent,
    BalanceSheetNtaShareComponent,
    SeparatorComponent,
    BalanceSheetPremGavComponent,
    BalanceSheetProportionalLtvComponent,
    BalanceSheetTableComponent,
    AsyncPipe
],
})
@autoDestroy()
export class BalanceSheetComponent implements OnInit {
  metadata!: ReitMetadataResponse;
  constructor(
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly reitStore: ReitStoreService,
    protected readonly kltModalService: KltModalService,
    public clientQuery: ClientQuery,
    public clientFacade: ClientFacadeService,
  ) {}

  ngOnInit(): void {
    this.metadata = this.activatedRoute.parent?.snapshot.data.metadata.data;
  }
  public handleClick(): void {
    this.kltModalService
      .open(SelectScenarioCompareDialogComponent, {
        data: {
          reitId: this.clientFacade.reitId,
        },
      })
      .afterClosed.pipe(
        first(),
        tap((value) => {}),
      )
      .subscribe();
  }
}
