import { autoDestroy, followDestroy } from '@kolytics/shared-functions';
import {
  KltModalService,
  TableColumns,
  TableSort,
} from '@kolytics/shared-components';
import { SelectColumnDialogComponent } from '../../../dialogs';
import { first, tap } from 'rxjs/operators';
import { KltToastService } from 'libs/shared-components/src/lib/services/toast/toast.service';
import { IReitData } from '../../../interfaces';
import { Router } from '@angular/router';

export interface SelectDialogItem {
  key: string;
  title: string;
}
@autoDestroy()
export abstract class BaseReit {
  public abstract reitTableData: IReitData;

  public filteredColumns: string[] = [];

  private _prevTableStateBeforeSorting:
    | { sortedBy: string; data: any }
    | undefined;

  protected constructor(
    protected readonly kltModalService: KltModalService,
    protected readonly kltToastService: KltToastService,
    protected readonly router: Router,
  ) {}

  public ngOnInit(): void {
    const leftKeys = this.reitTableData.stickLeftColumns
      ? Object.keys(this.reitTableData.stickLeftColumns)
      : [];
    const rightKeys = this.reitTableData.stickRightColumns
      ? Object.keys(this.reitTableData.stickRightColumns)
      : [];
    const keys = this.reitTableData.headers
      ? Object.keys(this.reitTableData.headers)
      : [];
    this.filteredColumns = [...leftKeys, ...keys, ...rightKeys];
  }

  public onOpenFilter(): void {
    const availableColumns: { key: string; title: string }[] = [];
    this.assignColumns(availableColumns, this.reitTableData.stickLeftColumns);
    this.assignColumns(availableColumns, this.reitTableData.headers);
    this.assignColumns(availableColumns, this.reitTableData.stickRightColumns);

    this.kltModalService
      .open(SelectColumnDialogComponent, {
        data: {
          availableColumns: availableColumns,
          filteredColumns: this.filteredColumns,
        },
      })
      .afterClosed.pipe(
        first(),
        tap((filteredColumns) => {
          if (!filteredColumns) return;
          this.filteredColumns = [...filteredColumns];
        }),
        followDestroy(this),
      )
      .subscribe();
  }

  public onTableSort(sortedCol: TableSort | undefined): void {
    // undefined means sort state is NONE, so we revert to the original state
    if (!sortedCol) {
      this.reitTableData.data = this._prevTableStateBeforeSorting?.data || [];
      return;
    }

    // We need to store the original table state before sorting
    if (
      !this._prevTableStateBeforeSorting ||
      this._prevTableStateBeforeSorting.sortedBy !== sortedCol.sortedBy
    ) {
      this._prevTableStateBeforeSorting = {
        sortedBy: sortedCol.sortedBy,
        data: this.reitTableData.data,
      };
    }

    this.reitTableData.data = Array.from(this.reitTableData.data).sort(
      (a, b) => {
        if (
          a[sortedCol.sortedBy].data!.toLowerCase() <
          b[sortedCol.sortedBy].data!.toLowerCase()
        ) {
          return sortedCol.state === 'asc' ? -1 : 1;
        }
        if (
          a[sortedCol.sortedBy].data!.toLowerCase() >
          b[sortedCol.sortedBy].data!.toLowerCase()
        ) {
          return sortedCol.state === 'asc' ? 1 : -1;
        }
        return 0;
      },
    );
  }

  public onRowClick() {
    this.router.navigate(['reits/overview']);
  }

  public getPagesCount(pageSize: number, totalRecord: number): number {
    return totalRecord < pageSize ? 1 : Math.ceil(totalRecord / pageSize);
  }

  public groupBy = <T, K extends keyof any>(
    list: T[],
    getKey: (item: T) => K,
  ) =>
    list.reduce(
      (previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
      },
      {} as Record<K, T[]>,
    );

  protected assignColumns(
    availableColumns: { key: string; title: string }[],
    columns?: TableColumns,
  ): void {
    if (columns) {
      Object.keys(columns).forEach((header) => {
        availableColumns.push({
          key: header,
          title: columns ? columns[header].title : '',
        });
      });
    }
  }
}
