import { Injectable } from '@angular/core';

/**
 * @name KltToastService
 * @description Main service when using the Toast message
 */
@Injectable()
export class KltToastService {
  static readonly icons = {
    success: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons / Alerts / Success">
      <circle id="Oval" cx="10" cy="10" r="9" stroke="#fff" stroke-width="2"/>
      <g id="Icons / Tick">
      <path id="Icons / Tick / White" d="M14 7L8.4 13L6 10.75" stroke="#fff" stroke-width="2"/>
      </g>
      </g>
    </svg>`,
    error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons / Alerts / Error">
      <circle id="Oval" cx="10" cy="10" r="9" stroke="#fff" stroke-width="2"/>
      <path id="Path 3" d="M17 3L3 17" stroke="#fff" stroke-width="2"/>
      </g>
    </svg>`,
    close: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons / System / Close">
      <path id="Line" d="M2.34183 13.6568L13.6555 2.34307" stroke="#fff" stroke-width="2"/>
      <path id="Line_2" d="M2.34183 2.34322L13.6555 13.6569" stroke="#fff" stroke-width="2"/>
      </g>
    </svg>`,
  };

  readonly transitionMaxDuration: number = 500;

  /**
   * @description Used to open success toast message
   * @param message
   */
  success(message: string, duration?: number): void {
    this._open(message, 'success', duration);
  }

  /**
   * @description Used to open error toast message
   * @param message
   */
  error(message: string, duration?: number): void {
    this._open(message, 'error', duration);
  }

  /**
   * @description Used to destroy active toasts message
   */
  destroy() {
    this._close();
  }

  /**
   * @description Used to close a specific toast
   */
  private _close(): void {
    this._cleanToastOverlay();
  }

  /**
   * @description Used to setup and show toast message
   */
  private _open(
    message: string,
    state: 'success' | 'error',
    duration?: number,
  ): void {
    document.body.appendChild(this._createToastOverlay(message, state));

    /**
     * Need to wait for DOM to finish appending the elements for the Toast
     * before adding extra features
     */
    setTimeout(() => {
      this._showToastOverlayContents();
      this._setToastCloseEvent();
      if (duration) {
        setTimeout(() => {
          this._close();
        }, duration);
      }
    }, this.transitionMaxDuration - 300);
  }

  /**
   * @description Used to create HTML element for toast message
   * @param message
   * @param state
   */
  private _createToastOverlay(
    message: string,
    state: 'success' | 'error',
  ): HTMLElement {
    // We check and replace an existing toast first to avoid duplicates
    const toast: HTMLElement | null = document.querySelector(
      '#klt-toast-overlay',
    ) as HTMLElement;
    if (toast) {
      toast.remove();
    }

    const icon =
      state === 'success'
        ? KltToastService.icons.success
        : KltToastService.icons.error;
    const closeIcon = KltToastService.icons.close;

    // Toast overlay elements
    const toastOverlay = document.createElement('div');
    const toastContainer = document.createElement('div');
    const toastContent = document.createElement('div');

    toastContent.innerHTML = `
      <div class="klt-toast-icon">${icon}</div>
      <div class="klt-toast-message">${message}</div>
      <div class="klt-toast-divider"></div>
      <div class="klt-toast-close"><button>${closeIcon}</button></div>
    `;

    // ID
    toastOverlay.id = `klt-toast-overlay`;
    toastContainer.id = `klt-toast-container`;
    toastContent.id = 'klt-toast-content';

    // Classes
    toastOverlay.classList.add('klt-toast-overlay');
    toastContainer.classList.add('klt-toast-container');
    toastContent.classList.add('klt-toast-content');
    toastContent.classList.add(state); // state

    // Append elements
    toastContainer.appendChild(toastContent);
    toastOverlay.appendChild(toastContainer);

    return toastOverlay;
  }

  /**
   * @description Used to show the toast when everything is on place
   * @private
   */
  private _showToastOverlayContents(): void {
    const toast: HTMLElement = document.querySelector(
      '#klt-toast-content',
    ) as HTMLElement;
    if (toast) {
      toast.classList.add('visible');
    }
  }

  /**
   * @description Used to set click-close toast close button is clicked
   * @private
   */
  private _setToastCloseEvent(): void {
    const toastCloseBtn: HTMLElement = document.querySelector(
      '#klt-toast-content .klt-toast-close button',
    ) as HTMLElement;
    if (toastCloseBtn) {
      toastCloseBtn.addEventListener('click', () => {
        this._close();
      });
    }
  }

  /**
   * @description Used to properly remove the toast elements from the DOM
   * @protected
   */
  protected _cleanToastOverlay(): void {
    const toastOverlay: HTMLElement = document.querySelector(
      '#klt-toast-overlay',
    ) as HTMLElement;
    if (toastOverlay) {
      const toastContent: HTMLElement = document.querySelector(
        '#klt-toast-content',
      ) as HTMLElement;
      toastContent.classList.remove('visible');

      /**
       * Need to wait for DOM to finish removing classes
       * before we can fully remove the ToastOverlay element
       */
      setTimeout(() => toastOverlay.remove(), this.transitionMaxDuration);
    }
  }
}
