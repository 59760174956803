import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import {
  IInvestabilityGraph,
  IReit,
  IReitDataResponse,
  IReitFinancial,
  IReitLocation,
  IReitOverview,
  IReitProjection,
} from '../../../interfaces';
import { EChartsOption, SeriesOption } from 'echarts';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-overview-investability',
  templateUrl: './overview-investability.component.html',
  styleUrls: ['./overview-investability.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SectionComponent, GraphComponent],
})
export class OverviewInvestabilityComponent {
  @Input() height = '28rem';
  @Input() width = '100%';
  @Input() investabilityDescription: string | undefined;

  @Input()
  set reitResponse(
    reitResponse:
      | {
          reit: IReit;
          reitOverview: IReitOverview;
          reitLocation: IReitLocation;
          reitProjection: IReitProjection;
          reitFinancials: IReitFinancial;
        }
      | undefined,
  ) {
    if (reitResponse) {
      this.reitName = reitResponse.reit.name;
      this.buildSeries(reitResponse);
    }
  }

  reitName: string = '';

  indicator = [
    { name: 'Risk-Adjusted return', max: 5 },
    { name: 'Size/\nliquidity', max: 5 },
    { name: 'Asset quality', max: 5 },
    { name: 'Balance sheet', max: 5 },
    { name: 'Corporate\nstructures', max: 5 },
  ];

  @Input() public options: EChartsOption | undefined = {
    grid: {
      backgroundColor: '#ffffff',
      show: true,
      borderColor: '#f2f2f0',
      borderWidth: 0,
      left: '0%',
      right: '0%',
      top: '0%',
      bottom: '0%',
    },
    type: 'radar',
    radar: {
      axisName: {
        color: '#000',
        fontSize: 14,
      },
      splitLine: {
        show: false,
      },
      splitArea: {
        areaStyle: {
          color: ['#f2f2f0'],
        },
      },
      indicator: this.indicator,
      axisLine: {
        show: false,
      },
    },
    responsive: true,
    height: 'auto',
    width: 'auto',
    series: [],
    tooltip: {
      trigger: 'item',
    },
  };
  public series: any[] | undefined;
  public titles: { name: string; color: string; disabled: boolean }[] = [];
  loading: boolean = true;

  constructor(protected readonly cdr: ChangeDetectorRef) {}

  buildSeries(response: {
    reit: IReit;
    reitOverview: IReitOverview;
    reitLocation: IReitLocation;
    reitProjection: IReitProjection;
    reitFinancials: IReitFinancial;
  }) {
    const data = response.reitOverview.investabilityGraph;
    const reitName = response.reit.name;
    const sectorName = response.reit.sector.name;

    var seriesdata: SeriesOption = {
      type: 'radar',
      data: [
        {
          name: reitName,
          value: [
            data[0].riskAdjustedReturn,
            data[0].sizeLiquidity,
            data[0].assetQuality,
            data[0].balanceSheet,
            data[0].corporateStructures,
          ],
          areaStyle: {
            color: colorPallete[GraphId.OverviewInvestability][0],
            opacity: 0.7,
          },
          lineStyle: {
            color: colorPallete[GraphId.OverviewInvestability][0],
          },
          itemStyle: {
            color: colorPallete[GraphId.OverviewInvestability][0],
            opacity: 0.7,
          },
        },
        {
          name: `${sectorName} AVERAGE (${data[1].sectorCount})`,
          value: [
            data[1].riskAdjustedReturn,
            data[1].sizeLiquidity,
            data[1].assetQuality,
            data[1].balanceSheet,
            data[1].corporateStructures,
          ],
          areaStyle: {
            color: colorPallete[GraphId.OverviewInvestability][1],
            opacity: 0.7,
          },
          lineStyle: {
            color: colorPallete[GraphId.OverviewInvestability][1],
          },
          itemStyle: {
            color: colorPallete[GraphId.OverviewInvestability][1],
            opacity: 0.7,
          },
        },
      ],
    };

    this.series = seriesdata.data;
    this.setTitles(reitName, sectorName, data[1].sectorCount);

    (this.options as EChartsOption).series = seriesdata;
    const temp = Object.assign({}, this.options);
    this.options = undefined;
    setTimeout(() => {
      this.options = temp;
      this.cdr.detectChanges();
    }, 0);

    this.loading = false;
  }

  setTitles(name: string, sectorName: string, sectorCount: number) {
    this.titles.push(
      {
        name: name,
        color: colorPallete[GraphId.OverviewInvestability][0],
        disabled: false,
      },
      {
        name: `${sectorName} AVERAGE (${sectorCount})`,
        color: colorPallete[GraphId.OverviewInvestability][1],
        disabled: false,
      },
    );
  }

  public runFilter(
    data: { name: string; color: string; disabled: boolean }[],
  ): void {
    this.titles = data;
    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series?.filter((e) =>
      filter.includes(e.name.toString().toLowerCase()),
    );

    var seriesdata: SeriesOption = {
      type: 'radar',
      data: series,
    };

    (this.options as EChartsOption).series = seriesdata;
    const temp = Object.assign({}, this.options);
    this.options = undefined;
    setTimeout(() => {
      this.options = temp;
      this.cdr.detectChanges();
    }, 0);
  }
}
