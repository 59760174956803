import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KltModalRefService } from '@kolytics/shared-components';
import { Observable, debounceTime, startWith, map } from 'rxjs';
import {
  FilterColumnData,
  SelectColumn,
  SelectColumnDialogComponent,
} from '../select-column-dialog/select-column-dialog.component';
import { ButtonComponent } from '../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { AsyncPipe } from '@angular/common';
import { InputExpandComponent } from '../../../../../../../../libs/shared-components/src/lib/components/form/input-expand/input-expand.component';
import { CheckboxComponent } from '../../../../../../../../libs/shared-components/src/lib/components/form/checkbox/checkbox.component';
import { ModalComponent } from '../../../../../../../../libs/shared-components/src/lib/components/modal/modal.component';

@Component({
    selector: 'klt-select-region-dialog',
    templateUrl: './select-region-dialog.component.html',
    styleUrls: ['./select-region-dialog.component.scss'],
    standalone: true,
    imports: [
    ModalComponent,
    CheckboxComponent,
    InputExpandComponent,
    ButtonComponent,
    AsyncPipe
],
})
export class SelectRegionDialogComponent {
  searchExpanded: boolean = false;
  isAllToggled: boolean = false;
  isIntermediateToggled: boolean = false;

  items: SelectColumn[] = [];

  filterFormControl = new FormControl();
  filteredColumns$: Observable<SelectColumn[]>;

  constructor(
    @Inject('KLT_MODAL_DATA') private modalData: FilterColumnData,
    private kltModalRefService: KltModalRefService,
  ) {
    this.items = this.modalData.availableColumns || [];
    this.modalData.filteredColumns.forEach((key) => {
      const index = this.items.find((e) => e.key === key);
      if (index) {
        index.selected = true;
      }
    });

    this.updateToggle();

    this.filteredColumns$ = this.filterFormControl.valueChanges.pipe(
      debounceTime(100),
      startWith(''),
      map((query) => {
        return this.items.filter((col) =>
          col.key.toLowerCase().includes(query),
        );
      }),
    );
  }

  updateToggle() {
    const itemsSelected = this.items.filter((e) => e.selected);

    this.isAllToggled = itemsSelected.length === this.items.length;
    this.isIntermediateToggled =
      itemsSelected.length > 0 && itemsSelected.length < this.items.length;
  }

  onClose(): void {
    this.kltModalRefService.close(SelectRegionDialogComponent);
  }

  onSubmit(): void {
    this.kltModalRefService.close(
      SelectRegionDialogComponent,
      this.items.filter((e) => e.selected).map((e) => e.key),
    );
  }

  onValueChange(query: { formValue: string } | string): void {
    if (!query) {
      query = '';
    }

    let queryString: string = '';

    if (typeof query === 'string') {
      queryString = query;
    } else {
      queryString = query.formValue;
    }

    if (!queryString) {
      queryString = '';
    }
    if (queryString.length < 3 && queryString !== '') {
      return;
    }

    this.filterFormControl.patchValue(queryString);
  }

  onSelectColumn(
    formState: { value: boolean; status: string },
    col: SelectColumn,
  ): void {
    col.selected = formState.value;
    this.updateToggle();
  }

  onToggleAll(formState: { value: boolean; status: string }): void {
    this.items.forEach((item) => {
      item.selected = formState.value;
    });
    this.updateToggle();
  }
}
