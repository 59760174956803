import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ActivatedRoute,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { downloadReportOptions } from '../../../../shared/constants/mock-data';
import { NavigationEnd, Router } from '@angular/router';
import { doc } from 'prettier';
import { NavigationService } from '../../../../shared/services';
import { autoDestroy, followDestroy } from '@kolytics/shared-functions';
import { map, switchMap, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { ReitService, SectorService } from '@kolytics/shared-api';

@Component({
  selector: 'klt-sectors-detail',
  templateUrl: './sectors-detail.component.html',
  styleUrls: ['./sectors-detail.component.scss'],
  standalone: true,
  imports: [RouterLink, RouterLinkActive, RouterOutlet, AsyncPipe],
})
@autoDestroy()
export class SectorsDetailComponent implements OnInit {
  constructor(
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly service: ReitService,
    private sectorService: SectorService,
    protected readonly navigationService: NavigationService,
  ) {}

  scrolling$ = this.navigationService.scrolling$.pipe(followDestroy(this));
  currentRoute = '';
  reportOptions = downloadReportOptions;
  dropdownExpanded: boolean = false;
  currentId = '';
  sectorId!: number;
  name?: string = 'Loading..';
  numberOfReits?: number = 0;

  public ngOnInit(): void {
    const path = document.location.pathname;
    this.currentRoute = `${path}`.split('/')[4];
    this.currentId = `${path}`.split('/')[4];
    this.sectorService
      .apiSectorIdGet(Number(this.currentId))
      .subscribe((data) => {
        this.name = data.sector.name;
        this.numberOfReits = data.sector.numberOfReits;
      });
  }

  public toggle(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropdownExpanded = !this.dropdownExpanded;
  }

  public back(): void {
    this.router.navigate(['reits/sectors'], {
      queryParamsHandling: 'preserve',
    });
  }
}
