import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { EChartsOption } from 'echarts';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
    selector: 'klt-sectors-projections-debt-maturity-profile',
    templateUrl: './sectors-projections-debt-maturity-profile.component.html',
    styleUrls: ['./sectors-projections-debt-maturity-profile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SectionComponent, GraphComponent],
})
export class SectorsProjectionsDebtMaturityProfileComponent implements OnInit {
  public options: EChartsOption | undefined = {
    grid: {
      backgroundColor: '#ffffff',
      show: true,
      borderColor: '#ffffff',
      borderWidth: 0,
      height: '86%',
      width: '90%',
      top: '6',
      left: '70',
    },
    xAxis: {
      type: 'category',
      data: [
        '21F',
        '22F',
        '23F',
        '24F',
        '25F',
        '26F',
        '27F',
        '28F',
        '29F',
        '30F+',
      ],
      boundaryGap: false,
      axisLine: {
        show: false,
      },
      axisLabel: {
        margin: 22,
        formatter: (value: any) => {
          if (value === '30F+') {
            return '30F+        ';
          }
          return value;
        },
        color: '#1E2337',
        fontSize: 10,
        lineHeight: 20,
      },
      axisTick: {
        show: false,
      },
      axisPointer: {
        show: true,
        type: 'none',
        label: {
          show: false,
        },
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 100,
      interval: 25,
      axisLabel: {
        formatter: (value: any) => {
          return value + '%';
        },
        margin: 20,
        color: '#1E2337',
        fontSize: 12,
        lineHeight: 20,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    tooltip: {
      show: true,
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: function (params: any) {
        if (params.componentType && params.componentType === 'series') {
          return `<div style="background: ${
            params.color
          }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);">${params.seriesName.toUpperCase()}-${
            params.name
          }: (${params.value}%)</div>`;
        }
        return '';
      },
    },
    series: [],
  };
  public series = [
    {
      data: [
        {
          value: 24,
          symbolSize: 0,
        },
        {
          value: 35,
          itemStyle: {
            borderWidth: 0,
            color: '#8C648C',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 32,
          symbolSize: 0,
        },
        {
          value: 47,
          itemStyle: {
            borderWidth: 0,
            color: '#8C648C',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 26,
          itemStyle: {
            borderWidth: 0,
            color: '#8C648C',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
      ],
      type: 'line',
      smooth: true,
      color: '#8C648C',
      lineStyle: {
        width: 4,
      },
      name: 'REIT 1',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        {
          value: 26,
          symbolSize: 0,
        },
        {
          value: 28,
          itemStyle: {
            borderWidth: 0,
            color: '#8C648C',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 60,
          symbolSize: 0,
        },
        {
          value: 68,
          itemStyle: {
            borderWidth: 0,
            color: '#8C648C',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 75,
          itemStyle: {
            borderWidth: 0,
            color: '#8C648C',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 78,
          symbolSize: 0,
        },
      ],
      type: 'line',
      smooth: true,
      color: '#8C648C',
      lineStyle: {
        type: 'dashed',
        width: 4,
      },
      name: 'REIT 1',
    },
    {
      data: [
        {
          value: 90,
          symbolSize: 0,
        },
        {
          value: 80,
          itemStyle: {
            borderWidth: 0,
            color: '#D2B964',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 92,
          symbolSize: 0,
        },
        {
          value: 73,
          itemStyle: {
            borderWidth: 0,
            color: '#D2B964',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 49,
          itemStyle: {
            borderWidth: 0,
            color: '#D2B964',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
      ],
      type: 'line',
      smooth: true,
      lineStyle: {
        width: 4,
      },
      color: '#D2B964',
      name: 'REIT 2',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        {
          value: 49,
          symbolSize: 0,
        },
        {
          value: 60,
          itemStyle: {
            borderWidth: 0,
            color: '#D2B964',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 50,
          symbolSize: 0,
        },
        {
          value: 25,
          itemStyle: {
            borderWidth: 0,
            color: '#D2B964',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 0,
          itemStyle: {
            borderWidth: 0,
            color: '#D2B964',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 0,
          symbolSize: 0,
        },
      ],
      type: 'line',
      smooth: true,
      lineStyle: {
        type: 'dashed',
        width: 4,
      },
      color: '#D2B964',
      name: 'REIT 2',
    },
    {
      data: [
        {
          value: 35,
          symbolSize: 0,
        },
        {
          value: 60,
          itemStyle: {
            borderWidth: 0,
            color: '#96AAAA',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 75,
          symbolSize: 0,
        },
        {
          value: 73,
          itemStyle: {
            borderWidth: 0,
            color: '#96AAAA',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 68,
          itemStyle: {
            borderWidth: 0,
            color: '#96AAAA',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
      ],
      type: 'line',
      smooth: true,
      color: '#96AAAA',
      lineStyle: {
        width: 4,
      },
      name: 'REIT 3',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        {
          value: 68,
          symbolSize: 0,
        },
        {
          value: 78,
          itemStyle: {
            borderWidth: 0,
            color: '#96AAAA',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 96,
          symbolSize: 0,
        },
        {
          value: 98,
          itemStyle: {
            borderWidth: 0,
            color: '#96AAAA',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 95,
          itemStyle: {
            borderWidth: 0,
            color: '#96AAAA',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 90,
          symbolSize: 0,
        },
      ],
      type: 'line',
      smooth: true,
      color: '#96AAAA',
      lineStyle: {
        type: 'dashed',
        width: 4,
      },
      name: 'REIT 3',
    },
    {
      data: [
        {
          value: 60,
          symbolSize: 0,
        },
        {
          value: 25,
          itemStyle: {
            borderWidth: 0,
            color: '#FA8C7D',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 20,
          symbolSize: 0,
        },
        {
          value: 24,
          itemStyle: {
            borderWidth: 0,
            color: '#FA8C7D',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 75,
          itemStyle: {
            borderWidth: 0,
            color: '#FA8C7D',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
      ],
      type: 'line',
      smooth: true,
      color: '#FA8C7D',
      lineStyle: {
        width: 4,
      },
      name: 'REIT 4',
    },
    {
      data: [
        null,
        null,
        null,
        null,
        {
          value: 75,
          symbolSize: 0,
        },
        {
          value: 78,
          itemStyle: {
            borderWidth: 0,
            color: '#FA8C7D',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 72,
          symbolSize: 0,
        },
        {
          value: 65,
          itemStyle: {
            borderWidth: 0,
            color: '#FA8C7D',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 50,
          itemStyle: {
            borderWidth: 0,
            color: '#FA8C7D',
          },
          symbolSize: 10,
          symbol: 'circle',
        },
        {
          value: 28,
          symbolSize: 0,
        },
      ],
      type: 'line',
      smooth: true,
      color: '#FA8C7D',
      lineStyle: {
        type: 'dashed',
        width: 4,
      },
      name: 'REIT 4',
    },
  ];

  public titles: {
    name: string;
    color: string;
    disabled: boolean;
    small?: boolean;
  }[] = [
    {
      name: 'REIT 1',
      color: 'purple',
      disabled: false,
      small: true,
    },
    {
      name: 'REIT 2',
      color: 'yellow',
      disabled: false,
      small: true,
    },
    {
      name: 'REIT 3',
      color: 'olive',
      disabled: false,
      small: true,
    },
    {
      name: 'REIT 4',
      color: 'coral',
      disabled: false,
      small: true,
    },
  ];

  constructor(protected readonly cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.runFilter(this.titles);
  }

  public runFilter(
    data: { name: string; color: string; disabled: boolean; small?: boolean }[],
  ): void {
    this.titles = data;
    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter((e) =>
      filter.includes(e.name.toLowerCase()),
    );
    (this.options as EChartsOption).series = [
      ...(series as any),
      {
        type: 'line',
        smooth: true,
        data: [],
        symbolSize: 0,
        silent: true,
        markArea: {
          itemStyle: {
            color: '#FAFAFA',
          },
          data: [
            [
              {
                xAxis: '21F',
              },
              {
                xAxis: '22F',
              },
            ],
            [
              {
                xAxis: '23F',
              },
              {
                xAxis: '24F',
              },
            ],
            [
              {
                xAxis: '25F',
              },
              {
                xAxis: '26F',
              },
            ],
            [
              {
                xAxis: '27F',
              },
              {
                xAxis: '28F',
              },
            ],
            [
              {
                xAxis: '29F',
              },
              {
                xAxis: '30F+',
              },
            ],
          ],
        },
      },
      {
        type: 'line',
        smooth: true,
        data: [],
        symbolSize: 0,
        silent: true,
        zlevel: 2,
        markArea: {
          itemStyle: {
            color: '#E6E1D7',
          },
          data: [
            [
              {
                xAxis: '21F',
                yAxis: 25,
              },
              {
                xAxis: '30F+',
                yAxis: 25.15,
              },
            ],
            [
              {
                xAxis: '21F',
                yAxis: 50,
              },
              {
                xAxis: '30F+',
                yAxis: 50.15,
              },
            ],
            [
              {
                xAxis: '21F',
                yAxis: 75,
              },
              {
                xAxis: '30F+',
                yAxis: 75.15,
              },
            ],
          ],
        },
      },
    ];
    const temp = Object.assign({}, this.options);
    this.options = undefined;
    setTimeout(() => {
      this.options = temp;
      this.cdr.detectChanges();
    }, 0);
  }
}
