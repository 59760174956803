import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _scrolling$ = new BehaviorSubject<boolean>(false);

  public get scrolling$(): Observable<boolean> {
    return this._scrolling$.asObservable();
  }

  public setScrolling(value: boolean): void {
    this._scrolling$.next(value);
  }
}
