import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  first,
  flatMap,
  map,
  mergeMap,
  share,
  switchMap,
  tap,
} from 'rxjs/operators';
import { autoDestroy, followDestroy } from '@kolytics/shared-functions';
import { ActivatedRoute } from '@angular/router';
import {
  GroupedDropDownOption,
  KltModalService,
} from '@kolytics/shared-components';
import { ReitStoreService } from '../../services/reit-store.service';
import { SelectScenarioCompareDialogComponent } from '../../dialogs/select-scenario-compare-dialog/select-scenario-compare-dialog.component';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import { ClientQuery } from 'apps/client/src/app/store/client.query';
import { combineLatest, forkJoin, merge } from 'rxjs';
import { ReitMetadataResponse, ReitService } from '@kolytics/shared-api';
import { IncomeStatementTableComponent } from './income-statement-table/income-statement-table.component';
import { AsyncPipe } from '@angular/common';
import { IncomeStatementInterestCostsComponent } from './income-statement-interest-costs/income-statement-interest-costs.component';
import { IncomeStatementIndirectCostsComponent } from './income-statement-indirect-costs/income-statement-indirect-costs.component';
import { IncomeStatementDirectCostsComponent } from './income-statement-direct-costs/income-statement-direct-costs.component';
import { IncomeStatementRevenueComponent } from './income-statement-revenue/income-statement-revenue.component';
import { SeparatorComponent } from '../../../../../../../../libs/shared-components/src/lib/components/separator/separator.component';
import { IncomeStatementDpsComponent } from './income-statement-dps/income-statement-dps.component';
import { IncomeStatementEpsComponent } from './income-statement-eps/income-statement-eps.component';
import { SectionComponent } from '../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-income-statement',
  templateUrl: './income-statement.component.html',
  styleUrls: ['./income-statement.component.scss'],
  standalone: true,
  imports: [
    SectionComponent,
    IncomeStatementEpsComponent,
    IncomeStatementDpsComponent,
    SeparatorComponent,
    IncomeStatementRevenueComponent,
    IncomeStatementDirectCostsComponent,
    IncomeStatementIndirectCostsComponent,
    IncomeStatementInterestCostsComponent,
    IncomeStatementTableComponent,
    AsyncPipe,
  ],
})
@autoDestroy()
export class IncomeStatementComponent implements OnInit {
  @Input() stickyTable = true;
  reitId!: number;
  scenarioAId!: number;

  metadata!: ReitMetadataResponse;

  constructor(
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly kltModalService: KltModalService,
    protected readonly service: ReitService,
    protected readonly reitStore: ReitStoreService,
    public clientFacade: ClientFacadeService,
    public clientQuery: ClientQuery,
  ) {}

  ngOnInit(): void {
    this.metadata = this.activatedRoute.parent?.snapshot.data.metadata.data;
  }

  public handleClick(): void {
    this.kltModalService
      .open(SelectScenarioCompareDialogComponent, {
        data: {
          reitId: this.clientFacade.reitId,
        },
      })
      .afterClosed.pipe(
        first(),
        tap((value) => {}),
      )
      .subscribe();
  }
}
