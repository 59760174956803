@if (!useOldTable) {
  <klt-table-v2
    [stickyTop]="true"
    [loading]="false"
    [groupLine]="false"
    [data]="reitTableData.data"
    [columns]="reitTableData.headers"
    [columnGroups]="reitTableData.sections"
    [filteredColumnKeys]="filteredColumns"
    [top]="isPopup ? 77 : 187"
    (cellSaveClick)="onCellSave($event)"
    >
    @if (showToolbar) {
      <klt-table-toolbar>
        @if ((isPopup && queueRunning) || !isPopup) {
          <div
            class="toolbar"
            [class.sticky]="queueRunning"
            [class.top]="isPopup"
            >
            <div class="toolbar--left">
              @if (!isPopup) {
                <klt-link-action
                  [iconRight]="'pop-out'"
                  (click)="popout()"
                  [text]="'Pop-out'"
                ></klt-link-action>
              }
            </div>
            <div class="toolbar--right">
              <klt-loading-spinner
                [show]="queueRunning"
          [label]="
            'Saving...' + (queueCount > 1 ? ' (' + queueCount + ')' : '')
          "
              ></klt-loading-spinner>
              @if (!isPopup) {
                <div class="more-group">
                  <span>More</span>
                  <klt-dropdown-wrapper>
                    <klt-button
                      class="more"
                      type="icon"
                      rightIcon="more-vertical"
                      (click)="toggleMore($event)"
                    ></klt-button>
                    <div class="options">
                      <klt-dropdown-actions
                        [open]="more"
                        [actions]="moreActions"
                        [options]="moreOptions"
                        (selectedOption)="solveOption($event)"
                        (selectedAction)="solveAction($event)"
                        (closed)="more = false"
                        >
                      </klt-dropdown-actions>
                    </div>
                  </klt-dropdown-wrapper>
                </div>
              }
            </div>
          </div>
        }
      </klt-table-toolbar>
    }
  </klt-table-v2>
}

@if (useOldTable) {
  <div class="company-assumptions-table">
    <klt-table
      [tableId]="'company'"
      [columnGroups]="reitTableData.sections"
      [columns]="reitTableData.headers"
      [filteredColumns]="filteredColumns"
      [stickLeftColumns]="reitTableData.stickLeftColumns"
      [stickRightColumns]="reitTableData.stickRightColumns"
      [data]="reitTableData.data"
      [pageLength]="reitTableData.total"
      [showTopHeader]="false"
      (sort)="onTableSort($event)"
      >
    </klt-table>
  </div>
}
