@if (open) {
  <div class="wrapper" [@dropdownAnimation]>
    @switch (type) {
      @case ('list') {
        <ng-container [ngTemplateOutlet]="list"></ng-container>
      }
      @case ('groupDropdown') {
        <ng-container
          [ngTemplateOutlet]="groupDropdown"
        ></ng-container>
      }
      @case ('multi') {
        <ng-container
          [ngTemplateOutlet]="multi"
        ></ng-container>
      }
    }
  </div>
}

<ng-template #list>
  @if (allowFilter || allowHttpFilter) {
    <div class="filter-option">
      <input
        class="filter-input"
        (keyup)="onFilterOption($event)"
        (click)="filterClick($event)"
        [formControl]="filterControl"
        (blur)="onFilterBlur()"
        (focus)="onFilterFocus()"
        type="text"
        placeholder="Search {{ label | lowercase }}..."
        />
      </div>
    }
    <ul class="list">
      @for (option of filteredOptions; track option) {
        <li
          class="list-item"
          [class.selected]="option.selected"
          (click)="selectOne(option)"
          >
          <div class="flex">
            @if (!option.edit) {
              <span>{{ option.label }}</span>
            }
            @if (option.edit) {
              <span class="edit-inline">
                <input
                  [value]="option.label"
                  type="text"
                  [id]="'dr' + option.value"
                  />
                </span>
              }
              @if (allowEdit && !option.edit) {
                <span
                  class="edit"
                  (click)="editItem(option, $event)"
                  >
                  <img src="/assets/icons/edit.png" />
                </span>
              }
              @if (allowEdit && option.edit) {
                <span
                  class="edit"
                  (click)="saveEditItem(option, $event)"
                  >
                  <img src="/assets/icons/save.png" />
                </span>
              }
            </div>
          </li>
        }
        @if ((allowFilter || allowHttpFilter) && !options.length) {
          <li
            class="list-item"
            >
            No data available
          </li>
        }
      </ul>
      @if (allowAddNew) {
        <div class="add" (click)="addNewItem()">
          <span class="icon"><img src="/assets/icons/plus.png" /></span>
          <span class="label">Add {{ label | lowercase }}</span>
        </div>
      }
    </ng-template>

    <ng-template #multi>
      <ul class="multi">
        <div class="options">
          @for (group of groupedOptions; track group) {
            <li class="list-group-item">
              <div class="eyebrow">{{ group.group }}</div>
              @for (option of group.options; track option) {
                <div class="checkbox-item">
                  <klt-checkbox-button
                    [label]="option.label"
                    [checked]="option.selected"
                    (click)="select(option)"
                  ></klt-checkbox-button>
                </div>
              }
            </li>
          }
        </div>
        <div class="actions">
          <span class="clear" [class.disabled]="empty" (click)="clear()"
            >Clear</span
            >
            <klt-button [type]="'primary'">DOWNLOAD</klt-button>
          </div>
        </ul>
      </ng-template>

      <ng-template #groupDropdown>
        <!--  <ul class="list">-->
        <!--    <li-->
        <!--      *ngFor="let option of filteredOptions"-->
        <!--      class="list-item"-->
        <!--      [class.selected]="option.selected"-->
        <!--      (click)="selectOne(option)"-->
        <!--    >-->
        <!--      <span *ngIf="!option.edit">{{ option.label }}</span>-->
        <!--      <span class="edit-inline" *ngIf="option.edit">-->
        <!--        <input [value]="option.label" type="text" [id]="'dr' + option.value" />-->
      <!--      </span>-->
      <!--      <span-->
      <!--        class="edit"-->
      <!--        *ngIf="allowEdit && !option.edit"-->
      <!--        (click)="editItem(option, $event)"-->
      <!--      >-->
      <!--        <img src="/assets/icons/edit.png" />-->
    <!--      </span>-->
    <!--      <span-->
    <!--        class="edit"-->
    <!--        *ngIf="allowEdit && option.edit"-->
    <!--        (click)="saveEditItem(option, $event)"-->
    <!--      >-->
    <!--        <img src="/assets/icons/save.png" />-->
  <!--      </span>-->
<!--    </li>-->
<!--    <li class="list-item" *ngIf="(allowFilter || allowHttpFilter) && !options.length">No data available</li>-->
<!--  </ul>-->
<!--  -->
<ul class="list">
  <div class="options options-grp">
    @for (group of groupedOptions; track group) {
      <li class="list-group-item">
        <div class="eyebrow">{{ group.group }}</div>
        @for (option of group.options; track option) {
          <ul>
            <li
              class="list-item"
              [class.selected]="option.selected"
              [value]="option.value"
              (click)="selectOne(option)"
              >
              @if (!option.edit) {
                <span>{{ option.label }}</span>
              }
            </li>
          </ul>
        }
      </li>
    }
  </div>
</ul>
</ng-template>
