import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, map, mergeMap, share, switchMap, tap } from 'rxjs/operators';
import { autoDestroy, followDestroy } from '@kolytics/shared-functions';
import { KltModalService } from '@kolytics/shared-components';
import { ReitStoreService } from '../../services/reit-store.service';
import { SelectScenarioCompareDialogComponent } from '../../dialogs/select-scenario-compare-dialog/select-scenario-compare-dialog.component';
import { ClientQuery } from 'apps/client/src/app/store/client.query';
import { forkJoin } from 'rxjs';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import { ReitMetadataResponse } from '@kolytics/shared-api';
import { CashFlowStatementTableComponent } from './cash-flow-statement-table/cash-flow-statement-table.component';
import { AsyncPipe } from '@angular/common';
import { CashFlowStatementInvestingComponent } from './cash-flow-statement-investing/cash-flow-statement-investing.component';
import { CashFlowStatementFinancingComponent } from './cash-flow-statement-financing/cash-flow-statement-financing.component';
import { SeparatorComponent } from '../../../../../../../../libs/shared-components/src/lib/components/separator/separator.component';
import { CashFlowStatementNetCashingComponent } from './cash-flow-statement-net-cashing/cash-flow-statement-net-cashing.component';
import { SectionComponent } from '../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-cash-flow-statement',
  templateUrl: './cash-flow-statement.component.html',
  styleUrls: ['./cash-flow-statement.component.scss'],
  standalone: true,
  imports: [
    SectionComponent,
    CashFlowStatementNetCashingComponent,
    SeparatorComponent,
    CashFlowStatementFinancingComponent,
    CashFlowStatementInvestingComponent,
    CashFlowStatementTableComponent,
    AsyncPipe,
  ],
})
@autoDestroy()
export class CashFlowStatementComponent implements OnInit {
  metadata!: ReitMetadataResponse;
  constructor(
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly kltModalService: KltModalService,
    protected readonly reitStore: ReitStoreService,
    public clientQuery: ClientQuery,
    public clientFacade: ClientFacadeService,
  ) {}

  ngOnInit(): void {
    this.metadata = this.activatedRoute.parent?.snapshot.data.metadata.data;
  }

  public handleClick(): void {
    this.kltModalService
      .open(SelectScenarioCompareDialogComponent, {
        data: {
          reitId: this.clientFacade.reitId,
        },
      })
      .afterClosed.pipe(
        first(),
        tap((value) => {}),
      )
      .subscribe();
  }
}
