import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FeaturedDirective } from '../../../../../../../../libs/shared-components/src/lib/directives/featured.directive';
import {
  QueryParamsHandling,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { HasClaimDirective } from '../../../../../../../../libs/shared-components/src/lib/directives/has-claim.directive';

import { StickyClassDirective } from '../../../../shared/directives/sticky-class.directive';

@Component({
  selector: 'klt-header-tab',
  templateUrl: './header-tab.component.html',
  styleUrls: ['./header-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    StickyClassDirective,
    HasClaimDirective,
    RouterLink,
    RouterLinkActive,
    FeaturedDirective,
  ],
})
export class HeaderTabComponent {
  @Input()
  public links: {
    path: string;
    text: string;
    feature?: string;
    claim?: string;
    queryParamsHandling?: QueryParamsHandling;
  }[] = [];

  @Input()
  public noTab: boolean = false;
}
