import {
  Component,
  ElementRef,
  Inject,
  Optional,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@kolytics/envs';
import { ImportService } from '@kolytics/shared-api';
import { ModalData } from '@kolytics/shared-components';
import { tap, finalize, take } from 'rxjs';
import { KltModalRefService } from '../../services';
import { ButtonComponent } from '../../components/common/button/button.component';
import { ProgressBarComponent } from '../../components/progress-bar/progress-bar.component';
import { IconComponent } from '../../../../../shared-assets/src/lib/components/icon/icon.component';
import { DragAndDropDirective } from '../../directives/drag-and-drop.directive';

import { CheckboxComponent } from '../../components/form/checkbox/checkbox.component';
import { ModalComponent } from '../../components/modal/modal.component';

@Component({
  selector: 'klt-modal-file-upload',
  templateUrl: './modal-file-upload.component.html',
  styleUrls: ['./modal-file-upload.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    CheckboxComponent,
    DragAndDropDirective,
    IconComponent,
    ProgressBarComponent,
    ButtonComponent,
  ],
})
export class ModalFileUploadComponent {
  selectedFile: File | undefined;
  progress: number = 0;
  completed: boolean = false;
  overwrite: boolean = false;
  uploading: boolean = false;
  errorMessages: string[] = [];

  checkboxTitle = '';
  checkboxChecked = false;

  @ViewChild('fileDropRef')
  public readonly fileDropRef!: ElementRef<HTMLInputElement>;

  public get showDrag(): boolean {
    return !this.selectedFile;
  }

  public get showProcess(): boolean {
    return !!this.selectedFile && !this.completed;
  }

  public get showError(): boolean {
    return this.completed && this.errorMessages.length > 0;
  }

  public get showSuccess(): boolean {
    return this.completed && this.errorMessages.length === 0;
  }

  public get showUpload(): boolean {
    return this.completed && this.errorMessages.length === 0;
  }

  constructor(
    private kltModalRefService: KltModalRefService,
    private importService: ImportService,
    @Inject('KLT_MODAL_DATA') private modalData: any,
  ) {
    this.checkboxTitle = modalData.checkboxTitle;
  }

  checkboxChanged(key: string, value: boolean) {}
  onClose() {
    this.kltModalRefService.close(ModalFileUploadComponent);
  }
  onFileUploaded(files: FileList) {
    this.prepareFilesList(files ? Array.from(files) : []);
  }

  fileBrowseHandler(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    this.prepareFilesList(files ? Array.from(files) : []);
  }
  prepareFilesList(files: File[]) {
    this.removeFile();

    if (files.length > 0) {
      const file = files[0];

      const af = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ];

      if (af.includes(file.type)) {
        this.selectedFile = file;

        this.progress = 100;
        const timeout = setTimeout(() => {
          this.completed = true;
          clearTimeout(timeout);
        }, 500);
      } else {
        alert('Only xls or xlsx docs allowed!');
      }
    }
  }

  uploadFile(): void {
    if (this.uploading) return;
    this.uploading = true;

    this.importService
      .apiImportSharePricesPost(this.checkboxChecked, this.selectedFile)
      .pipe(take(1))
      .subscribe(() => {
        this.kltModalRefService.close(ModalFileUploadComponent);
        this.uploading = false;
      });
  }

  removeFile() {
    this.completed = false;
    this.errorMessages = [];
    this.progress = 0;
    this.selectedFile = undefined;
    this.fileDropRef.nativeElement.value = '';
  }
}
