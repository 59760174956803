<div class="section">
  <ng-template #content><ng-content></ng-content></ng-template>
  @switch (type) {
    @case ('none') {
      <div class="section-title with-none {{ sectionTitleClass }}">
        {{ title }}
      </div>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    }
    @case ('button') {
      <div class="section-title with-button {{ sectionTitleClass }}">
        <div class="title-content">
          {{ title }}
        </div>
        @if (showCta && !ctaFeature) {
          <klt-button
            [leftIcon]="buttonIconLeft"
            [rightIcon]="buttonIconRight"
            type="secondary"
            (click)="onClick()"
            >{{ buttonText }}</klt-button
            >
          }
          @if (showCta && ctaFeature) {
            <klt-button
              [leftIcon]="buttonIconLeft"
              [rightIcon]="buttonIconRight"
              kltFeatured
              [feature]="ctaFeature"
              type="secondary"
              (click)="onClick()"
              >{{ buttonText }}</klt-button
              >
            }
          </div>
          <ng-container [ngTemplateOutlet]="content"></ng-container>
        }
        @case ('pagination') {
          <div class="section-title with-pagination {{ sectionTitleClass }}">
            <div class="title-content">
              {{ title }}
            </div>
            @if (showTitleScroll) {
              <div class="title-scroll">
                <klt-icon
                  icon="left"
                  (click)="decrease()"
                  [color]="pageActive === 1 ? 'darkGray' : 'dark'"
                ></klt-icon>
                <klt-icon
                  icon="right"
                  (click)="increase()"
                  [color]="pageActive === pages.length ? 'darkGray' : 'dark'"
                ></klt-icon>
              </div>
            }
          </div>
          <ng-container [ngTemplateOutlet]="content"></ng-container>
          @if (showPagination) {
            <div class="section-pagination">
              <div class="pagination-list">
                @for (page of pages; track page) {
                  <div
                    class="pagination-item"
                    (click)="pageActive = page; paginate.emit(pageActive)"
                    [class.active]="page === pageActive"
                  ></div>
                }
              </div>
            </div>
          }
        }
      }
    </div>
