<div id="table-content" class="table-content">
  <div id="js-category-content" class="table-category-content">
    <div class="table-layout-width">
      <div class="tv-category__tab-page tv-card-exterior">
        <div class="tv-category__tab-page tv-card-exterior">
          <div
            class="js-screener-markets-page-init-ssr screener-markets-page-container"
            data-props-id="6v1KUc"
          >
            <div
              class="table-root wrapper-table"
              [class.table-root-groups]="filteredColumnGroups"
            >
              <div class="table-header">
                <div class="toolbar-container">
                  <ng-content
                    select="klt-table-toolbar, [kltTableToolbar]"
                  ></ng-content>
                </div>
              </div>
              <div class="shadow">
                <div
                  #tableWrapSticky
                  class="tableWrapSticky top-{{ top }}"
                  [class.display-none]="loading"
                  [style.top]="top + 'px'"
                  *ngIf="stickyTop"
                >
                  <div
                    #scrollbarInverted
                    class="scrollbar-inverted"
                    (scroll)="onScrollInverted()"
                  >
                    <div
                      class="scrollbar"
                      [style.width]="tableWidth + 'px'"
                    ></div>
                  </div>
                  <div class="scrollWrapSticky" #scrollWrapSticky>
                    <table class="table tableSticky" #tableHeadGroup>
                      <thead class="tableHead">
                        <ng-container
                          *ngIf="filteredColumnGroups && showColumnGroups"
                        >
                          <tr>
                            <th
                              *ngFor="let group of filteredColumnGroups"
                              class="cell-group"
                              [class.cell-fixed]="group.sticky"
                              [attr.colspan]="group.columnDefSize"
                              [attr.data-group-header]="group.key"
                            >
                              <div class="group">
                                <span
                                  class="line"
                                  *ngIf="group.sectionTitle"
                                ></span>

                                <span class="title">{{
                                  group.sectionTitle
                                }}</span>
                                <span
                                  class="line"
                                  *ngIf="group.sectionTitle"
                                ></span>
                              </div>
                            </th>
                          </tr>
                        </ng-container>
                      </thead>
                    </table>
                    <table class="table tableSticky" #tableHead>
                      <thead class="tableHead">
                        <tr class="row">
                          <th
                            *ngFor="
                              let column of filteredColumns
                                | keyvalue: originalOrder
                            "
                            class="table-cell"
                            [class.fill-purple]="column.value.fill === 'purple'"
                            [class.fill-dark-light]="
                              column.value.fill === 'dark-light'
                            "
                            [class.fill-purpleDisabled]="
                              column.value.fill === 'purpleDisabled'
                            "
                            [class.cell-fixed]="column.value.sticky"
                            [class.group-start]="
                              column.value.firstOfGroup && groupLine
                            "
                            [style.height]="autoHeight ? 'auto' : height"
                            [attr.data-fixed-header]="column.key"
                          >
                            <div
                              class="cellWrapper cellContent cellHover table-cell-header"
                              [class.fill-purple]="
                                column.value.fill === 'purple'
                              "
                              [class.fill-dark-light]="
                                column.value.fill === 'dark-light'
                              "
                              [class.fill-purpleDisabled]="
                                column.value.fill === 'purpleDisabled'
                              "
                              [class.center-headers]="
                                column.value.style?.center
                              "
                              [class.no-vertical-padding]="noVerticalPadding"
                              [style.padding-top]="verticalPadding"
                              [style.padding-bottom]="verticalPadding"
                            >
                              <span
                                [innerHTML]="column.value.title | safeHtml"
                                [matTooltipDisabled]="!showTooltip"
                                [matTooltip]="
                                  column.value.tooltip ?? column.value.title
                                "
                                matTooltipClass="header-tooltip"
                              ></span>
                              <ng-container *ngIf="column.value.sortable">
                                <klt-icon
                                  class="sort-btn"
                                  [class.active]="
                                    sortedColumn?.sortedBy === column.key
                                  "
                                  size="xsmall"
                                  color="light"
                                  [icon]="
                                    sortedColumn?.sortedBy !== column.key
                                      ? 'arrow-down'
                                      : sortedColumn?.state === 'asc'
                                        ? 'arrow-up'
                                        : 'arrow-down'
                                  "
                                  (click)="onSort(column.key)"
                                ></klt-icon>
                              </ng-container>
                            </div>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
                <div [class.tableWrap]="stickyTop" #tableWrap>
                  <div tabindex="100">
                    <div
                      class="scrollWrap"
                      #scrollWrap
                      (scroll)="onScroll($event)"
                    >
                      <div>
                        <div *ngIf="loading" class="loading-table">
                          <klt-loading-spinner [size]="'medium'" [show]="true">
                          </klt-loading-spinner>
                        </div>
                        <table class="table" #table>
                          <thead class="tableHead">
                            <ng-container
                              *ngIf="filteredColumnGroups && showColumnGroups"
                            >
                              <tr class="tableRowTooltip">
                                <th
                                  *ngFor="let group of filteredColumnGroups"
                                  class="cell-group"
                                  [class.cell-fixed]="group.sticky"
                                  [attr.colspan]="group.columnDefSize"
                                >
                                  <div class="group">
                                    <span
                                      class="line"
                                      *ngIf="group.sectionTitle"
                                    ></span>

                                    <span class="title">{{
                                      group.sectionTitle
                                    }}</span>
                                    <span
                                      class="line"
                                      *ngIf="group.sectionTitle"
                                    ></span>
                                  </div>
                                </th>
                              </tr>
                            </ng-container>
                            <tr class="row">
                              <th
                                *ngFor="
                                  let column of filteredColumns
                                    | keyvalue: originalOrder
                                "
                                class="table-cell"
                                [class.cell-fixed]="column.value.sticky"
                                [style.height]="autoHeight ? 'auto' : height"
                                [attr.data-header]="column.key"
                              >
                                <div
                                  class="cellWrapper cellContent cellHover table-cell-header"
                                  [class.center-headers]="
                                    column.value.style?.center
                                  "
                                  [class.no-vertical-padding]="
                                    noVerticalPadding
                                  "
                                  [style.padding-top]="verticalPadding"
                                  [style.padding-bottom]="verticalPadding"
                                >
                                  <span
                                    [innerHTML]="column.value.title | safeHtml"
                                    [matTooltipDisabled]="!showTooltip"
                                    [matTooltip]="
                                      column.value.tooltip ?? column.value.title
                                    "
                                    matTooltipClass="header-tooltip"
                                  ></span>
                                  <ng-container *ngIf="column.value.sortable">
                                    <klt-icon
                                      class="sort-btn"
                                      [class.active]="
                                        sortedColumn?.sortedBy === column.key
                                      "
                                      size="xsmall"
                                      color="light"
                                      [icon]="
                                        sortedColumn?.sortedBy !== column.key
                                          ? 'arrow-down'
                                          : sortedColumn?.state === 'asc'
                                            ? 'arrow-up'
                                            : 'arrow-down'
                                      "
                                      (click)="onSort(column.key)"
                                    ></klt-icon>
                                  </ng-container>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container
                              *ngFor="
                                let column of filteredColumns
                                  | keyvalue: originalOrder
                              "
                            >
                              <ng-container
                                *ngFor="let row of data; let trIndex = index"
                              >
                              </ng-container>
                            </ng-container>
                            <ng-container
                              *ngFor="let row of data; let trIndex = index"
                            >
                              <tr class="row listRow" (click)="onClickRow(row)">
                                <ng-container
                                  *ngFor="
                                    let column of filteredColumns
                                      | keyvalue: originalOrder
                                  "
                                >
                                  <ng-container>
                                    <ng-container>
                                      <td
                                        #tableRowData
                                        [ngSwitch]="column.value.type"
                                        (click)="onClickCell(column.key, row)"
                                        class="cell-data"
                                        [class.cell-chart]="
                                          column.value.type === 'chart'
                                        "
                                        [class.group-start]="
                                          column.value.firstOfGroup && groupLine
                                        "
                                        [class.group-end]="
                                          column.value.lastOfGroup && groupLine
                                        "
                                        [class.cell-fixed-ZtyEm8a1]="
                                          column.value.sticky
                                        "
                                        [class.onscroll-shadow]="
                                          scrollLeft && column.value.sticky
                                        "
                                        [class.td-highlight-bottom]="
                                          row[column.key] &&
                                          row[column.key].highlight
                                        "
                                        [class.td-highlight-top]="
                                          row[column.key] &&
                                          row[column.key].highlight
                                        "
                                        [class.td-spacing]="
                                          row[column.key] &&
                                          row[column.key].spacing
                                        "
                                        [style.height]="
                                          autoHeight ? 'auto' : height
                                        "
                                        [style.width]="
                                          equalWidth ? columnWidth : 'unset'
                                        "
                                      >
                                        <div
                                          class="cell-wrapper"
                                          kltChangeHighlight
                                          [row]="row[column.key]"
                                          [class.editable]="
                                            row[column.key] &&
                                            row[column.key].editable
                                          "
                                          [class.no-vertical-padding]="
                                            noVerticalPadding
                                          "
                                          [style.padding-top]="verticalPadding"
                                          [style.padding-bottom]="
                                            verticalPadding
                                          "
                                          [style.height]="
                                            autoHeight ? 'auto' : height
                                          "
                                          (click)="
                                            onEditCell(
                                              row[column.key],
                                              column.key,
                                              $event
                                            )
                                          "
                                        >
                                          <div *ngSwitchCase="'html'">
                                            <p
                                              [class.center-cell]="
                                                row[column.key] &&
                                                row[column.key].style?.center
                                              "
                                              [style.width]="
                                                row[column.key] &&
                                                (row[column.key].style?.width ??
                                                  'unset')
                                              "
                                              [style.white-space]="
                                                row[column.key] &&
                                                (row[column.key].style
                                                  ?.whiteSpace ??
                                                  'unset')
                                              "
                                              [innerHTML]="
                                                row[column.key] &&
                                                  row[column.key].data
                                                  | safeHtml
                                              "
                                            ></p>
                                          </div>
                                          <div *ngSwitchCase="'text'">
                                            <p
                                              [class.center-cell]="
                                                row[column.key] &&
                                                row[column.key].style?.center
                                              "
                                              kltTableNumberFormatter
                                              [value]="
                                                row[column.key] &&
                                                row[column.key].data
                                              "
                                            >
                                              <ng-container
                                                *ngIf="row[column.key]?.number"
                                              >
                                                <klt-number
                                                  [value]="
                                                    row[column.key].number
                                                  "
                                                  [decimalPoints]="
                                                    row[column.key]
                                                      .decimalPoints
                                                  "
                                                ></klt-number>
                                              </ng-container>
                                              <ng-container
                                                *ngIf="!row[column.key]?.number"
                                              >
                                                <div
                                                  #tool
                                                  [matTooltipDisabled]="
                                                    !showTooltipField ||
                                                    (row[column.key] &&
                                                      !row[column.key].tooltip)
                                                  "
                                                  [matTooltip]="
                                                    row[column.key] &&
                                                    (row[column.key].tooltip ??
                                                      column.value.title)
                                                  "
                                                  matTooltipClass="header-tooltip"
                                                >
                                                  {{
                                                    row[column.key] &&
                                                      row[column.key].data
                                                      | negativeFormatter
                                                  }}
                                                </div>
                                              </ng-container>
                                            </p>

                                            <div
                                              *ngIf="
                                                row[column.key] &&
                                                row[column.key]?.editable &&
                                                row[column.key]?.opened
                                              "
                                              class="edit-wizard"
                                              [id]="
                                                'editable-cell-' + column.key
                                              "
                                              kltClickOutside
                                              (clickOutside)="
                                                onDismissWizard(row[column.key])
                                              "
                                            >
                                              <klt-input
                                                type="number"
                                                [value]="
                                                  row[column.key] &&
                                                  (row[column.key].editData
                                                    ?.value ??
                                                    0)
                                                "
                                                [hideNotch]="true"
                                                [hasFocus]="true"
                                                [errorLabel]="''"
                                                [error]="
                                                  row[column.key] &&
                                                  !!row[column.key].editData
                                                    ?.invalid
                                                "
                                                [hideStateIndicator]="true"
                                                (enterPressed)="
                                                  onSaveEditWizard(
                                                    $event,
                                                    row,
                                                    column.key
                                                  )
                                                "
                                              >
                                              </klt-input>
                                              <div class="edit-action">
                                                <klt-icon
                                                  (click)="
                                                    onSaveEditWizard(
                                                      $event,
                                                      row,
                                                      column.key
                                                    )
                                                  "
                                                  icon="tick"
                                                  size="small"
                                                  color="cta"
                                                ></klt-icon>
                                                <div
                                                  class="edit-separator"
                                                ></div>
                                                <klt-icon
                                                  icon="close"
                                                  size="small"
                                                  (click)="
                                                    onCloseEditWizard(
                                                      $event,
                                                      row[column.key]
                                                    )
                                                  "
                                                ></klt-icon>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            *ngSwitchCase="'chart'"
                                            style="height: 48px"
                                          >
                                            @defer (on viewport(tableRowData)) {
                                              <klt-table-cell-graph
                                                [row]="row"
                                              ></klt-table-cell-graph>
                                            }
                                          </div>

                                          <div *ngSwitchCase="'action'">
                                            <ng-container
                                              *ngIf="
                                                row[column.key]?.action
                                                  ?.style === 'basic';
                                                else circle
                                              "
                                            >
                                              <klt-icon
                                                [icon]="
                                                  row[column.key]?.action
                                                    ?.icon || ''
                                                "
                                                (click)="
                                                  onClickAction(
                                                    $event,
                                                    column.key,
                                                    row
                                                  )
                                                "
                                              ></klt-icon>
                                            </ng-container>
                                            <ng-template #circle>
                                              <div class="circle">
                                                <klt-button
                                                  type="icon"
                                                  iconSize="xsmall"
                                                  [border]="'round'"
                                                  [leftIcon]="
                                                    row[column.key]?.action
                                                      ?.icon || ''
                                                  "
                                                  (click)="
                                                    onClickAction(
                                                      $event,
                                                      column.key,
                                                      row
                                                    )
                                                  "
                                                >
                                                </klt-button>
                                              </div>
                                            </ng-template>
                                          </div>
                                        </div>
                                      </td>
                                    </ng-container>
                                  </ng-container>
                                </ng-container>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="totalPages && page && pageSize">
                  <klt-pagination
                    [totalPages]="totalPages"
                    [page]="page"
                    [pageSize]="pageSize"
                    (pageChange)="pageChange.emit($event)"
                  ></klt-pagination>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
