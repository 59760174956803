<span kltNegativeFormatter [value]="value"
  >{{
    value
      | localeCurrency
        : decimalPoints
        : locale
        : currencyCode
        : undefined
        : currencyFormat
      | toPence: pence
      | negativeFormatter
  }}<ng-content></ng-content>
</span>
