import { Injectable } from '@angular/core';
import { ClientSignalStore, ClientStore } from './client.store';

@Injectable({ providedIn: 'root' })
export class ClientStoreService {
  constructor(
    private clientStore: ClientStore,
    private clientSignalStore: ClientSignalStore,
  ) {}

  setReitId(reitId: number) {
    this.clientStore.update({ reitId: reitId });
  }

  setFinancialLoading(isLoading: boolean) {
    this.clientStore.update({ financialIsLoading: isLoading });
  }

  setProjectionLoading(isLoading: boolean) {
    this.clientStore.update({ projectionIsLoading: isLoading });
  }

  setGraphLoading(graphId: string, isLoading: boolean) {
    this.clientStore.update((state) => ({
      graphLoading: {
        ...state.graphLoading,
        [graphId]: isLoading,
      },
    }));
  }

  setScenarios(
    reitId: number,
    scenarios: {
      name: string;
      scenarioId: number;
      isDefault: boolean;
      initialScenarioId?: number;
    }[],
  ) {
    const stateValue = this.clientStore.getValue();

    const isOnInitialState = scenarios.findIndex((e) => !e.isDefault) <= -1;

    const baseScenario: any = scenarios.find(
      (e) => e.isDefault && e.name.toLowerCase() === 'base',
    );
    const goodScenario: any = scenarios.find(
      (e) => e.isDefault && e.name.toLowerCase() === 'good',
    );
    const poorScenario: any = scenarios.find(
      (e) => e.isDefault && e.name.toLowerCase() === 'poor',
    );

    let selectedScenarioId =
      stateValue.selectedScenario[reitId] ?? baseScenario?.scenarioId;
    let selectedScenarios =
      stateValue.selectedScenarios[reitId]?.filter(
        (e) => scenarios.findIndex((p) => p.scenarioId === e) > -1,
      ) || [];
    let assumptionScenarios =
      stateValue.selectedAssumptionScenarios[reitId]?.filter(
        (e) => scenarios.findIndex((p) => p.scenarioId === e) > -1,
      ) || [];

    let rightScenarioId: number | undefined =
      stateValue.selectedRightScenarioId[reitId] ?? baseScenario?.scenarioId;
    let leftScenarioId: number | undefined =
      stateValue.selectedLeftScenarioId[reitId] ?? goodScenario?.scenarioId;

    if (isOnInitialState) {
      selectedScenarioId = baseScenario.scenarioId;

      leftScenarioId = baseScenario?.scenarioId;
      rightScenarioId = goodScenario?.scenarioId;

      selectedScenarios = scenarios.map((e) => e.scenarioId);
      assumptionScenarios = scenarios
        .filter(
          (e) =>
            e.name.toLowerCase() === 'base' || e.name.toLowerCase() === 'good',
        )
        .map((e) => e.scenarioId);
    }

    this.clientStore.update((state) => ({
      selectedRightScenarioId: {
        ...state.selectedRightScenarioId,
        [reitId]: rightScenarioId,
      },
      selectedLeftScenarioId: {
        ...state.selectedLeftScenarioId,
        [reitId]: leftScenarioId,
      },
      scenarios: {
        ...state.scenarios,
        [reitId]: [...scenarios],
      },
      selectedScenario: {
        [reitId]: selectedScenarioId,
      },
      selectedScenarios: {
        ...state.selectedScenarios,
        [reitId]: [
          ...(selectedScenarios.length <= 0
            ? scenarios.filter((e) => e.isDefault).map((e) => e.scenarioId)
            : selectedScenarios),
        ],
      },
      selectedAssumptionScenarios: {
        ...state.selectedAssumptionScenarios,
        [reitId]: [
          ...(assumptionScenarios.length <= 1 ? [] : assumptionScenarios),
        ],
      },
    }));
  }

  setSelectedScenario(reitId: number, scenarioId: number) {
    this.clientStore.update((state) => ({
      selectedScenario: {
        [reitId]: scenarioId,
      },
    }));
  }

  setSelectedLeftScenarioId(reitId: number, scenarioId: number) {
    this.clientStore.update((state) => ({
      selectedLeftScenarioId: {
        [reitId]: scenarioId,
      },
    }));
  }

  setSelectedRightScenarioId(reitId: number, scenarioId: number) {
    this.clientStore.update((state) => ({
      selectedRightScenarioId: {
        [reitId]: scenarioId,
      },
    }));
  }

  setSelectedScenarios(reitId: number, scenarioIds: number[]) {
    this.clientStore.update((state) => ({
      financialIsLoading: true,
      selectedScenarios: {
        ...state.selectedScenarios,
        [reitId]: [
          ...(scenarioIds.length <= 0
            ? state.scenarios[reitId]
                .filter((e) => e.isDefault)
                .map((e) => e.scenarioId)
            : scenarioIds),
        ],
      },
    }));
  }

  addScenario(
    reitId: number,
    scenario: { name: string; scenarioId: number; isDefault: boolean },
  ) {
    this.clientStore.update((state) => ({
      scenarios: {
        ...state.scenarios,
        [reitId]: [...state.scenarios[reitId], scenario],
      },
    }));
  }

  renameScenario(reitId: number, scenarioName: string, scenarioId: string) {
    const updatedScenarios = this.clientStore
      .getValue()
      .scenarios[reitId].filter((e) => {
        if (e.scenarioId === Number(scenarioId)) {
          e.name = scenarioName;
        }
        return e;
      });
    this.setScenarios(reitId, updatedScenarios);
  }

  removeScenario(reitId: number, scenarioId: number) {
    const updatedScenarios = this.clientStore
      .getValue()
      .scenarios[reitId].filter((e) => e.scenarioId !== scenarioId);
    this.setScenarios(reitId, updatedScenarios);
  }

  setSelectedAssumptionScenarios(reitId: number, scenarioIds: number[]) {
    this.setSelectedScenarios(reitId, scenarioIds);
    this.clientStore.update((state) => ({
      selectedAssumptionScenarios: {
        ...state.selectedAssumptionScenarios,
        [reitId]: [...scenarioIds],
      },
    }));
  }

  setStickyKey(elementId: string, isSticky: boolean) {
    this.clientStore.update((state) => ({
      stickyKeys: {
        [elementId]: isSticky,
      },
    }));
  }
  clearSticky() {
    this.clientStore.update((state) => ({
      stickyKeys: {},
    }));
  }
}
