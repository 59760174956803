import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ReitMetadataResponse } from '@kolytics/shared-api';
import { formatNumber, formatPercentage } from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { EChartsOption } from 'echarts';
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared';

import {
  IReitDataResponse,
  IReitProjectionDebtMaturingGraphItem,
  ISeries,
} from '../../../interfaces';
import {
  getColNameByYear,
  getDynamicRange,
  getDynamicRangePercentage,
  roundToNearestMutliple,
} from '../../../utils';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-projection-debt-maturities',
  templateUrl: './projection-debt-maturities.component.html',
  styleUrls: ['./projection-debt-maturities.component.scss'],
  standalone: true,
  imports: [SectionComponent, GraphComponent],
})
export class ProjectionDebtMaturitiesComponent {
  @Input() selectedScenarioName = 'base';
  @Input() height = '415px';
  @Input()
  public set reit(reit: IReitDataResponse | null) {
    if (reit) {
      this.reset(reit.reits.reitProjection.debtMaturingGraph);
    }
  }
  @Input() loading: boolean | null = false;

  @Input() public options: EChartsOption | undefined = {
    grid: {
      show: true,
      backgroundColor: '#ffffff',
      borderWidth: 0,
      containLabel: true,
      top: 10,
      right: 10,
      bottom: 0,
      left: 5,
    },
    xAxis: {
      type: 'category',
      data: [
        '21F',
        '22F',
        '23F',
        '24F',
        '25F',
        '26F',
        '27F',
        '28F',
        '29F',
        '30F+',
      ],
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        lineHeight: 50,
      },
      axisPointer: {
        show: false,
      },
    },
    yAxis: [
      {
        min: (value) => {
          return value.min;
        },
        type: 'value',
        position: 'left',
        axisLabel: {
          formatter: (value: any, index: number) => {
            if (value === 100) {
              return '  Maturing';
            }
            return formatNumber(value, 1);
          },
        },
      },
      {
        min: (value) => {
          return value.min;
        },
        show: true,
        type: 'value',
        position: 'right',
        splitLine: {
          show: true,
        },
        axisLabel: {
          showMinLabel: true,
          showMaxLabel: true,
          formatter: (value: any, index: number) => {
            if (value === 6) {
              return 'Spread';
            }
            return formatPercentage(value, 1);
          },
        },
      },
    ],
    series: [],
    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: function (params: any) {
        if (params.componentType && params.componentType === 'series') {
          let value = '';
          if (params.seriesName.toUpperCase() === 'RATE SPREAD (RHS)') {
            value = formatPercentage(params.value, 1);
          } else {
            value = formatNumber(params.value, 1);
          }
          return `<div style="background: ${
            params.color
          }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);">${params.seriesName.toUpperCase()}-${
            params.name
          }: (${value})</div>`;
        }
        return '';
      },
    },
  };

  public series: ISeries[] = [];

  public lines = [];

  public titles: {
    name: string;
    color: string;
    disabled: boolean;
    text?: string;
  }[] = [
    {
      name: 'MATURING',
      text: `MATURING (${this.selectedScenarioName})`,
      color: colorPallete[GraphId.ProjectionDebtMaturities][0],
      disabled: false,
    },
    {
      name: 'RATE SPREAD (RHS)',
      text: `RATE SPREAD (RHS) (${this.selectedScenarioName})`,
      color: colorPallete[GraphId.ProjectionDebtMaturities][1],
      disabled: false,
    },
  ];

  public constructor(private cdr: ChangeDetectorRef) {}

  public runFilter(
    data: { name: string; color: string; disabled: boolean; text?: string }[],
  ): void {
    this.titles = data;

    this.titles[0].text = `MATURING (${this.selectedScenarioName})`;
    this.titles[1].text = `RATE SPREAD (RHS) (${this.selectedScenarioName})`;

    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter((e) =>
      filter.includes(e.name.toLowerCase()),
    );

    (this.options as EChartsOption).series = [...(series as any)];

    const temp = Object.assign({}, this.options);

    this.options = undefined;

    setTimeout(() => {
      this.options = temp;
      this.cdr.detectChanges();
    }, 0);
  }

  public reset(value: IReitProjectionDebtMaturingGraphItem[]): void {
    value = value.sort((a, b) => a.year - b.year);

    ((this.options as EChartsOption).xAxis as any).data = value.map(
      (e) => e.label,
    );
    const maturing = this.getMaturing(value);
    const spread = this.getSpread(value);

    this.series = [maturing, spread] as any;

    const min = Math.min(...maturing.data);
    const max = Math.max(...maturing.data);

    const aroundMin = roundToNearestMutliple(min);
    const aroundInterval = Math.abs(aroundMin / 4);

    const rateMin = Math.min(...spread.data);
    const rateMax = Math.max(...spread.data);

    const [spreadMin, spreadMax, spreadInterval] = getDynamicRangePercentage(
      rateMin,
      rateMax,
    );

    // const intervalLinesLastIndex = this.intervalLinesLastIndex(spreadMin, spreadMax, spreadInterval);

    ((this.options as EChartsOption).yAxis as YAXisOption[])[0].min = aroundMin;
    ((this.options as EChartsOption).yAxis as YAXisOption[])[0].max = 0;
    (
      ((this.options as EChartsOption).yAxis as YAXisOption[])[0] as any
    ).interval = aroundInterval;

    ((this.options as EChartsOption).yAxis as YAXisOption[])[1].min = spreadMin;
    ((this.options as EChartsOption).yAxis as YAXisOption[])[1].max = spreadMax;

    (
      ((this.options as EChartsOption).yAxis as YAXisOption[])[1] as any
    ).interval = spreadInterval;

    (
      ((this.options as EChartsOption).yAxis as XAXisOption[])[0]
        .axisLabel as any
    ).formatter = (v: any) => {
      if (v === 0) {
        return 'Maturing';
      }

      return formatNumber(v, 1);
    };

    // Overrides formatter above
    (
      ((this.options as EChartsOption).yAxis as XAXisOption[])[1]
        .axisLabel as any
    ).formatter = (v: any, index: number) => {
      if (v >= spreadMax) {
        return 'Spread';
      }
      return formatPercentage(v, 1);
    };
    this.series = [maturing, spread] as any;

    this.runFilter(this.titles);
  }

  public getMaturing(value: IReitProjectionDebtMaturingGraphItem[]) {
    const items = value.map((e) => e.maturing);
    return {
      // ensure we don't have -0
      data: items.map((e: number) => (e === 0 ? 0 : e)),
      type: 'bar',
      stack: 'one',
      color: colorPallete[GraphId.ProjectionDebtMaturities][0],
      name: 'MATURING',
      itemStyle: {
        borderRadius: 2,
      },
    };
  }

  public getSpread(value: IReitProjectionDebtMaturingGraphItem[]) {
    const data = {
      data: value.map((e) => {
        return e.rateSpread;
      }),
      type: 'scatter',
      color: colorPallete[GraphId.ProjectionDebtMaturities][1],
      name: 'RATE SPREAD (RHS)',
      symbolSize: 8,
      yAxisIndex: 1,
    };

    return data;
  }
}
