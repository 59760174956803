import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { KltModalService } from '@kolytics/shared-components';
import { SelectProfileDialogComponent } from '../../../dialogs';
import { first, tap } from 'rxjs/operators';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
    selector: 'klt-cash-flow-statement-compare',
    templateUrl: './cash-flow-statement-compare.component.html',
    styleUrls: ['./cash-flow-statement-compare.component.scss'],
    standalone: true,
    imports: [SectionComponent],
})
export class CashFlowStatementCompareComponent implements OnInit {
  @Output()
  public onSelect: EventEmitter<any> = new EventEmitter<any>();

  constructor(protected readonly kltModalService: KltModalService) {}

  ngOnInit(): void {}

  public handleClick(): void {
    this.kltModalService
      .open(SelectProfileDialogComponent, {
        data: {},
      })
      .afterClosed.pipe(
        first(),
        tap((value) => {
          this.onSelect.emit(value);
        }),
      )
      .subscribe();
  }
}
