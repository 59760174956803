<div class="assumptions--content">
  <div class="description">
    Compare your assumptions with Kolytics scenarios
  </div>
  <h6 class="title">Please select an assumption scenario to modify</h6>

  <div [ngClass]="assumptions.length > 3 ? 'profile-creation' : 'content'">
    @for (assumption of assumptions; track assumption) {
      <app-assumption-item
        (assumptionSelected)="onAssumptionSeleted($event)"
        [selectedScenarioId]="selectedAssumption?.scenarioId"
        [assumptionProfileItem]="assumption"
      ></app-assumption-item>
    }
  </div>
</div>
