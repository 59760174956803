import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { ReitMetadataResponse } from '@kolytics/shared-api';
import { formatCurrency } from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import {
  IReitOverview,
  IReitOverviewPropertyValueByType,
} from '../../../interfaces';
import { TreeMapModule } from '@swimlane/ngx-charts';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-overview-property-value-type',
  templateUrl: './overview-property-value-type.component.html',
  styleUrls: ['./overview-property-value-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SectionComponent, TreeMapModule],
})
export class OverviewPropertyValueTypeComponent {
  single: any[] = [];

  @Input() height = '365px';
  @Input() width = '100%';

  @Input() countryCode!: string;
  @Input() metadata!: ReitMetadataResponse;

  @Input()
  set reit(reit: IReitOverview | undefined) {
    if (reit && reit.propertyValueByType.length) {
      reit.propertyValueByType.forEach((s, i) => {
        let displayValue =
          s.value > 1000
            ? `${
                formatCurrency(
                  s.value / 1000,
                  0,
                  this.metadata.culture,
                  this.metadata.currencyCode,
                  undefined,
                  this.metadata.currencyFormat,
                ) + 'b'
              }`
            : `${
                formatCurrency(
                  s.value,
                  0,
                  this.metadata.culture,
                  this.metadata.currencyCode,
                  undefined,
                  this.metadata.currencyFormat,
                ) + 'm'
              }`;
        this.single.push({
          name: s.sector,
          value: s.value,
          extra: { idx: i, percentage: s.percentage, display: displayValue },
        });
      });
    }
  }

  colorScheme: any = {
    domain: colorPallete[GraphId.OverviewPropertyByType],
  };

  customColors = (name: any) => {
    const value = this.single.find((s) => s.name === name).extra.idx;
    return this.colorScheme.domain[value];
  };

  labelFormatting(c: { data: any; label: any }) {
    return `<div class='value-information'>
            <div class='name'>${c.label}</div>
            <div class='total'>
              <div class='total-value'>${c.data.extra.display}</div>
              <div class='total-percent'>(${c.data.extra.percentage}%)</div>
            </div>
          </div>`;
  }
  tooltipDisabled: boolean = false;
  gradient: boolean = false;
  animations: boolean = true;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe,
  ) {}
  protected getCurrency(data: any, currencyCode: string, suffix?: string) {
    const value = parseFloat(data);

    let result = '';
    if (currencyCode === 'GBP') {
      result =
        this.currencyPipe.transform(value, currencyCode, 'symbol', '0.2-2') ||
        '';
    }
    result =
      this.currencyPipe.transform(value, currencyCode, 'symbol', '0.2-2') || '';

    result = `${result}${suffix}`;
    return result;
  }
}
