import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import {
  KltModalService,
  PaginationPageChange,
  TableSort,
} from '@kolytics/shared-components';
import { KltToastService } from 'libs/shared-components/src/lib/services/toast/toast.service';
import { mySectorTableData } from '../../../../shared/constants/mock-data';
import { IReitData } from '../../interfaces';
import { BaseReit } from '../../utils/components/base-reit/base-reit.util';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ProductFeature } from '@kolytics/types/common';
import { SectorRegionService } from '../../services/sector-region.service';
import { ExportService, ReitService } from '@kolytics/shared-api';
import { ButtonComponent } from '../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { FeaturedDirective } from '../../../../../../../../libs/shared-components/src/lib/directives/featured.directive';
import { LinkActionComponent } from '../../components/link-action/link-action.component';

import { ReitTableNavigationComponent } from '../../../../../../../../libs/shared-components/src/lib/components/table-navigation/table-navigation.component';
import { TableToolbarDirective } from '../../../../../../../../libs/shared-components/src/lib/components/table/table.component';
import { TableV2Component } from '../../../../../../../../libs/shared-components/src/lib/components/table-v2/table-v2.component';

@Component({
  selector: 'klt-sectors',
  templateUrl: './sectors.component.html',
  styleUrls: ['./sectors.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TableV2Component,
    TableToolbarDirective,
    ReitTableNavigationComponent,
    LinkActionComponent,
    FeaturedDirective,
    ButtonComponent,
  ],
})
export class SectorsComponent extends BaseReit {
  pageIndex = 1;
  pageSize = 1000;
  orderBy = '';
  orderByDirection = '';

  excelLoading = false;
  decimalPoints = 1;
  isPopout = false;
  downloadsFeature = ProductFeature.Downloads;
  public reitTableData: IReitData = mySectorTableData as IReitData;

  constructor(
    protected readonly kltModalService: KltModalService,
    protected readonly kltToastService: KltToastService,
    protected readonly service: ReitService,
    private exportService: ExportService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly router: Router,
    private sectorRegionService: SectorRegionService,
  ) {
    super(kltModalService, kltToastService, router);
  }

  sort(e: TableSort | undefined) {
    this.orderBy = e?.sortedBy ?? '';
    this.orderByDirection = e?.state ?? '';
    this.fetchSectors();
  }

  public fetchSectors(regionsIds?: number[]): void {
    this.service
      .apiReitSectorsGet(
        regionsIds,
        this.pageIndex,
        this.pageSize,
        this.orderBy,
        this.orderByDirection,
      )
      .pipe(
        tap((res) => {
          this.reitTableData = {
            ...(mySectorTableData as IReitData),
            data: res.data!.sectorData!.map((item: any) => ({
              id: { data: item.sectorId + '' },
              sector: { data: item.sectorName + '    ' },

              nnniy: {
                data: item.nnniy?.toFixed(this.decimalPoints) + '%',
                color: 'success',
              },
              growth: {
                data: item.growth?.toFixed(this.decimalPoints) + '%',
                color: 'warn',
              },

              devt: {
                data: item.devt?.toFixed(this.decimalPoints) + '%',
                color: 'warn',
              },
              unLeveredIrr: {
                data: item.unleveredIrr?.toFixed(this.decimalPoints) + '%',
              },

              wacd: { data: item.wacd?.toFixed(this.decimalPoints) + '%' },
              ltv: { data: item.ltv?.toFixed(this.decimalPoints) + '%' },
              debtBoost: {
                data: item.debtBoost?.toFixed(this.decimalPoints) + '%',
              },
              leveredIrr: {
                data: item.leveredIrr?.toFixed(this.decimalPoints) + '%',
                color: 'success',
              },
              openingPDGav: {
                data: item.openingPDGav?.toFixed(this.decimalPoints) + '%',
                color: 'success',
              },
              closingPDGav: {
                data: item.closingPDGav?.toFixed(this.decimalPoints) + '%',
                color: 'success',
              },
              impIrr: {
                data: item.impliedIrr?.toFixed(this.decimalPoints) + '%',
                color: 'success',
              },

              eqRP: {
                data: item.eqRiskPrem?.toFixed(this.decimalPoints) + '%',
                color: 'warn',
              },
              requiredReturn: {
                data: item.requiredReturn?.toFixed(this.decimalPoints) + '%',
                color: 'success',
              },

              excessReturns: {
                data: item.excessReturns?.toFixed(this.decimalPoints) + '%',
                color: 'success',
              },
              niy: { data: item.niy?.toFixed(this.decimalPoints) + '%' },
              irr: { data: item.r?.toFixed(this.decimalPoints) + '%' },
              r: { data: item.r?.toFixed(this.decimalPoints) + '%' },
              b: { data: item.b?.toFixed(this.decimalPoints) },
              correlationToMkt: {
                data: item.correlationToMkt?.toFixed(this.decimalPoints),
              },

              giy: { data: item.giy?.toFixed(this.decimalPoints) + '%' },
              opex: { data: item.opex?.toFixed(this.decimalPoints) + '%' },
              capex: { data: item.capex?.toFixed(this.decimalPoints) + '%' },
              gandA: { data: item.gandA?.toFixed(this.decimalPoints) + '%' },
              investmentIrr: {
                data: item.investmentIrr?.toFixed(this.decimalPoints) + '%',
              },
            })),
            total: 0,
          };
          super.ngOnInit();
          this.cdr.detectChanges();
        }),
      )
      .subscribe();
  }
  async ngOnInit() {
    this.isPopout = this.router.url.indexOf('/popout/') > -1;
    this.reitTableData.data = [
      {
        sector: { data: 'Loading...' },
        nnniy: { data: 'Loading...' },
        growth: { data: 'Loading...' },
        devt: { data: 'Loading...' },
        unLeveredIrr: { data: 'Loading...' },
        wacd: { data: 'Loading...' },
        ltv: { data: 'Loading...' },
        debtBoost: { data: 'Loading...' },
        leveredIrr: { data: 'Loading...' },
        openingPDGav: { data: 'Loading...' },
        closingPDGav: { data: 'Loading...' },
        impIrr: { data: 'Loading...' },
        eqRP: { data: 'Loading...' },
        requiredReturn: { data: 'Loading...' },
        //'correlation': { data: 'Loading...' },
        excessReturns: { data: 'Loading...' },
        niy: { data: 'Loading...' },
        irr: { data: 'Loading...' },
        r: { data: 'Loading...' },
        b: { data: 'Loading...' },
        correlationToMkt: { data: 'Loading...' },

        giy: { data: 'Loading...' },
        opex: { data: 'Loading...' },
        capex: { data: 'Loading...' },
        gandA: { data: 'Loading...' },
        investmentIrr: { data: 'Loading...' },
      },
    ];
    this.cdr.detectChanges();

    await this.sectorRegionService.init();
    this.fetchSectors(this.sectorRegionService.regionsIds);
  }

  public popout(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/reits/popout/reits/sectors`]),
    );

    window.open(url, '_blank');
  }

  public onRowClickId(id?: string) {
    this.router.navigate([`reits/sectors/sector/${id}/overview`], {
      queryParamsHandling: 'preserve',
    });
  }

  downloadExcel() {
    this.excelLoading = true;
    this.exportService.apiExportSectorsGet().subscribe(
      (data: Blob) => {
        const file = new Blob([data], { type: 'text/csv' });
        const fileURL = URL.createObjectURL(file);

        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'Sector Metrics.xlsx';
        document.body.appendChild(a);
        a.click();

        this.excelLoading = false;
      },
      (err) => {
        this.excelLoading = false;
      },
    );
  }

  onPageChange(e: PaginationPageChange) {
    this.pageIndex = e.pageIndex;
    this.fetchSectors();
  }

  public openRegionFilter(): void {
    this.sectorRegionService.openRegionFilter(undefined, () => {
      this.fetchSectors(this.sectorRegionService.regionsIds);
    });
  }
}
