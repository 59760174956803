import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSize } from '@kolytics/shared-assets';
import { IconComponent } from '../../../../../../shared-assets/src/lib/components/icon/icon.component';
import { NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'klt-checkbox-button',
    templateUrl: './checkbox-button.component.html',
    styleUrls: ['./checkbox-button.component.scss'],
    standalone: true,
    imports: [
        NgSwitch,
        NgSwitchCase,
        NgTemplateOutlet,
        IconComponent,
    ],
})
export class CheckboxButtonComponent {
  @Input() size: 'small' | 'large' | 'tiny' = 'small';

  @Input() id!: number;
  @Input() label: string | null = null;
  @Input() value: string | null = null;
  @Input() description: string | null = null;
  @Input() checked: boolean | undefined = false;
  @Input() disabled = false;
  @Input() iconColor = 'light';
  @Input() iconSize: IconSize = 'xsmall';

  @Output() selectionChanged = new EventEmitter<{
    label: string | null;
    selected: boolean;
    id?: number;
  }>();

  // tickIcon: IconImage = 'tick';
  // tickIconColor: IconColor = 'light';
  // tickIconSize: IconSize = 'small';

  select(): void {
    if (this.disabled) return;
    this.checked = !this.checked;
    this.selectionChanged.emit({
      id: this.id,
      label: this.label,
      selected: this.checked,
    });
  }
}
