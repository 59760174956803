<klt-section title="Balance sheet strength">
  <div class="graph-box">
    <div class="graph">
      <klt-graph
        height="340px"
        [loading]="titles.length == 0"
        [options]="options"
        [titles]="titles"
        (filter)="runFilter($event)"
      >
      </klt-graph>
      <div class="bottom">
        <b>Debt / EBITDA</b>
      </div>
    </div>
    <div>
      <div class="legend">
        <div class="shapes">
          <div class="shape">
            <div id="diamond"></div>
            <div class="text">: {{ pastYears }}</div>
          </div>
          <div class="shape">
            <div id="polygon"></div>
            <div class="text">: {{ presentYears }}</div>
          </div>
          <div class="shape">
            <div id="circle"></div>
            <div class="text">: {{ futureYears }}</div>
          </div>
        </div>
      </div>
      <div class="explained">
        <h4>Balance sheet strength explained</h4>
        <p [innerHTML]="desc"></p>
      </div>
    </div>
  </div>
</klt-section>
