import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'klt-dropdown-wrapper',
    templateUrl: './dropdown-wrapper.component.html',
    styleUrls: ['./dropdown-wrapper.component.scss'],
    standalone: true,
})
export class DropdownWrapperComponent implements OnInit, OnDestroy {
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('dropDownContainer', { static: true })
  dropDownContainer!: ElementRef;
  private onDestroy$: Subject<boolean> = new Subject<boolean>();

  constructor(@Inject(DOCUMENT) private readonly _document: Document) {}

  ngOnInit(): void {
    fromEvent(document, 'click')
      .pipe(
        filter(() => !!this.dropDownContainer),
        map((event: Event) => event.target),
        filter(
          (el: EventTarget | null) =>
            !(el && this.dropDownContainer.nativeElement.contains(el)),
        ),
        takeUntil(this.onDestroy$),
      )
      .subscribe(() => {
        this.onClose.emit(false);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
