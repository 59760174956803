<klt-section title="Prem/(Disc) to Gross Asset Value (GAV)">
  <div class="graph-box">
    <klt-graph
      [loading]="showLoading ? (loading$ | async) : false"
      [height]="height"
      [options]="options"
      [titles]="titles"
      (filter)="runFilter($event)"
    >
    </klt-graph>
  </div>
</klt-section>
