import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
    selector: '[kltAsHtml]',
    standalone: true,
})
export class AsHtmlDirective implements OnInit, OnChanges {
  @Input('kltAsHtml') htmlString: string = '';

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.htmlString.currentValue !== changes.htmlString.previousValue) {
      this.renderInnerHtml(this.htmlString);
    }
  }

  ngOnInit(): void {
    this.renderInnerHtml(this.htmlString);
  }

  private renderInnerHtml(html: string): void {
    this._renderer.setProperty(this._el.nativeElement, 'innerHTML', html);
  }
}
