import { Component, Input } from '@angular/core';
import { Scoreboard } from '../../interfaces/scoreboard.interface';
import { NgClass } from '@angular/common';

/**
 * @name ScoreboardComponent
 * @desc Scoreboard showing board title and score in a nice view
 *
 * @param scoreBoards<ScoreBoard[]|undefined> - Will receive list of ScoreBoards
 */

@Component({
    selector: 'klt-scoreboard',
    templateUrl: './scoreboard.component.html',
    styleUrls: ['./scoreboard.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class ScoreboardComponent {
  @Input() title!: string;
  @Input() state!: string;
  @Input() score: string | undefined;
  @Input() margin!: boolean;
}
