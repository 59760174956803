<div class="overview-investability-section">
  <klt-section type="none" title="{{ reitName }} Investability">
    <div class="value-box">
      <div class="value-zone">
        <div class="value-left half">
          <klt-graph
            [loading]="loading"
            [options]="options"
            [titles]="titles"
            [height]="height"
            [width]="width"
            (filter)="runFilter($event)"
          >
          </klt-graph>
        </div>
        <div class="value-right">
          <div class="company-box">
            <div
              class="company-zone"
              [innerHTML]="investabilityDescription"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </klt-section>
</div>
