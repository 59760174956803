import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency } from '@kolytics/shared-functions';

@Pipe({
  name: 'localeCurrency',
  standalone: true,
})
export class LocaleCurrencyPipe implements PipeTransform {
  transform(
    value?: number,
    decimalPoints?: number,
    locale?: string,
    currencyCode?: string,
    pence?: boolean,
    currencyFormat?: string,
  ): string | undefined {
    if (!value || isNaN(value)) return '';
    return formatCurrency(
      value,
      decimalPoints,
      locale,
      currencyCode,
      pence,
      currencyFormat,
    );
  }
}
