import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { IconComponent } from '../../../../../shared-assets/src/lib/components/icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'klt-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    NgClass,
    IconComponent
],
})
export class ToastComponent implements OnInit {
  @Input()
  public config: {
    showClose: boolean;
    lineCount: 1 | 2;
    type: 'info' | 'error';
    main: { icon: 'success' | 'info' | 'error'; message: string };
    extra: { icon: 'success' | 'info' | 'error'; message: string } | undefined;
  } = {
    showClose: false,
    main: {
      icon: 'info',
      message: '',
    },
    type: 'info',
    lineCount: 1,
    extra: undefined,
  };

  constructor() {}

  public ngOnInit(): void {}

  public onClose(): void {}

  public onOke(): void {}
}
