import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
    selector: 'klt-separator',
    templateUrl: './separator.component.html',
    styleUrls: ['./separator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class SeparatorComponent implements OnInit {
  @Input() size: 'small' | 'medium' | 'large' = 'large';
  constructor() {}

  ngOnInit(): void {}
}
