import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'klt-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  standalone: true,
  imports: [],
})
export class LoadingSpinnerComponent implements OnInit {
  @Input() sticky: boolean = false;
  @Input() size: 'xsmall' | 'small' | 'medium' | 'large' = 'small';
  @Input() show: boolean = false;
  @Input() label: string = '';
  @Input() classes = '';
  @Input() styles = '';
  constructor() {}

  ngOnInit(): void {}
}
