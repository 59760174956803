@if (headerVisible) {
  <div class="klt-modal-header">
    <h3>{{ modalTitle }}</h3>
    <button (click)="closeModal.emit()">
      <klt-icon icon="close" size="small"></klt-icon>
    </button>
  </div>
}
<div class="klt-modal-content">
  <ng-content></ng-content>
</div>
