<div class="overview--content">
  <klt-overview-scoreboard
    [data]="scoreboardData"
    [metadata]="metadata"
  ></klt-overview-scoreboard>
  <klt-separator></klt-separator>
  <div class="overview-zone">
    <div class="overview-zone-item">
      <klt-overview-performance
        [metadata]="metadata"
      ></klt-overview-performance>
    </div>
    <div class="overview-zone-item">
      <klt-overview-gva [metadata]="metadata"></klt-overview-gva>
    </div>
  </div>
  <klt-separator></klt-separator>
  <klt-overview-investment [reit]="reit$ | async"></klt-overview-investment>
  <klt-separator></klt-separator>
  <klt-overview-reit-update></klt-overview-reit-update>
  <klt-separator></klt-separator>
  <div class="overview-zone">
    <div class="overview-zone-item">
      <klt-overview-property-value-location
        [metadata]="metadata"
        [countryCode]="countryCode"
        [reit]="reitOverview"
      >
      </klt-overview-property-value-location>
    </div>
    <div class="overview-zone-item">
      <klt-overview-property-value-type
        [metadata]="metadata"
        [countryCode]="countryCode"
        [reit]="reitOverview"
      >
      </klt-overview-property-value-type>
    </div>
  </div>
  <klt-separator></klt-separator>
  <klt-overview-investability
    [reitResponse]="(reit$ | async)?.reits"
    [investabilityDescription]="investabilityDescription"
  >
  </klt-overview-investability>
  <klt-separator></klt-separator>
  <klt-overview-company
    [websiteUrl]="websiteUrl"
    [companyBackground]="companyBackground"
  ></klt-overview-company>
  <klt-separator></klt-separator>
  <klt-overview-team></klt-overview-team>
</div>
