import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
  ClientSignalState,
  ClientState,
  createInitialSignalState,
  createInitialState,
} from './client.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'client' })
export class ClientStore extends Store<ClientState> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'clientSignals' })
export class ClientSignalStore extends Store<ClientSignalState> {
  constructor() {
    super(createInitialSignalState());
  }
}
