import { Component, OnInit } from '@angular/core';
import { TableV2Component } from '../../../../../libs/shared-components/src/lib/components/table-v2/table-v2.component';

@Component({
    selector: 'klt-table-page',
    templateUrl: './table-page.component.html',
    styleUrls: ['./table-page.component.css'],
    standalone: true,
    imports: [TableV2Component],
})
export class TablePageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  allReitTableData: any = {
    sections: [
      {
        sectionTitle: 'MARKET PRICING',
        columnDefs: [
          'price',
          'pdToNav',
          'dailyMove',
          'wHigh',
          'wLow',
          'wHistory',
        ],
      },
      { sectionTitle: 'SCALE', columnDefs: ['mcap', 'liquidity'] },
      {
        sectionTitle: 'VALUATION / RETURNS',
        columnDefs: ['impIrr', 'impValue', 'affo', 'dividendYield'],
      },
      {
        sectionTitle: 'BALANCE SHEET',
        columnDefs: ['ltv', 'ebitda', 'kolyticsRating'],
      },
    ],
    headers: {
      sector: {
        title: 'Sector',
        fill: 'dark',
        sortable: true,
        type: 'text',
        sticky: true,
      },
      price: {
        title: 'Price',
        fill: 'dark',
        sortable: true,
        type: 'text',
        tooltip: "Most recent day's closing share price",
      },
      pdToNav: {
        title: 'P/(D)\nto NAV',
        fill: 'dark',
        sortable: true,
        type: 'text',
        tooltip:
          'Premium / (Discount) to Net Asset Value based on the last closing share price. Skewed by leverage',
      },
      dailyMove: {
        title: 'Daily\nmove',
        fill: 'dark',
        sortable: true,
        type: 'text',
      },
      wHigh: {
        title: 'vs. 52w\nhigh',
        fill: 'dark',
        sortable: true,
        type: 'text',
        tooltip: 'Based on daily closing values',
      },
      wLow: {
        title: 'vs. 52w\nlow',
        fill: 'dark',
        sortable: true,
        type: 'text',
        tooltip: 'Based on daily closing values',
      },
      wHistory: {
        title: '52w history',
        fill: 'dark',
        sortable: true,
        type: 'chart',
      },
      mcap: {
        title: 'Mcap (m)',
        fill: 'dark',
        sortable: true,
        type: 'text',
        tooltip: 'The share price multiplied by the outstanding shares',
      },
      liquidity: {
        title: 'Liquidity\n(M/day)',
        fill: 'dark',
        sortable: true,
        type: 'text',
        tooltip: 'Average daily liquidity in local currency',
      },
      impIrr: {
        title: 'Implied\nIRR',
        fill: 'dark',
        sortable: true,
        type: 'text',
        tooltip:
          'The expected IRR an equity investor could realise over the medium-term.<br>Includes the impact of changes in Dividends, changes in NTA, leverage, starting / expected changes in premium to net assets',
      },
      impValue: {
        title: 'Implied\nvalue/Sf',
        fill: 'dark',
        sortable: true,
        type: 'text',
        tooltip:
          "The implied value per square foot based on previous day's closing share price",
      },
      affo: {
        title: 'AFFO\nyield',
        fill: 'dark',
        sortable: true,
        type: 'text',
        tooltip: 'Adjusted Funds From Operations yield',
      },
      dividendYield: {
        title: 'Dividend\nyield',
        fill: 'dark',
        sortable: true,
        type: 'text',
      },
      ltv: {
        title: 'LTV',
        fill: 'dark',
        sortable: true,
        type: 'text',
        tooltip:
          'Loan-to-Value: Net debt divided by total property portfolio value',
      },
      ebitda: {
        title: 'Debt/\nEBITDA',
        fill: 'dark',
        sortable: true,
        type: 'text',
        tooltip: 'Net Debt to EBITDA ratio',
      },
      kolyticsRating: {
        title: 'Rating',
        fill: 'dark',
        sortable: true,
        type: 'text',
        tooltip:
          'Primarily based on the excess return, with adjustments made on a portfolio-wide basis to account<br>for correlations between similar companies and sectors',
      },
      'action-my-reit': { title: 'My REITs', fill: 'dark', type: 'action' },
    },
    stickLeftColumns: {
      company: { title: 'Company', fill: 'dark', sortable: true, type: 'text' },
    },
    data: [],
    total: 0,
  };
}
