import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { KltModalService } from '@kolytics/shared-components';
import { first, tap } from 'rxjs/operators';
import { SelectProfileDialogComponent } from '../../dialogs';
import { IScenarios } from '../../interfaces';
import { PortfolioTableComponent } from './portfolio-table/portfolio-table.component';
import { PortfolioCompareComponent } from './portfolio-compare/portfolio-compare.component';

@Component({
    selector: 'klt-portfolio-assumptions',
    templateUrl: './portfolio-assumptions.component.html',
    styleUrls: ['./portfolio-assumptions.component.scss'],
    standalone: true,
    imports: [PortfolioCompareComponent, PortfolioTableComponent],
})
export class PortfolioAssumptionsComponent implements OnInit {
  scenarios!: IScenarios;

  tableData: any;

  constructor(protected readonly kltModalService: KltModalService) {}

  ngOnInit(): void {}

  public openDialog(): void {
    this.kltModalService
      .open(SelectProfileDialogComponent, {
        data: {},
      })
      .afterClosed.pipe(first())
      .subscribe((value) => {
        this.tableData = value;
      });
  }

  onSelectScenarios(value: IScenarios): void {
    this.scenarios = value;
  }
}
