import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { EChartsOption, SeriesOption } from 'echarts';
import {
  IReitDataResponse,
  IReitProjectionPremToGavGraphData,
  IReitProjectionPremToGavGraphItem,
  ISeries,
} from '../../../interfaces';
import { colorPallete, GraphId, GRAPH_ID } from '@kolytics/types/graph';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ClientStoreService } from 'apps/client/src/app/store/client.store.service';
import { Observable } from 'rxjs';
import { ClientQuery } from 'apps/client/src/app/store/client.query';
import { formatPercentage } from '@kolytics/shared-functions';
import {
  ReitMetadataResponse,
  ReitProjectionService,
  ReitService,
} from '@kolytics/shared-api';
import { AsyncPipe } from '@angular/common';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@UntilDestroy()
@Component({
  selector: 'klt-projection-gav',
  templateUrl: './projection-gav.component.html',
  styleUrls: ['./projection-gav.component.scss'],
  standalone: true,
  imports: [SectionComponent, GraphComponent, AsyncPipe],
})
export class ProjectionGavComponent implements OnInit {
  private reitId!: number;
  private graphId = GRAPH_ID.PROJECTIONS.PREM_DISC_TO_GAV;

  @Input() height = '415px';
  @Input() selectedScenarioName = 'base';
  @Input() showLoading = true;
  loading$!: Observable<boolean>;

  private _updated!: boolean;
  get updated(): boolean {
    return this._updated;
  }
  @Input() set updated(v: boolean) {
    this._updated = v;

    if (v) {
      this.initializeGraph();
    }
  }

  private _reit!: IReitDataResponse | null;
  public get reit(): IReitDataResponse | null {
    return this._reit;
  }

  @Input()
  public set reit(res: IReitDataResponse | null) {
    if (res) {
      this._sectorName = res.reits.reit.sector.name;
      this._reitName = res.reits.reit.name;

      this.titles[0].name =
        res.reits.reitProjection.premToGavGraph.reitData[0].pointer;
      this.titles[1].name =
        res.reits.reitProjection.premToGavGraph.sectorData[0].pointer;
      this.titles[2].name =
        res.reits.reitProjection.premToGavGraph.allSectorData[0].pointer;

      this.titles[0].text = `${res.reits.reitProjection.premToGavGraph.reitData[0].pointer}`;
      // this.titles[1].text = `${res.reits.reitProjection.premToGavGraph.sectorData[0].pointer} (base)`;
      // this.titles[2].text = `${res.reits.reitProjection.premToGavGraph.allSectorData[0].pointer} (base)`;
      this.titles[1].text = `${res.reits.reitProjection.premToGavGraph.sectorData[0].pointer}`;
      this.titles[2].text = `${res.reits.reitProjection.premToGavGraph.allSectorData[0].pointer}`;

      this._reit = res;
      this._value = res.reits.reitProjection.premToGavGraph;
      this.reset(this._value);
    } else {
      // this.reset([]);
    }
  }

  @Input() public options: EChartsOption | undefined = {
    grid: {
      show: true,
      borderWidth: 0,
      containLabel: true,
      top: 10,
      right: 10,
      bottom: 0,
      left: 5,
    },
    xAxis: {
      type: 'category',
      data: [],
      boundaryGap: false,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        margin: 22,
      },
      splitArea: {
        show: true,
      },
      axisPointer: {
        show: true,
        type: 'none',
        label: {
          show: false,
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: any, index: number) => {
          return formatPercentage(value, 1);
        },
        margin: 20,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
      },
    },
    series: [],
    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: function (params: any) {
        if (params.componentType && params.componentType === 'series') {
          return `<div style="background: ${
            params.color
          }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);">${params.seriesName.toUpperCase()}-${
            params.name
          }: (${formatPercentage(params.value, 1)})</div>`;
        }
        return '';
      },
    },
  };

  public series: ISeries[] = [];

  public lines = [];

  public titles: {
    name: string;
    color: string;
    disabled: boolean;
    text?: string;
  }[] = [
    {
      name: 'REIT NAME',
      text: 'REIT NAME',
      color: colorPallete[GraphId.ProjectionPremDiscToGav][0],
      disabled: false,
    },
    {
      name: 'REIT SECTOR',
      text: 'REIT SECTOR',
      color: colorPallete[GraphId.ProjectionPremDiscToGav][1],
      disabled: false,
    },
    {
      name: 'ALL SECTORS',
      text: 'ALL SECTORS',
      color: colorPallete[GraphId.ProjectionPremDiscToGav][2],
      disabled: false,
    },
  ];

  protected _sectorName = 'REIT SECTOR';
  protected _reitName = 'REIT NAME';
  protected _value!: IReitProjectionPremToGavGraphData;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly service: ReitService,
    private reitProjectionService: ReitProjectionService,
    private clientFacade: ClientFacadeService,
    private store: ClientStoreService,
    private query: ClientQuery,
  ) {
    this.loading$ = this.query.selectGraphLoading$(this.graphId);
  }

  ngOnInit(): void {
    if (!this.reit) {
      this.initializeGraph();
    }
  }

  private initializeGraph() {
    this.store.setGraphLoading(this.graphId, true);
    this.clientFacade
      .getReitId()
      .pipe(
        untilDestroyed(this),
        tap((reitId: number) => (this.reitId = reitId)),
        switchMap((reitId: number) =>
          this.clientFacade.getSelectedScenario(reitId),
        ),
        switchMap((selectedScenarioId: number) =>
          this.reitProjectionService.apiReitReitIdProjectionPremDiscToGavScenarioIdGet(
            this.reitId,
            selectedScenarioId,
          ),
        ),
        tap(() => this.store.setGraphLoading(this.graphId, false)),
      )
      .subscribe((data) => {
        this.reit = data;
      });
  }

  public runFilter(
    data: { name: string; color: string; disabled: boolean; text?: string }[],
  ): void {
    this.titles = data;
    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter((e: ISeries) =>
      filter.includes(e.name.toLowerCase()),
    );

    (this.options as EChartsOption).series = [
      ...(series as any),
      ...this.lines,
    ];

    this.options = Object.assign({}, this.options);

    this.cdr.detectChanges();
  }

  public reset(values: IReitProjectionPremToGavGraphData): void {
    const onlyUnique = (value: any, index: number, self: any) => {
      return self.indexOf(value) === index;
    };
    const dataYears = [
      ...values.allSectorData.map((e) => e.year),
      ...values.reitData.map((e) => e.year),
      ...values.sectorData.map((e) => e.year),
    ].filter(onlyUnique);

    if (true) {
      // value = value.sort((a, b) => a.year - b.year);
      ((this.options as EChartsOption).xAxis as any).data =
        values.allSectorData.map((e) => e.label);

      const middle =
        dataYears.length % 2 === 0
          ? dataYears.length / 2 - 1
          : parseInt((dataYears.length / 2).toString().split('.')[0], 0);

      const d1 = this.getPointerArea(
        values.reitData[0].pointer,
        values.reitData,
        colorPallete[GraphId.ProjectionPremDiscToGav][0],
        middle,
      );
      const d2 = this.getPointerLine(
        values.sectorData[0].pointer,
        values.sectorData,
        colorPallete[GraphId.ProjectionPremDiscToGav][1],
        middle,
      );
      const d3 = this.getPointerLine(
        values.allSectorData[0].pointer,
        values.allSectorData,
        colorPallete[GraphId.ProjectionPremDiscToGav][2],
        middle,
      );

      this.series = [...d1, ...d2, ...d3] as any;

      this.runFilter(this.titles);
    } else {
      (this.options as EChartsOption).series = [];
    }
  }

  public getPointerArea(
    pointerName: string,
    value: IReitProjectionPremToGavGraphItem[] = [],
    color: string,
    middle: number,
  ) {
    const actuals = value.filter((e) => e.label.endsWith('A'));
    const lastActual = actuals[actuals.length - 1];
    return [
      {
        data: value.map((e) => (e.year <= lastActual.year ? e.pdValue : null)),
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: color,
        name: pointerName,
        areaStyle: {
          color: color,
          opacity: 1,
        },
        lineStyle: {
          width: 2,
        },
      },
      {
        data: value.map((e) => (e.year >= lastActual.year ? e.pdValue : null)),
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: color,
        name: pointerName,
        areaStyle: {
          color: color,
          opacity: 0.6,
        },
        lineStyle: {
          width: 2,
        },
      },
    ] as SeriesOption[];
  }

  public getPointerLine(
    pointerName: string,
    value: IReitProjectionPremToGavGraphItem[] = [],
    color: string,
    middle: number,
  ) {
    const actuals = value.filter((e) => e.label.endsWith('A'));
    const lastActual = actuals[actuals.length - 1];
    return [
      {
        data: value.map((e) => (e.year <= lastActual.year ? e.pdValue : null)),
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: color,
        name: pointerName,
        lineStyle: {
          width: 2,
        },
      },
      {
        data: value.map((e) => (e.year >= lastActual.year ? e.pdValue : null)),
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: color,
        name: pointerName,
        lineStyle: {
          type: 'dashed',
          width: 2,
        },
      },
    ] as SeriesOption[];
  }
}
