import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KltModalRefService } from 'libs/shared-components/src/lib/services/modal/modal-ref.service';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { ButtonComponent } from '../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { InputExpandComponent } from '../../../../../../../../libs/shared-components/src/lib/components/form/input-expand/input-expand.component';
import { AsyncPipe } from '@angular/common';
import { CheckboxComponent } from '../../../../../../../../libs/shared-components/src/lib/components/form/checkbox/checkbox.component';
import { ModalComponent } from '../../../../../../../../libs/shared-components/src/lib/components/modal/modal.component';

export interface SelectColumn {
  key: string;
  title: string;
  selected: boolean;
}
export interface FilterColumnData {
  showSearch?: boolean;
  availableColumns: SelectColumn[];
  filteredColumns: string[];
}

@Component({
    selector: 'klt-select-column-dialog',
    templateUrl: './select-column-dialog.component.html',
    styleUrls: ['./select-column-dialog.component.scss'],
    standalone: true,
    imports: [
    ModalComponent,
    CheckboxComponent,
    InputExpandComponent,
    ButtonComponent,
    AsyncPipe
],
})
export class SelectColumnDialogComponent {
  showSearch = true;
  searchExpanded: boolean = false;
  isAllToggled: boolean = false;
  isIntermediateToggled: boolean = false;
  availableColumns: { key: string; title: string }[];

  selectedColumns: string[];

  filterFormControl = new FormControl();
  filteredColumns$: Observable<{ key: string; title: string }[]>;

  constructor(
    @Inject('KLT_MODAL_DATA') private modalData: FilterColumnData,
    private kltModalRefService: KltModalRefService,
  ) {
    this.showSearch = this.modalData.showSearch ?? true;
    this.availableColumns = this.modalData.availableColumns || [];
    this.selectedColumns = this.modalData.filteredColumns || [];
    this.isAllToggled =
      this.selectedColumns.length === this.availableColumns.length;
    this.isIntermediateToggled =
      this.selectedColumns.length > 0 &&
      this.selectedColumns.length < this.availableColumns.length;

    this.filteredColumns$ = this.filterFormControl.valueChanges.pipe(
      debounceTime(100),
      startWith(''),
      map((query) =>
        this.availableColumns.filter((col) =>
          col.key.toLowerCase().includes(query),
        ),
      ),
    );
  }

  onClose(): void {
    this.kltModalRefService.close(SelectColumnDialogComponent);
  }
  onSubmit(): void {
    this.kltModalRefService.close(
      SelectColumnDialogComponent,
      this.selectedColumns,
    );
  }

  onValueChange(query: { formValue: string } | string): void {
    if (!query) {
      query = '';
    }

    let queryString: string = '';

    if (typeof query === 'string') {
      queryString = query;
    } else {
      queryString = query.formValue;
    }

    if (!queryString) {
      queryString = '';
    }
    if (queryString.length < 3 && queryString !== '') {
      return;
    }

    this.filterFormControl.patchValue(queryString);
  }

  onSelectColumn(
    formState: { value: boolean; status: string },
    col: string,
  ): void {
    if (formState.value) {
      this.selectedColumns.push(col);
    } else {
      this.selectedColumns.splice(this.selectedColumns.indexOf(col), 1);
    }
    this.isAllToggled =
      this.selectedColumns.length === this.availableColumns.length;
    this.isIntermediateToggled =
      this.selectedColumns.length > 0 &&
      this.selectedColumns.length < this.availableColumns.length;
  }

  onToggleAll(formState: { value: boolean; status: string }): void {
    if (formState.value) {
      this.selectedColumns = [
        ...this.availableColumns.map((column) => column.key),
      ];
    } else {
      this.selectedColumns = [];
    }
    this.isAllToggled =
      this.selectedColumns.length === this.availableColumns.length;
    this.isIntermediateToggled =
      this.selectedColumns.length > 0 &&
      this.selectedColumns.length < this.availableColumns.length;
  }

  isColumnSelected(col: string): boolean {
    return this.selectedColumns.indexOf(col) !== -1;
  }
}
