@if (open) {
  <div #wrapper class="wrapper" [@dropdownAnimation]>
    <ul class="list">
      @for (option of options; track option) {
        <li
          class="list-item"
          (click)="selectOption(option)"
          >
          <span>{{ option.label }}</span>
        </li>
      }
      @for (action of actions; track action; let i = $index) {
        <li
          class="list-item action"
          [class.first]="i === 0"
          (click)="selectAction(action)"
          >
          @if (action.icon) {
            <klt-icon
              [icon]="action.icon"
              [color]="action.color ? action.color : 'dark'"
            ></klt-icon>
          }
          <span class="color-{{ action.color ? action.color : 'dark' }}">{{
            action.label
          }}</span>
        </li>
      }
    </ul>
  </div>
}
