export enum ProductFeature {
  Overview = 'overview',
  Projections = 'projections',
  Financials = 'financials',
  Downloads = 'downloads',
  Assumptions = 'assumptions',
  Research = 'research',
  AllReits = 'all.reits',
  MyReits = 'my.reits',
  Sectors = 'sectors',
  MarketUpdateEmail = 'market.email.update',
  Recommendations = 'recommendations',
  AnalystTime = 'analyst.time',
  Preview = 'my.reits',
  DataAnalytics = 'data.analytics',
}
