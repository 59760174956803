import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, map, switchMap, take, tap } from 'rxjs/operators';

import {
  DropDownAction,
  DropDownOption,
  KltModalService,
  TableColumnGroup,
  TableColumns,
  TableData,
} from '@kolytics/shared-components';
import { autoDestroy, followDestroy } from '@kolytics/shared-functions';
import { KltToastService } from 'libs/shared-components/src/lib/services/toast/toast.service';
import {
  IAssumptionProfileScenarioResponse,
  IBaseReit,
  IBaseReitRowItem,
  IReitData,
} from '../../../interfaces';
import {
  RemoveScenarioModalComponent,
  RenameScenarioModalComponent,
  ResetScenarioModalComponent,
  ResetToLastSaveModalComponent,
  CreateScenarioModalComponent,
} from '../../../modals';
import { getColNameByYear } from '../../../utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReitStoreService } from '../../../services/reit-store.service';
import { AssumptionBaseComponent } from '../../../utils/components/assumption/assumption-base-component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ClientFacadeService } from 'apps/client/src/app/client-facade.service';
import { forkJoin, fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClientSignalStore } from 'apps/client/src/app/store/client.store';
import { snapshotManager } from '@datorama/akita';
import { ClientSignalState } from 'apps/client/src/app/store/client.state';
import { DropdownActionsComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/form/dropdown-actions/dropdown-actions.component';
import { ButtonComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { DropdownWrapperComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/form/dropdown-wrapper/dropdown-wrapper.component';
import { LoadingSpinnerComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/loading-spinner/loading-spinner.component';
import { LinkActionComponent } from '../../../components/link-action/link-action.component';
import {
  TableToolbarDirective,
  TableComponent,
} from '../../../../../../../../../libs/shared-components/src/lib/components/table/table.component';
import { TableV2Component } from '../../../../../../../../../libs/shared-components/src/lib/components/table-v2/table-v2.component';
import {
  ReitAssumptionService,
  ReitService,
  UserAssumptionsService,
} from '@kolytics/shared-api';

@Component({
  selector: 'klt-portfolio-table',
  templateUrl: './portfolio-table.component.html',
  styleUrls: ['./portfolio-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          height: '200px',
          opacity: 1,
          backgroundColor: '#fff',
        }),
      ),
      state(
        'closed',
        style({
          height: 'auto',
          opacity: 0.8,
          backgroundColor: '#fff',
        }),
      ),
      transition('* => closed', [animate('1s')]),
      transition('* => open', [animate('0.5s')]),
    ]),
  ],
  standalone: true,
  imports: [
    TableV2Component,
    TableToolbarDirective,
    LinkActionComponent,
    LoadingSpinnerComponent,
    DropdownWrapperComponent,
    ButtonComponent,
    DropdownActionsComponent,
    TableComponent,
  ],
})
@UntilDestroy()
@autoDestroy()
export class PortfolioTableComponent
  extends AssumptionBaseComponent
  implements OnInit
{
  public more: boolean = false;
  public moreOptions: DropDownOption[] = [
    //{ label: 'Reset to last save', value: 0 },
    { label: 'Reset scenario', value: 1 },
    { label: 'Rename scenario', value: 2 },
    { label: 'Delete scenario', value: 3 },
  ];
  public moreActions: DropDownAction[] = [
    {
      label: 'Create scenario',
      value: 'create',
      icon: 'plus',
      color: 'cta',
    },
  ];

  @Input() decimalPoints = 2;

  public reitTableData: IReitData = {} as IReitData;
  reitId!: number;
  loading = true;

  updatedIds: number[] = [];
  private readonly baseScenarioName = 'Base';
  private readonly userScenarioName = 'User';

  @Input() scenarioAId!: number;
  @Input() scenarioBId!: number;

  private _updatedData: any;
  get updatedData(): any {
    return this._updatedData;
  }
  @Input() set updatedData(v: any) {
    this._updatedData = v;

    if (v) {
      this.buildTableData(v);
      this.loading = true;
      this.loading = false;
    }
  }

  @Input() showToolbar = true;
  @Input() showTopHeader = true;
  @Input() useOldTable = false;

  @Input() single = false;
  @Input() singleBase = false;

  constructor(
    protected readonly kltModalService: KltModalService,
    protected readonly kltToastService: KltToastService,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected reitService: ReitService,
    protected ref: ChangeDetectorRef,
    protected spinner: NgxSpinnerService,
    protected reitStoreService: ReitStoreService,
    protected cdr: ChangeDetectorRef,
    protected clientFacade: ClientFacadeService,
    protected clientSignalStore: ClientSignalStore,
    protected reitAssumptionService: ReitAssumptionService,
    protected userAssumptionService: UserAssumptionsService,
  ) {
    super(
      kltModalService,
      kltToastService,
      router,
      reitService,
      userAssumptionService,
      spinner,
      reitStoreService,
      cdr,
      reitAssumptionService,
    );
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setReitId();

    if (!this.allData) {
      this.initiateAssumptionScenario();
      this.listeningStorageUpdates(
        this.reitId,
        this.scenarioAId,
        'portfolio',
        () => this.refreshData(this.scenarioAId, false),
        () => this.initiateAssumptionScenario(false),
      );
    } else {
      if (this.single) {
        if (this.singleBase) {
          this.buildTableDataBase(this.allData);
        } else {
          this.buildTableDataSelected(this.allData);
        }
      } else {
        this.buildTableData(this.allData);
      }
    }
  }

  popout(): void {
    const key = 'portfolio';
    this.storeTableInfoInStorage(key);
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        `/reits/popout/portfolio/${this.reitId}/${this.scenarioAId}/${this.scenarioBId}`,
      ]),
    );

    window.open(
      url,
      '_blank',
      'location=yes,height=800,width=600,scrollbars=yes,status=yes',
    );
  }

  storeTableInfoInStorage(key: string) {
    localStorage.setItem(key, JSON.stringify(this.reitTableData));
  }

  public toggleMore(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.more = !this.more;
  }

  public save(): void {
    this.kltToastService.success('Changes save', 4000);
  }

  public solveOption(option: DropDownOption): void {
    switch (option.value) {
      case 0:
        this.resetToLastSave();
        break;
      case 1:
        this.resetScenario();
        break;
      case 2:
        this.renameScenario();
        break;
      case 3:
        this.deleteScenario();
        break;
    }
  }

  public solveAction(action: DropDownAction): void {
    switch (action.value) {
      case 'create':
        this.createScenario();
        break;
    }
  }

  protected resetToLastSave(): void {
    this.kltModalService
      .open(ResetToLastSaveModalComponent)
      .afterClosed.pipe(
        first(),
        tap((value: boolean) => {
          if (value) {
            this.kltToastService.success('Last save reset', 4000);
          }
        }),
        followDestroy(this),
      )
      .subscribe();
  }

  protected resetScenario(): void {
    this.kltModalService
      .open(ResetScenarioModalComponent, { data: { reitId: this.reitId } })
      .afterClosed.pipe(first(), followDestroy(this))
      .subscribe(() => {
        this.initiateAssumptionScenario(false);
        this.clientSignalStore.update((state) => ({
          portfolio: {
            ...state.portfolio,
            [this.reitId]: {
              scenarioId: this.scenarioAId,
              updatedAt: Date.now(),
              resetted: true,
            },
          },
        }));
      });
  }

  protected renameScenario(): void {
    this.kltModalService
      .open(RenameScenarioModalComponent, { data: { reitId: this.reitId } })
      .afterClosed.pipe(
        first(),
        tap((data: { name: string; scenarioId: string }) => {
          if (data.name) {
            if (data.name === 'REIT') {
              this.kltToastService.error(
                'This name is already used for a scenario',
                4000,
              );
            } else {
              this.updateSectionTitle(data);
              this.kltToastService.success('Scenario renamed', 4000);
            }
          }
        }),
        followDestroy(this),
      )
      .subscribe();
  }

  protected deleteScenario(): void {
    this.kltModalService
      .open(RemoveScenarioModalComponent, { data: { reitId: this.reitId } })
      .afterClosed.pipe(
        first(),
        tap((value: boolean) => {
          if (value) {
            this.kltToastService.success('Scenario deleted', 4000);
            this.router.navigate([`/reits/${this.reitId}/assumptions`]);
          }
        }),
        followDestroy(this),
      )
      .subscribe();
  }

  protected createScenario(): void {
    this.kltModalService
      .open(CreateScenarioModalComponent)
      .afterClosed.pipe(
        first(),
        tap((value: string | undefined) => {
          if (value) {
            if (value === 'REIT') {
              this.kltToastService.error(
                'This name is already used for a scenario',
                4000,
              );
            } else {
              this.router.navigate([
                `/reits/${this.reitId}/assumptions/create/${value}`,
              ]);
            }
          }
        }),
        followDestroy(this),
      )
      .subscribe();
  }

  setReitId() {
    const queryReitId = this.activatedRoute.snapshot.params['reitId'];
    const scenarioAId = this.activatedRoute.snapshot.params['scenarioA'];
    const scenarioBId = this.activatedRoute.snapshot.params['scenarioB'];
    this.isPopup = queryReitId && scenarioAId && scenarioBId;
    if (this.isPopup) {
      this.reitId = queryReitId;
      this.scenarioAId = scenarioAId;
      this.scenarioBId = scenarioBId;
    } else {
      const path = document.location.pathname;
      const pathSplit = `${path}`.split('/');
      this.reitId = Number(pathSplit[2]);
      this.scenarioAId = Number(pathSplit[pathSplit.length - 2]);
    }
  }

  protected valueOfReitId(): string {
    return this.reitId.toString();
  }

  protected valueOfUserScenarioId(): number | string {
    return this.scenarioAId;
  }

  // Run only once
  initiateAssumptionScenario(notifyUpdates: boolean = false) {
    this.clientFacade
      .getReitId()
      .pipe(
        take(1),
        switchMap((reitId: number) =>
          this.clientFacade.getSelectedLeftScenarioId(reitId).pipe(
            take(1),
            switchMap((left) =>
              this.clientFacade.getSelectedRightScenarioId(reitId).pipe(
                take(1),
                map((right) => [left, right]),
              ),
            ),
          ),
        ),
        switchMap(([left, right]) =>
          this.reitAssumptionService.apiReitReitIdAssumptionComparePost(
            Number(this.reitId),
            {
              isDefaultScenarioA: true,
              isDefaultScenarioB: true,
              reitId: Number(this.reitId),
              scenarioAId: left,
              scenarioBId: right,
              userId: 1,
            },
          ),
        ),
      )
      .subscribe((data: any) => {
        this.buildTableData(data);
        this.ref.markForCheck();

        if (notifyUpdates) {
          this.clientSignalStore.update((state) => ({
            portfolio: {
              ...state.portfolio,
              [this.reitId]: {
                scenarioId: this.scenarioAId,
                updatedAt: Date.now(),
              },
            },
          }));
        }
      });
  }

  buildTableDataBase(data: any) {
    this.scenarioBId = data.result.userBaseScenario.scenarioId;

    const { sections, header } = this.createSectionsAndHeadersBase(data);
    this.allData = this.setTableDataBase(data);
    this.reitTableData = {
      sections: sections,
      headers: header,
      data: this.allData,
      stickLeftColumns: {},
    } as IReitData;
  }
  buildTableDataSelected(data: any) {
    this.scenarioBId = data.result.userBaseScenario.scenarioId;

    const { sections, header } = this.createSectionsAndHeadersSelected(data);
    this.allData = this.setTableDataSelected(data);
    this.reitTableData = {
      sections: sections,
      headers: header,
      data: this.allData,
      stickLeftColumns: {},
    } as IReitData;
  }

  buildTableData(data: any) {
    this.scenarioAId = data.result.userScenario.scenarioId;
    this.scenarioBId = data.result.userBaseScenario.scenarioId;

    const { sections, header } = this.createSectionsAndHeaders(data);
    this.allData = this.setTableData(data);
    this.reitTableData = {
      sections: sections,
      headers: header,
      data: this.allData,
      stickLeftColumns: {},
    } as IReitData;

    this.loading = false;
  }

  createSectionsAndHeaders(data: IAssumptionProfileScenarioResponse) {
    const sections: TableColumnGroup[] = [];
    if (!this.useOldTable) {
      sections.push({
        sectionTitle: '',
        columnDefs: ['inputOutput'],
        sticky: true,
      });
    }
    const length =
      data.result.userAssumptionScenario?.length ??
      data.result.assumptionScenario.length;
    const header: TableColumns = {
      inputOutput: {
        title: 'Input / output',
        fill:
          (data.result.userAssumptionScenario?.length ??
            data.result.assumptionScenario.length) > 0
            ? 'purple'
            : 'dark',
        sortable: true,
        type: 'text',
        sticky: true,
      },
      subPortfolio: {
        title: 'Sub-portfolio',
        fill: length > 0 ? 'purple' : 'dark',
        sortable: true,
        type: 'text',
      },
    };
    const userScenarios =
      data.result.userAssumptionScenario ?? data.result.assumptionScenario;
    if (userScenarios.length > 0) {
      const yearsBySection = userScenarios[0].rows.map((x) => x.year).sort();
      sections.push({
        sectionTitle: data.result.userScenario.name,
        columnDefs: yearsBySection.map((year: number) =>
          this.generateColumnDefs(year, this.userScenarioName),
        ),
        spacing: true,
        spacingFill: 'light',
      });

      for (const year of yearsBySection) {
        const colName = this.generateColumnDefs(year, this.userScenarioName);
        header[colName] = {
          title: getColNameByYear(year),
          fill: 'purpleDisabled',
          sortable: false,
          type: 'text',
          style: { center: true },
        };
      }
    }

    const baseScenarios =
      data.result.baseAssumptionScenario ||
      data.result.userBaseAssumptionScenario;
    if (baseScenarios.length > 0) {
      const yearsBySection = baseScenarios[0].rows.map((x) => x.year).sort();
      sections.push({
        sectionTitle:
          data.result?.userBaseScenario?.name || this.baseScenarioName,
        columnDefs: yearsBySection.map((year: number) =>
          this.generateColumnDefs(year, this.baseScenarioName),
        ),
      });

      for (const year of yearsBySection) {
        const colName = this.generateColumnDefs(year, this.baseScenarioName);
        header[colName] = {
          title: getColNameByYear(year),
          fill: 'dark',
          sortable: false,
          type: 'text',
          style: { center: true },
        };
      }
    }
    return { sections, header };
  }
  createSectionsAndHeadersSelected(data: IAssumptionProfileScenarioResponse) {
    const sections: TableColumnGroup[] = [];
    const header: TableColumns = {
      inputOutput: {
        title: 'Input / output',
        fill: data.result.assumptionScenario.length > 0 ? 'purple' : 'dark',
        sortable: true,
        type: 'text',
        sticky: true,
      },
      subPortfolio: {
        title: 'Sub-portfolio',
        fill: 'purple',
        sortable: true,
        type: 'text',
      },
    };
    const userScenarios = data.result.assumptionScenario;
    if (userScenarios.length > 0) {
      const yearsBySection = userScenarios[0].rows.map((x) => x.year).sort();
      sections.push({
        sectionTitle: data.result.userScenario.name,
        columnDefs: yearsBySection.map((year: number) =>
          this.generateColumnDefs(year, this.userScenarioName),
        ),
        spacing: true,
        spacingFill: 'light',
      });

      for (const year of yearsBySection) {
        const colName = this.generateColumnDefs(year, this.userScenarioName);
        header[colName] = {
          title: getColNameByYear(year),
          fill: 'purpleDisabled',
          sortable: false,
          type: 'text',
          style: { center: true },
        };
      }
    }
    return { sections, header };
  }
  createSectionsAndHeadersBase(data: IAssumptionProfileScenarioResponse) {
    const sections: TableColumnGroup[] = [];
    const header: TableColumns = {
      inputOutput: {
        title: 'Input / output',
        fill: data.result.baseAssumptionScenario.length > 0 ? 'purple' : 'dark',
        sortable: true,
        type: 'text',
        sticky: true,
      },
      subPortfolio: {
        title: 'Sub-portfolio',
        fill:
          data.result?.userAssumptionScenario?.length > 0 ? 'purple' : 'dark',
        sortable: true,
        type: 'text',
      },
    };
    const baseScenarios =
      data.result.baseAssumptionScenario ||
      data.result.userBaseAssumptionScenario;
    if (baseScenarios.length > 0) {
      const yearsBySection = baseScenarios[0].rows.map((x) => x.year).sort();
      sections.push({
        sectionTitle:
          data.result?.userBaseScenario?.name || this.baseScenarioName,
        columnDefs: yearsBySection.map((year: number) =>
          this.generateColumnDefs(year, this.baseScenarioName),
        ),
      });

      for (const year of yearsBySection) {
        const colName = this.generateColumnDefs(year, this.baseScenarioName);
        header[colName] = {
          title: getColNameByYear(year),
          fill: 'dark',
          sortable: false,
          type: 'text',
          style: { center: true },
        };
      }
    }
    return { sections, header };
  }

  generateColumnDefs(year: number, scenarioName: string) {
    const colName = getColNameByYear(year);
    return `${colName}_${scenarioName}`;
  }

  setTableDataSelected(data: IAssumptionProfileScenarioResponse): TableData[] {
    const userScenarios = data.result.assumptionScenario.filter(
      (x) => x.subPortfolioName,
    );

    if (userScenarios.length > 0) {
      return userScenarios.map((item: IBaseReit, index: number) => {
        let userItem = null;
        if (userScenarios.length > 0) {
          userItem = userScenarios.find(
            (x) =>
              x.fieldName === item.fieldName &&
              x.subPortfolioName === item.subPortfolioName,
          );
          if (!userItem) {
            userItem = {
              ...item,
              editable: false,
              rows: item.rows.map((x) => {
                return { ...{ ...x, fieldValue: 0 } };
              }),
            };
          }
        }
        return this.createTableItem(userItem, item, userScenarios, index);
      });
    }
    return [];
  }

  setTableDataBase(data: IAssumptionProfileScenarioResponse): TableData[] {
    const baseScenarios = (
      data.result.baseAssumptionScenario ||
      data.result.userBaseAssumptionScenario
    ).filter((x) => x.subPortfolioName);

    if (baseScenarios.length > 0) {
      return baseScenarios.map((item: IBaseReit, index: number) => {
        let baseItem = null;
        if (baseScenarios.length > 0) {
          baseItem = baseScenarios.find(
            (x) =>
              x.fieldName === item.fieldName &&
              x.subPortfolioName === item.subPortfolioName,
          );
          if (!baseItem) {
            baseItem = {
              ...item,
              editable: false,
              rows: item.rows.map((x) => {
                return { ...{ ...x, fieldValue: 0 } };
              }),
            };
          }
        }
        return this.createTableItemBase(item, baseScenarios, index);
      });
    }
    return [];
  }
  setTableData(data: IAssumptionProfileScenarioResponse): TableData[] {
    const userScenarios = (
      data.result.userAssumptionScenario ?? data.result.assumptionScenario
    ).filter((x) => x.subPortfolioName);
    const baseScenarios = (
      data.result.baseAssumptionScenario ||
      data.result.userBaseAssumptionScenario
    ).filter((x) => x.subPortfolioName);

    if (baseScenarios.length > 0) {
      return baseScenarios.map((item: IBaseReit, index: number) => {
        let userItem = null;
        if (userScenarios.length > 0) {
          userItem = userScenarios.find(
            (x) =>
              x.fieldName === item.fieldName &&
              x.subPortfolioName === item.subPortfolioName,
          );
          if (!userItem) {
            userItem = {
              ...item,
              editable: false,
              rows: item.rows.map((x) => {
                return { ...{ ...x, fieldValue: 0 } };
              }),
            };
          }
        }
        return this.createTableItem(userItem, item, baseScenarios, index);
      });
    }
    return [];
  }

  createTableItemBase(
    baseItem: IBaseReit,
    scenarios: IBaseReit[],
    index: number,
  ) {
    const spacing = this.isSpacing(scenarios, baseItem.subPortfolioName, index);
    const value: TableData = {
      inputOutput: { data: baseItem.fieldName, spacing: spacing, fill: 'none' },
      subPortfolio: {
        data: baseItem.subPortfolioName,
        spacing: spacing,
      },
      subPortfolioId: {
        data: baseItem.rows[0].subPortfolio.id as any,
        spacing: spacing,
        fill: 'none',
      },
    };

    baseItem.rows.forEach((row: IBaseReitRowItem) => {
      const yearLabel = this.generateColumnDefs(
        row.year,
        this.baseScenarioName,
      );
      value[yearLabel] = {
        data:
          row.fieldValue.toFixed(this.decimalPoints) +
          (baseItem.type === 'percentage' ? '%' : ''),
        dataType: baseItem.type,
        editable: baseItem.editable,
        style: { center: true },
        editData: {
          value: row.fieldValue,
          validate: (value: string) => {
            return +value > 0;
          },
          invalidErrorMessage: 'Invalid Value',
        },
        id: row.id,
        spacing: spacing,
      } as any;
    });
    return value;
  }

  createTableItem(
    userItem: IBaseReit | null,
    baseItem: IBaseReit,
    scenarios: IBaseReit[],
    index: number,
  ) {
    const spacing = this.isSpacing(scenarios, baseItem.subPortfolioName, index);
    const value: TableData = {
      inputOutput: { data: baseItem.fieldName, spacing: spacing, fill: 'none' },
      methodName: { data: baseItem.methodName, spacing: spacing, fill: 'none' },
      subPortfolio: {
        data: baseItem.subPortfolioName,
        spacing: spacing,
        fill: 'none',
      },
      subPortfolioId: {
        data: baseItem.rows[0].subPortfolio.id as any,
        spacing: spacing,
        fill: 'none',
      },
    };

    baseItem.rows.forEach((row: IBaseReitRowItem) => {
      const yearLabel = this.generateColumnDefs(
        row.year,
        this.baseScenarioName,
      );
      value[yearLabel] = {
        data:
          row.fieldValue.toFixed(this.decimalPoints) +
          (baseItem.type === 'percentage' ? '%' : ''),
        dataType: baseItem.type,
        editable: baseItem.editable,
        editData: {
          value: row.fieldValue,
          validate: (value: string) => {
            return +value > 0;
          },
          invalidErrorMessage: 'Invalid Value',
        },
        id: row.id,
        spacing: spacing,
        style: { center: true },
      } as any;
    });
    userItem?.rows.forEach((row: IBaseReitRowItem) => {
      const yearLabel = this.generateColumnDefs(
        row.year,
        this.userScenarioName,
      );
      value[yearLabel] = {
        data:
          row.fieldValue.toFixed(this.decimalPoints) +
          (userItem.type === 'percentage' ? '%' : ''),
        dataType: baseItem.type,
        editable: userItem.editable,
        editData: {
          value: row.fieldValue,
          validate: (value: string) => {
            return +value > 0;
          },
          invalidErrorMessage: 'Invalid Value',
        },
        id: row.id,
        spacing: spacing,
        style: { center: true },
      } as any;
    });
    return value;
  }

  isSpacing(items: IBaseReit[], subPortfolioName: string, index: number) {
    const firstIndex = items.findIndex(
      (x) => x.subPortfolioName === subPortfolioName,
    );
    const totalsubPortfolioNameByName = items.filter(
      (x) => x.subPortfolioName === subPortfolioName,
    ).length;
    return (
      index === firstIndex + totalsubPortfolioNameByName - 1 &&
      index !== items.length - 1
    );
  }

  updateSectionTitle(data: { name: string; scenarioId: string }) {
    if (this.reitTableData.sections.length == 2) {
      this.reitTableData.sections[0].sectionTitle = data.name;
      this.reitTableData.data = [...this.reitTableData.data];
      this.clientFacade.renameScenario(this.reitId, data.name, data.scenarioId);
      this.ref.markForCheck();

      this.clientSignalStore.update((state) => ({
        status: {
          ...state.status,
          [this.reitId]: {
            shouldUpdate: true,
          },
        },
      }));
    }
  }

  onCellSave({
    value,
    row,
    column,
  }: {
    value: string;
    row: any;
    column: any;
  }): void {
    this.reitTableData.headers.size;
    const methodName = row.methodName;
    const fieldName = row.inputOutput;
    const subPortfolio = row.subPortfolioId;
    const { id: rowId, dataType } = row[column] as any;

    console.log(row);

    if (this.reitId === null || !fieldName || !subPortfolio || !column) return;

    this.reitAssumptionService
      .apiReitReitIdAssumptionQueuePost(this.reitId, {
        fieldValue: parseFloat(value),
        rowId,
        scenarioId: this.scenarioAId,
        type: dataType,
        methodName: methodName.data,
        subPortfolioName: row.subPortfolio.data,
        fieldName: row.inputOutput.data,
        subPortfolioId: row.subPortfolioId.data,
      })
      .pipe(take(1))
      .subscribe(
        (data: any) => {
          const fValue =
            dataType === 'percentage'
              ? parseFloat(value) + '%'
              : parseFloat(value) + '';
          this.reitTableData.data = this.reitTableData.data.map(
            (item, index) =>
              index === row
                ? {
                    ...item,
                    [column]: {
                      ...item[column],
                      data: fValue,
                    },
                  }
                : item,
          );

          this.queueRunning = data.assumptionQueueState === 2;
          this.queueCount = data.queueCount;

          if (this.queueState === 1) {
            console.log('[Queue] Timer started');
            this.queueInterval = setInterval(() => {
              this.checkQueue(this.scenarioAId);
            }, 1000);
          }
          this.queueState = data.assumptionQueueState;
        },
        (err) => {
          this.queueRunning = false;
        },
      );
  }

  refreshData(scenarioId: number, notifyUpdates: boolean) {
    this.clientFacade
      .getReitId()
      .pipe(
        take(1),
        switchMap((reitId: number) =>
          this.clientFacade.getSelectedLeftScenarioId(reitId).pipe(
            take(1),
            switchMap((left) =>
              this.clientFacade.getSelectedRightScenarioId(reitId).pipe(
                take(1),
                map((right) => [left, right]),
              ),
            ),
          ),
        ),
        switchMap(([left, right]) =>
          this.reitAssumptionService.apiReitReitIdAssumptionComparePost(
            Number(this.reitId),
            {
              isDefaultScenarioA: true,
              isDefaultScenarioB: true,
              reitId: Number(this.reitId),
              scenarioAId: left,
              scenarioBId: right,
              userId: 1,
            },
          ),
        ),
      )
      .subscribe((data: any) => {
        const allData = this.setTableData(data);
        const { sections, header } = this.createSectionsAndHeaders(data);

        let updatesAvailable = false;
        Object.keys(header).forEach((key) => {
          this.allData.forEach((oldDataItem: any, index) => {
            const oldDataId = oldDataItem[key].id;
            const oldDataValue = oldDataItem[key].data;
            if (oldDataId) {
              const newData = allData.find((e: any) => e[key].id === oldDataId);
              if (newData && newData[key].data !== oldDataValue) {
                this.allData[index][key] = {
                  ...allData[index][key],
                  fill: allData[index][key].fill,
                };
                updatesAvailable = true;
              }
            }
          });
        });

        if (updatesAvailable && notifyUpdates) {
          this.clientSignalStore.update((state) => ({
            portfolio: {
              ...state.portfolio,
              [this.reitId]: {
                scenarioId: Number(scenarioId),
                updatedAt: Date.now(),
              },
            },
          }));
        }

        this.cdr.detectChanges();
      });
  }

  checkQueue(scenarioId: number) {
    super.checkQueue(this.reitId, scenarioId, 1, () => {
      this.refreshData(scenarioId, true);
    });
  }
}
