<div class="map--wrapper">
  <div #mapElement class="map" id="map" style="width: 100%; height: 100%"></div>

  <div style="display: none">
    @for (feature of source().features; track feature) {
      <div>
        <klt-map-tooltip
          #matTooltip
          [feature]="feature"
          [displayPropertyData]="displayPropertyData"
        ></klt-map-tooltip>
      </div>
    }
    <div #iconElement>
      <klt-icon icon="pin" color="purple" size="medium"></klt-icon>
    </div>
  </div>

  <div class="map-plus-and-extra">
    <button class="plus" (click)="plus()">
      <klt-icon icon="plus"></klt-icon>
    </button>
    <button class="minus" (click)="minus()">
      <klt-icon icon="minus"></klt-icon>
    </button>
  </div>
  @if (!closeButtonVisible) {
    <button
      class="map-expand"
      (click)="expand.emit()"
      >
      <b>EXPAND</b>
      <klt-icon icon="expand"></klt-icon>
    </button>
  }
  @if (closeButtonVisible) {
    <button class="map-close" (click)="close.emit()">
      <klt-icon icon="close"></klt-icon>
    </button>
  }
</div>
