import { ChangeDetectorRef, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { snapshotManager } from '@datorama/akita';
import { followDestroy } from '@kolytics/shared-functions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, fromEvent, Observable } from 'rxjs';
import {
  filter,
  finalize,
  map,
  mergeMap,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { ClientQuery, ClientSignalQuery } from './store/client.query';
import { ClientSignalState } from './store/client.state';
import { ClientStoreService } from './store/client.store.service';
import {
  ReitFinancialService,
  ReitService,
  UserAssumptionsService,
} from '@kolytics/shared-api';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ClientFacadeService {
  constructor(
    private reitService: ReitService,
    private reitFinancialService: ReitFinancialService,
    private store: ClientStoreService,
    public storeQuery: ClientQuery,
    private storeSignalQuery: ClientSignalQuery,
    public activatedRoute: ActivatedRoute,
    private userAssumptionService: UserAssumptionsService,
  ) {}

  public reitId!: number;

  public financialData: any;

  localStorageUpdates$ = fromEvent<StorageEvent>(window, 'storage').pipe(
    untilDestroyed(this),
    filter((event) => event.key === 'client-state'),
    map(
      (event: StorageEvent) =>
        JSON.parse(event.newValue as string)
          ?.clientSignals as ClientSignalState,
    ),
  );
  public financial$ = this.storeQuery.selectedScenarios$.pipe(
    switchMap(() => this.storeQuery.selectReitId$),
    tap((reitId) => (this.reitId = reitId)),
    switchMap(() => this.storeQuery.selectedScenarios$),
    switchMap((selectedScenarios) =>
      this.storeQuery.selectedAssumptionScenarios$.pipe(
        map((assumptions) => [selectedScenarios, assumptions]),
      ),
    ),
    switchMap(([data1, data2]) =>
      this.reitFinancialService.apiReitReitIdFinancialPost(this.reitId, {
        scenarioIds: data1[this.reitId],
        assumptionScenarioIds: data2[this.reitId],
      }),
    ),
    tap(() => this.store.setFinancialLoading(false)),
  );

  public financialIsLoading$ = this.storeQuery.selectFinancialIsLoading$;
  public projectionIsLoading$ = this.storeQuery.selectProjectionIsLoading$;

  getReitId = () => this.storeQuery.selectReitId$;
  getScenarios = (reitId: number) =>
    this.storeQuery.scenarios$.pipe(map((e) => e[reitId]));
  getUserScenarios = (reitId: number) =>
    this.storeQuery.scenarios$.pipe(
      map((e) => {
        const userScenarios = e[reitId].filter((v) => !v.isDefault);
        if (userScenarios.length <= 0) return e[reitId];
        return userScenarios;
      }),
    );

  getSelectedScenario = (reitId: number) =>
    this.storeQuery.selectedScenario$.pipe(map((e) => e[reitId]));
  getSelectedScenarios = (reitId: number) =>
    this.storeQuery.selectedScenarios$.pipe(map((e) => e[reitId]));
  getSelectedLeftScenarioId = (reitId: number) =>
    this.storeQuery.selectedLeftScenarioId$.pipe(map((e) => e[reitId]));
  getSelectedRightScenarioId = (reitId: number) =>
    this.storeQuery.selectedRightScenarioId$.pipe(map((e) => e[reitId]));
  getSelectedAssumptionScenarios = (reitId: number) =>
    this.storeQuery.selectedAssumptionScenarios$.pipe(map((e) => e[reitId]));

  getPortfolioSignal = (reitId: number, scenarioId: number) =>
    this.storeSignalQuery.selectPortfolio$.pipe(
      map((e) => e[reitId]),
      filter((e) => e.scenarioId == scenarioId),
    );
  getCompanySignal = (reitId: number, scenarioId: number) =>
    this.storeSignalQuery.selectCompany$.pipe(
      map((e) => e[reitId]),
      filter((e) => e.scenarioId == scenarioId),
    );
  getOtherSignal = (reitId: number, scenarioId: number) =>
    this.storeSignalQuery.selectOther$.pipe(
      map((e) => e[reitId]),
      filter((e) => e.scenarioId == scenarioId),
    );

  loadReitScenarios(reitId: number) {
    return this.userAssumptionService.apiUserAssumptionsReitIdGet(reitId).pipe(
      take(1),
      tap((data) => {
        const scenarioData: {
          [key: string]: {
            name: string;
            scenarioId: number;
            isDefault: boolean;
            initialScenarioId?: number;
          }[];
        } = {};
        scenarioData[reitId] = data.result.map((e: any) => {
          return {
            name: e.name,
            isDefault: e.isDefaultScenario,
            scenarioId: e.scenarioId,
            initialScenarioId: e.initialScenarioId,
          };
        });
        this.store.setScenarios(reitId, scenarioData[reitId]);
      }),
    );
  }

  addNewScenario(
    reitId: number,
    scenario: {
      name: string;
      scenarioId: number;
      isDefault: boolean;
      initialScenarioId?: number;
    },
  ) {
    this.store.addScenario(reitId, scenario);
  }

  renameScenario(reitId: number, name: string, scenarioId: string) {
    this.store.renameScenario(reitId, name, scenarioId);
  }
  setSelectedScenario(reitId: number, scenarioId: number) {
    this.store.setSelectedScenario(reitId, scenarioId);
  }
  setSelectedScenarios(reitId: number, scenarioIds: number[]) {
    this.store.setSelectedScenarios(reitId, scenarioIds);
  }
  setSelectedLeftScenarioId(reitId: number, scenarioId: number) {
    this.store.setSelectedLeftScenarioId(reitId, scenarioId);
  }
  setSelectedRightScenarioId(reitId: number, scenarioId: number) {
    this.store.setSelectedRightScenarioId(reitId, scenarioId);
  }
  setSelectedAssumptionScenarios(reitId: number, scenarioIds: number[]) {
    this.store.setSelectedAssumptionScenarios(reitId, scenarioIds);
  }
}
