<div class="projection--content">
  <div class="projection--content-header">
    <h3>
      Projections for
      @if (selectedScenarioData) {
        <span>{{ selectedScenarioData.name }}</span>
      }
    </h3>
    <klt-single-selection
      label="Scenario"
      [items]="scenarios$ | async | scenarioToOption"
      [selectedValue]="selectedScenario$ | async"
      (selectionChange)="onScenarioChange($event)"
    ></klt-single-selection>
  </div>
  <klt-projection-gav
    [selectedScenarioName]="selectedScenarioData.name"
    [updated]="updated"
  ></klt-projection-gav>
  <klt-separator></klt-separator>
  <klt-projection-tar
    [loading]="projectionIsLoading$ | async"
    [items]="reit && reit.reits.reitProjection.tsrVSTarGraph"
    [selectedScenarioName]="selectedScenarioData.name"
  ></klt-projection-tar>
  <klt-separator></klt-separator>
  <section style="display: flex">
    <div style="flex: 1 1 50%; margin-right: var(--size--spacing-x6)">
      <klt-projection-lfl-nri
        [loading]="projectionIsLoading$ | async"
        [reit]="reit"
        [selectedScenarioName]="selectedScenarioData.name"
      ></klt-projection-lfl-nri>
    </div>
    <div style="flex: 1 1 50%">
      <klt-projection-affo-dps-yield
        [loading]="projectionIsLoading$ | async"
        [items]="reit && reit.reits.reitProjection.affoToDpsYieldGraph"
        [selectedScenarioName]="selectedScenarioData.name"
        >
      </klt-projection-affo-dps-yield>
    </div>
  </section>
  <klt-separator></klt-separator>
  <section style="display: flex">
    <div style="flex: 1 1 50%; margin-right: var(--size--spacing-x6)">
      <klt-projection-balance-sheet-strength
        [loading]="projectionIsLoading$ | async"
        [items]="reit && reit.reits.reitProjection.balanceSheetGraph"
        [selectedScenarioName]="selectedScenarioData.name"
        >
      </klt-projection-balance-sheet-strength>
    </div>
    <div style="flex: 1 1 50%">
      <klt-projection-debt-maturities
        [loading]="projectionIsLoading$ | async"
        [reit]="reit"
        [selectedScenarioName]="selectedScenarioData.name"
      ></klt-projection-debt-maturities>
    </div>
  </section>
</div>
