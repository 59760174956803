<klt-section title="Historical sector returns">
  <div class="graph-box">
    <klt-graph
      height="345px"
      [loading]="titles.length == 0"
      [legendGrid]="true"
      [options]="options"
      [titles]="titles"
      (filter)="runFilter($event)"
    >
    </klt-graph>
  </div>
</klt-section>
