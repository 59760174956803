export enum Role {
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  PP1 = 'PP1',
  PP2 = 'PP2',
  PP3 = 'PP3',
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin',
  R1 = 'R1',
  ModelAccess = 'ModelAccess',
}
