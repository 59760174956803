import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'in',
  standalone: true,
  pure: false,
})
export class InPipe implements PipeTransform {
  transform(
    value: any,
    values: any[] | null | undefined,
    mapProperty?: any,
  ): boolean {
    if (!value || !values) return false;
    if (!value || value.length <= 0) return false;
    if (mapProperty) {
      return values.map((e) => e[mapProperty]).includes(value);
    }
    return values.includes(value);
  }
}
