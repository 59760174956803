<div
  class="wrapper--chip"
  [class.selected]="selected"
  [class.disabled]="disabled"
  (click)="select()"
  >
  @if (icon) {
    <klt-icon
      class="icon"
      [icon]="icon"
      size="regular"
      [color]="disabled ? 'ctaDisabled' : 'cta'"
    ></klt-icon>
  }
  @if (isNew) {
    <span class="label-tag">{{ labelTag }}</span>
  }
  <span class="label">{{ label }}</span>
  @if (selected && cancellable) {
    <div class="icon-close">
      <klt-icon
        icon="close"
        size="tiny"
        [color]="disabled ? 'inactive' : 'dark'"
      ></klt-icon>
    </div>
  }
</div>
