<span
  class="switch"
  [class.checked]="checked"
  [class.disabled]="disabled"
  [class.switch-labeled]="!!labelOn || !!labelOff"
>
  <input
    type="checkbox"
    id="enabled"
    name="enabled"
    [checked]="checked"
    style="display: none"
    aria-invalid="false"
  />
  <small>
    <klt-icon
      icon="tick"
      [color]="checked ? 'cta' : 'light'"
      size="tiny"
    ></klt-icon>
  </small>
  <!--  <span class="switch-text" *ngIf="!!labelOn || !!labelOff">-->
  <!--    <span class="on" [innerHtml]="labelOn"></span>-->
  <!--    <span class="off" [innerHtml]="labelOff"></span>-->
  <!--  </span>-->
</span>
