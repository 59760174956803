// Module Imports
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { SharedComponentsModule } from '@kolytics/shared-components';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PageHeaderComponent } from './components/header/header.component';
import { BaseGraphComponent } from './components/base-graph/base-graph.component';
import { OverviewPerformanceComponent } from '../pages/reits/pages/overview/overview-performance/overview-performance.component';
import { OverviewScoreboardComponent } from '../pages/reits/pages/overview/overview-scoreboard/overview-scoreboard.component';
import { OverviewGvaComponent } from '../pages/reits/pages/overview/overview-gva/overview-gva.component';
import { OverviewPropertyValueLocationComponent } from '../pages/reits/pages/overview/overview-property-value-location/overview-property-value-location.component';
import { OverviewPropertyValueTypeComponent } from '../pages/reits/pages/overview/overview-property-value-type/overview-property-value-type.component';
import { OverviewReitUpdateComponent } from '../pages/reits/pages/overview/overview-reit-update/overview-reit-update.component';
import { LinkActionComponent } from '../pages/reits/components/link-action/link-action.component';
import { OverviewInvestabilityComponent } from '../pages/reits/pages/overview/overview-investability/overview-investability.component';
import { OverviewCompanyComponent } from '../pages/reits/pages/overview/overview-company/overview-company.component';
import { OverviewTeamComponent } from '../pages/reits/pages/overview/overview-team/overview-team.component';
import { ProjectionGavComponent } from '../pages/reits/pages/projections/projection-gav/projection-gav.component';
import { ProjectionTarComponent } from '../pages/reits/pages/projections/projection-tar/projection-tar.component';
import { ProjectionLflNriComponent } from '../pages/reits/pages/projections/projection-lfl-nri/projection-lfl-nri.component';
import { ProjectionAffoDpsYieldComponent } from '../pages/reits/pages/projections/projection-affo-dps-yield/projection-affo-dps-yield.component';
import { ProjectionBalanceSheetStrengthComponent } from '../pages/reits/pages/projections/projection-balance-sheet-strength/projection-balance-sheet-strength.component';
import { ProjectionDebtMaturitiesComponent } from '../pages/reits/pages/projections/projection-debt-maturities/projection-debt-maturities.component';
import {
  AllReitsComponent,
  CashFlowStatementTableComponent,
  IncomeStatementDirectCostsComponent,
  IncomeStatementDpsComponent,
  IncomeStatementEpsComponent,
  IncomeStatementIndirectCostsComponent,
  IncomeStatementInterestCostsComponent,
  IncomeStatementRevenueComponent,
  IncomeStatementTableComponent,
  MyReitsComponent,
  PortfolioTableComponent,
} from '../pages/reits/pages';
import { FinancialsCompareComponent } from '../pages/reits/pages/financials/financials-compare/financials-compare.component';
import { BalanceSheetTableComponent } from '../pages/reits/pages/balance-sheet/components/balance-sheet-table/balance-sheet-table.component';
import { StickyClassDirective } from './directives/sticky-class.directive';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedComponentsModule,
    PageHeaderComponent,
    LinkActionComponent,
    OverviewGvaComponent,
    OverviewScoreboardComponent,
    OverviewPerformanceComponent,
    OverviewPropertyValueLocationComponent,
    OverviewPropertyValueTypeComponent,
    OverviewReitUpdateComponent,
    OverviewInvestabilityComponent,
    OverviewCompanyComponent,
    OverviewTeamComponent,
    ProjectionGavComponent,
    ProjectionTarComponent,
    ProjectionLflNriComponent,
    ProjectionAffoDpsYieldComponent,
    ProjectionBalanceSheetStrengthComponent,
    ProjectionDebtMaturitiesComponent,
    IncomeStatementDirectCostsComponent,
    IncomeStatementDpsComponent,
    IncomeStatementEpsComponent,
    IncomeStatementIndirectCostsComponent,
    IncomeStatementInterestCostsComponent,
    IncomeStatementRevenueComponent,
    FinancialsCompareComponent,
    IncomeStatementTableComponent,
    BalanceSheetTableComponent,
    CashFlowStatementTableComponent,
    PortfolioTableComponent,
    MyReitsComponent,
    AllReitsComponent,
    StickyClassDirective,
  ],
  imports: [
    AngularSvgIconModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedComponentsModule,
    NgxChartsModule,
    PageHeaderComponent,
    BaseGraphComponent,
    LinkActionComponent,
    OverviewGvaComponent,
    OverviewScoreboardComponent,
    OverviewPerformanceComponent,
    OverviewPropertyValueLocationComponent,
    OverviewPropertyValueTypeComponent,
    OverviewReitUpdateComponent,
    OverviewInvestabilityComponent,
    OverviewCompanyComponent,
    OverviewTeamComponent,
    ProjectionGavComponent,
    ProjectionTarComponent,
    ProjectionLflNriComponent,
    ProjectionAffoDpsYieldComponent,
    ProjectionBalanceSheetStrengthComponent,
    ProjectionDebtMaturitiesComponent,
    IncomeStatementDirectCostsComponent,
    IncomeStatementDpsComponent,
    IncomeStatementEpsComponent,
    IncomeStatementIndirectCostsComponent,
    IncomeStatementInterestCostsComponent,
    IncomeStatementRevenueComponent,
    FinancialsCompareComponent,
    IncomeStatementTableComponent,
    BalanceSheetTableComponent,
    CashFlowStatementTableComponent,
    PortfolioTableComponent,
    MyReitsComponent,
    AllReitsComponent,
    StickyClassDirective,
  ],
  providers: [CurrencyPipe, provideHttpClient(withInterceptorsFromDi())],
})
export class SharedModule {}
