import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ClaimService } from '../services/jwt/claim.service';

@Directive({
  selector: '[hasClaim]',
  standalone: true,
})
export class HasClaimDirective implements OnInit {
  hidden = false;
  @Input() hasClaim: string | undefined;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private jwtService: JwtHelperService,
    private claimService: ClaimService,
  ) {}

  ngOnInit(): void {
    this.updateView();
  }

  updateView() {
    if (!this.hasClaim) {
      this.show();
      return;
    }

    if (this.claimService.hasClaim(this.hasClaim)) {
      this.show();
    } else {
      this.hide();
    }
  }

  private hide() {
    this.hidden = true;
    this.viewContainer.clear();
  }

  private show() {
    this.hidden = false;
    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
