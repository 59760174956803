import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { EChartsOption } from 'echarts';
import { IReitProjectionTsrVSTarGraphItem, ISeries } from '../../../interfaces';
import { XAXisOption } from 'echarts/types/dist/shared';
import { getColNameByYear } from '../../../utils';
import { formatPercentage } from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-projection-tar',
  templateUrl: './projection-tar.component.html',
  styleUrls: ['./projection-tar.component.scss'],
  standalone: true,
  imports: [SectionComponent, GraphComponent],
})
export class ProjectionTarComponent {
  @Input() height = '415px';
  @Input() selectedScenarioName = 'base';
  @Input()
  public set items(value: IReitProjectionTsrVSTarGraphItem[] | undefined) {
    this.reset(value || []);
  }
  @Input() loading: boolean | null = false;

  @Input() public options: EChartsOption | undefined = {
    grid: {
      show: true,
      borderWidth: 0,
      containLabel: true,
      top: 10,
      right: 10,
      bottom: 0,
      left: 5,
    },
    xAxis: {
      type: 'category',
      data: ['17A', '18A', '19A', '20A', '21F', '22F', '23F', '24F', '25F'],
      axisLine: {
        onZero: true,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        margin: 22,
      },
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false,
      },
      axisLabel: {
        formatter: (value: any, index: number) => {
          return formatPercentage(value, 1);
        },
        margin: 20,
      },
    },
    series: [],
    tooltip: {
      trigger: 'item',
      padding: 0,
      position: 'top',
      displayMode: 'single',
      confine: true,
      formatter: function (params: any) {
        if (params.componentType && params.componentType === 'series') {
          return `<div style="background: ${
            params.color
          }; color: var(--color--text-light); padding: var(--size--spacing-x2) var(--size--spacing-x4);">${params.seriesName.toUpperCase()}-${
            params.name
          }: (${formatPercentage(params.value, 1)})</div>`;
        }
        return '';
      },
    },
  };

  public series: ISeries[] = [];

  public titles: {
    name: string;
    color: string;
    disabled: boolean;
    text?: string;
  }[] = [
    {
      name: 'TOTAL SHAREHOLDER RETURNS',
      text: `TOTAL SHAREHOLDER RETURNS (${this.selectedScenarioName})`,
      color: colorPallete[GraphId.ProjectionsTotalShareholderReturns][0],
      disabled: false,
    },
    {
      name: 'NTA GROWTH',
      text: `NTA GROWTH (${this.selectedScenarioName})`,
      color: colorPallete[GraphId.ProjectionsTotalShareholderReturns][1],
      disabled: false,
    },
    {
      name: 'DIVIDEND',
      text: `DIVIDEND (${this.selectedScenarioName})`,
      color: colorPallete[GraphId.ProjectionsTotalShareholderReturns][2],
      disabled: false,
    },
  ];

  constructor(private cdr: ChangeDetectorRef) {}

  public runFilter(
    data: { name: string; color: string; disabled: boolean; text?: string }[],
  ): void {
    this.titles = data;

    this.titles[0].text = `TOTAL SHAREHOLDER RETURNS (${this.selectedScenarioName})`;
    this.titles[1].text = `NTA GROWTH (${this.selectedScenarioName})`;
    this.titles[2].text = `DIVIDEND (${this.selectedScenarioName})`;

    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter(
      (e) => e?.name && filter.includes(e.name.toString().toLowerCase()),
    );

    (this.options as EChartsOption).series = [...(series as any)];

    this.options = Object.assign({}, this.options);
    this.cdr.detectChanges();
  }

  public reset(value: IReitProjectionTsrVSTarGraphItem[] = []): void {
    if (value.length > 0) {
      value = value.sort((a, b) => a.year - b.year);
      ((this.options as EChartsOption).xAxis as any).data = value.map(
        (e) => e.label,
      );
      const dividend = this.getDividend(value);
      const cap = this.getCap(value);
      const nav = this.getNav(value);
      this.series = [dividend, cap, nav] as any;
      this.runFilter(this.titles);
    } else {
      (this.options as EChartsOption).series = [];
    }
  }

  public getDividend(value: IReitProjectionTsrVSTarGraphItem[] = []) {
    const list = value.map((e) => e.dividend);
    return {
      data: list,
      type: 'bar',
      stack: 'one',
      color: colorPallete[GraphId.ProjectionsTotalShareholderReturns][2],
      name: 'DIVIDEND',
      itemStyle: {
        borderRadius: 2,
      },
    };
  }

  public getCap(value: IReitProjectionTsrVSTarGraphItem[] = []) {
    const list = value.map((e) => e.capitalReturn);
    return {
      data: list,
      type: 'bar',
      stack: 'two',
      color: colorPallete[GraphId.ProjectionsTotalShareholderReturns][0],
      name: 'TOTAL SHAREHOLDER RETURNS',
      itemStyle: {
        borderRadius: 2,
      },
    };
  }

  public getNav(value: IReitProjectionTsrVSTarGraphItem[] = []) {
    const list = value.map((e) => e.navGrowth);
    return {
      data: list,
      type: 'bar',
      stack: 'one',
      color: colorPallete[GraphId.ProjectionsTotalShareholderReturns][1],
      name: 'NTA GROWTH',
      itemStyle: {
        borderRadius: 2,
      },
    };
  }
}
