import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconComponent } from '../../../../../../shared-assets/src/lib/components/icon/icon.component';


@Component({
    selector: 'klt-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss'],
    standalone: true,
    imports: [IconComponent],
})
export class ChipComponent implements OnInit {
  @Input() label = '';
  @Input() icon = '';
  @Input() value!: string;
  @Input() labelTag = 'NEW';
  @Input() isNew = false;
  @Input() selected: boolean | undefined = false;
  @Input() disabled = false;
  @Input() alwaysSelected = false;
  @Input() cancellable = true;

  @Output() chipSelected = new EventEmitter<{
    selected: boolean;
    value: string;
  }>();

  constructor() {}

  ngOnInit(): void {}

  select(): void {
    if (this.alwaysSelected) {
      return;
    }
    this.selected = !this.selected;
    this.chipSelected.emit({
      selected: this.selected,
      value: this.value,
    });
  }
}
