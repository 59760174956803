import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { formatNumber } from '@kolytics/shared-functions';
import { colorPallete, GraphId } from '@kolytics/types/graph';
import { EChartsOption } from 'echarts';
import {
  ISectorOverviewInvestabilityResponse,
  ISectorOverviewMCapResponse,
} from '../../../interfaces';
import { GraphComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/graph/graph.component';
import { SectionComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/section/section.component';

@Component({
  selector: 'klt-sector-investability',
  templateUrl: './sector-investability.component.html',
  styleUrls: ['./sector-investability.component.scss'],
  standalone: true,
  imports: [SectionComponent, GraphComponent],
})
export class SectorInvestabilityComponent implements OnInit {
  @Input() height = '28rem';

  investabilityDescription: string = '';
  @Input()
  set data(data: ISectorOverviewInvestabilityResponse | undefined) {
    if (data) {
      this.initGraph(data);
    }
  }

  indicator = [
    { name: 'Risk-Adjusted return', max: 5 },
    { name: 'Size/\nliquidity', max: 5 },
    { name: 'Asset quality', max: 5 },
    { name: 'Balance sheet', max: 5 },
    { name: 'Corporate\nstructures', max: 5 },
  ];
  @Input() public options: EChartsOption | undefined = {
    grid: {
      backgroundColor: '#ffffff',
      show: true,
      borderColor: '#f2f2f0',
      borderWidth: 0,
      left: '0%',
      right: '0%',
      top: '0%',
      bottom: '0%',
    },
    animation: true,
    type: 'radar',
    radar: {
      axisName: {
        color: '#000',
        fontSize: 14,
      },
      splitLine: {
        show: false,
      },
      splitArea: {
        areaStyle: {
          color: ['#f2f2f0'],
        },
      },
      indicator: this.indicator,
      axisLine: {
        show: false,
      },
    },
    responsive: true,
    height: 'auto',
    width: 'auto',
    series: [],
    tooltip: {
      trigger: 'item',
    },
  };

  series: any[] = [];

  lines = [];

  titles: { name: string; color: string; disabled: boolean }[] = [];
  loading: boolean = true;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.runFilter(this.titles);
  }

  runFilter(data: { name: string; color: string; disabled: boolean }[]): void {
    this.titles = data;
    const filter = data
      .filter((e) => !e.disabled)
      .map((e) => e.name.toLowerCase());
    const series = this.series.filter((e) =>
      filter.includes(e.name.toLowerCase()),
    );

    (this.options as EChartsOption).series = [
      ...this.lines,
      ...(series as any),
    ];

    const temp = Object.assign({}, this.options);

    // this.options = undefined;

    setTimeout(() => {
      this.options = temp;
      this.loading = false;
      this.cdr.detectChanges();
    }, 0);
  }

  initGraph(data: ISectorOverviewInvestabilityResponse): void {
    this.titles = [
      {
        name: data.selectedSectorInvestability.series,
        color: colorPallete[GraphId.SectorOverviewInvestability][0],
        disabled: false,
      },
      {
        name: `${data.allSectorInvestability.series}(${data.allSectorInvestability.sectorCount})`,
        color: colorPallete[GraphId.SectorOverviewInvestability][1],
        disabled: false,
      },
    ];

    this.investabilityDescription = 'ddd';
    this.series = [
      {
        zlevel: 1,
        radarId: 2,
        data: [
          {
            areaStyle: {
              color: colorPallete[GraphId.SectorOverviewInvestability][1],
              opacity: 0.7,
            },
            lineStyle: {
              color: colorPallete[GraphId.SectorOverviewInvestability][1],
            },
            itemStyle: {
              color: colorPallete[GraphId.SectorOverviewInvestability][1],
              opacity: 0.7,
            },
            value: [
              formatNumber(data.allSectorInvestability.riskAdjustedReturn, 1),
              formatNumber(data.allSectorInvestability.sizeLiquidity, 1),
              formatNumber(data.allSectorInvestability.assetQuality, 1),
              formatNumber(data.allSectorInvestability.balanceSheet, 1),
              formatNumber(data.allSectorInvestability.corporateStructures, 1),
            ],
          },
        ],
        type: 'radar',
        color: colorPallete[GraphId.SectorOverviewInvestability][1],
        name: `${data.allSectorInvestability.series}(${data.allSectorInvestability.sectorCount})`,
      },
      {
        zlevel: 2,
        radarId: 1,
        data: [
          {
            areaStyle: {
              color: colorPallete[GraphId.SectorOverviewInvestability][0],
              opacity: 0.7,
            },
            lineStyle: {
              color: colorPallete[GraphId.SectorOverviewInvestability][0],
            },
            itemStyle: {
              color: colorPallete[GraphId.SectorOverviewInvestability][0],
              opacity: 0.7,
            },
            value: [
              formatNumber(
                data.selectedSectorInvestability.riskAdjustedReturn,
                1,
              ),
              formatNumber(data.selectedSectorInvestability.sizeLiquidity, 1),
              formatNumber(data.selectedSectorInvestability.assetQuality, 1),
              formatNumber(data.selectedSectorInvestability.balanceSheet, 1),
              formatNumber(
                data.selectedSectorInvestability.corporateStructures,
                1,
              ),
            ],
          },
        ],
        type: 'radar',
        color: colorPallete[GraphId.SectorOverviewInvestability][0],
        name: data.selectedSectorInvestability.series,
      },
    ];
    this.addInfoBox(data);
    this.runFilter(this.titles);
  }

  addInfoBox(data: ISectorOverviewInvestabilityResponse) {
    const selectedSectorAvg =
      (data.selectedSectorInvestability.sizeLiquidity +
        data.selectedSectorInvestability.riskAdjustedReturn +
        +data.selectedSectorInvestability.corporateStructures +
        +data.selectedSectorInvestability.assetQuality +
        +data.selectedSectorInvestability.balanceSheet) /
      5;

    const allSectorsAvg =
      (data.allSectorInvestability.sizeLiquidity +
        data.allSectorInvestability.riskAdjustedReturn +
        +data.allSectorInvestability.corporateStructures +
        +data.allSectorInvestability.assetQuality +
        +data.allSectorInvestability.balanceSheet) /
      5;

    this.investabilityDescription = `<ul><li>SECTOR SCORE: ${selectedSectorAvg.toFixed(
      2,
    )}</li><li>ALL SECTORS: ${allSectorsAvg.toFixed(2)}</li></ul>`;
  }
}
