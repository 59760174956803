import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { KltModalService } from '@kolytics/shared-components';
import { SelectProfileDialogComponent } from '../../../dialogs';
import { filter, first, tap } from 'rxjs/operators';
import { ButtonComponent } from '../../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';

@Component({
    selector: 'klt-financials-compare',
    templateUrl: './financials-compare.component.html',
    styleUrls: ['./financials-compare.component.scss'],
    standalone: true,
    imports: [ButtonComponent],
})
export class FinancialsCompareComponent implements OnInit {
  @Output() afterClosed = new EventEmitter<any>();

  constructor(protected readonly kltModalService: KltModalService) {}

  ngOnInit(): void {}

  public showCompareDialog(): void {
    this.kltModalService
      .open(SelectProfileDialogComponent, {
        data: {},
      })
      .afterClosed.pipe(
        filter((x) => x),
        first(),
        tap((value) => {
          if (value?.length <= 0) {
            return;
          }
          this.afterClosed.emit(value);
        }),
      )
      .subscribe();
  }
}
