import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
} from '@angular/core';
import { LngLatLike } from 'mapbox-gl';
import { KltModalRefService } from '@kolytics/shared-components';
import { MapComponent } from '../../../../../../../../libs/shared-components/src/lib/components/map/map.component';

@Component({
  selector: 'klt-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MapComponent],
})
export class MapDialogComponent {
  public zoom = signal(4.8);

  public current: LngLatLike = [0.7839646685014259, 52.07317888945238];

  public source: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: 'FeatureCollection',
    features: [],
  };

  public markers: {
    lngLat: LngLatLike;
    type: 'circle' | 'position';
    hideContent?: boolean;
    size?: 'small' | 'large' | 'medium';
    content?: string;
  }[] = [];

  constructor(
    @Inject('KLT_MODAL_DATA')
    protected readonly modalData: {
      zoom: number;
      current: LngLatLike;
      markers: {
        lngLat: LngLatLike;
        type: 'circle' | 'position';
        hideContent?: boolean;
        size?: 'small' | 'large' | 'medium';
        content?: string;
      }[];
      source: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
    },
    protected readonly kltModalRefService: KltModalRefService,
  ) {
    this.zoom.set(modalData.zoom);
    this.current = modalData.current;
    this.markers = modalData.markers;
    this.source = modalData.source;
  }

  public close(): void {
    this.kltModalRefService.close(MapDialogComponent);
  }
}
