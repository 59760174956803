import { Injectable } from '@angular/core';
import { ProductFeature } from '@kolytics/types/common';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public config: any = {
    entryRoute: './reits/all',
    navigation: [
      // {route: '/dashboard', token: 'Dashboard', icon: 'dashboard'},
      {
        route: '/data',
        token: 'Data',
        icon: 'research',
        feature: ProductFeature.DataAnalytics,
        claim: 'permission:data-analytics',
      },
      {
        route: '/reits',
        token: 'REITs',
        icon: 'reit',
        claim: 'permission:reits',
      },
      {
        route: '/research',
        token: 'Research',
        icon: 'research',
        feature: ProductFeature.Research,
        claim: 'permission:research',
      },
    ],
    profile: [
      { route: '/account', token: 'My account', icon: 'settings' },
      {
        route: 'mailto:research@kolytics.com',
        token: 'Send us feedback',
        icon: 'email',
      },
    ],
  };
  constructor() {}

  set(variable: string, value: any): void {
    this.config[variable] = value;
  }

  get(variable: string): any {
    return this.config[variable];
  }
}
