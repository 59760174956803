import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { routerTransition } from './shared/animations/router.animations';
import { NavigationService } from './shared/services';
import { MetatagsService } from './shared/services/metatags.service';
import { TrackPageService } from '@kolytics/shared-components';
import { UserService } from '@kolytics/shared-api';

@Component({
  selector: 'klt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition],
})
export class AppComponent implements OnInit {
  currentRoute: string | undefined;
  currentParent: string | undefined = 'auth';
  currentParentSub: string | undefined = 'auth';
  isPreview: boolean = false;
  date = new Date();
  user: any;

  get navPaths(): string[] {
    return [
      'auth',
      'login',
      'table',
      'password',
      'register',
      'on-boarding',
      'popout',
      'pdf',
      'unsubscribe',
      'preview',
      'signup',
    ];
  }
  get canShow() {
    return this.currentParent && !this.navPaths.includes(this.currentParent);
  }

  constructor(
    protected readonly router: Router,
    protected readonly navigationService: NavigationService,
    private readonly route: ActivatedRoute,
    private metatagsService: MetatagsService,
    private trackService: TrackPageService,
  ) {
    this.trackService.trackPageViews();
    router.events.subscribe((event) => {
      this.currentRoute = this.router.url.split('?')[0];
      this.currentParent = this.currentRoute.split('/')[1];
      this.currentParentSub = this.currentRoute.split('/')[2];

      if (event instanceof NavigationEnd) {
        this.isPreview = `/${event.urlAfterRedirects.split('/')[1]}`.includes(
          '/preview',
        );
      }

      if (event instanceof NavigationStart) {
      }
    });
  }

  ngOnInit() {
    this.setMetaTitle();
  }

  setMetaTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.metatagsService.updateTitle(route.data.title);
      });
  }

  getState(outlet: RouterOutlet): string {
    return outlet.activatedRouteData.state;
  }

  onActivate(): void {
    window.scroll(0, 0);
  }

  public handleScroll(event: any): void {
    const stick = event.target.scrollTop >= 342;
    this.navigationService.setScrolling(stick);
  }
}
