import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { NgStyle } from '@angular/common';
@Component({
  selector: 'klt-map-tooltip',
  templateUrl: './map-tooltip.component.html',
  styleUrls: ['./map-tooltip.component.scss'],
  standalone: true,
  imports: [NgStyle],
})
export class MapTooltipComponent implements AfterViewChecked {
  @Input() hide = true;

  @Input()
  feature!: any;

  @Input()
  displayPropertyData!: boolean;

  @ViewChild('content') contentView: ElementRef | undefined;
  offsetTop = 0;

  constructor(public element: ElementRef) {}

  ngAfterViewChecked(): void {
    if (this.feature.hideTooltip === false && this.contentView) {
      this.offsetTop = (this.contentView.nativeElement.offsetHeight + 15) * -1;
    }
  }

  mFormatter(number: number) {
    if (isNaN(number)) {
      return 'n/a';
    }
    if (number <= 0) {
      return '-';
    }
    return number + 'm';
  }

  kFormatter(number: number) {
    if (isNaN(number)) {
      return 'n/a';
    }
    if (number <= 0) {
      return '-';
    }
    return Math.abs(number) > 999
      ? (number / 1000).toFixed(1).replace(/\.0$/, '') + 'k'
      : number;
  }
}
