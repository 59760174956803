<div class="overview-scoreboard">
  <div class="wrapper--scoreboard">
    <klt-scoreboard title="Share price">
      <klt-currency
        [value]="data.sharePrice"
        [currencyCode]="metadata.currencyCode"
        [currencyFormat]="metadata.currencyFormat"
        [locale]="metadata.culture"
        [pence]="true"
        [decimalPoints]="metadata.decimalPoints"
      ></klt-currency>
    </klt-scoreboard>

    <klt-scoreboard title="Prem/(Disc) to gav">
      <klt-percentage
        [value]="data.premiumGav"
        [locale]="metadata.culture"
      ></klt-percentage>
    </klt-scoreboard>

    <klt-scoreboard title="market cap">
      <klt-currency
        [value]="data.marketCap"
        [currencyCode]="metadata.currencyCode"
        [currencyFormat]="metadata.currencyFormat"
        [locale]="metadata.culture"
        [decimalPoints]="0"
        >m</klt-currency
      >
    </klt-scoreboard>
    <klt-scoreboard title="gross assets">
      <klt-currency
        [value]="data.grossAssets"
        [currencyCode]="metadata.currencyCode"
        [currencyFormat]="metadata.currencyFormat"
        [locale]="metadata.culture"
        [decimalPoints]="0"
        >m</klt-currency
      >
    </klt-scoreboard>

    <klt-scoreboard title="net assets">
      <klt-currency
        [value]="data.netAssets"
        [currencyCode]="metadata.currencyCode"
        [currencyFormat]="metadata.currencyFormat"
        [locale]="metadata.culture"
        [decimalPoints]="0"
        >m</klt-currency
      >
    </klt-scoreboard>

    <klt-scoreboard title="Loan to value">
      <klt-percentage
        [value]="data.loanToValue"
        [decimalPoints]="1"
        [locale]="metadata.culture"
      ></klt-percentage>
    </klt-scoreboard>

    <klt-scoreboard title="debt to ebitda">
      <klt-number
        [value]="data.debtToEbitda"
        [decimalPoints]="1"
        [minimumDecimalPoints]="1"
        [locale]="metadata.culture"
        >x</klt-number
      >
    </klt-scoreboard>

    <klt-scoreboard title="wa debt cost">
      <klt-percentage
        [value]="data.waDebtCost"
        [decimalPoints]="1"
        [locale]="metadata.culture"
      ></klt-percentage>
    </klt-scoreboard>

    <klt-scoreboard title="wa debt expiry">
      <klt-number
        [value]="data.waDebtExpiry"
        [decimalPoints]="1"
        [locale]="metadata.culture"
      >
        years</klt-number
      >
    </klt-scoreboard>

    <klt-scoreboard title="WA Lease Term">
      <klt-number
        [value]="data.waLeaseTerm"
        [decimalPoints]="1"
        [locale]="metadata.culture"
      >
        years</klt-number
      >
    </klt-scoreboard>
  </div>
</div>
