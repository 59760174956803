import { ChangeDetectionStrategy, Component } from '@angular/core';
import { KltModalRefService } from '@kolytics/shared-components';
import { ButtonComponent } from '../../../../../../../../libs/shared-components/src/lib/components/common/button/button.component';
import { ModalComponent } from '../../../../../../../../libs/shared-components/src/lib/components/modal/modal.component';

@Component({
    selector: 'klt-reset-to-last-save-modal',
    templateUrl: './reset-to-last-save-modal.component.html',
    styleUrls: ['./reset-to-last-save-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ModalComponent, ButtonComponent],
})
export class ResetToLastSaveModalComponent {
  constructor(protected readonly kltModalRefService: KltModalRefService) {}

  public onClose(): void {
    this.kltModalRefService.close(ResetToLastSaveModalComponent, false);
  }

  public onRemove(): void {
    this.kltModalRefService.close(ResetToLastSaveModalComponent, true);
  }
}
