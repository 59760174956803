import {
  ChipOption,
  DropDownOption,
  GroupedDropDownOption,
} from '@kolytics/shared-components';
import { IReitData } from '../../pages/reits/interfaces';

export const downloadReportOptions: GroupedDropDownOption[] = [
  {
    group: 'COMPARISON SCENARIOS',
    options: [
      { label: 'Base', value: 'base', selected: false },
      { label: 'Good', value: 'good', selected: false },
      { label: 'Poor', value: 'poor', selected: false },
    ],
  },
  {
    group: 'SECTIONS',
    options: [
      { label: 'Overview', value: 'overview', selected: false },
      { label: 'Projections', value: 'projections', selected: false },
    ],
  },
];

export const reitsPersonalizeOptions: DropDownOption[] = [
  { label: 'Hammerson', value: 'hammerson', selected: false },
  { label: 'Land Securities', value: 'landSecurities', selected: false },
  { label: 'British Land', value: 'britishLand', selected: false },
  {
    label: 'Kite Realty Group Trust',
    value: 'KiteRealtyGroupTrust',
    selected: false,
  },
  { label: 'Hammerson', value: 'hammerson', selected: false },
  { label: 'Land Securities', value: 'landSecurities', selected: false },
  { label: 'British Land', value: 'britishLand', selected: false },
  {
    label: 'Kite Realty Group Trust',
    value: 'KiteRealtyGroupTrust',
    selected: false,
  },
  { label: 'Hammerson', value: 'hammerson', selected: false },
  { label: 'Land Securities', value: 'landSecurities', selected: false },
  { label: 'British Land', value: 'britishLand', selected: false },
  {
    label: 'Kite Realty Group Trust',
    value: 'KiteRealtyGroupTrust',
    selected: false,
  },
];

export const reitCategoriesOptions: ChipOption[] = [
  { label: 'Office', value: 1, selected: false },
  { label: 'Retail', value: 2, selected: false },
  { label: 'Residential', value: 3, selected: false },
  { label: 'Student Accommodation', value: 4, selected: false },
  { label: 'Healthcare', value: 5, selected: false },
  { label: 'Self Storage', value: 6, selected: false },
  { label: 'Diversified', value: 7, selected: false },
];

export const mySectorTableData: IReitData = {
  sections: [
    { sectionTitle: '', columnDefs: ['sector'], sticky: true },
    {
      sectionTitle: 'Return on investment (ROI)',
      columnDefs: [
        'giy',
        'niy',
        'nnniy',
        'growth',
        'investmentIrr',
        'devt',
        'yieldImpact',
        'opex',
        'capex',
        'gandA',
        'unLeveredIrr',
      ],
    },
    {
      sectionTitle: 'Capital structure',
      columnDefs: [
        'wacd',
        'ltv',
        'debtBoost',
        'leveredIrr',
        'pdToGav',
        'openingPDGav',
        'closingPDGav',
        'impIrr',
      ],
    },
    {
      sectionTitle: 'Cost of capital / risk return',
      columnDefs: [
        'r',
        'b',
        'eqRP',
        'requiredReturn',
        'excessReturns',
        'kolyticsRating',
      ],
    },
  ],
  headers: {
    sector: {
      title: 'Sector',
      fill: 'dark',
      sortable: true,
      type: 'text',
      sticky: true,
    },
    giy: {
      title: 'GIY',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'One year forward Gross Rental Income, as a percentage of last reported GAV',
    },
    opex: {
      title: 'Direct\nExpenses',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Expenses attributable directly to property assets such as leasing and void costs',
    },
    niy: {
      title: 'NIY',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Similar to EPRA Net Initial Yield but without adjusting for purchaser costs (note: not a market defined NIY which is gross of property expenses)',
    },
    capex: {
      title: 'Capex\nReserve',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Structural Capex reserve estimate to maintain a property in good order but not new standard',
    },
    gandA: {
      title: 'G&A',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Net head office costs (i.e. add back management fees)',
    },
    nnniy: {
      title: 'NNNIY',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Triple Net yield after substantially all costs relating to operating a property portfolio are accounted for',
    },
    growth: {
      title: 'Wtd.\nGrowth',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Weighted growth: Near-term from like-for-like calculations; medium-term and long-term converging towards inflation',
    },
    investmentIrr: {
      title: 'IRR',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Unlevered Internal Rate of Return (IRR) on investment portfolio',
    },
    devt: {
      title: 'Dev’t\nBoost',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The impact of development on returns over the medium-term; estimated annualised profit on development as a percentage of investment portfolio',
    },
    unLeveredIrr: {
      title: 'Unlevered\nIRR',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Unlevered Internal Rate of Return (IRR) on total property portfolio',
    },
    wacd: {
      title: 'WACD',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Weigthed Average Cost of Debt',
    },
    ltv: {
      title: 'LTV',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Loan-to-Value: Net debt divided by property portfolio',
    },
    debtBoost: {
      title: 'Debt\nboost',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The boost (if positive) to unlevered returns resulting from using debt financing',
    },
    leveredIrr: {
      title: 'Levered\nIRR',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Levered IRR at last reported NAV',
    },
    openingPDGav: {
      title: 'Opening\nP/(D) to GAV',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Premium / (Discount) to Gross Asset Value based on the last closing share price. Adjusted for skewing effects of leverage',
    },
    closingPDGav: {
      title: 'Closing\nP/(D) to GAV',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The forecast Premium/(Discount) to GAV in five years. Note this is driven by the estimated future NTA and share price',
    },
    impIrr: {
      title: 'Imp.\nIRR',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The expected IRR an equity investor could realise over the medium-term. Includes the impact of leverage in the company',
    },
    r: {
      title: 'Local\nRf',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Local "risk free" 10-year government bond yield',
    },
    b: {
      title: 'Adjusted\nBeta',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The beta of the company relative to the local REIT Exchange Traded Fund (ETF) adjusted - by looking at the corporate structure, balance sheet risk, takeout odds, and implied value/sf relative ro replacement cost - to reflect an element of future downside risk',
    },
    eqRP: {
      title: 'Eq. Risk\nPrem',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The estimate average equity risk premium required above the local 10-year government bond',
    },
    requiredReturn: {
      title: 'Required\nReturn',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The return an equity investor should require for the given risk level, using the CAPM approach',
    },
    // 'correlationToMkt': { title: 'Correlation to Mkt', fill: 'dark', sortable: true, type: 'text' },
    excessReturns: {
      title: 'Excess\nreturns',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'The implied IRR less the required return',
    },
  },
  stickLeftColumns: {
    sector: { title: 'Sector', fill: 'dark', sortable: true, type: 'text' },
  },
  data: [{}],
  total: 0,
};

export const reitTableData: IReitData = {
  sections: [
    { sectionTitle: '', columnDefs: ['company'], sticky: true },
    {
      sectionTitle: 'Return on investment (ROI)',
      columnDefs: [
        'giy',
        'niy',
        'nnniy',
        'growth',
        'investmentIrr',
        'devt',
        'yieldImpact',
        'opex',
        'capex',
        'gandA',
        'unLeveredIrr',
      ],
    },
    {
      sectionTitle: 'Capital structure',
      columnDefs: [
        'wacd',
        'ltv',
        'debtBoost',
        'leveredIrr',
        'pdToGav',
        'openingPDGav',
        'closingPDGav',
        'impIrr',
      ],
    },
    {
      sectionTitle: 'Cost of capital / risk return',
      columnDefs: [
        'r',
        'b',
        'eqRP',
        'requiredReturn',
        'excessReturns',
        'kolyticsRating',
      ],
    },
  ],
  headers: {
    company: {
      title: 'Company',
      fill: 'dark',
      sortable: true,
      type: 'text',
      sticky: true,
    },
    country: { title: 'Country', fill: 'dark', sortable: true, type: 'text' },
    sector: { title: 'Sector', fill: 'dark', sortable: true, type: 'text' },
    price: {
      title: 'Price',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: "Most recent day's closing share price",
    },
    corporateScore: {
      title: 'Corp.\nScore',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Corporate governance, management and alignment score',
    },
    bsRiskScore: {
      title: 'B/S\nScore',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Score reflects LTV and Debt/EBITDA profile',
    },
    giy: {
      title: 'GIY',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'One year forward Gross Rental Income, as a percentage of last reported GAV',
    },
    opex: {
      title: 'Direct\nExpenses',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Expenses attributable directly to property assets such as leasing and void costs',
    },
    niy: {
      title: 'NIY',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Similar to EPRA Net Initial Yield but without adjusting for purchaser costs (note: not a market defined NIY which is gross of property expenses)',
    },
    capex: {
      title: 'Capex\nReserve',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Structural Capex reserve estimate to maintain a property in good order but not new standard',
    },
    gandA: {
      title: 'G&A',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Net head office costs (i.e. add back management fees)',
    },
    nnniy: {
      title: 'NNNIY',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Triple Net yield after substantially all costs relating to operating a property portfolio are accounted for',
    },
    growth: {
      title: 'Rental\nValue\nGrowth',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Average medium-term (5 year) growth in property value due to increased market rental values. Impact of long-term expected growth is reflected in yield shifts.',
    },
    investmentIrr: {
      title: 'IRR',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Unlevered Internal Rate of Return (IRR) on investment portfolio',
    },
    devt: {
      title: 'Dev’t\nBoost',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The impact of development on returns over the medium-term; estimated annualised profit on development as a percentage of investment portfolio',
    },
    yieldImpact: {
      title: 'Yield\nImpact',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The impact over the medium term of expected yield movements on property return, accounting for changing interest rates/return expectations, as well as changing growth rates between near-term and long-term',
    },
    unLeveredIrr: {
      title: 'Unlevered\nIRR',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Unlevered Internal Rate of Return (IRR) on total property portfolio',
    },
    wacd: {
      title: 'WACD',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Average forecast weighted average cost of debt over the next 5 years',
    },
    ltv: {
      title: 'LTV',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Average forecast Loan-To-Value (net debt divided by property portfolio value) over the next 5 years',
    },
    debtBoost: {
      title: 'Debt\nboost',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The boost (if positive) to unlevered returns resulting from using debt financing',
    },
    leveredIrr: {
      title: 'Levered\nIRR',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Levered IRR at last reported NAV',
    },
    openingPDGav: {
      title: 'Opening\nP/(D) to GAV',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Premium / (Discount) to Gross Asset Value based on the last closing share price. Adjusted for skewing effects of leverage',
    },
    closingPDGav: {
      title: 'Closing\nP/(D) to GAV',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The forecast Premium/(Discount) to GAV in five years. Note this is driven by the estimated future NTA and share price',
    },
    impIrr: {
      title: 'Imp.\nIRR',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Total shareholder return: the spot implied IRR, adjusted for movements in the premium or discount to GAV, as well as cash flow timing, capital allocation impacts, and all other drivers of return',
    },
    r: {
      title: 'Local\nRf',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Local "risk free" 10-year government bond yield',
    },
    b: {
      title: 'Adjusted\nBeta',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The beta of the company relative to the local REIT Exchange Traded Fund (ETF) adjusted - by looking at the corporate structure, balance sheet risk, takeout odds, and implied value/sf relative ro replacement cost - to reflect an element of future downside risk',
    },
    eqRP: {
      title: 'Eq. Risk\nPrem',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The estimate average equity risk premium required above the local 10-year government bond',
    },
    requiredReturn: {
      title: 'Required\nReturn',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The return an equity investor should require for the given risk level, using the CAPM approach',
    },
    // 'correlationToMkt': { title: 'Correlation to Mkt', fill: 'dark', sortable: true, type: 'text' },
    excessReturns: {
      title: 'Excess\nreturns',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'The implied IRR less the required return',
    },
    kolyticsRating: {
      title: 'Kolytics\nRating',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Primarily based on the excess return, with adjustments made on a portfolio-wide basis to account for correlations between similar companies and sectors',
    },
    'action-my-reit': { title: 'My REITs', fill: 'dark', type: 'action' },
  },
  stickLeftColumns: {
    company: { title: 'Company', fill: 'dark', sortable: true, type: 'text' },
  },
  data: [],
  total: 0,
};
export const incomeTableData: IReitData = {
  sections: [
    {
      sectionTitle: 'Scenario name',
      columnDefs: [
        'field',
        '17A',
        '18A',
        '19A',
        '20A',
        '21F',
        '22F',
        '23F',
        '24F',
        '25F',
      ],
      spacing: true,
    },
    {
      sectionTitle: 'Base',
      columnDefs: ['21F_1', '22F_1', '23F_1', '24F_1', '25F_1'],
    },
  ],
  headers: {
    field: { title: 'Field', fill: 'purple', sortable: true, type: 'text' },
    '17A': { title: '17A', fill: 'purple', sortable: true, type: 'text' },
    '18A': { title: '18A', fill: 'purple', sortable: true, type: 'text' },
    '19A': { title: '19A', fill: 'purple', sortable: true, type: 'text' },
    '20A': { title: '20A', fill: 'purple', sortable: true, type: 'text' },
    '21F': {
      title: '21F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '22F': {
      title: '22F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '23F': {
      title: '23F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '24F': {
      title: '24F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '25F': {
      title: '25F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '21F_1': { title: '21F', fill: 'dark', sortable: true, type: 'text' },
    '22F_1': { title: '22F', fill: 'dark', sortable: true, type: 'text' },
    '23F_1': { title: '23F', fill: 'dark', sortable: true, type: 'text' },
    '24F_1': { title: '24F', fill: 'dark', sortable: true, type: 'text' },
    '25F_1': { title: '25F', fill: 'dark', sortable: true, type: 'text' },
  },
  data: [
    {
      field: { data: 'Revenue / GRI' },
      '17A': { data: '539.1' },
      '18A': { data: '391.0' },
      '19A': { data: '402.7' },
      '20A': { data: '402.7' },
      '21F': { data: '402.7' },
      '22F': { data: '402.7' },
      '23F': { data: '402.7' },
      '24F': { data: '402.7' },
      '25F': { data: '402.7' },
      '21F_1': { data: '402.7' },
      '22F_1': { data: '402.7' },
      '23F_1': { data: '402.7' },
      '24F_1': { data: '402.7' },
      '25F_1': { data: '402.7' },
    },
    {
      field: { data: 'Direct expenses' },
      '17A': { data: '(103.7)', color: 'warn' },
      '18A': { data: '(149.6)', color: 'warn' },
      '19A': { data: '(149.6)', color: 'warn' },
      '20A': { data: '(149.6)', color: 'warn' },
      '21F': { data: '(149.6)', color: 'warn' },
      '22F': { data: '(149.6)', color: 'warn' },
      '23F': { data: '(149.6)', color: 'warn' },
      '24F': { data: '(149.6)', color: 'warn' },
      '25F': { data: '(149.6)', color: 'warn' },
      '21F_1': { data: '(149.6)', color: 'warn' },
      '22F_1': { data: '(149.6)', color: 'warn' },
      '23F_1': { data: '(149.6)', color: 'warn' },
      '24F_1': { data: '(149.6)', color: 'warn' },
      '25F_1': { data: '(149.6)', color: 'warn' },
    },
    {
      field: { data: 'Provisions / other' },
      '17A': { data: '-' },
      '18A': { data: '(12.0)', color: 'warn' },
      '19A': { data: '(12.0)', color: 'warn' },
      '20A': { data: '(12.0)', color: 'warn' },
      '21F': { data: '(12.0)', color: 'warn' },
      '22F': { data: '(12.0)', color: 'warn' },
      '23F': { data: '(12.0)', color: 'warn' },
      '24F': { data: '(12.0)', color: 'warn' },
      '25F': { data: '(12.0)', color: 'warn' },
      '21F_1': { data: '(12.0)', color: 'warn' },
      '22F_1': { data: '(12.0)', color: 'warn' },
      '23F_1': { data: '(12.0)', color: 'warn' },
      '24F_1': { data: '(12.0)', color: 'warn' },
      '25F_1': { data: '(12.0)', color: 'warn' },
    },
    {
      field: { data: 'LFL NRI' },
      '17A': { data: '435.4' },
      '18A': { data: '229.4' },
      '19A': { data: '234.8' },
      '20A': { data: '229.4' },
      '21F': { data: '234.8' },
      '22F': { data: '229.4' },
      '23F': { data: '435.4' },
      '24F': { data: '234.8' },
      '25F': { data: '234.8' },
      '21F_1': { data: '234.8' },
      '22F_1': { data: '234.8' },
      '23F_1': { data: '234.8' },
      '24F_1': { data: '234.8' },
      '25F_1': { data: '234.8' },
    },
    {
      field: { data: 'Development NRI' },
      '17A': { data: '-' },
      '18A': { data: '-' },
      '19A': { data: '7.4' },
      '20A': { data: '7.4' },
      '21F': { data: '7.4' },
      '22F': { data: '7.4' },
      '23F': { data: '7.4' },
      '24F': { data: '7.4' },
      '25F': { data: '7.4' },
      '21F_1': { data: '7.4' },
      '22F_1': { data: '7.4' },
      '23F_1': { data: '7.4' },
      '24F_1': { data: '7.4' },
      '25F_1': { data: '7.4' },
    },
    {
      field: { data: 'Acquisition NRI' },
      '17A': { data: '-' },
      '18A': { data: '-' },
      '19A': { data: '30.6' },
      '20A': { data: '30.6' },
      '21F': { data: '30.6' },
      '22F': { data: '30.6' },
      '23F': { data: '30.6' },
      '24F': { data: '30.6' },
      '25F': { data: '30.6' },
      '21F_1': { data: '30.6' },
      '22F_1': { data: '30.6' },
      '23F_1': { data: '30.6' },
      '24F_1': { data: '30.6' },
      '25F_1': { data: '30.6' },
    },
    {
      field: { data: 'Acquisition NRI' },
      '17A': { data: '-' },
      '18A': { data: '-' },
      '19A': { data: '(42.0)', color: 'warn' },
      '20A': { data: '(42.0)', color: 'warn' },
      '21F': { data: '(42.0)', color: 'warn' },
      '22F': { data: '(42.0)', color: 'warn' },
      '23F': { data: '(42.0)', color: 'warn' },
      '24F': { data: '(42.0)', color: 'warn' },
      '25F': { data: '(42.0)', color: 'warn' },
      '21F_1': { data: '(42.0)', color: 'warn' },
      '22F_1': { data: '(42.0)', color: 'warn' },
      '23F_1': { data: '(42.0)', color: 'warn' },
      '24F_1': { data: '(42.0)', color: 'warn' },
      '25F_1': { data: '(42.0)', color: 'warn' },
    },
    {
      field: { data: 'Net rental income' },
      '17A': { data: '435.4' },
      '18A': { data: '229.4' },
      '19A': { data: '230.8' },
      '20A': { data: '230.8' },
      '21F': { data: '230.8' },
      '22F': { data: '230.8' },
      '23F': { data: '230.8' },
      '24F': { data: '230.8' },
      '25F': { data: '229.4' },
      '21F_1': { data: '230.8' },
      '22F_1': { data: '435.4' },
      '23F_1': { data: '230.8' },
      '24F_1': { data: '435.4' },
      '25F_1': { data: '230.8' },
    },
  ],
  total: 0,
};
export const portfolioTableData: IReitData = {
  sections: [
    {
      sectionTitle: 'Scenario name',
      columnDefs: [
        'inputOutput',
        'subPortfolio',
        '21F',
        '22F',
        '23F',
        '24F',
        '25F',
      ],
      spacing: true,
      spacingFill: 'light',
    },
    {
      sectionTitle: 'Base',
      columnDefs: ['21F_1', '22F_1', '23F_1', '24F_1', '25F_1'],
    },
  ],
  headers: {
    inputOutput: {
      title: 'Input / output',
      fill: 'purple',
      sortable: true,
      type: 'text',
    },
    subPortfolio: {
      title: 'Sub-portfolio',
      fill: 'purple',
      sortable: true,
      type: 'text',
    },
    '21F': {
      title: '21F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '22F': {
      title: '22F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '23F': {
      title: '23F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '24F': {
      title: '24F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '25F': {
      title: '25F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '21F_1': { title: '21F', fill: 'dark', sortable: true, type: 'text' },
    '22F_1': { title: '22F', fill: 'dark', sortable: true, type: 'text' },
    '23F_1': { title: '23F', fill: 'dark', sortable: true, type: 'text' },
    '24F_1': { title: '24F', fill: 'dark', sortable: true, type: 'text' },
    '25F_1': { title: '25F', fill: 'dark', sortable: true, type: 'text' },
  },
  data: [
    {
      inputOutput: { data: 'EPRA NIY' },
      subPortfolio: { data: 'UK Flagship' },
      '21F': { data: '6.8%' },
      '22F': { data: '6.8%' },
      '23F': { data: '6.8%' },
      '24F': { data: '6.8%' },
      '25F': { data: '6.8%' },
      '21F_1': { data: '6.8%' },
      '22F_1': { data: '6.8%' },
      '23F_1': { data: '6.8%' },
      '24F_1': { data: '6.8%' },
      '25F_1': { data: '6.8%' },
    },
    {
      inputOutput: { data: 'Yield change' },
      subPortfolio: { data: 'UK Flagship' },
      '21F': { data: '0.2%', editable: true },
      '22F': { data: '0.2%', editable: true },
      '23F': { data: '0.2%', editable: true },
      '24F': { data: '0.2%', editable: true },
      '25F': { data: '0.2%', editable: true },
      '21F_1': { data: '0.2%' },
      '22F_1': { data: '0.2%' },
      '23F_1': { data: '0.2%' },
      '24F_1': { data: '0.2%' },
      '25F_1': { data: '0.2%' },
    },
    {
      inputOutput: { data: 'ERV growth' },
      subPortfolio: { data: 'UK Flagship' },
      '21F': { data: '-10.0%', editable: true },
      '22F': { data: '-10.0%', editable: true },
      '23F': { data: '-10.0%', editable: true },
      '24F': { data: '-10.0%', editable: true },
      '25F': { data: '-10.0%', editable: true },
      '21F_1': { data: '-10.0%' },
      '22F_1': { data: '-10.0%' },
      '23F_1': { data: '-10.0%' },
      '24F_1': { data: '-10.0%' },
      '25F_1': { data: '-10.0%' },
    },
    {
      inputOutput: { data: 'LFL capital values' },
      subPortfolio: { data: 'UK Flagship' },
      '21F': { data: '-12.6%', fill: 'ctaDisabled' },
      '22F': { data: '-12.6%' },
      '23F': { data: '-12.6%' },
      '24F': { data: '-12.6%' },
      '25F': { data: '-12.6%' },
      '21F_1': { data: '-12.6%' },
      '22F_1': { data: '-12.6%' },
      '23F_1': { data: '-12.6%' },
      '24F_1': { data: '-12.6%' },
      '25F_1': { data: '-12.6%' },
    },
    {
      inputOutput: { data: 'Annual indexed uplift' },
      subPortfolio: { data: 'UK Flagship' },
      '21F': { data: '1320', fill: 'ctaDisabled' },
      '22F': { data: '1320' },
      '23F': { data: '1320' },
      '24F': { data: '1320' },
      '25F': { data: '1320' },
      '21F_1': { data: '1320' },
      '22F_1': { data: '1320' },
      '23F_1': { data: '1320' },
      '24F_1': { data: '1320' },
      '25F_1': { data: '1320' },
    },
    {
      inputOutput: { data: 'Yield change' },
      subPortfolio: { data: 'UK Flagship' },
      '21F': { data: '0.2%', editable: true },
      '22F': { data: '0.2%', editable: true },
      '23F': { data: '0.2%', editable: true },
      '24F': { data: '0.2%', editable: true },
      '25F': { data: '0.2%', editable: true },
      '21F_1': { data: '0.2%' },
      '22F_1': { data: '0.2%' },
      '23F_1': { data: '0.2%' },
      '24F_1': { data: '0.2%' },
      '25F_1': { data: '0.2%' },
    },
    {
      inputOutput: { data: 'ERV growth' },
      subPortfolio: { data: 'UK Flagship' },
      '21F': { data: '-10.0%', editable: true },
      '22F': { data: '-10.0%', editable: true },
      '23F': { data: '-10.0%', editable: true },
      '24F': { data: '-10.0%', editable: true },
      '25F': { data: '-10.0%', editable: true },
      '21F_1': { data: '-10.0%' },
      '22F_1': { data: '-10.0%' },
      '23F_1': { data: '-10.0%' },
      '24F_1': { data: '-10.0%' },
      '25F_1': { data: '-10.0%' },
    },
    {
      inputOutput: { data: 'LFL capital values' },
      subPortfolio: { data: 'UK Flagship' },
      '21F': { data: '-12.6%' },
      '22F': { data: '-12.6%' },
      '23F': { data: '-12.6%' },
      '24F': { data: '-12.6%' },
      '25F': { data: '-12.6%' },
      '21F_1': { data: '-12.6%' },
      '22F_1': { data: '-12.6%' },
      '23F_1': { data: '-12.6%' },
      '24F_1': { data: '-12.6%' },
      '25F_1': { data: '-12.6%' },
    },
    {
      inputOutput: { data: 'Annual indexed uplift' },
      subPortfolio: { data: 'UK Flagship' },
      '21F': { data: '1320' },
      '22F': { data: '1320' },
      '23F': { data: '1320' },
      '24F': { data: '1320' },
      '25F': { data: '1320' },
      '21F_1': { data: '1320' },
      '22F_1': { data: '1320' },
      '23F_1': { data: '1320' },
      '24F_1': { data: '1320' },
      '25F_1': { data: '1320' },
    },
  ],
  total: 0,
};
export const otherCapexTableData: IReitData = {
  sections: [
    {
      sectionTitle: 'Scenario name',
      columnDefs: ['structural_capex', 'capex_1'],
      spacing: true,
      spacingFill: 'light',
    },
    { sectionTitle: 'Base', columnDefs: ['capex_2'] },
  ],
  headers: {
    structural_capex: {
      title: 'Structural CAPEX reserve',
      fill: 'purple',
      sortable: true,
      type: 'text',
      colspan: 1,
      fixWidth: '32.6%',
    },
    capex_1: {
      title: 'CAPEX',
      fill: 'purple',
      sortable: true,
      type: 'text',
      colspan: 1,
    },
    capex_2: {
      title: 'CAPEX',
      fill: 'forecast',
      sortable: true,
      type: 'text',
      colspan: 1,
    },
  },
  data: [
    {
      structural_capex: { data: 'UK flagship' },
      capex_1: { data: '18%', editable: true },
      capex_2: { data: '18%', editable: true },
    },
    {
      structural_capex: { data: 'France' },
      capex_1: { data: '16%', editable: true },
      capex_2: { data: '16%', editable: true },
    },
    {
      structural_capex: { data: 'Ireland' },
      capex_1: { data: '18%', editable: true },
      capex_2: { data: '18%', editable: true },
    },
    {
      structural_capex: { data: 'UK retail parks' },
      capex_1: { data: '15%', editable: true },
      capex_2: { data: '15%', editable: true },
    },
    {
      structural_capex: { data: 'UK other' },
      capex_1: { data: '20%', editable: true },
      capex_2: { data: '20%', editable: true },
    },
  ],
  total: 0,
};
export const otherErvTableData: IReitData = {
  hideSection: true,
  sections: [
    {
      sectionTitle: 'Scenario name',
      columnDefs: ['growth', 'weighting_1'],
      spacing: true,
      spacingFill: 'light',
    },
    { sectionTitle: 'Base', columnDefs: ['weighting_2'] },
  ],
  headers: {
    growth: {
      title: 'ERV growth period',
      fill: 'purple',
      sortable: true,
      type: 'text',
      colspan: 1,
      fixWidth: '32.6%',
    },
    weighting_1: {
      title: 'Weighting',
      fill: 'purple',
      sortable: true,
      type: 'text',
      colspan: 1,
    },
    weighting_2: {
      title: 'Weighting',
      fill: 'forecast',
      sortable: true,
      type: 'text',
      colspan: 1,
    },
  },
  data: [
    {
      growth: { data: 'Near-term' },
      weighting_1: { data: '20%', editable: true },
      weighting_2: { data: '20%', editable: true },
    },
    {
      growth: { data: 'Medium-term' },
      weighting_1: { data: '15%', editable: true },
      weighting_2: { data: '15%', editable: true },
    },
    {
      growth: { data: 'Long-term' },
      weighting_1: { data: '65%', editable: true },
      weighting_2: { data: '65%', editable: true },
    },
  ],
  total: 0,
};
export const otherMarketTableData: IReitData = {
  hideSection: true,
  sections: [
    {
      sectionTitle: 'Scenario name',
      columnDefs: ['market', 'borrowing_1'],
      spacing: true,
      spacingFill: 'light',
    },
    { sectionTitle: 'Base', columnDefs: ['borrowing_2'] },
  ],
  headers: {
    market: {
      title: 'Market',
      fill: 'purple',
      sortable: true,
      type: 'text',
      colspan: 1,
      fixWidth: '32.6%',
    },
    borrowing_1: {
      title: 'Borrowing spreads',
      fill: 'purple',
      sortable: true,
      type: 'text',
      colspan: 1,
    },
    borrowing_2: {
      title: 'Borrowing spreads',
      fill: 'forecast',
      sortable: true,
      type: 'text',
      colspan: 1,
    },
  },
  data: [
    {
      market: { data: 'GBP' },
      borrowing_1: { data: '2.25%', editable: true },
      borrowing_2: { data: '2.25%', editable: true },
    },
    {
      market: { data: 'EUR' },
      borrowing_1: { data: '2.50%', editable: true },
      borrowing_2: { data: '2.50%', editable: true },
    },
    {
      market: { data: 'USD' },
      borrowing_1: { data: '2.00%', editable: true },
      borrowing_2: { data: '2.00%', editable: true },
    },
    {
      market: { data: 'CHF' },
      borrowing_1: { data: '2.00%', editable: true },
      borrowing_2: { data: '2.00%', editable: true },
    },
  ],
  total: 0,
};

export const allDataAnalyticsTableData: IReitData = {
  sections: [
    {
      sectionTitle: '',
      columnDefs: ['name', 'sectorName'],
      sticky: true,
    },
    {
      sectionTitle: 'Income Statement',
      columnDefs: ['IS0001', 'IS0002', 'IS0003'],
    },
    {
      sectionTitle: 'Balance Sheet',
      columnDefs: ['BS001', 'BS002'],
    },
    {
      sectionTitle: 'Cash Flow',
      columnDefs: ['CF001', 'CF002'],
    },
    {
      sectionTitle: 'Operating Metric',
      columnDefs: ['OM001', 'OM002', 'OM003', 'OM004', 'OM005'],
    },
    {
      sectionTitle: 'Valuation Metric',
      columnDefs: ['VM001'],
    },
  ],
  headers: {
    name: {
      title: 'Company',
      fill: 'dark',
      sortable: true,
      type: 'text',
      sticky: true,
    },
    sectorName: {
      title: 'Sector',
      fill: 'dark',
      sortable: true,
      type: 'text',
      sticky: true,
    },
    IS0001: {
      title: 'EPRA Earnings / FFO',
      fill: 'dark',
      sortable: true,
      type: 'text',
      firstOfGroup: true,
      lastOfGroup: false,
    },
    IS0002: {
      title: 'EPRA Earnings / FFO per share',
      fill: 'dark',
      sortable: true,
      type: 'text',
      firstOfGroup: false,
      lastOfGroup: false,
    },
    IS0003: {
      title: 'Dividends per share',
      fill: 'dark',
      sortable: true,
      type: 'text',
      firstOfGroup: false,
      lastOfGroup: true,
    },
    BS001: {
      title: 'Investment Property Value',
      fill: 'dark',
      sortable: true,
      type: 'text',
      firstOfGroup: true,
      lastOfGroup: false,
    },
    BS002: {
      title: 'Debt Borrowings',
      fill: 'dark',
      sortable: true,
      type: 'text',
      firstOfGroup: false,
      lastOfGroup: true,
    },
    CF001: {
      title: 'Net Cash From Operating Activities',
      fill: 'dark',
      sortable: true,
      type: 'text',
      firstOfGroup: true,
      lastOfGroup: false,
    },
    CF002: {
      title: 'Debt Issuance',
      fill: 'dark',
      sortable: true,
      type: 'text',
      firstOfGroup: false,
      lastOfGroup: true,
    },
    OM001: {
      title: 'Total Debt',
      fill: 'dark',
      sortable: true,
      type: 'text',
      firstOfGroup: true,
      lastOfGroup: false,
    },
    OM002: {
      title: 'Debt/ EBITDA',
      fill: 'dark',
      sortable: true,
      type: 'text',
      firstOfGroup: false,
      lastOfGroup: false,
    },
    OM003: {
      title: 'Occupancy (%)',
      fill: 'dark',
      sortable: true,
      type: 'text',
      firstOfGroup: false,
      lastOfGroup: false,
    },
    OM004: {
      title: 'Total Square Feet',
      fill: 'dark',
      sortable: true,
      type: 'text',
      firstOfGroup: false,
      lastOfGroup: false,
    },
    OM005: {
      title: 'Total Number of Properties',
      fill: 'dark',
      sortable: true,
      type: 'text',
      firstOfGroup: false,
      lastOfGroup: true,
    },
    VM001: {
      title: 'Market Capitalisation',
      fill: 'dark',
      sortable: true,
      type: 'text',
      firstOfGroup: true,
      lastOfGroup: true,
    },
  },
  stickLeftColumns: {
    name: {
      title: 'Company',
      fill: 'dark',
      sortable: true,
      type: 'text',
    },
    sectorName: {
      title: 'Sector',
      fill: 'dark',
      sortable: true,
      type: 'text',
    },
  },
  data: [],
  total: 0,
};
export const allReitTableData: IReitData = {
  sections: [
    { sectionTitle: '', columnDefs: ['company'], sticky: true },
    {
      sectionTitle: 'MARKET PRICING',
      columnDefs: [
        'price',
        'pdToNav',
        'dailyMove',
        'wHigh',
        'wLow',
        'wHistory',
      ],
    },
    { sectionTitle: 'SCALE', columnDefs: ['mcap', 'liquidity'] },
    {
      sectionTitle: 'VALUATION / RETURNS',
      columnDefs: ['impIrr', 'impValue', 'affo', 'dividendYield'],
    },
    {
      sectionTitle: 'BALANCE SHEET',
      columnDefs: ['ltv', 'ebitda', 'kolyticsRating'],
    },
  ],
  headers: {
    company: {
      title: 'Company',
      fill: 'dark',
      sortable: true,
      type: 'text',
      sticky: true,
    },
    country: {
      title: 'Country',
      fill: 'dark',
      sortable: true,
      type: 'text',
    },
    sector: {
      title: 'Sector',
      fill: 'dark',
      sortable: true,
      type: 'text',
    },
    price: {
      title: 'Price',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: "Most recent day's closing share price",
    },
    pdToNav: {
      title: 'P/(D)\nto NAV',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Premium / (Discount) to Net Asset Value based on the last closing share price. Skewed by leverage',
    },
    dailyMove: {
      title: 'Daily\nmove',
      fill: 'dark',
      sortable: true,
      type: 'text',
    },
    wHigh: {
      title: 'vs. 52w\nhigh',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Based on daily closing values',
    },
    wLow: {
      title: 'vs. 52w\nlow',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Based on daily closing values',
    },
    wHistory: {
      title: '52w history',
      fill: 'dark',
      sortable: false,
      type: 'chart',
    },
    mcap: {
      title: 'Mcap (m)',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The share price multiplied by the outstanding shares, in local currency',
    },
    liquidity: {
      title: 'Liquidity\n(M/day)',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Average daily liquidity in local currency',
    },
    impIrr: {
      title: 'Implied\nIRR',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The expected IRR an equity investor could realise over the medium-term. Includes the impact of changes in Dividends, changes in NTA, leverage, starting / expected changes in premium to net assets',
    },
    impValue: {
      title: 'Implied\nvalue/Sf',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        "The implied value per square foot (where for specific sectors with alternate measures, we equate 1 macro cell tower = 10 small cells = 1 route mile of fibre = 1000 sf) based on previous day's closing share price, in local currency",
    },
    affo: {
      title: 'AFFO\nyield',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Adjusted Funds from Operations Yield, based on the last full year reported and the share price at the end of the last reporting period',
    },
    dividendYield: {
      title: 'Dividend\nyield',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Dividend Yield, based on the last full year reported and the share price at the end of the last reporting period',
    },
    ltv: {
      title: 'LTV',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Loan-to-Value: Net debt divided by total property portfolio value',
    },
    ebitda: {
      title: 'Debt/\nEBITDA',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Net Debt to EBITDA ratio',
    },
    kolyticsRating: {
      title: 'Rating',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Primarily based on the excess return, with adjustments made on a portfolio-wide basis to account for correlations between similar companies and sectors',
    },
    'action-my-reit': { title: 'My REITs', fill: 'dark', type: 'action' },
  },
  stickLeftColumns: {
    company: { title: 'Company', fill: 'dark', sortable: true, type: 'text' },
  },
  data: [],
  total: 0,
};
export const sectorTableData: IReitData = {
  sections: [
    {
      sectionTitle: 'building the unlevered irr (inc) dev’t',
      columnDefs: ['giy', 'ninny', 'growth', 'devt', 'portIRR'],
    },
    {
      sectionTitle: 'Capital structure',
      columnDefs: [
        'wacd',
        'ltv',
        'debtBoost',
        'leveredIrr',
        'pdToGav',
        'impIrr',
      ],
    },
    {
      sectionTitle: 'Cost of capital / risk return',
      columnDefs: ['rf', 'bit', 'eqRP', 'wacc', 'excessReturns'],
    },
  ],
  headers: {
    'sub-sector': {
      title: 'Sub-\nsector',
      fill: 'dark',
      sortable: true,
      type: 'text',
    },
    country: {
      title: 'Country\nlisted',
      fill: 'dark',
      sortable: true,
      type: 'text',
    },
    giy: {
      title: 'GIY',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Gross income divided by property portfolio (investment not development assets)\n',
    },
    ninny: {
      title: 'NINNY',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Triple Net Yield after deduction of 1) direct property costs, 2) maintaince CAPEX estimate, and 3) administration or G&A costs',
    },
    growth: {
      title: 'Growth',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Weighted average short and long-term growth',
    },
    devt: {
      title: 'Dev’t',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'The boost to investment returns provoded by development.',
    },
    portIRR: {
      title: 'Port.\nIRR',
      fill: 'dark',
      sortable: true,
      type: 'text',
    },
    wacd: {
      title: 'WACD',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'The weighted average cost of capital',
    },
    ltv: {
      title: 'LTV',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Loan to value: financial debt net of cash holdings divided by property assets net of cash',
    },
    debtBoost: {
      title: 'Debt\nboost',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'Impact of financial leverage; often positive but can be negative when the cost of debt is greater than estimated property returns.',
    },
    leveredIrr: {
      title: 'Levered\nIRR',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'The levered IRR on the property portfolio',
    },
    pdToGav: {
      title: 'P/(D)\nto GAV',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'The premium / (discount) to Gross Asset Value',
    },
    impIrr: {
      title: 'Implied\nIRR',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The implied IRR on the company based on the premium / (discount) to gross asset value',
    },
    rf: { title: 'Rf', fill: 'dark', sortable: true, type: 'text' },
    bit: { title: 'β', fill: 'dark', sortable: true, type: 'text' },
    eqRP: {
      title: 'EQ. Risk\nPremium\n',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip:
        'The required equity premium (based on the historical Beta relative to the benchmark) over and above the local 10Y risk free rate',
    },
    wacc: {
      title: 'WACC',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'The weighted average cost of capital',
    },
    excessReturns: {
      title: 'Excess\nreturns',
      fill: 'dark',
      sortable: true,
      type: 'text',
      tooltip: 'Please remove this as no longer there',
    },
  },
  stickLeftColumns: {
    sector: { title: 'Sector', fill: 'dark', sortable: true, type: 'text' },
  },
  data: [
    {
      id: { data: '467193f8-0181-416e-9994-665a334f0d3d' },
      sector: { data: 'Office' },
      'sub-sector': { data: 'Office' },
      country: { data: 'US' },
      giy: { data: '1.0%' },
      ninny: { data: '1.0%' },
      growth: { data: '1.0%' },
      devt: { data: '1.0%' },
      portIRR: { data: '1.0%' },
      wacd: { data: '1.0%' },
      ltv: { data: '1.0%' },
      debtBoost: { data: '1.0%' },
      leveredIrr: { data: '1.0%' },
      pdToGav: { data: '1.0%' },
      impIrr: { data: '1.0%' },
      rf: { data: '1.0%' },
      bit: { data: '1.0%' },
      eqRP: { data: '1.0%' },
      wacc: { data: '1.0%' },
      excessReturns: { data: '1.0%' },
    },
    {
      id: { data: 'f337e369-1d89-474b-a293-97c8c6ae0fe2' },
      sector: { data: 'Resi' },
      'sub-sector': { data: 'Resi' },
      country: { data: 'UK' },
      giy: { data: '1.0%' },
      ninny: { data: '1.0%' },
      growth: { data: '1.0%' },
      devt: { data: '1.0%' },
      portIRR: { data: '1.0%' },
      wacd: { data: '1.0%' },
      ltv: { data: '1.0%' },
      debtBoost: { data: '1.0%' },
      leveredIrr: { data: '1.0%' },
      pdToGav: { data: '1.0%' },
      impIrr: { data: '1.0%' },
      rf: { data: '1.0%' },
      bit: { data: '1.0%' },
      eqRP: { data: '1.0%' },
      wacc: { data: '1.0%' },
      excessReturns: { data: '1.0%' },
    },
    {
      id: { data: '313018a6-6632-473a-9c45-955a5adf3cd3' },
      sector: { data: 'Resi' },
      'sub-sector': { data: 'Resi' },
      country: { data: 'France' },
      giy: { data: '1.0%' },
      ninny: { data: '1.0%' },
      growth: { data: '1.0%' },
      devt: { data: '1.0%' },
      portIRR: { data: '1.0%' },
      wacd: { data: '1.0%' },
      ltv: { data: '1.0%' },
      debtBoost: { data: '1.0%' },
      leveredIrr: { data: '1.0%' },
      pdToGav: { data: '1.0%' },
      impIrr: { data: '1.0%' },
      rf: { data: '1.0%' },
      bit: { data: '1.0%' },
      eqRP: { data: '1.0%' },
      wacc: { data: '1.0%' },
      excessReturns: { data: '1.0%' },
    },
    {
      id: { data: 'af3f9071-cc83-4d4c-b690-51787bf64ae0' },
      sector: { data: 'Retail' },
      'sub-sector': { data: 'Retail' },
      country: { data: 'Germany' },
      giy: { data: '1.0%' },
      ninny: { data: '1.0%' },
      growth: { data: '1.0%' },
      devt: { data: '1.0%' },
      portIRR: { data: '1.0%' },
      wacd: { data: '1.0%' },
      ltv: { data: '1.0%' },
      debtBoost: { data: '1.0%' },
      leveredIrr: { data: '1.0%' },
      pdToGav: { data: '1.0%' },
      impIrr: { data: '1.0%' },
      rf: { data: '1.0%' },
      bit: { data: '1.0%' },
      eqRP: { data: '1.0%' },
      wacc: { data: '1.0%' },
      excessReturns: { data: '1.0%' },
    },
  ],
  total: 0,
};
export const companyTableData: IReitData = {
  sections: [
    {
      sectionTitle: 'Scenario name',
      columnDefs: ['19A', '20A', '21F', '22F', '23F', '24F', '25F', '26F'],
      spacing: true,
    },
    {
      sectionTitle: 'Base',
      columnDefs: ['21F_1', '22F_1', '23F_1', '24F_1', '25F_1', '26F_1'],
    },
  ],
  headers: {
    '19A': { title: '19A', fill: 'purple', sortable: true, type: 'text' },
    '20A': { title: '20A', fill: 'purple', sortable: true, type: 'text' },
    '21F': {
      title: '21F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '22F': {
      title: '22F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '23F': {
      title: '23F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '24F': {
      title: '24F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '25F': {
      title: '25F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '26F': {
      title: '26F',
      fill: 'purpleDisabled',
      sortable: true,
      type: 'text',
    },
    '21F_1': { title: '21F', fill: 'dark', sortable: true, type: 'text' },
    '22F_1': { title: '22F', fill: 'dark', sortable: true, type: 'text' },
    '23F_1': { title: '23F', fill: 'dark', sortable: true, type: 'text' },
    '24F_1': { title: '24F', fill: 'dark', sortable: true, type: 'text' },
    '25F_1': { title: '25F', fill: 'dark', sortable: true, type: 'text' },
    '26F_1': { title: '26F', fill: 'dark', sortable: true, type: 'text' },
  },
  stickLeftColumns: {
    field: { title: 'Field', fill: 'purple', sortable: true, type: 'text' },
  },
  data: [
    {
      field: { data: 'Taxable Income' },
      '19A': { data: '20%' },
      '20A': { data: '20%' },
      '21F': { data: '20%', editable: true },
      '22F': { data: '20%', editable: true },
      '23F': { data: '20%', editable: true },
      '24F': { data: '20%', editable: true },
      '25F': { data: '20%', editable: true },
      '26F': { data: '20%', editable: true },
      '21F_1': { data: '20%' },
      '22F_1': { data: '20%' },
      '23F_1': { data: '20%' },
      '24F_1': { data: '20%' },
      '25F_1': { data: '20%' },
      '26F_1': { data: '20%' },
    },
    {
      field: { data: 'Tax rate' },
      '19A': { data: '20%' },
      '20A': { data: '20%' },
      '21F': { data: '20%', editable: true },
      '22F': { data: '20%', editable: true },
      '23F': { data: '20%', editable: true },
      '24F': { data: '20%', editable: true },
      '25F': { data: '20%', editable: true },
      '26F': { data: '20%', editable: true },
      '21F_1': { data: '20%' },
      '22F_1': { data: '20%' },
      '23F_1': { data: '20%' },
      '24F_1': { data: '20%' },
      '25F_1': { data: '20%' },
      '26F_1': { data: '20%' },
    },
    {
      field: { data: 'Administrative costs' },
      '19A': { data: '20%' },
      '20A': { data: '20%' },
      '21F': { data: '20%', editable: true },
      '22F': { data: '20%', editable: true },
      '23F': { data: '20%', editable: true },
      '24F': { data: '20%', editable: true },
      '25F': { data: '20%', editable: true },
      '26F': { data: '20%', editable: true },
      '21F_1': { data: '20%' },
      '22F_1': { data: '20%' },
      '23F_1': { data: '20%' },
      '24F_1': { data: '20%' },
      '25F_1': { data: '20%' },
      '26F_1': { data: '20%' },
    },
    {
      field: { data: 'Mgmt fee income / payments' },
      '19A': { data: '20%' },
      '20A': { data: '20%' },
      '21F': { data: '20%', editable: true },
      '22F': { data: '20%', editable: true },
      '23F': { data: '20%', editable: true },
      '24F': { data: '20%', editable: true },
      '25F': { data: '20%', editable: true },
      '26F': { data: '20%', editable: true },
      '21F_1': { data: '20%' },
      '22F_1': { data: '20%' },
      '23F_1': { data: '20%' },
      '24F_1': { data: '20%' },
      '25F_1': { data: '20%' },
      '26F_1': { data: '20%' },
    },
    {
      field: { data: 'Other' },
      '19A': { data: '20%' },
      '20A': { data: '20%' },
      '21F': { data: '20%', editable: true },
      '22F': { data: '20%', editable: true },
      '23F': { data: '20%', editable: true },
      '24F': { data: '20%', editable: true },
      '25F': { data: '20%', editable: true },
      '26F': { data: '20%', editable: true },
      '21F_1': { data: '20%' },
      '22F_1': { data: '20%' },
      '23F_1': { data: '20%' },
      '24F_1': { data: '20%' },
      '25F_1': { data: '20%' },
      '26F_1': { data: '20%' },
    },
    {
      field: { data: 'Debt issuance / (redemption)' },
      '19A': { data: '20%' },
      '20A': { data: '20%' },
      '21F': { data: '20%' },
      '22F': { data: '20%' },
      '23F': { data: '20%' },
      '24F': { data: '20%' },
      '25F': { data: '20%' },
      '26F': { data: '20%' },
      '21F_1': { data: '20%' },
      '22F_1': { data: '20%' },
      '23F_1': { data: '20%' },
      '24F_1': { data: '20%' },
      '25F_1': { data: '20%' },
      '26F_1': { data: '20%' },
    },
    {
      field: { data: 'Debt issuance / (redemption) Cumulative' },
      '19A': { data: '20%' },
      '20A': { data: '20%' },
      '21F': { data: '20%' },
      '22F': { data: '20%' },
      '23F': { data: '20%' },
      '24F': { data: '20%' },
      '25F': { data: '20%' },
      '26F': { data: '20%' },
      '21F_1': { data: '20%' },
      '22F_1': { data: '20%' },
      '23F_1': { data: '20%' },
      '24F_1': { data: '20%' },
      '25F_1': { data: '20%' },
      '26F_1': { data: '20%' },
    },
    {
      field: { data: 'Debt change timing' },
      '19A': { data: '20%' },
      '20A': { data: '20%' },
      '21F': { data: '20%' },
      '22F': { data: '20%' },
      '23F': { data: '20%' },
      '24F': { data: '20%' },
      '25F': { data: '20%' },
      '26F': { data: '20%' },
      '21F_1': { data: '20%' },
      '22F_1': { data: '20%' },
      '23F_1': { data: '20%' },
      '24F_1': { data: '20%' },
      '25F_1': { data: '20%' },
      '26F_1': { data: '20%' },
    },
    {
      field: { data: 'Yield' },
      '19A': { data: '20%' },
      '20A': { data: '20%' },
      '21F': { data: '20%' },
      '22F': { data: '20%' },
      '23F': { data: '20%' },
      '24F': { data: '20%' },
      '25F': { data: '20%' },
      '26F': { data: '20%' },
      '21F_1': { data: '20%' },
      '22F_1': { data: '20%' },
      '23F_1': { data: '20%' },
      '24F_1': { data: '20%' },
      '25F_1': { data: '20%' },
      '26F_1': { data: '20%' },
    },
  ],
  total: 0,
};

export const financialIncomeStatement: {
  data: { [key: string]: { tooltip: string } };
} = {
  data: {
    'Revenue / GRI': {
      tooltip:
        'Revenue / Gross Rental Income: total rental income and associated revenue from Real Estate investments',
    },
    'Direct Costs': {
      tooltip:
        'Direct Costs: total costs directly associated with owning and operating real estate, excluding any corporate-level and admin costs',
    },
    'Provisions / Other': {
      tooltip:
        'Provisions / Other: other costs or revenue at the property level, particularly provisions made for bad debts (i.e. revenue that has to be set aside to account for the long-run inability of some tenants to pay)',
    },
    'LFL NRI / SSNOI': {
      tooltip:
        'Like-for-Like Net Rental Income / Same-Store Net Operating Income: the income from properties, minus direct costs, forecast on a like-for-like basis, ignoring external growth (developments, acquisitions, disposals)',
    },
    'Development NRI / NOI': {
      tooltip:
        'Development Net Rental Income: net rental / operating income from development pipeline',
    },
    'Acquisition NRI / NOI': {
      tooltip:
        'Acquisition Net Rental Income: net rental / operating income from future acquisitions',
    },
    'Disposal NRI / NOI': {
      tooltip:
        'Disposal Net Rental Income: net rental / operating income from future disposals',
    },
    'Net Rental / Operating Income': {
      tooltip:
        'Net Rental/Operating Income: total net rent (property-level revenues subtract costs) including forecast future external growth',
    },
    'Administrative Costs': {
      tooltip:
        'Administrative Costs: Corporate-level costs that cannot be associated with specific properties, including employees, offices etc. but not finance costs',
    },
    'Mgmt Fee Income / Payments': {
      tooltip:
        'Management Fee income / payments: fees received from, or paid to, external companies for property management',
    },
    'Other G&A': {
      tooltip:
        'Other General & Admin: Other, often non-recurring, expenses on a corporate level but not classed as administrative costs',
    },
    'Trading Profit': {
      tooltip:
        'Trading Profit: overall profit at a company level, before any capital items (revaluations, profit on disposal) or finance costs. Approximately equivalent to EBITDA',
    },
    'Revaluation (D/S)': {
      tooltip:
        'Revaluation (Deficit/Surplus): under IFRS only, the profit or loss recognised on changes in the fair value of property',
    },
    'Profit on Disposal': {
      tooltip:
        'Profit on Disposal: the profit recognised on selling real estate assets above fair value (IFRS) or cost - depreciation (US GAAP)',
    },
    'Other Capital Items': {
      tooltip:
        'Other Capital Items: other changes in the value (or profits recognised on such changes) that are not associated with any revenues or cash flows in the traditional sense',
    },
    'Profit From Operations': {
      tooltip:
        'Profit From Operations: total profit from operations, before financing costs or tax, but including capital items',
    },
    'Net Finance Costs': {
      tooltip:
        'Net Finance Costs: total finance income, subtract finance costs. Largely payments on interest-bearing debt (including dividends paid to preferred shareholders), but not including any principal payments or repayments',
    },
    Derivatives: {
      tooltip:
        'Derivatives: profit or loss associated with derivatives in the period. ',
    },
    'Other Financing Items': {
      tooltip:
        'Other Financing Items: any other items related to financing, often non-recurring costs (like loan arrangement fees) that are recognised through the income statement',
    },
    'Share of JV': {
      tooltip:
        'Share of Joint Ventures: the share of JV profit or loss recognised (likely to be different to the cash received from JVs)',
    },
    'P&L (Pre-tax)': {
      tooltip:
        'Profit & Loss (Pre-tax): the total profit or loss, before accounting for any taxes due',
    },
    Tax: {
      tooltip:
        'Tax: the total tax payable on a corporate level (corporation or corporate income tax, not any property-level taxes, which will have been accounted for under direct costs)',
    },
    'P&L (Post-tax)': {
      tooltip:
        'Profit & Loss (Post-tax): the total profit or loss, after accounting for taxes',
    },
    'Less Group Capital Items': {
      tooltip:
        'Less Group Capital Items: an adjustment to remove the impact of volatile capital (non-cash) items at group level (including revaluations and profits on disposal, among others)',
    },
    'Less JV/Associates Capital Items': {
      tooltip:
        'Less Joint Venture/Associates Capital Items: an adjustment to remove the impact of volatile capital (non-cash) items associated with JVs or associates',
    },
    'Other Adjustments': {
      tooltip:
        'Other Adjustments: any other adjustments made to arrive at a EPRA earnings/FFO',
    },
    'Total Adjustments': {
      tooltip:
        'Total adjustments: total adjustments from P&L to EPRA P&L / FFO',
    },
    'EPRA P&L / FFO': {
      tooltip:
        'EPRA (European Public Real Estate Association) Profit & Loss / Funds From Operations: the metric that is as close as possible to underlying, repeatable earnings, before accounting for a capex reserve, adjusted by Kolytics for consistency between definitions across geographies',
    },
    Dividends: {
      tooltip:
        "Dividends: total dividends paid with regard to this financial year's earnings (regardless of whether the payment date is before or after the financial year-end)",
    },
    'Average Number of Shares (EPS)': {
      tooltip:
        'Average Number of Shares (Earnings Per Share): the weighted average number of shares (fully diluted, so accounting for any options or convertibles that could be converted to shares) in issue during the year, used as the denominator for calculating earnings per share',
    },
    'EPRA EPS / FFO Per Share': {
      tooltip:
        'EPRA (European Public Real Estate Association) Earnings Per Share / Funds From Operations Per Share: the total EPRA P&L / FFO divided by the weighted average number of shares in issue - the amount of earnings attributable to each share of common stock',
    },
    'Capex %': {
      tooltip:
        'Capital Expenditure %: the percentage of NRI estimated to be required to put in reserve to account for capital expenditure required at end-of-lease to bring real estate back up to market standard',
    },
    'AFFO / Share': {
      tooltip:
        'Adjusted Funds From Operations / Share: EPRA EPS/FFO per share with the capex reserve removed',
    },
    'AFFO / DPS': {
      tooltip:
        'Adjusted Funds From Operations Per Share / Dividend Per Share: The ratio between AFFO and dividends paid out - a measure of how well-covered the dividend is, and how much cash the company retains, rather than pays out',
    },
    DPS: {
      tooltip:
        "Dividend Per Share: the common dividend paid out to each common stock with regard to this financial year's earnings (regardless of whether the payment date is before or after the financial year-end)",
    },
  },
};

export const financialBalanceSheet: {
  data: { [key: string]: { tooltip: string } };
} = {
  data: {
    'Investment Property': {
      tooltip:
        'Investment Property: property owned as an investment (not intended to be sold within 12 months). US companies do not mark-to-market values under US GAAP; therefore, this is estimated by Kolytics. Future values are forecast on a like-for-like basis based on expected rental growth and yield changes.',
    },
    'External Growth': {
      tooltip:
        'External Growth: the forecast value of real estate developed and acquired, subtract the value of real estate sold, for future years',
    },
    PPE: {
      tooltip:
        "Plant, Property, and Equipment: a company's tangible assets used by the company that do not generate revenue",
    },
    'Other Non-CA': {
      tooltip:
        'Other non-current assets: any other assets with a lifetime of over 12 months',
    },
    'Non-Current Assets': {
      tooltip:
        'Non-current Assets: The total assets that are not expected to be converted to cash within a year',
    },
    'Trading Property': {
      tooltip:
        'Trading Property: the book value of real estate that is intended to be sold within 12 months',
    },
    'Trade and Other Receivables': {
      tooltip:
        'Trade and Other Receivables: revenue that has been accrued (the transaction has occurred) but where the cash has not yet been received, or similar short-term assets',
    },
    'Cash and Cash Equivalents': {
      tooltip:
        'Cash and Cash Equivalents: assets that are cash or can be converted to cash immediately, e.g. marketable securities - debt securities with maturities less than 90 days',
    },
    'Current Assets': {
      tooltip:
        'Current Assets: total short term-assets - cash or convertible to cash within a year',
    },
    'Total Assets': {
      tooltip: 'Total Assets: the total assets owned by the company',
    },
    'Current Borrowings': {
      tooltip:
        'Current Borrowings: borrowings with a maturity date in less than one year',
    },
    'Trade and Other Payables': {
      tooltip:
        'Trade and Other Payables: costs that have been accrued (the transaction has occurred) but where the cash has not yet been paid, or similar short-term liabilities',
    },
    'Other CL': {
      tooltip:
        'Other Current Liabilities: any other liabilities that mature or are expected to be paid within 12 months',
    },
    'Current Liabilities': {
      tooltip:
        'Current Liabilities: total liabilities that mature or are expected to be paid within 12 months',
    },
    Borrowings: {
      tooltip:
        'Borrowings: interest-bearing loans with maturities longer than one year',
    },
    'Leasehold Liabilities': {
      tooltip:
        'Leasehold Liabilities: financial obligations to make payments for leases, discounted to present value',
    },
    'Other Non-CL': {
      tooltip:
        'Other Non-Current Liabilities: any other liabilities or obligations not due within 12 months',
    },
    'Non-Current Liabilities': {
      tooltip:
        'Non-Current Liabilities: total liabilities and obligations that do not need to be repaid within 12 months',
    },
    'Total Liabilities': {
      tooltip:
        'Total Liabilities: total liabilities, debts, and obligations owed by the company',
    },
    'Net Assets': {
      tooltip:
        "Net Assets: total assets subtract total liabilities - the 'total value' attributed to shareholders",
    },
    'Revaluation of Trading Properties': {
      tooltip:
        'Revaluation of Trading Properties: an adjustment under EPRA to include any movements in fair value of trading properties (typically held at cost under IFRS)',
    },
    'FV in Financial Instruments / Bonds': {
      tooltip:
        'teFair Value in Financial Instruments / Bonds: an adjustment to remove the fair value of any financial instruments or bonds that the company intends to hold to maturity, as these fluctuations will all cancel out on settlementst',
    },
    'Other BS Adj': {
      tooltip:
        "Other Balance Sheet Adjustments: any other adjustments required to reach EPRA NTA or a similar measure, intending to capture the total value of a company's net assets, as they are relevant to an investor",
    },
    'Net Tangible Assets': {
      tooltip:
        'Net Tangible Assets: the total net assets attributable to a company, including fair value of all property but excluding financial instruments, deferred tax, and intangible assets',
    },
    Shares: {
      tooltip:
        'Shares: number of shares in issuance at financial year-end, fully diluted (taking into account any options/convertible bonds that could be converted into shares)',
    },
    'NTA / Share': {
      tooltip:
        'Net Tangible Assets / Share: the total tangible asset value attributed to each common stock',
    },
    'Proportional LTV': {
      tooltip:
        'Proportional Loan to Value: the total interest-bearing debt (subtract any cash) as a proportion of all investment and trading property',
    },
    'Overall LTV': {
      tooltip:
        'Overall Loan to Value: the total liabilities divided by the total assets',
    },
  },
};

export const financialCashflow: {
  data: { [key: string]: { tooltip: string } };
} = {
  data: {
    'Rents Received': {
      tooltip:
        'Rents Received: cash received as rental payments on owned real estate',
    },
    'Property Expenses': {
      tooltip:
        'Property Expenses: expenses paid due to costs incurred at a property level',
    },
    'Employee Related': {
      tooltip:
        'Employee Related: employee-related expenses, largely salaries, at a corporate (rather than direct property) level',
    },
    'Other Admin': { tooltip: 'Other Admin: other administrative costs' },
    'Net Finance Interest': {
      tooltip:
        'Net Finance Interest: total cash paid as interest on debts (including dividends on preferred shares), subtract cash interest received on interest-paying assets',
    },
    'Other Finance': {
      tooltip:
        'Other Finance: other finance costs/gains (e.g. Loan arrangement fees etc.)',
    },
    'JV and Associate Distributions': {
      tooltip:
        'Joint Venture and Associate Distributions: cash received as payments from JVs or Associates as distributions of profits',
    },
    Tax: {
      tooltip:
        'Tax: any tax paid on a corporate level (corporation or corporate income tax)',
    },
    'Other OA': {
      tooltip:
        'Other Operating Activity: any other cash flows or adjustments related to operating activity',
    },
    'Net Cash from Operating Activities': {
      tooltip:
        'Net Cash from Operating Activities: total net cash received through normal business operations',
    },
    'Acquisition of Properties': {
      tooltip:
        'Acquisition of Properties: cash spent on acquiring new investment real estate',
    },
    'Development and Major Refurbishments': {
      tooltip:
        'Development and Major Refurbishments: cash spent developing new properties, or doing major work on old properties (outside of the usual capital expenditure required to keep up with the market standard)',
    },
    'CAPEX on Portfolio': {
      tooltip:
        'Capital Expenditure on Portfolio: cash used in the usual course of business to refit or update properties to keep up with the market standard',
    },
    'Disposal of Properties': {
      tooltip:
        'Disposal of Properties: cash received in sales of investment or trading properties',
    },
    'JV and Associate (Acquisition) / Disposal': {
      tooltip:
        'Joint Venture and Associate (Acquisition) / Disposal: cash received (or spent) on selling (or acquiring) JVs, associates, or stakes thereof',
    },
    'Other IA': {
      tooltip:
        'Other Investing Activity: any other cash flows or adjustments related to investment activity',
    },
    'Net Cash from Investing Activities': {
      tooltip:
        'Net Cash from Investing Activities: total cash received (subtract cash spent) on investing activities',
    },
    'New Issuances Equity': {
      tooltip:
        'New Issuances Equity: proceeds from the issuance of common shares',
    },
    'New Issuances Debt': {
      tooltip: 'New Issuances Debt: proceeds from the issuance of new debt',
    },
    'Drawdowns of Facilities Increase in Cash and Equivalents (in Period)': {
      tooltip:
        'Drawdowns of facilities: proceeds from withdrawing from an existing agreed-upon credit facility',
    },
    'Redemptions and Repayments': {
      tooltip:
        'Redemptions and Repayments: cash spent redeeming debts, or repaying creditors',
    },
    Dividends: {
      tooltip:
        'Dividends: cash paid in dividends to common (not preferred) shareholders',
    },
    'Special Payments/Buy Backs': {
      tooltip:
        'Special Payments/Buy Backs: cash paid out via special dividends to shareholders, or by buying back common stock from the market',
    },
    'Other FA': {
      tooltip:
        'Other Financing Activity: any other cash flows or adjustments related to financing',
    },
    'Net Cash from Financing Activities': {
      tooltip:
        'Net Cash from Financing Activities: total net cash spent on financing activities - those related to how the company is funded',
    },
    'Increase in Cash and Equivalents (in Period)': {
      tooltip:
        'Increase in Cash and Equivalents (in Period): the total cash flows in and out of the company for the year',
    },
    'Cash and Equivalents (at Start)': {
      tooltip:
        'Cash and Equivalents (at Start): the cash held by the company at the start of the year',
    },
    'Other Changes': {
      tooltip:
        'Other Changes: any other changes to the cash balance, outside of cash flows in and out (usually due to currency fluctuations)',
    },
    'Cash and Equivalents (at Close)': {
      tooltip:
        'Cash and Equivalents (at Close): the total cash on the balance sheet at the end of the period',
    },
  },
};
