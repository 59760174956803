import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PreviewService } from '../services/preview.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ForwardHeadersInterceptor implements HttpInterceptor {
  headers: { [key: string]: string } = {
    'X-Draft': 'false',
    'X-Public': 'true',
  };
  constructor(
    private activatedRoute: ActivatedRoute,
    private previewService: PreviewService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const url = location.pathname;
    const isPreview = this.previewService.isPreview(url);
    const isPreviewQuery = this.activatedRoute.snapshot.queryParams['preview'];

    this.headers['X-Draft'] = isPreview || isPreviewQuery ? 'true' : 'false';
    this.headers['X-Public'] = isPreview || isPreviewQuery ? 'false' : 'true';

    req = req.clone({
      setHeaders: this.headers,
    });
    return next.handle(req);
  }
}
