<div class="overview-reit-update">
  <klt-section
    title="Sector updates"
    type="pagination"
    (paginate)="pageActive = $event; pagination()"
    [length]="reits.length"
    >
    <div class="reit-box">
      @for (reit of paginateReits; track reit) {
        <div class="reit-item">
          @if (reit.reitName != null) {
            <div class="date">
              {{ reit.reitName }} - {{ reit.date }}
            </div>
          }
          @if (reit.reitName == null) {
            <div class="date">
              {{ reit.date }}
            </div>
          }
          <div class="title">
            {{ reit.title }}
          </div>
          <div class="description" [kltAsHtml]="reit.description"></div>
          @if (reit.showMore) {
            <klt-link-action
              text="Read more"
              [iconRight]="'down'"
            ></klt-link-action>
          }
        </div>
      }
    </div>
  </klt-section>
</div>
