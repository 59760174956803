<div class="wrapper--checkbox" (click)="handleClickItem()">
  <div
    class="checkbox large"
    [class.checked]="checked"
    [class.disabled]="
      selectedScenarioId &&
      selectedScenarioId !== assumptionProfileItem.scenarioId
    "
  >
    <div class="image-wrapper">
      <img
        [src]="
          '/assets/images/assumptions/' + assumptionProfileItem.image + '.png'
        "
      />
    </div>
    <div class="content">
      <span class="label">{{ assumptionProfileItem.title }}</span>
      <span class="description">{{ assumptionProfileItem.description }}</span>
    </div>
    <div class="tick-box" [class.checked]="checked">
      <klt-icon
        class="icon"
        [size]="'large'"
        icon="tick"
        [color]="'light'"
      ></klt-icon>
    </div>
  </div>
</div>
