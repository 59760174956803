<div class="overview-company">
  <klt-section
    title="Company background"
    type="button"
    buttonIconRight="pop-out"
    buttonText="VIEW WEBSITE"
    [showCta]="showCta"
    (buttonClick)="goToWebsite()"
  >
    <div class="company-box">
      <div class="company-zone" [innerHTML]="companyBackground"></div>
    </div>
  </klt-section>
</div>
<!-- -->

<!-- Start Replace -->
<!-- <div class='company-history' *ngFor='let history of histories; let i = index'>
          <div class='company-title'>
            {{history.title}}
          </div>
          <ul class='item-{{i + 1}}'>
            <li *ngFor='let description of history.descriptions'>{{description}}</li>
          </ul>
        </div> -->
<!-- End Replace -->
